import React, { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import style from "./BannerNew.module.css";
import { Paper } from "@mui/material";
import "./Banner.css";
import { useHistory } from "react-router-dom";
const BannerNew = (productpage) => {
  const history = useHistory();
  const [bannerlist, setBannerlist] = useState([]);
  const [homebannerlist, setHomeBannerlist] = useState([]);
  const getBannerlist = () => {
    api.getBannerlist().then((res) => {
      if (res && res.data) {
        setBannerlist(res.data);
        const homeBanner = res.data.filter(
          (index) => index.page === "home" && index.display === 1
        );
        setHomeBannerlist(homeBanner);
      }
    });
  };
  useEffect(() => {
    getBannerlist();
  }, []);
  const settings = {
    indicators: true,
    navButtonsAlwaysVisible: true,
  };
  return productpage.productpage == true ? (
    bannerlist &&
      bannerlist.map((item, i) => {
        if (item.page === "product" && item.display === 1) {
          return (
            <>
              <div className="productBanner" style={{margin:'2% 0%'}}>
                <img src={item.file.filePath} />
              </div>
            </>
            // <div
            //   className={style["row-container2"]}
            //   style={{
            //     backgroundImage: `url(${item.file.filePath})`,
            //     margin: "2% 0%",
            //     backgroundSize: "100%",
            //   }}
            //   key={i}
            //   //    onClick={()=>history.push(item.link)}
            // >
            //   <div className={style["info1"]}>
            //     <div className={style["name"]}>{item.subtitle}</div>
            //     <div className={style["title"]}>{item.title}</div>
            //     <div className={style["description"]}>{item.description}</div>
            //   </div>
            // </div>
          );
        } else {
          <div></div>;
        }
      })
  ) : homebannerlist && homebannerlist.length === 1 ? (
    homebannerlist &&
    homebannerlist.map((item, i) => {
      return (
        <div
          className={style["row-container1"]}
          style={{ backgroundImage: `url(${item.file.filePath})` }}
          onClick={() => item.link && history.push(item.link)}
          key={i}
        >
          <div
            className={style["info1"]}
            style={item.link === " " ? { background: "red" } : null}
          >
            <div className={style["name"]}>{item.subtitle}</div>
            <div className={style["title"]}>{item.title}</div>
            <div className={style["description"]}>{item.description}</div>
          </div>
        </div>
      );
    })
  ) : (
    <Carousel
      {...settings}
      className="banner"
      // navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
      //     style: {
      //         backgroundColor: "transparent",
      //         borderRadius: 0,

      //         margin: "0 100px",
      //         "&>svg": {
      //             fontSize: "50px"
      //         }
      //     }

      // }}
    >
      {homebannerlist &&
        homebannerlist.map((item, i) => <Item key={i} item={item} />)}
    </Carousel>
  );
};

function Item(props) {
  // const history = useHistory();
  // // console.log(props.item.link, "Hello World");
  // return (
  //   <div
  //     className={style["row-container1"]}
  //     style={{
  //       backgroundImage: `url(${props.item.file.filePath})`,
  //       cursor: props.item.link ? `pointer` : ``,
  //     }}
  //     onClick={() => props.item.link && history.push(props.item.link)}
  //   >
  //     <div className={style["info1"]}>
  //       <div className={style["name"]}>{props.item.subtitle}</div>
  //       <div className={style["title"]}>{props.item.title}</div>
  //       <div className={style["description"]}>{props.item.description}</div>
  //     </div>
  //   </div>
  // );

  const history = useHistory();
  return (
    <div>
      {props.item.link != null ? (
        <div
          className={style["row-container1"]}
          style={{
            backgroundImage: `url(${props.item.file.filePath})`,
            cursor: "pointer",
          }}
          onClick={() => props.item.link && history.push(props.item.link)}
        >
          <div className={style["info1"]}>
            <div className={style["name"]}>{props.item.subtitle}</div>
            <div className={style["title"]}>{props.item.title}</div>
            <div className={style["description"]}>{props.item.description}</div>
          </div>
        </div>
      ) : (
        <div
          className={style["row-container1"]}
          style={{
            backgroundImage: `url(${props.item.file.filePath})`,
            cursor: "default",
          }}
        >
          <div className={style["info1"]}>
            <div className={style["name"]}>{props.item.subtitle}</div>
            <div className={style["title"]}>{props.item.title}</div>
            <div className={style["description"]}>{props.item.description}</div>
          </div>
        </div>
      )}
    </div>
  );
}
export default BannerNew;
