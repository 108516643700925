import React, { Component } from 'react'
import { Modal, Box, TextareaAutosize, Button, Select, MenuItem } from '@mui/material';
import changebackgroundImg from "../SelfPublish/menuIcons/background.svg";
import BackgroundModal from './BackgroundModal/BackgroundModal';
import BookPreview from './BookPreview/BookPreview';
import Custombookpreview from '../CustomCharector/Custombookpreview';
import HandprintView from '../HandPrint/HandprintView';

export class RightToolbar extends Component {

  constructor(props) {
    super(props);
    initialConstruct.bind(this)('RightToolbar');
    this.backgroundColorRef = React.createRef();
    this.backgroundImageRef = React.createRef();
    this.backgroundSplitRef = React.createRef();
  }

  changeBackground(index) {
    let self = this;
    let { pages, applyBackground } = this.props;
    let indexObj = pages[index]?.backgroundObj||{};
    const setBackground = (background, isUrl = false, splitMode = false) => {
      applyBackground({
        backgroundImage: '',
        backgroundColor: '',
        [isUrl ? 'backgroundImage' : 'backgroundColor']: background,
        splitMode
      });
    };
    let dialog = confirm(() => <BackgroundModal
      colorRef={this.backgroundColorRef}
      imageRef={this.backgroundImageRef}
      defaultImage={indexObj.backgroundImage}
      defaultColor={indexObj.backgroundColor || "#ffffff"}
      splitRef={this.backgroundSplitRef}
    />, "Change Background", {
      ok: "Apply",
      class: "BackgroundModal",
      parentclass: "BackgroundModal"
    });
    dialog.promise.then(() => {
      if (self.backgroundImageRef.current.files.length) {
        let splitMode = self.backgroundSplitRef.current.checked;
        self.backgroundImageRef.current.files[0].toBase64().then((src) => {
          // console.log({setBackground:splitMode,splitMode});
          setBackground(`url(${src})`, true, splitMode);
        });
      } else {
        setBackground(self.backgroundColorRef.current.value);
      }
    });
  }

  previewBook() {
    let me = this;
    let { onPreview,pages,getPayload,bookDetails,message,onUpdate,saveBook,closablePreview,params,onPreviewClose } = this.props;
    if(this.props.params.name == 'customcharacter-book' ){
      let modal = alert(
        (props) => (
          
          <Custombookpreview 
            alert={props} 
            pages={pages} 
            getPayload={getPayload} 
            bookDetails={bookDetails} 
            message={message} 
            onUpdate={onUpdate} 
            saveBook={saveBook} 
            closablePreview={closablePreview}
            onPreviewClose={onPreviewClose}
            bookname={params.name}
          />
        ),
        "",
        "model",
        {
          footer: false,
          class: "BookPreview characterView col-12 col-md-10 col-lg-10 col-xl-8",
          parentclass: params.name+" BookPreview row",
          options: {},
        }
      );
    }
    else if(this.props.params.name == 'handprint-book' ){
      let modal = alert(
        (props) => (
          
          <HandprintView 
            alert={props} 
            pages={pages} 
            getPayload={getPayload} 
            bookDetails={bookDetails} 
            message={message} 
            onUpdate={onUpdate} 
            saveBook={saveBook} 
            closablePreview={closablePreview}
            onPreviewClose={onPreviewClose}
            bookname={params.name}
          />
        ),
        "",
        "model",
        {
          footer: false,
          class: "BookPreview characterView handprintView col-12 col-md-10 col-lg-10 col-xl-8",
          parentclass: params.name+" BookPreview row",
          options: {},
        }
      );
    }
    else{
      let modal = alert(
        (props) => (
          <BookPreview 
            alert={props} 
            pages={pages} 
            getPayload={getPayload} 
            bookDetails={bookDetails} 
            message={message} 
            onUpdate={onUpdate} 
            saveBook={saveBook} 
            closablePreview={closablePreview}
            onPreviewClose={onPreviewClose}
            bookname={params.name}
          />
        ),
        "",
        "model",
        {
          footer: false,
          class: "BookPreview col-12 col-md-10 col-lg-10 col-xl-8",
          parentclass: params.name+" BookPreview row",
          options: {},
        }
      );
    }
    
  }

  render() {
    let { saveBook, onPreview, currentIndex, children = () => (""), hasBtns={},bookDetails={}, closablePreview=true } = this.props;
    let { editbackground=true,needsave=true,needpreview=true } = hasBtns;
    let Extras = children || (() => (""));
    return (
      <div className='row no-gutters'>
        <div className='toolbar' style={{marginLeft:'auto'}}>
          {
            editbackground && <Button
              className='changeBG'
              variant="outlined"
              onClick={() => this.changeBackground(currentIndex)}
              title="Change Background">
              <img src={changebackgroundImg} />
              <small>Background</small>
            </Button>
          }
          {
            needsave && <Button className="save"
              variant="outlined"
              onClick={() => saveBook()} >
              <i className='fa fa-save'></i>
              <small>Save</small>
            </Button>
          }
          {
            needpreview && <Button className="save"
              variant="outlined"
              id="previewBook"
              onClick={() => this.previewBook()} >
              <i className="fas fa-eye"></i>
              <small>Preview</small>
            </Button>
          }
          { needsave && <Extras />}
        </div>
      </div>
    )
  }
}

export default RightToolbar