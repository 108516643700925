import React, { useEffect, useRef, useState } from "react";
import { PageFlip } from "page-flip";
import Page from "./Page/Page";
import "./Booklet.css";
import { useSelector } from "react-redux";

function Booklet(props) {
  const {
    sizes = {
      desk: 750,
      tv: 750,
      tab: 750,
      mob: 750,
    },
    pageWidth = 492,
    pageHeight = null
  } = props;
  const device = useSelector((state) => state.device);
  const [pageFlip, setpageFlip] = useState(null);
  const [pages, setPages] = useState(
    props.pages.map((obj, i) => {
      return props.onPage
        ? props.onPage(obj, i, props.pages)
        : (() => ({
            title: "Page 1",
            content: "Conetent 1",
            style: { backgroundColor: "pink" },
          }))();
    })
  );
  useEffect(() => {
    setPages(
      props.pages.map((obj, i) => {
        return props.onPage
          ? props.onPage(obj, i, props.pages)
          : (() => ({
              title: "Page 1",
              content: "Conetent 1",
              style: { backgroundColor: "pink" },
            }))();
      })
    );
  }, [props.svg, props.pages]);

  const setupBook = (id) => {
    //console.log({setupBook:{width:Math.ceil(pageWidth),height:Math.ceil(pageHeight)}})
    let pageflip = new PageFlip(document.getElementById(id), {
      // options here
      width: Math.ceil(pageWidth), // base page width
      height: Math.ceil(pageHeight), // base page height
      //size: "stretch",
      // set threshold values:
      minWidth: Math.ceil(pageWidth),
      //maxWidth: 1000,
      minHeight: Math.ceil(pageHeight),
      //maxHeight: 1350,
      maxShadowOpacity: 1, // Half shadow intensity
      showCover: true,
      mobileScrollSupport: true, // disable content scrolling on mobile devices,,
      disableFlipByClick: false,
      swipeDistance: 100,
    });
    setpageFlip(pageflip);
    pageflip.loadFromHTML(document.querySelectorAll(".page"));
    setTimeout(()=>{
      props?.onSetup(pageflip);
    });
  };

  useEffect(() => {
    setupBook("booklet");
  }, []);

  const pageRefs = props.pageRefs || pages.map((v) => useRef(null));

  return (
    <div id="booklet" className="Booklet flip-book">
      {pages.map((o, i) => (
        <Page
          ref={pageRefs[i] || null}
          className={
            i == 0 || i == pages.length - 1
              ? "page-cover " +
                (i == 0 ? "page-cover-top" : "page-cover-bottom")
              : ""
          }
          {...o}
          key={i}
        />
      ))}
    </div>
  );
}

export default Booklet;
