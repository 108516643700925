import Modal from "@material-ui/core/Modal";
import lock from "../../assets/images/lock.png";
import email from "../../assets/images/email.png";
import SignInForm from "./SignInForm";
import ForgetPasswordForm from "./ForgetPasswordForm";
import LoginForm from "./LoginForm";
import VerifyOtpForm from "./VerifyOtpForm";
import PasswordResetForm from "./PasswordResetForm";

export default function (props, store, state) {
	
	const closemodel =()=>{
		props.model.close();
	}
	return (
		<>
		<i onClick={(e)=>closemodel()} className="popup-close fa fa-times"></i>
			{state.form == "login" && (
				<LoginForm
					validate={(...arg) => this.validate(...arg)}
					submit={(...arg) => this.onLogin(...arg)}
					setState={(...arg) => this.setState(...arg)}
					store={this.state}
					formref={this.loginref}
				></LoginForm>
			)}
			{state.form == "signup" && (
				<SignInForm
					validate={(...arg) => this.validate(...arg)}
					submit={(...arg) => this.onSignup(...arg)}
					setState={(...arg) => this.setState(...arg)}
					store={this.state}
					formref={this.signupref}
				></SignInForm>
			)}
			{state.form == "forgetpassword" && (
				<ForgetPasswordForm
					validate={(...arg) => this.validate(...arg)}
					submit={(...arg) => this.onForgetPassword(...arg)}
					setState={(...arg) => this.setState(...arg)}
					store={this.state}
					formref={this.forgetpasswordref}
				></ForgetPasswordForm>
			)}
			{state.form == "otp-verify" && (
				<VerifyOtpForm
					validate={(...arg) => this.validate(...arg)}
					submit={(...arg) => this.onVerifyOtp(...arg)}
					setState={(...arg) => this.setState(...arg)}
					store={this.state}
					formref={this.verifyotpref}
				></VerifyOtpForm>
			)}
			{state.form == "new-password" && (
				<PasswordResetForm
					validate={(...arg) => this.validate(...arg)}
					submit={(...arg) => this.onPasswordReset(...arg)}
					setState={(...arg) => this.setState(...arg)}
					store={this.state}
					formref={this.passwordresetref}
				></PasswordResetForm>
			)}
		</>
	);
}
