import { Fragment, useEffect, useState, useRef } from "react";
import "../chapter.css";
const pagefive = (props) => {
    let pronoun = "";
    let his="";
    if(props.currentGender=='boy'){
        pronoun ="he";
        his="his";
    }
    else{
        pronoun ="she";
        his="her";
    } 
    return (
        <Fragment >
            <div className="pageformat height">
                <div className="text-center chapter-title">
                    {/* <h1>Chapter 4</h1> */}
                </div>
                <div className="text-center chapter-content">
                    <p>"So where are we going now?" Gabby asked. "Did you hear something again?"</p>
                    <p>"No, no. I just saw two children downstairs. It looked like they were running after something small. I just wanted to see what they were doing," Ellie explained.</p>
                    <p>The lift door opened and Snowy darted out, pulling Ellie along. He scampered up to where the two children were standing and stopped a short distance away, just to be polite. He badly wanted to sniff them but knew that it would be rude, so he sat and looked at Ellie and Gabby.</p>
                    <p>Ellie and Gabby stared at the two children. They looked to be about Ellie's age or slightly older. One was wearing a blue T-shirt and the other, a collared T-shirt and blue shorts. They looked worried. </p>
                    <p>"What?" the boy in the blue T-shirt said. He looked more worried than angry. The {props.currentGender} who was standing just behind him looked a little friendlier. "Hi!" {pronoun} said.</p>
                </div>
                <div className="page-number">
                    <span className="digite-number">5</span>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(pagefive);