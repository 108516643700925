import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from "react-router-dom";
import "./subscrptionPlan.css";
import close from "../../../assets/images/close.png"
import api from "../../../app-services/API";

const SubscriptionPlan = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const { handleSubmit } = useForm({ mode: 'onBlur' });
    const { alert } = props;
    const [plans, setPlans] = useState(null)
    useEffect(() => {
        getSubscriptionList();
    }, []);
    const getSubscriptionList = () => {
        api.getSubscriptionPlanList().then((res) => {
            setPlans(res.data);
        })

    }
    const onClose = () => {
        alert.close();
    }
    const oncreateSubmit = (item) => {
        //console.log("item", item);
        mixins.setStore("subscriptionPlan", item.price, props)
        Modal.state.list.find((o) => o.type == "model")?.close();
        history.push("/subscriptionpayment")
    }

    return (
        <div className="subscriptionplancontainer">
            <div className="close" onClick={onClose}><img src={close} style={{ "width": "25px" }} /></div>
            <div className="subscriptiontitle">Subscription Offers</div>
            <div className="plans">
                {plans && plans.map((item, key) => {
                    // if (item.type.toLowerCase() === "monthly")
                    return (
                        <>
                            <div className="plancontent" key={key}>
                                <div className="plansubtitle">
                                    {item.description}
                                </div>
                                <div className="planprice">
                                    $ {item.price} SGD
                                </div>
                                <div className="planreminder">
                                    (recurring subscription until it is cancelled)
                                </div>
                                <button className="plansubscribebtn" onClick={() => {
                                    mixins.setStore("subscriptionPlan", item.price, props)
                                    mixins.setStore("subscriptionCategoryId", item.id, props)
                                    Modal.state.list.find((o) => o.type == "model")?.close();
                                    history.push("/subscriptionpayment")
                                }} >
                                    <div className="subbtntxt" >SUBSCRIBE NOW</div>
                                </button>
                            </div>
                            { (key+1)!=plans.length && <div className="line" key={'line'+key}></div> } 
                        </>

                    )

                })}
                {/* <div className="line"></div>
                {plans && plans.map((item, key) => {
                    if (item.type.toLowerCase() === "annualy")
                        return (
                            <div className="plancontent" key={key}>
                                <div className="plansubtitle">
                                    {item.description}
                                </div>
                                <div className="planprice">
                                    {item.price}
                                </div>
                                <div className="planreminder">
                                    (recurring subscription until it is cancelled)
                                </div>
                                <button className="plansubscribebtn">
                                    <div className="subbtntxt">SUBSCRIBE NOW</div>
                                </button>
                            </div>
                        )
                })} */}
            </div>
        </div>
    )
}
export default connect(SubscriptionPlan);