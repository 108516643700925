export default {
  DEVELOPMENT_URL: "https://plotterbox.tsprojects.net",
  STAGING_URL: "https://plotterbox.tsprojects.net,https://52.15.201.28:7000",
  PRODUCTION_URL: "https://plotterbox.com,https://www.plotterbox.com",
  STATIC_TOKEN:
    "GOhPVZ9YRmKZz4tC1DJ51gKwzF2yK9uQfjuxkku4VOtVURRNevuAqKjEjU2yV0G7",
  LOCAL_BASEURL: "https://admin74.plotterbox.tsprojects.net",
  LOCAL_UPLOADHANDPRINT:
    "http://handprint-plotterbox.tsprojects.net/uploadImage",
};
