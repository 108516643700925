import React, { useState } from "react";
import forget from "../../../assets/images/forget.png";
import lock from "../../../assets/images/lock.png";
import "./ForgetPassword.css";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import validator from "validator";

export default connect((props) => {
  const history = useHistory();
  const { handleSubmit } = useForm({ mode: "onBlur" });
  const [passwerroer, setpasswerroer] = useState(false);
  const [password, setpassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState(" ");
  const [alertFail, setAlertFail] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmpasswordType, setCconfirmPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmpasswordType === "password") {
      setCconfirmPasswordType("text");
      return;
    }
    setCconfirmPasswordType("password");
  };
  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setpassword(value);
      setErrorMessage("");
    } else {
      setErrorMessage(
        "Password not strong. Use Minimum One special character,Minimum 8 letters,One uppercase letter,One lowercase letter,One number."
      );
    }
  };
  const onsubmit = () => {
    const data = { password: password };
    if (!passwerroer) {
      api.password_reset(data).then((res) => {
        //console.log("res", res);

        if (res && res.statusCode === 200) {
          mixins.setStore("user", null, props);
          alert(res.message);
          history.push("/login");
        } else if (res && res.message) {
          setAlertFail(res.message);
        }
      });
    }
  };
  return (
    <div className="forgetcontainer">
      <div className="forget-formcontainer">
        {alertFail && (
          <Alert severity="error" variant="filled">
            <strong>{alertFail}</strong>
          </Alert>
        )}
        {/* <img src={forget} className="img" /> */}
        <div className="subcontainer">
          <div className="title">Enter New Password</div>
          <div className="des">
            {/* Please fill the registered email ID and we will send you a
            verification code there to reset your password. */}
          </div>
          <form onSubmit={handleSubmit(onsubmit)}>
            <div className="form-input">
              <img src={lock} className="icon" />
              <input
                type={passwordType}
                className="input"
                placeholder="New Password"
                onChange={(e) => {
                  setpassword[validate(e.target.value)];
                }}
                required
              />
              <span onClick={togglePassword}>
                {passwordType === "password" ? (
                  <i className="fa fa-eye eyeicon" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-eye-slash eyeicon" aria-hidden="true"></i>
                )}
              </span>
            </div>
            <div className="form-input">
              <img src={lock} className="icon" />
              <input
                type={confirmpasswordType}
                className="input"
                placeholder="Confirm Password"
                onChange={(e) => {
                  if (password === e.target.value) {
                    setpasswerroer(false);
                  } else {
                    setpasswerroer(true);
                  }
                }}
                required
              />

              <span onClick={toggleConfirmPassword}>
                {confirmpasswordType === "password" ? (
                  <i className="fa fa-eye eyeicon" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-eye-slash eyeicon" aria-hidden="true"></i>
                )}
              </span>
              {errorMessage && (
                <span style={{ color: "#36857a", textAlign: "left" }}>
                  Use Minimum One special character, Minimum 8 letters, One
                  uppercase letter, One lowercase letter,One number.
                </span>
              )}

              <div>
                {passwerroer ? (
                  <span style={{ color: "red" }}>Password didn't match</span>
                ) : null}
              </div>
            </div>
            <div className="form-input">
              <button type="submit" className="buttoncontainer">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
