import royalty from "../../assets/images/royalty.png";
import i from "../../assets/images/iwhite.png";
import basket from "../../assets/images/basket.png";
import arrowwhite from "../../assets/images/arrowwhite.png";
import books from "../../assets/images/books.png";
import booksclose from "../../assets/images/booksclose.png";
import money from "../../assets/images/money.png";
import booksicon from "../../assets/images/booksicon.png";
import userImage from "../../assets/images/user.png";
import mail from "../../assets/images/msg.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Dashboard.css";
import { useHistory } from "react-router-dom";
export default connect((props) => {
  const history = useHistory();
  const [leastPopularBooks, setLeastPopularBooks] = useState([]);
  const [mostPopularBooks, setMostPopularBooks] = useState([]);
  const user = useSelector((state) => state.user);
  const userName = user?.firstName + " " + user?.lastName;
  const [royaltiesEarned, setRoyaltiesEarned] = useState("");
  const [recommendations, setRecommendations] = useState("");
  const [userOffer, setUserOffer] = useState([]);

  useEffect(() => {
    // api.leastPopularBooks().then((res) => {
    //   console.log({ leastPopularBooks: res });
    //   setLeastPopularBooks(res);
    getRoyaltiesEarnedDetails();
    recomendationsDetails();
    getAllUserOffers();
  }, []);

  const getRoyaltiesEarnedDetails = () => {
    api.getRoyaltiesEarned().then((res) => {
     // console.log("royalties", res);
      if (res && res.data && res.statusCode === 200) {
        setRoyaltiesEarned(res.data);
        mixins.setStore("royaltyPoints", res.data, props);
      }
    });
  };

  const recomendationsDetails = () => {
    api.orderHistory().then((res) => {
      const recomendation = Object.values(res?.data?.recommendation);
      //console.log("recommendations", recomendation);
      if (recomendation) {
        const filterrecommendation = recomendation.filter(
          (item) => item.bookDetails != null
        );
        //console.log(filterrecommendation);
        setRecommendations(filterrecommendation);
      }
    });
  };

  const getAllUserOffers = () => {
    api.getUserOffers().then((res) => {
      //console.log("user offers", res);
      if (res && res.data && res.statusCode === 200) {
        setUserOffer(res.data);
      }
    });
  };

  //console.log(userOffer);

  return (
    <div className="dashboard">
      <div className="content">
        <div className="maincontainer">
          {user &&
            user?.roles &&
            (user?.roles[0]?.name === "author" ||
              user?.roles[0]?.name === "artist") && (
              <div className="leftcontainer">
                <div className="left-container-row">
                  <div className="left-col">
                    <div className="left-col-row">
                      <img src={royalty} className="royalty" />
                      {/* <img src={i} className="i-white" /> */}
                    </div>
                    <div className="left-row">
                      <span>{royaltiesEarned.royaltyEarned} SGD</span>
                    </div>
                    <div className="left-col-row">
                      <div className="left-row">Royalties Earned</div>
                      {/* <img src={arrowwhite} className="i-white" /> */}
                    </div>
                  </div>

                  <div className="right-col">
                    <div className="right-col-row">
                      <img src={basket} className="royalty" />
                      {/* <img src={i} className="i-white" /> */}
                    </div>
                    <div className="right-row">
                      <span>{royaltiesEarned.booksSold}</span>
                    </div>
                    <div className="right-col-row">
                      <div className="right-row">Books Sold</div>
                      {/* <img src={arrowwhite} className="i-white" /> */}
                    </div>
                  </div>
                </div>
                <div className="left-container-row">
                  <div className="most-left-col">
                    <div className="left-col-row">
                      <img src={books} className="royalty" />
                      {/* <img src={i} className="i-white" /> */}
                    </div>
                    <div className="left-col-row">
                      <div className="left-row">Most Popular Books</div>
                      {/* <img src={arrowwhite} className="i-white" /> */}
                    </div>
                    {royaltiesEarned &&
                      royaltiesEarned.mostPopularBooks &&
                      royaltiesEarned.mostPopularBooks.map((item) => {
                        return (
                          <div className="list">
                            <img src={booksicon} />
                            <label>{item.bookTitle}</label>
                          </div>
                        );
                      })}
                    {/* <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div> */}
                    {/* <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div>
                  <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div>
                  <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div>
                  <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div> */}
                  </div>
                  <div className="least-left-col">
                    <div className="right-col-row">
                      <img src={booksclose} className="royalty" />
                      {/* <img src={i} className="i-white" /> */}
                    </div>
                    <div className="right-col-row">
                      <div className="right-row">Least Popular Books</div>
                      {/* <img src={arrowwhite} className="i-white" /> */}
                    </div>
                    {royaltiesEarned &&
                      royaltiesEarned.leastPopularBooks &&
                      royaltiesEarned.leastPopularBooks.map((item) => {
                        return (
                          <div className="list">
                            <img src={booksicon} />
                            <label>{item.bookTitle}</label>
                          </div>
                        );
                      })}
                    {/* {leastPopularBooks.map((obj) => (
                    <div className="list">
                      <img src={booksicon} />
                      <label>{obj.name}</label>
                    </div>
                  ))}
                  <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div> */}
                    {/* <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div>
                  <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div>
                  <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div>
                  <div className="list">
                    <img src={booksicon} />
                    <label>Book 02</label>
                  </div> */}
                  </div>
                </div>
                <div className="left-container-row">
                  <div className="left-col-3">
                    <div className="left-col-row">
                      <img src={money} className="royalty" />
                      {/* <img src={i} className="i-white" /> */}
                    </div>
                    <div className="left-row">
                      <span>{royaltiesEarned.royaltyBalance} SGD</span>
                    </div>
                    <div className="left-col-row">
                      <div className="left-row">Royalty Balance</div>
                      {/* <img src={arrowwhite} className="i-white" /> */}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {user && user?.roles && user?.roles[0]?.name === "customer" && (
            <div className="leftcontainer offer ">
              <div className="left-container-row">
                <div className="left-col-2 ">
                  <div className="left-col-row">
                    <img src={books} className="royalty" />
                    {/* <img src={i} className="i-white" /> */}
                  </div>
                  <div className="left-col-row">
                    <div className="left-row">Offers</div>
                    <div className="right-listscroll"></div>
                    {/* <img src={arrowwhite} className="i-white" /> */}
                  </div>
                  {/* {royaltiesEarned &&
                    royaltiesEarned.mostPopularBooks &&
                    royaltiesEarned.mostPopularBooks.map((item) => {
                      return (
                        <div className="list">
                          <img src={booksicon} />
                          <label>{item.bookTitle}</label>
                        </div>
                      );
                    })} */}
                  <div className="left-col-offer">
                    {userOffer.offers &&
                      userOffer.offers.map((item) => {
                        if (item.firstPurchaseCode || item.secondPurchaseCode) {
                          return (
                            <div>
                              {item.disType ? (
                                <div className="list">
                                  <label className="list-label-one">
                                    {" "}
                                    First Purchase Code :{" "}
                                    {item.firstPurchaseCode}
                                  </label>
                                  {item.disType == "fixed" ? (
                                    <label className="list-label-two">
                                      Flat S${item.disValue} OFF
                                    </label>
                                  ) : (
                                    <label className="list-label-two">
                                      {item.disValue} % OFF
                                    </label>
                                  )}
                                  <br />
                                  {item.description && (
                                    <label> * {item.description}</label>
                                  )}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              {item.secondOfferType ? (
                                <div className="list">
                                  <label className="list-label-one">
                                    {" "}
                                    Second Purchase Code :{" "}
                                    {item.secondPurchaseCode}
                                  </label>
                                  {item.secondOfferType == "fixed" ? (
                                    <label className="list-label-two">
                                      Flat S${item.secondOfferDiscount} OFF
                                    </label>
                                  ) : (
                                    <label className="list-label-two">
                                      {item.secondOfferDiscount}% OFF
                                    </label>
                                  )}
                                  <br />
                                  {item.description && (
                                    <label> * {item.description}</label>
                                  )}
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="list">
                              <label className="list-label-one">
                                {" "}
                                No Offers avilable .
                              </label>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
                <div className="right-col-2 ">
                  <div className="right-col-row ">
                    <img src={booksclose} className="royalty" />
                    {/* <img src={i} className="i-white" /> */}
                  </div>
                  <div className="right-col-row">
                    <div className="right-row">Recommendations</div>
                    {/* <img src={arrowwhite} className="i-white" /> */}
                  </div>

                  {recommendations &&
                    recommendations !== undefined &&
                    recommendations.slice(0, 4).map((item, key) => {
                      return (
                        <div
                          className="row-listing"
                          key={key}
                          onClick={() => {
                            if (item.bookDetails.ageGroups === "kids") {
                              localStorage.setItem(
                                "category",
                                item.bookDetails.category
                              );
                              localStorage.setItem(
                                "subCategory",
                                item.bookDetails.ageGroups
                              );
                              history.push(
                                `/custom-books/product-details/${item.bookDetails.id}`
                              );
                            } else if (item.bookDetails.ageGroups === "teens") {
                              localStorage.setItem(
                                "category",
                                item.bookDetails.category
                              );
                              localStorage.setItem(
                                "subCategory",
                                item.bookDetails.ageGroups
                              );
                              history.push(
                                `/custom-books/product-details/${item.bookDetails.id}`
                              );
                            } else if (
                              item.bookDetails.ageGroups === "chapter"
                            ) {
                              localStorage.setItem(
                                "category",
                                item.bookDetails.category
                              );
                              localStorage.setItem(
                                "subCategory",
                                item.bookDetails.ageGroups
                              );
                              history.push(
                                `/self-publishing/product-details/${item.bookDetails.id}`
                              );
                            } else if (
                              item.bookDetails.ageGroups === "picture"
                            ) {
                              localStorage.setItem(
                                "category",
                                item.bookDetails.category
                              );
                              localStorage.setItem(
                                "subCategory",
                                item.bookDetails.ageGroups
                              );
                              history.push(
                                `/self-publishing/product-details/${item.bookDetails.id}`
                              );
                            } else {
                              localStorage.setItem(
                                "category",
                                item.bookDetails.category
                              );
                              localStorage.setItem(
                                "subCategory",
                                item.bookDetails.ageGroups
                              );
                              history.push(
                                `/handprint-books/product-details/${item.bookDetails.id}`
                              );
                            }
                          }}
                        >
                          <img src={item.bookImageUrl} />
                          <span>{item.bookTitle} </span>
                          <p>
                            {item.customPrice} {item.currency}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          <div className={"rightcontainer"}>
            <div className="title">
              {user?.roles[0]?.name?.charAt(0).toUpperCase() +
                user?.roles[0]?.name?.slice(1)}{" "}
              Dashboard
            </div>
            {user?.roles[0]?.name === "author" && (
              <div className="user-img">{/* <img src={userImage} /> */}</div>
            )}
            <div className="user-name">User Name : {userName} </div>
            <div className="contents">
              Designation: {user?.roles.map((o) => o.name).join(", ")}
            </div>
            <div className="contents">
              <img src={mail} />
              {user?.email}
            </div>
            {user?.roles[0]?.name != "customer" && (
              <div className="contents">{user?.authorInfo}</div>
            )}
            <div className="botton-container">
              <button
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/dashboard/profile")}
              >
                View Detail
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
