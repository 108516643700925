import React, { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import style from "./ProductCarrousal.module.css";
import { Paper } from "@mui/material";

import { useHistory } from "react-router-dom";
const ProductCarrousal = (props) => {
  const settings = {
    indicators: true,
    navButtonsAlwaysVisible: true,
  };
  return (
    <Carousel
      {...settings}
      // navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
      //     style: {
      //         backgroundColor: "transparent",
      //         borderRadius: 0,

      //         margin: "0 100px",
      //         "&>svg": {
      //             fontSize: "50px"
      //         }
      //     }

      // }}
    >
      {props.images &&
        props?.images?.map((item, i) => <Item key={i} item={item} />)}
    </Carousel>
  );
};

function Item(props) {
  return (
    <div className={style["row-container1"]}>
      <img src={props.item} style={{ height: "500px" }} />
    </div>
  );
}
export default ProductCarrousal;
