import React, { useState } from "react";
import AppInput from "../../partials/AppInput";
import "./ThumbPrint.css";
import step01 from "../../assets/images/step1.png";
import step02 from "../../assets/images/step2.png";
import stepArrow from "../../assets/images/stepArrow.png";
import { useHistory } from "react-router-dom";

let answer = {};

function PreInfoColletion(props) {
  const history = useHistory();
  const {
    alert,
    onChange = () => ({}),
    onSave = () => ({}),
    closable = false,
  } = props;
  const thumbprintPreInfo = props.store?.book?.thumbprintPreInfo || {};
  answer = {
    ...thumbprintPreInfo.answer,
    ...answer,
  };
  const handleChange = (val) => {
    //console.log({ before: answer });
    answer = { ...answer, ...val };
    onChange(answer);
    //console.log({ after: answer });
  };

  const steps = [
    {
      Content: ({ data }) => (
        <div className="maincontainer">
          <div className="image-flow">
            <div className="uploadcontainer">
              <img src={step01} className="step1-image" />
              <div className="centered">Step 01</div>
            </div>
            <div>
              <img src={stepArrow} className="stepArrow-image" />
            </div>
            <div className="uploadcontainer">
              <img src={step02} className="step2-image" />
              <div className="centered_02">Step 02</div>
            </div>
          </div>
          <div className="first-row">What is the child's name. </div>

          <AppInput
            className="second-row"
            type={"text"}
            variant="filled"
            label={"Child name"}
            onChange={(e) =>
              handleChange({ ...answer, child_name: e.target.value })
            }
            defaultValue={answer["child_name"] || ""}
          ></AppInput>
        </div>
      ),
    },
    {
      Content: ({ data }) => (
        <div className="maincontainer">
          <div className="image-flow">
            <div className="uploadcontainer">
              <img src={step01} className="step1-image" />
              <div className="centered">Step 01</div>
            </div>
            <div>
              <img src={stepArrow} className="stepArrow-image" />
            </div>
            <div className="uploadcontainer">
              <img src={step01} className="step2-image" />
              <div className="centered_02">Step 02</div>
            </div>
          </div>
          <div className="first-row">What is your name. </div>
          <AppInput
            className="second-row"
            type={"text"}
            variant="filled"
            label={"Your name"}
            onChange={(e) =>
              handleChange({ ...answer, parent_name: e.target.value })
            }
            defaultValue={answer["parent_name"] || ""}
          ></AppInput>
        </div>
      ),
    },
    {
      Content: ({ data }) => (
        <div className="maincontainer">
          <div className="first-row">Dedication. </div>
          <textarea
            className="second-row"
            type={"textarea"}
            variant="filled"
            placeholder="You are special just the way you are."
            // label={"Your name"}
            onChange={(e) =>
              handleChange({ ...answer, dedication: e.target.value })
            }
            defaultValue={answer["dedication"] || ""}
          ></textarea>
        </div>
      ),
    },
    {
      Content: ({ data }) => (
        <div className="popup">
          <div className="file-upload">
            <h3>Upload Your Handprints</h3>
            <div className="uploadInstruction">
              Instructions
              <ul>
                <li>Picture should be taken with a white background.</li>
                <li>
                  Hand in the picture should be upright (vertical) and not
                  horizontal.
                </li>
                <li>
                  Hands in the picture should be very clear and without shadow.
                </li>
                <li>
                  The palm should face the camera and the fingers should be
                  apart while taking the picture.
                </li>
                <li>
                  Child and adult images should not be interchanged while
                  uploading the images.
                </li>
                <li>The images should be in the jpg/jpeg/png format.</li>
              </ul>
            </div>
            <div className="fileUploadSection">
              <div>
                <h4>For Child</h4>
                <AppInput
                  className="chooseFile"
                  type={"file"}
                  variant="filled"
                  // label={"Parent Handprint"}
                  onChange={(e) =>
                    handleChange({
                      ...answer,
                      child_handprint: e.target.files[0],
                    })
                  }
                ></AppInput>
              </div>
              <div>
                <h4>For Adult</h4>
                <AppInput
                  className="chooseFile"
                  type={"file"}
                  variant="filled"
                  //   label={"Child Handprint"}
                  onChange={(e) =>
                    handleChange({
                      ...answer,
                      parent_handprint: e.target.files[0],
                    })
                  }
                ></AppInput>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const isLast = () => {
    return currentStep == steps.length - 1;
  };

  const onPrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onNext = () => {
    //console.log({ onNext: answer, isLast: isLast(), onSave });
    if (isLast()) {
      onSave(answer, props.alert);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  // const [errorMessage, setErrorMessage] = useState("");
  // const handleClick = () => {
  //   setErrorMessage("Example error message!")
  // }

  return (
    <div className="PreInfoCollection" id="handprint_pre_info_collection">
      <div className="row">
        {steps.map((step, i) => (
          <div
            key={i}
            className={Object.className({
              "col-12": true,
              active: currentStep == i,
              step: true,
            })}
          >
            <step.Content data={{ index: i }}></step.Content>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12">
          <button className="continue" onClick={() => onNext()}>
            {isLast() ? "Finish" : "Next"}
          </button>
        </div>
      </div>
      <div className="row">
        {currentStep > 0 && (
          <button className="previous" onClick={() => onPrev()}>
            BACK TO PREVIOUS STEP
          </button>
        )}
      </div>
      <div className="row">
        {currentStep == 0 && (
          <button
            className="previous"
            onClick={() => {
              history.push(
                `/thumbprint-books/product-details/${props.store.bookId}`
              );
              alert.close();
            }}
          >
            BACK TO PRODUCT STEP
          </button>
        )}
      </div>
      {closable && (
        <div className="row">
          <span
            onClick={() => alert.close()}
            style={{
              position: "absolute",
              top: "-15px",
              right: "-15px",
              padding: "5px 10px",
              background: " lightgray",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <i className="fa fa-times"></i>
          </span>
        </div>
      )}
    </div>
  );
}

export default connect(PreInfoColletion);
