import React, { useEffect, useState } from "react";
import "./Signup.css";
import lock from "../../../assets/images/lock.png";
import email from "../../../assets/images/email.png";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import validator from "validator";
export default connect((props) => {
  let { id } = useParams();
  const history = useHistory();
  const { handleSubmit } = useForm({ mode: "onBlur" });
  const [errorMessage, setErrorMessage] = useState("");
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [emailid, setEmail] = useState(null);
  const [password, setpassword] = useState(null);
  const [errors, setError] = useState(false);
  const [alertFail, setAlertFail] = useState(null);
  const [subscribe, setsubscribe] = useState(false);
  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setpassword(value);
      setErrorMessage("");
    } else {
      setErrorMessage(
        "Password not strong. Use Minimum One special character,Minimum 8 letters,One uppercase letter,One lowercase letter,One number."
      );
    }
  };
  const OnHandleclickSubcribe = () => {
    if (!errors) {
      const data = {
        firstName: firstname,
        lastName: lastname,
        email: emailid,
        password: password,
        subscribe: `${subscribe}`,
      };
      api.signup(data).then((res) => {
        if (res && res.statusCode === 200 && res.accessToken) {
          mixins.setAccessToken(res.accessToken);
          setTimeout(() => {
            api.getSessionID().then((sessionID) => {
              let localSessionID = localStorage.getItem("sessionID");
              localStorage.setItem("sessionID", localSessionID || sessionID);
              api.getuserProfile().then((resuser) => {
                //console.log(resuser.data);
                mixins.setStore("user", resuser.data, props);
                let redirect = localStorage.getItem("redirect");
                if (redirect) {
                  history.push(redirect);
                  mixins.setStore("user", resuser.data, props);
                  localStorage.setItem("redirect", "");
                } else {
                  history.push("/home");
                }
              });
            });
            // mixins.setStore("token", res.accessToken, props);
          },100);
        } else if (res && res.message) {
          let $USER_EXISTS = "The email has already been taken.";
          if (
            res.errorMessages.length >= 1 &&
            res.errorMessages.includes($USER_EXISTS)
          ) {
            alert(
              "User is already registered, please login to continue"
            ).promise.then(() => {
              history.push("/login");
            });
          } else {
            setAlertFail(res.message);
          }
        }
      });
    }
  };
  return (
    <div className="signupcontainer">
      <div className="formContainer">
        <form onSubmit={handleSubmit(OnHandleclickSubcribe)}>
          <div className="title">Create an account</div>
          {alertFail && (
            <Alert severity="error" variant="filled" className="alert">
              <strong>{alertFail}</strong>
            </Alert>
          )}
          <div className="rowcontainer">
            <div className="columncontainer">
              <i className="fas fa-user icon"></i>
              <input
                type="text"
                className="input"
                placeholder="First Name*"
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
                required
              />
            </div>
            <div className="columncontainer">
              <i className="fas fa-user icon-right"></i>
              <input
                type="text"
                className="input-right"
                placeholder="Last Name*"
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div className="rowcontainer">
            <div className="columncontainer">
              <img src={email} className="icon-email" />{" "}
            </div>
            <input
              type="email"
              className="input"
              placeholder="Email Address*"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
            />
          </div>
          <div className="rowcontainer">
            <div className="columncontainer">
              <img src={lock} className="icon" />
              <input
                type="password"
                className="input"
                placeholder="Password*"
                onChange={(e) => validate(e.target.value)}
                required
              />
            </div>
            <div className="columncontainer">
              <img src={lock} className="icon-right" />
              <input
                type="password"
                className="input-right"
                placeholder="Confirm Password*"
                onChange={(e) => {
                  if (password === e.target.value) {
                    setError(false);
                  } else {
                    setError(true);
                  }
                }}
                required
              />
              {errors ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  password incorrect
                </span>
              ) : null}
            </div>
          </div>
          <span style={{ fontWeight: "bold", color: "red", fontSize: "12px" }}>
            {errorMessage}
          </span>
          <div className="rowcontainer">
            <span>
              <input
                type="checkbox"
                className="checkbox"
                onClick={(e) => setsubscribe(e.target.checked)}
              />
              Treat your inbox: splendid discounts, new products and clever
              ideas.
            </span>
          </div>
          <div className="rowcontainer">
            <button type="submit" className="buttoncontainer">
              Sign Up Now
            </button>
          </div>
          <div className="rowcontainer">
            <span>
              Already have an account?{" "}
              <a onClick={() => history.push("/login")}>Sign In </a>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
});
