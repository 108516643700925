import React from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Header from "../components/layouts/header/Header";
import Banner from "../components/about/banner/Banner";
import SecondPart from "../components/about/second_part/second_part";
// import Thirdpart from "../components/about/third_part/thirdPart";
// import ForthPart from "../components/about/forthPart/forthPart";
import ContactPart from "../components/about/contact/contact";


const about = (props) => {
    // console.log("Home props", props);

    //mixins.setStore("loading",true,props);
    return (<div className={style['container']}>
        <Header current={props.location.pathname} />
        <div className={style['cmsmin_height']}>
            <Banner />
            <SecondPart />
            {/* <Thirdpart /> */}
            {/* <ForthPart /> */}
            <ContactPart />
        </div>
        <Footer />
    </div>)
}
export default connect(about);