import React, { useEffect, useState, useRef } from "react";
import "./header.css";
import menus from "./menus";
import { useHistory, Link } from "react-router-dom";
import { AppBar, IconButton, Tooltip, Badge } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import actoricon from "../../../assets/images/actoricon.png";
import bagicon from "../../../assets/images/bag.png";
import logo from "../../../assets/images/logo.png";
import { useSelector } from "react-redux";
import api from "../../../app-services/API";

export default connect((props) => {
  const history = useHistory();
  const wrapperRef = useRef(null);
  const [headerMenu, setheaderMenu] = useState([]);
  const [activemenu, setActivemenu] = useState(false);
  const [menu, setMenu] = useState(false);
  const user = useSelector((state) => state.user);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const getheaderMenu = () => {
    api.getHeaderMenue().then((res) => {
      if (res && res.data) {
        setheaderMenu(res.data);
        mixins.setStore("menulist", res.data, props);
      }
    });
  };

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current.contains(event.target)) {
        setActivemenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    getheaderMenu();
  }, []);
 
  const onClickmenu = () => {
    setMenu(!menu);
  };
  const checkActive = (menu) => {
    if (menu.exact && props?.current) {
      return props?.current == menu.url;
    } else if (props?.current) {
      return props?.current.includes(menu.url);
    }
  };
  const MenuItems = (props) => {
    const getUrlPath = (item)=>{
      const path = (menu)=>menu.url || menus.getobj((o) => o.name == menu.name).path;
      let pathurl =  !!((item["sub-menus"]||[]).length)?'':path(item);
      return pathurl;
    };
    return (
      <ul className="menuitems-ul" key={props.name || "main"}>
        {headerMenu &&
          headerMenu.map((menu, i) => (
            <li
              key={menu.name + props.name || ""}
              tabIndex={i}
              className={"menu-item " + (checkActive(menu) ? "active" : "")}
              onClick={(e) => {
                //console.log({e});
                if (menu["sub-menus"].length < 1) {
                  localStorage.setItem("category", menu.category);
                  localStorage.setItem("subCategory", menu.subCategory);
                } else if(e.target.tagName=="SPAN"){
                  e.stopPropagation();
                }
              }}
            >
              {
                !!getUrlPath(menu)?
                <Link to={getUrlPath(menu)}>
                {menu.name}
                </Link>:
                <span>
                {menu.name}
                </span>
              }
              
              {menu["sub-menus"] && menu["sub-menus"].length > "0" && (
                <ul className="dropdown">
                  {menu["sub-menus"].filter((o)=>o.display == 1).map((cmenu) => (
                   <Link to={cmenu.url}>
                    <li
                      key={cmenu.name + props.name || ""}
                      className="menu-item dropitem"
                      onClick={() => {
                        localStorage.setItem("category", cmenu.category);
                        localStorage.setItem("subCategory", cmenu.subCategory);
                        // history.push(cmenu.url);
                      }}
                    >
                    
                      {cmenu.name}
                    
                    </li>
                    </Link>
                  ))}
                </ul>
              )}
            </li>
          ))}
      </ul>
    );
  };
  const appLogout = () => {
    const resolve = ()=>{
        setToken(window.token);
         //console.log({appLogout:token});
        mixins.setStore('user',null,props);
        history.push("/login");
    };
    api
      .appLogout()
      .then(() => {
        resolve();
      })
      .catch((err)=>{
        resolve();
        console.log(err);
      });
  };

  return (
    <div className="Header">
      <div className="Container">
        <div className="row no-gutters space">
          <div className="left-menu col-2">
            <div onClick={() => history.push("/")}>
              <img src={logo} className="logo-image" />
            </div>
          </div>
          <div className="right-menu ">
            <div className="d-none d-xl-flex menuitems">
              <MenuItems props={props} />
            </div>
          </div>
          <div className="right-btns">
            <div className="float-right" style={{ position: "relative" }}>
              <button onClick={user ? null : () => history.push("/login")}>
                <img src={actoricon} />
                {user ? (
                  <i className="fa fa-caret-down" onClick={onClickmenu}></i>
                ) : null}
              </button>
              {menu ? (
                <ul className="dropdown">
                  {user && (
                    <li
                      className="dropitem"
                      onClick={() => history.push("/dashboard")}
                    >
                      MY Account
                    </li>
                  )}
                  {user && (
                    <li className="dropitem" onClick={() => appLogout()}>
                      {" "}
                      Logout
                    </li>
                  )}
                </ul>
              ) : null}

              {props.store.user?.roles && props.store.user?.roles?.length>0 && props.store.user?.roles[0].name  === "customer" &&<Badge badgeContent={props.store.cartItem} color="primary">
                <IconButton
                  onClick={() => {
                    if (localStorage.getItem("token")) {
                      history.push("/checkout-page");
                    } else {
                      history.push("/login");
                    }
                  }}
                >
                  <img src={bagicon} />
                </IconButton>
              </Badge>}
              <IconButton
                className={"d-md-inline-flex d-xl-none hamburger "}
                onClick={() => setActivemenu(!activemenu)}
                ref={ref}
              >
                <MenuIcon />
                <div
                  className={
                    "menu-container menuitems " + (activemenu && "active")
                  }
                  tabIndex={0}
                >
                  <MenuItems name={"sub"} />
                </div>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
