import React from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import ForgetPassword from "../components/auth/forgetPassword/ForgetPassword";


const LoginPage = (props) => {
    return (<div className={style['container']}>
        <Announcement />
        <Header current={props.location.pathname} />
        <ForgetPassword />
        <Footer />
    </div>)
}
export default connect(LoginPage);