export default [
    {
        name: "home",
        label: "Home",
        path: "/",
        exact:true
    },
    {
        name: "custom_book",
        label: "Custom Books",
        path: "/custom-books",
        children: [
            {
                name: "viewall",
                label: "View All",
                path: "/custom-books/product-list"
            },
            {
                name: "forkids",
                label: "For Kids",
                path: "/custom-books/product-list"
            },
            {
                name: "forteen",
                label: "For Teen",
                path: "/custom-books/product-list"
            },
            {
                name: "forgowups",
                label: "For Grow-Ups",
                path: "/custom-books/product-list"
            },
        ]
    },
    {
        name: "handprint_books",
        label: "Handprint Books",
        path: "/handprint-books"
    },
    {
        name: "self-publishing",
        label: "Self Publishing",
        path: "/self-publishing"
    },
    {
        name: "promotion",
        label: "Promotion",
        path: "/promotion"
    }
];