import React, { useState, useEffect } from "react";
import edit from "../../../assets/images/edit.png";
import search from "../../../assets/images/search.png";
import deleteicon from "../../../assets/images/delete.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
export default connect((props) => {
  const [loading, setLoading] = useState("none");
  const history = useHistory();
  // console.log("id", props);
  const deleteCartitem = (item) => {
    let subId = item.subscription[0].id;
    setLoading("flex");
    api.deleteSubscriptionItem(subId).then((res) => {
      setLoading("none");
      if (res && res.statusCode === 200) {
        props.getAllSaveForLaterDetails();
        mixins.reFectchSavedBookDetails(props);
      }
    });
  };
  const movetoCartitem = (item) => {
    let id = item.id;
    setLoading("flex");
    api.movetocart(id,{
      "customBookId": item?.customProduct?.customBookId
    }).then((res) => {
      setLoading("none");
      if (res && res.statusCode === 200) {
        props.getCartDetails();
        props.getAllSaveForLaterDetails();
        mixins.reFectchSavedBookDetails(props);
      }
    });
  };
  return (
    <div className="ProductCard row no-gutters card">
        {/* { console.log(props.item) } */}
      <div className="image-container">
        <img
          src={props.item?.product?.bookImageUrl}
          className="cart-image-container"
        />
        <img src={search} className="search-image-container" />
      </div>
      <div className="col">
        <div className="product_name">{props.item?.product?.bookTitle}</div>
        <div className="Subtitle">Hard Cover</div>
        <div className="price">
          Price: $ {props.item?.product?.actualPrice} {props.item?.product?.currency}
        </div>
        <div style={{ display: loading }}>
          <CircularProgress color="success" />
        </div>
        <div className="button-container">
              <div
                className="delete-button"
                onClick={() => deleteCartitem(props.item)}
              >
                <img src={deleteicon} />
                Remove book
              </div>
              <div
                className="save-button"
                onClick={() => movetoCartitem(props.item)}
              >
                <img src={edit} />
                Move to Cart
              </div>
        </div>
      </div>
    </div>
  );
});
