import React from 'react';
export default connect((props) => {
    const { setAnswer, answer = {}, index, data, pos } = props;
    return (
        <div className="dearuser imagform">
            <div className="bold-header title">{data.question}</div>
            <div className='row' style={{ padding: "0% 18%",}}>
                {/* <div className='col-12 col-lg-6 dearimage'>
                    <img src={data.image} className="dearuserimage" />
                </div> */}
                <div className='col-12 col-lg-12'>
                    <textarea type="textarea"
                        name="textValue"
                        onInput={(e) => setAnswer({ [data.name]: e.target.value })}
                        className='textarea'
                    >{answer[data.name]}</textarea>
                    <button className='savebtn' onClick={(e) => {
                        //console.log("answer", answer)
                        if (answer.dedication) {
                            setAnswer({ ["save"]: true });
                            alert("your dedication is saved")
                        } else {
                            alert("please enter the dedication")
                        }
                    }}>Save</button>
                </div>
            </div>
        </div>)
})