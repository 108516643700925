import royalty from "../../assets/images/royalty.png";
import i from "../../assets/images/iwhite.png";
import basket from "../../assets/images/basket.png";
import arrowwhite from "../../assets/images/arrowwhite.png";
import books from "../../assets/images/books.png";
import booksclose from "../../assets/images/booksclose.png";
import money from "../../assets/images/money.png";
import booksicon from "../../assets/images/booksicon.png";
import user from "../../assets/images/user.png";
import mail from "../../assets/images/msg.png";
import { useEffect, useState } from "react";
export default connect((props) => {
  const [leastPopularBooks, setLeastPopularBooks] = useState([]);
  const [royaltiesEarned, setRoyaltiesEarned] = useState([]);
  const [booksSold, setBooksSold] = useState([]);

  useEffect(() => {
    api.leastPopularBooks().then((res) => {
      //console.log({ leastPopularBooks: res });
      setLeastPopularBooks(res);
    });
  }, []);

   const  getRoyaltiesEarnedDetails=(id)=> {
    api.getRoyaltiesEarned(id).then((res) => {
      //console.log({ royaltiesEarned: res });
    });
  }

  function getBooksSoldDetails(id) {
    api.getBooksSold(id).then((res) => {
      //console.log({ booksSold: res });
    });
  }

  function getMostPopularBooksDetails(id) {
    api.getMostPopularBooks(id).then((res) => {
      //console.log({ mostPopularBooks: res });
    });
  }

  function getLeastPopularBooksDetails(id) {
    api.getLeastPopularBooks(id).then((res) => {
      //console.log({ leastPopularBooks: res });
    });
  }

  function getRoyaltyBalanceDetails(id) {
    api.getRoyaltyBalance(id).then((res) => {
      //console.log({ royaltyBalance: res });
    });
  }

  function getAuthorView(id) {
    api.getAuthor(id).then((res) => {
      //console.log({ authorView: res });
    });
  }
  //    "body": {
  //     "mode": "formdata",
  //     "formdata": [
  //         {
  //             "key": "firstName",
  //             "value": "dfdg\"",
  //             "type": "default"
  //         },
  //         {
  //             "key": "secondName",
  //             "value": "wryy",
  //             "type": "default"
  //         },
  //         {
  //             "key": "authorInfo",
  //             "value": "teueu",
  //             "type": "default"
  //         },
  //         {
  //             "key": "type",
  //             "value": "Author",
  //             "type": "text"
  //         }
  //     ]
  // },
  function createAuthorView() {
    api.createAuthor().then((res) => {
     // console.log({ author: res });
    });
  }

  function deleteAuthordetails() {
    api.deleteAuthor().then((res) => {
      //console.log({ author: res });
    });
  }

  return (
    <div className="maincontainer">
      {user && user.roles && user.roles[0]?.name === "author" && (
        <div className="leftcontainer">
          <div className="left-container-row">
            <div className="left-col">
              <div className="left-col-row">
                <img src={royalty} className="royalty" />
                <img src={i} className="i-white" />
              </div>
              <div className="left-row">
                <span>$25023240.65</span>(Jan.2022)
              </div>
              <div className="left-col-row">
                <div className="left-row">Royalties Earned</div>
                <img src={arrowwhite} className="i-white" />
              </div>
            </div>
            <div className="right-col">
              <div className="right-col-row">
                <img src={basket} className="royalty" />
                <img src={i} className="i-white" />
              </div>
              <div className="right-row">
                <span>$210</span>
              </div>
              <div className="right-col-row">
                <div className="right-row">Books Sold</div>
                <img src={arrowwhite} className="i-white" />
              </div>
            </div>
          </div>
          <div className="left-container-row">
            <div className="left-col-2">
              <div className="left-col-row">
                <img src={books} className="royalty" />
                <img src={i} className="i-white" />
              </div>
              <div className="left-col-row">
                <div className="left-row">Most Popular Books</div>
                <img src={arrowwhite} className="i-white" />
              </div>
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
            </div>
            <div className="right-col-2">
              <div className="right-col-row">
                <img src={booksclose} className="royalty" />
                <img src={i} className="i-white" />
              </div>
              <div className="right-col-row">
                <div className="right-row">Least Popular Books</div>
                <img src={arrowwhite} className="i-white" />
              </div>
              {/* {
                            leastPopularBooks.map(obj=>(
                                <div className='list'><img src={booksicon}/><label>{obj.name}</label></div>
                            ))
                        } */}
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
              <div className="list">
                <img src={booksicon} />
                <label>Book 02</label>
              </div>
            </div>
          </div>
          <div className="left-container-row">
            <div className="left-col-3">
              <div className="left-col-row">
                <img src={money} className="royalty" />
                <img src={i} className="i-white" />
              </div>
              <div className="left-row">
                <span>$2500.65</span>(Jan.2022)
              </div>
              <div className="left-col-row">
                <div className="left-row">Royalty Balance</div>
                <img src={arrowwhite} className="i-white" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="rightcontainer">
        <div className="title">User Dashboard</div>
        <div className="user-img">
          <img src={user} />
        </div>
        <div className="user-name">User Name</div>
        <div className="contents">Designation: Author</div>
        <div className="contents">
          <img src={mail} />
          info@username.com
        </div>
        <div className="contents">
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English.
        </div>
        <div className="botton-container">
          <button>View Detail</button>
        </div>
      </div>
    </div>
  );
});
