import { Fragment, useEffect, useState, useRef } from "react";
import "../chapter.css";
const pagefour = (props) => {
    return (
        <Fragment >
            <div className="pageformat height">
                <div className="text-center chapter-title">
                    {/* <h1>Chapter 4</h1> */}
                </div>
                <div className="text-center chapter-content">
                    <p>It was just that… Gabby had been really stressed out lately, because this was her PSLE year. She didn't think Gabby would want to be involved in another one of her adventures.</p>
                    <p>"No," Gabby said. "You're not allowed to go out alone."</p>
                    <p>"But–" Ellie started to protest.</p>
                    <p>"I'll come with you," Gabby said, with a knowing look in her eye. "I know you're up to something and you’d better tell me what it is."</p>
                    <p>"Okaaay," Ellie said. She should have known better than to try to hide something from Gabby. </p>
                    <p>So as best she could, while they were on their way down to the park with Snowy, Ellie told Gabby about all the strange things that had happened to her that week. Gabby nodded and listened intently. She believed Ellie. Strange as her sister was, when it came to animals, she never made things up.</p>
                </div>
                <div className="page-number">
                    <span className="digite-number">4</span>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(pagefour);