import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import NewPassword from "../auth/forgetPassword/NewPassword";
import Profile from "./components/profile/Profile";
import Dashboard from "./Dashboard";
import AuthorDashboard from "../authorDashboard/Dashboard";
// import Redeem from "./components/Redeem";
import Royalty from "./Royalty";
import BookTable from "./components/book/BookTable";
import dash_home from "../../assets/images/dash_home.png";
import booksicon from "../../assets/images/booksicon.png";
import ChangePwdIcon from "../../assets/images/Change Pwd Icon.png";
import ProfileIcon from "../../assets/images/ProfileIcon.png";
import RoyaltyBonusicon from "../../assets/images/RoyaltyBonusicon.png";
import Logout from "../../assets/images/Logout.png";
const routes = [
  {
    path: "/author-dashboard",
    exact: true,
    sidebar: () => <div>Dashboard</div>,
    main: () => <AuthorDashboard />,
  },
  {
    path: "/my-dashboard",
    exact: true,
    sidebar: () => <div>Dashboard</div>,
    main: () => <Dashboard />,
  },
  {
    path: "/author-dashboard/books",
    sidebar: () => <div>Books</div>,
    main: () => <BookTable />,
  },
  {
    path: "/author-dashboard/royalty-history",
    sidebar: () => <div>Royalty History</div>,
    main: () => <Royalty />,
  },
  // {
  //   path: "/author-dashboard/redeem",
  //   sidebar: () => <div>Redeem</div>,
  //   main: () => <Redeem />,
  // },
  {
    path: "/author-dashboard/profile",
    sidebar: () => <div>Profile</div>,
    main: () => <Profile />,
  },
  {
    path: "/author-dashboard/new-password",
    sidebar: () => <div> Change Password</div>,
    main: () => <NewPassword />,
  },
  {
    path: "/logout",
    sidebar: () => <div>Logout</div>,
    main: () => <h2>Logout</h2>,
  },
];
const SidebarExample = (props) => {
  return (
    <Router>
      {/* {console.log()} */}
      <div className="dashboard row no-gutters">
        <div className="sidebar">
          <ul style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <Link to="/author-dashboard">
                <img src={dash_home} />
                <label>Dashboard</label>
              </Link>
            </li>
            <li>
              <Link to="/author-dashboard/books">
                <img src={booksicon} />
                <label>Books</label>
              </Link>
            </li>
            <li>
              <Link to="/author-dashboard/royalty-history">
                <img src={RoyaltyBonusicon} />
                <label>Royalty History</label>
              </Link>
            </li>
            {/* <li>
              <Link to="/author-dashboard/redeem">
                <img src={RoyaltyBonusicon} />
                <label>Redeem</label>
              </Link>
            </li> */}
            <li>
              <Link to="/author-dashboard/profile">
                <img src={ProfileIcon} />
                <label>Profile</label>
              </Link>
            </li>
            <li>
              <Link to="/author-dashboard/new-password">
                <img src={ChangePwdIcon} />
                <label>Change Password</label>
              </Link>
            </li>
            <li>
              <Link to="/author-dashboard/logout">
                <img src={Logout} />
                <label>Logout</label>
              </Link>
            </li>
          </ul>
        </div>

        <div className="content col">
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
          </Switch>
        </div>
      </div>
    </Router>
  );
};
export default connect(SidebarExample);
