import React, { useState, useRef } from 'react';
import { FormGroup, Checkbox, FormControlLabel } from "@material-ui/core";
import ScrollContainer from '../common/ScrollContainer';
export default connect((props) => {
    let scrl = useRef(null);
    let scrlcards = useRef(null);
    const { setAnswer, answer = {}, index, data, pos } = props;
    const Inputs = (obj, i) => {
        const isChecked = (radio,ans,obj)=>{
            if(obj.type=="radio") {
                return radio.value==ans;
            }
            return ans;
        };
        return <div className="col-12" style={{ "display": "flex", "flexDirection": "row","justify-content": "center" }} key={i} >
            <FormGroup
                className="formgroup"
                row={obj?.row || true}
                defaultValue={answer[obj.name] || ""}
                onChange={(e) => setAnswer({ ...answer, [obj.name]: e.target.value })}>
                {(obj.radios || []).map((radio, idx) => (
                    <div className='option-card'>
                        <div className='image-card'>{radio.label}</div>
                        <div className='price'>$ {radio.price}</div>

                        <FormControlLabel control={<Checkbox className='checkbox' checked={isChecked(radio,answer[obj.name],obj)} />}
                            label={radio.display||radio.value}
                            value={radio.value}
                            ref={scrl}
                            {...(radio?.attr || {})}
                        />
                    </div>

                ))}
            </FormGroup>
        </div>

    };
    //console.log({ scrlcards })
    return (
        <div className="imagform">
            <div className="bold-header title">{data.question}</div>
            <div className="formcollection">
                <ScrollContainer scrlref={scrlcards} shift={100} >
                    {(cprop) => <div ref={scrlcards} className={"scrlcards"} >
                        {(data.collect || []).map((obj, i) => (
                            Inputs(obj, i)
                        ))}
                    </div>}
                </ScrollContainer>
            </div>
        </div>
    );
});