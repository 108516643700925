import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
const paypalScriptOptions = {
  "client-id":
    "AUrlRZ5qTsdMhuZ0bz0kL2ur3pLtSyd4vbmjtYD5bHXSfLB4jiUPvYlA0iaGeOdLcEerjn-GAhtuOU8I",
  currency: "SGD"
};

function Button() {
  const price = useSelector((state) => state.subscriptionPlan);
  const subscriptionCategoryId = useSelector((state) => state.subscriptionCategoryId);
  const subscriptionStatus = useSelector((state) => state.subscriptionStatus);
  const history = useHistory();
  /**
   * usePayPalScriptReducer use within PayPalScriptProvider
   * isPending: not finished loading(default state)
   * isResolved: successfully loaded
   * isRejected: failed to load
   */
  //console.log("prize", price)
  const [{ isPending }] = usePayPalScriptReducer();
  const paypalbuttonTransactionProps = {
    style: { layout: "vertical", innerWidth: "100px" },
    createOrder(data, actions) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: price
            }
          }
        ]
      });
    },
    onApprove(data, actions) {
      /**
       * data: {
       *   orderID: string;
       *   payerID: string;
       *   paymentID: string | null;
       *   billingToken: string | null;
       *   facilitatorAccesstoken: string;
       * }
       */
      return actions.order.capture({}).then((details) => {
        //console.log("paypal response", details)
        const paypalresponse = {
          "amount": details.purchase_units[0].amount.value,
          "currency": details.purchase_units[0].amount.currency_code,
          "orderId": details.id,
          "payerId": details.payer.payer_id,
          "paymentId": details.purchase_units[0].payments.captures[0].id,
          "paymentComments": JSON.stringify(details.purchase_units[0].payee, null, 2),
          "paymentStatus": details.status
        }
        //console.log("paypal response paypal", paypalresponse)
        if (subscriptionStatus === "new") {
          api.subscriptioncreate({
            "subscriptionCategoryId": subscriptionCategoryId,
            "transactionId": details.purchase_units[0].payments.captures[0].id,
            "paymentStatus": "success"
          }).then((res) => {
            if (res && res.data && res.statusCode === 200) {
              history.push("/checkout-page")
            } else {
              if (res && res.errorMessages) {
                alert(res.errorMessages[0])
              } else {
                alert(res.message)
              }
            }
          })
        } else if (subscriptionStatus === "renew") {
          api.subscriptionrenewal({
            "subscriptionCategoryId": subscriptionCategoryId,
            "transactionId": details.purchase_units[0].payments.captures[0].id,
            "paymentStatus": "success"
          }).then((res) => {
            if (res && res.data && res.StatusCode === 200) {
              history.push("/checkout-page")
            } else {
              if (res && res.errorMessages) {
                alert(res.errorMessages[0])
              } else {
                alert(res.message)
              }
            }
          })
        }

      });
    }
  };
  return (
    <>
      {isPending ? <h2>Load Smart Payment Button...</h2> : null}
      <PayPalButtons {...paypalbuttonTransactionProps} />
    </>
  );
}
export default function SubscriptionPayment() {
  return (
    <div style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
      {/* <h1>Hello PayPal</h1> */}
      <div style={{ width: "500px" }}>
        <PayPalScriptProvider options={paypalScriptOptions} >
          <Button />
        </PayPalScriptProvider>
      </div>

    </div>
  );
}