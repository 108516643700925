export default connect((props) => {
    const { setAnswer,answer, data,index } = props;
    return (
        <>
            <div className="bold-header">
                {data.question}
            </div>
            <div className="options"   >
                {
                    data.options.map((obj, i) => (
                        <div className="option card"
                            key={i}
                            onClick={() => setAnswer(obj)}
                            active={(answer?.value == obj.value).toString()}
                        >
                            {obj.label}
                        </div>

                    ))
                }
            </div>
        </>
    );
})