import Dashboard from "../../dashboard/Dashboard";
import BookTable from "../../dashboard/components/book/BookTable";
import Royalty from "../../dashboard/Royalty";
import Profile from "../../dashboard/components/profile/Profile";
import NewPassword from "../../auth/forgetPassword/NewPassword";
// import History from "../../dashboard/components/history/History";
import IconHome from "../../../assets/images/dash_home.png";
import IconBooks from "../../../assets/images/booksicon.png";
import IconChangePwd from "../../../assets/images/Change Pwd Icon.png";
import IconProfile from "../../../assets/images/ProfileIcon.png";
import IconRoyaltyBonus from "../../../assets/images/RoyaltyBonusicon.png";
import HistoryChanges from "../../dashboard/components/HistoryChanges";
// import Redeem from "../../dashboard/components/Redeem";

export default [
  {
    path: "/dashboard",
    exact: true,
    icon: () => <img src={IconHome} />,
    sidebar: () => <div>Dashboard</div>,
    main: () => <Dashboard />,
    access: "both",
  },
  {
    path: "/dashboard/books",
    icon: () => <img src={IconBooks} />,
    sidebar: () => <div>Books</div>,
    main: () => <BookTable />,
    access: "author",
  },
  {
    path: "/dashboard/books",
    icon: () => <img src={IconBooks} />,
    sidebar: () => <div>Books</div>,
    main: () => <BookTable />,
    access: "artist",
  },
  {
    path: "/dashboard/royalty-history",
    icon: () => <img src={IconRoyaltyBonus} />,
    sidebar: () => <div>Royalty History</div>,
    main: () => <Royalty />,
    access: "author",
  },
  {
    path: "/dashboard/royalty-history",
    icon: () => <img src={IconRoyaltyBonus} />,
    sidebar: () => <div>Royalty History</div>,
    main: () => <Royalty />,
    access: "artist",
  },
  // {
  //   path: "/dashboard/redeem",
  //   icon: () => <img src={IconRoyaltyBonus} />,
  //   sidebar: () => <div>Redeem</div>,
  //   main: () => <Redeem />,
  //   access: "author",
  // },
  // {
  //   path: "/dashboard/redeem",
  //   icon: () => <img src={IconRoyaltyBonus} />,
  //   sidebar: () => <div>Redeem</div>,
  //   main: () => <Redeem />,
  //   access: "artist",
  // },
  {
    path: "/dashboard/profile",
    icon: () => <img src={IconProfile} />,
    sidebar: () => <div>Profile</div>,
    main: () => <Profile />,
    access: "both",
  },
  // {
  //   path: "/dashboard/history",
  //   icon: () => <img src={IconProfile} />,
  //   sidebar: () => <div>History</div>,
  //   main: () => <History />,
  //   access: "customer",
  // },
  {
    path: "/dashboard/HistoryChanges",
    icon: () => <img src={IconProfile} />,
    sidebar: () => <div>History</div>,
    main: () => <HistoryChanges />,
    access: "customer",
  },
  {
    path: "/dashboard/new-password",
    icon: () => <img src={IconChangePwd} />,
    sidebar: () => <div> Change Password</div>,
    main: () => <NewPassword />,
    access: "both",
  },
];
