import React from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import Signup from "../components/auth/signup/Signup";
const SignupPage = (props) => {
    return (<div className={style['container']}>
        <Announcement />
        <Header current={props.location.pathname} />
       <Signup/>
        <Footer />
    </div>)
}
export default connect(SignupPage);