import React, { Component } from "react";
import { withRouter } from "react-router";
import { RowWrapper, CheckoutFlow } from "./CheckoutFLow";
import "./CheckoutSteps.css";

export class CheckoutSteps extends Component {
  constructor(props) {
    super(props);
    initialConstruct.bind(this)("CheckoutSteps");
    this.addToCart = props.addToCart || (() => "");
    this.state = {
      steps: CheckoutFlow,
      currentStep: props.currentStep || 0,
    };
  }

  componentWillUpdate(props) {
    this.watch(
      {
        steps(val) {
          this.setState({
            steps: val || [],
          });
        },
      },
      props
    );
  }

  nextStep(dir) {
    let { currentStep, steps } = this.state;
    switch (dir + "") {
      case "1":
        if (currentStep + dir <= steps.length - 1) {
          this.setState({
            currentStep: currentStep + dir,
          });
        }
        break;
      case "-1":
        if (currentStep + dir >= 0) {
          this.setState({
            currentStep: currentStep + dir,
          });
        }
        break;
    }
  }

  render() {
    let me = this;
    return ((
      { onAnswer, keys = [], onSave, bookname },
      { steps, currentStep },
      {}
    ) => (
      <div className="CheckoutSteps">
        <div className="step-contents">
          {steps
            .filter((v) => keys.includes(v.name))
            .map((obj, i) => (
              <div
                className={Object.className({
                  step: true,
                  active: i == currentStep,
                })}
                key={i}
              >
                {
                  <obj.Component
                    onAnswer={onAnswer}
                    onSave={onSave}
                    bookname={bookname}
                  />
                }
              </div>
            ))}
        </div>
        <RowWrapper>
          <div className="step-bars col-lg-12">
            <div className="row no-gutters customize-container">
              {steps
                .filter((v) => keys.includes(v.name))
                .map((obj, i) => (
                  <div
                    className={Object.className({
                      stepbar: true,
                      col: true,
                      "col-lg-3": true,
                      "col-xl-2": true,
                      visited: i <= currentStep,
                      active: i == currentStep,
                    })}
                    key={i}
                  >
                    <div
                      className="subheading"
                      onClick={() =>
                        (
                          obj.onClick || (() => me.setState({ currentStep: i }))
                        )({ me, obj, currentStep, i })
                      }
                    >
                      <i className="fa fa-caret-right"></i>
                      {obj.subheading}
                    </div>
                    <div className="label">{obj.label}</div>
                  </div>
                ))}
            </div>
          </div>
        </RowWrapper>
        <RowWrapper>
          <div className="col-lg-10 pt-2 continuebtns">
            {currentStep ==
              steps.filter((v) => keys.includes(v.name)).length - 1 ||
            steps.filter((v) => keys.includes(v.name)).length == 0 ? (
              <>
                {/* {(
                                    currentStep > 0 &&
                                    <button
                                        className="continuebtn"
                                        type="button"
                                        onClick={() => me.nextStep(-1)}
                                    >
                                        PREV
                                    </button>
                                )} */}
                <button
                  className="continuebtn"
                  type="button"
                  id="add2cart"
                  onClick={() => me.addToCart()}
                >
                  ADD TO CART
                </button>
              </>
            ) : (
              <>
                {/* <button
                                        className="continuebtn"
                                        type="button"
                                        onClick={() => me.nextStep(-1)}
                                    >
                                        PREV
                                    </button> */}
                <button
                  className="continuebtn"
                  type="button"
                  onClick={() => me.nextStep(1)}
                >
                  NEXT
                </button>
              </>
            )}
          </div>
        </RowWrapper>
      </div>
    ))(this.props, this.state, this);
  }
}

export default connect(withRouter(CheckoutSteps));
