import { color } from "@mui/system";
import React, { useEffect, useState } from "react";
// import "./page.scss";

function Page(props, ref) {
  return (
    <div className={"page " + props.className} style={{ background: "white" }}>
      <div className="page-content" style={props.style || {}}>
        <div className="page-image" style={props.pagebgstyle || {}}></div>
        {props.content && (
          <div className="page-text" ref={ref}>
            {typeof props.content == "string" ? (
              props.content
            ) : (
              <props.content></props.content>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default React.forwardRef(Page);
