import { Fragment, useEffect, useState, useRef } from "react";

import "./character.css";
import backcover from "./backcover.png";


const Chap1Back = (props) => {
    return (
        <Fragment >
            <div className="back-cover height">
                <img src={backcover} />
                <div className="custom_name"><p>{props.name||'CUSTOMNAME'}</p></div>
            </div>
        </Fragment>
    )
}
export default connect(Chap1Back);