import React, { useEffect, useState } from "react";
import "./Login.css";
import lock from "../../../assets/images/lock.png";
import email from "../../../assets/images/email.png";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useParams } from "react-router-dom";

export default connect((props) => {
  let { id } = useParams();
  const history = useHistory();
  const [emailid, setEmail] = useState(null);
  const [password, setpassword] = useState(null);
  const [alertFail, setAlertFail] = useState(null);
  const [errorspass, setErrorspass] = useState(false);
  const [emailerror, setEmailerror] = useState(null);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    handleKeyDown();
    let redirect = localStorage.getItem("redirect");
    if (redirect) {
      Modal.state.list.find((o) => o.type == "alert")?.close();
      Modal.state.list.find((o) => o.type == "model")?.close();
      let messages =
        localStorage.getItem("redirect-message") ||
        "Please login to save the book & proceed to purchase";
      alert(messages);
      localStorage.removeItem("redirect-message");
      // localStorage.removeItem("redirect");
    }
  }, []);
  const OnHandleclickSubcribe = () => {
    const data = {
      email: emailid,
      password: password,
    };
    if (validateEmail(emailid)) {
      setEmailerror("");

      if (password === null || password?.length <= 0) {
        setErrorspass(true);
      } else {
        api.login(data).then((res) => {
          if (res && res.statusCode === 200 && res.accessToken) {
            mixins.setAccessToken(res.accessToken);
            api.getSessionID().then((sessionID) => {
              let localSessionID = localStorage.getItem("sessionID");
              localStorage.setItem("sessionID", localSessionID || sessionID);
              api.getuserProfile().then((res) => {
                mixins.setStore("user", res.data, props);
              });
              // mixins.setStore("token", res.accessToken, props);
              let redirect = localStorage.getItem("redirect");
              if (redirect) {
                localStorage.setItem("redirect", "");
                history.push(redirect);
              } else {
                if (res.customer) {
                  localStorage.setItem("customer", true);
                  history.push("/home");
                } else {
                  localStorage.setItem("customer", false);
                  history.push("/dashboard");
                }

                //console.log("response", res);
              }
            });
          } else if (res && res.message) {
            setAlertFail(res.message);
            setTimeout(() => {
              setAlertFail(null);
            }, 4000);
          }
        });
      }
    } else {
      setEmailerror("please provide valid email");
    }
  };
  const handleKeyDown = (event) => {
    addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("signup").click();
      }
    });
  };
  return (
    <div className="logincontainer">
      <div className="maincontainer">
        <div className="formcontainer">
          <div className="title">Sign In</div>
          {alertFail && (
            <Alert severity="error" variant="filled" className="alert">
              <strong>{alertFail}</strong>
            </Alert>
          )}

          <div className="form-input">
            <img src={email} className="icon" />
            <input
              type="text"
              className="input"
              placeholder="Email Address"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
            />
          </div>
          {emailerror ? (
            <span style={{ color: "red" }}>{emailerror}</span>
          ) : null}
          <div className="form-input">
            <img src={lock} className="icon" />
            <input
              type="password"
              className="input"
              placeholder="Password"
              onChange={(e) => {
                setpassword(e.target.value);
                setErrorspass(false);
              }}
              required
            />
          </div>
          {errorspass ? (
            <span style={{ color: "red" }}>please enter password</span>
          ) : null}
          <div className="form-input">
            <label onClick={() => history.push("/forgot-password")}>
              Forgot my password
            </label>
          </div>
          <div className="form-input">
            <button
              type="submit"
              className="buttoncontainer"
              id="signup"
              onClick={OnHandleclickSubcribe}
            >
              Sign In Now
            </button>
          </div>
          <div className="form-input">
            <span>
              Don't have an account?{" "}
              <a
                onClick={() => {
                  if (id) {
                    history.push(`/sign-up/${id}`);
                  } else {
                    history.push("/signup");
                  }
                }}
              >
                Sign Up Now{" "}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
