import React, { useState, useEffect, Fragment } from "react";
import style from './About.module.css'
import story from '../../../assets/images/card122.png'
const About = () => {
    const [cmsinfo, setcmsinfo] = useState({});
    const getcmsinfo = () => {
        api.getcmsInfo().then((res) => {
            
            if (res && res.data){
                let ret= res.data.reduce((ov, nv) => {
                    ov[nv.cmsPages.type] = nv;
                    return ov;
                }, {})
                // console.log(ret);
                setcmsinfo(ret);
            }   
           
        });
    }
    useEffect(() => {
        getcmsinfo();
    }, [])
    return (
        <div className={style['maincontainer']}>
            <div className={style['aboutcontainer']}>
            <div className={style['title-container']}>
                {cmsinfo && cmsinfo["Our Story"] && <div className={style['title']}>Our Story</div>}
                <div className={style['sub-title']}>
                    <img src={story}/>
                </div>
            </div>
            <div className={style['description-container']}>
            {cmsinfo && cmsinfo["Our Story"] && cmsinfo["Our Story"].description &&
           <div dangerouslySetInnerHTML={{ __html: cmsinfo["Our Story"].description }} />
    }
            </div>
            </div>
        </div>
    )
}
export default About;