import { Fragment, useEffect, useState, useRef } from "react";

import "./bootstrap.min.css";
import "./bootstrap.min.js";
import "./jquery.min.js";
import "./faq.css";
import banner from "./banner_faq.jpg"
const faqcontent = (props) => {
    const [cmsinfo, setcmsinfo] = useState({});
    const getcmsinfo = () => {
        api.getcmsInfo().then((res) => {

            if (res && res.data) {
                let ret = res.data.reduce((ov, nv) => {
                    ov[nv.cmsPages.type] = nv;
                    return ov;
                }, {})
                // console.log(ret);
                setcmsinfo(ret);
            }

        });
    }
    useEffect(() => {
        getcmsinfo();
    }, [])
    // console.log(cmsinfo['Privacy Policy']);
    let faqCms = cmsinfo['FAQ'];
    //console.log(faqCms);
    // console.log(faqCms.description);
    return (
      <Fragment>
        <div className="banner singlePageBanner">
          {faqCms && faqCms.file["filePath"] && (
            <img src={faqCms.file["filePath"]} />
          )}
        </div>
        <div className="container faq-page">
          <div className="row pt-5">
            <div className="col-12 p-0">
              {faqCms && faqCms.cmsPages["type"] && (
                <h1
                  dangerouslySetInnerHTML={{ __html: faqCms.cmsPages["type"] }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="container faq-page pb-5">
          <div className="row">
            <div className="col-12 p-0">
              {faqCms && faqCms.description && (
                <div dangerouslySetInnerHTML={{ __html: faqCms.description }} />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
}
export default connect(faqcontent);