import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./newtestimonial.css";
import rightImage from "../../../assets/images/testimonialbackground.png";

const Newtestimonial = () => {
  const [reviews, setReviews] = useState([]);
  const getReviews = () => {
    api.getTestimonials().then((res) => {
      if (res && res.data) {
        const result = res.data.filter(
          (index) => index.publish === 1 && index.approved == 1
        );
        // console.log(result);
        let size = 3;
        if (screen.width <= 390) {
          size = 1;
        } else if (screen.width <= 1024) {
          size = 1;
        } else if (screen.width <= 1280) {
          size = 2;
        }
        setReviews(result.chunk(size));
      }
    });
  };
  useEffect(() => {
    getReviews();
  }, []);
  return (
    <>
      <div className="testimonialContainer">
        <div className={"Testimonials title"}>What people are saying</div>
        <Carousel
          interval={1000}
          variant="dark"
          nextIcon={
            <span className="nxticon">
              <i className="fas fa-chevron-right"></i>
            </span>
          }
          prevIcon={
            <span className="prvicon">
              <i className="fas fa-chevron-left"></i>
            </span>
          }
        >
          {reviews.map((item, i) => (
            <Carousel.Item key={i} item={item} index={i}>
              {/* {console.log("Hello World",item)} */}
              <div className="row align-items-center">
                <div className="col-md-7">
                  <div className="testimonialcontent">
                    {item.map((v, i) => (
                      <div className="cards testimonialCard" key={i}>
                        <div className="chat-bubbel-container">
                          <p>{v.testimonial}</p>
                          <span className="testimonial-name">
                            {v.userDescription}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="rightimage">
                    <img src={rightImage} />
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default Newtestimonial;
