import React, { useState } from "react";
import "./Checkout.css";
import ProductCard from "./ProductCard";
import arrow from "../../../assets/images/arrow.png";
import plus from "../../../assets/images/plus.png";
import OrderDetails from "./OrderDetails";
import AddressCard from "./AddressCard";
import Features from "./Features";

const ShippingDetails = () => {
  return (
    <div className="Checkout">
      <div className="checkout-container container">
        <div className="item-col">
          <div className="cart">
            <div className="cart-count">Shipping Details</div>
            {/* <div className="add-new"><img src={plus} className="img-plus"/>  ADD NEW BOOK</div> */}
          </div>
          <AddressCard />
        </div>
        <div className="bill-col">
          <OrderDetails />
        </div>
      </div>
      <div className="container">
        <Features />
      </div>
    </div>
  );
};
export default ShippingDetails;
