import React, { Component } from 'react';
import fulltexticon from '../SelfPublish/menuIcons/fulltext.png';
import fullimageicon from '../SelfPublish/menuIcons/image.png';
import imagetext from '../SelfPublish/menuIcons/textimage.png';
import ChapterPage from './Boilerplates/ChapterPage';
import { Modal, Box, TextareaAutosize, Button, Select, MenuItem } from '@mui/material';
import chapterIcon from "./menuIcons/chapter.png";

export class LeftToolbar extends Component {
  constructor(props) {
    super(props);
    initialConstruct.bind(this)('LeftToolbar');
    this.state = {
      pageTypes: [
        {
          name: "fulltext",
          pagetype:"fulltext",
          label: "Full Text",
          img: fulltexticon
        },
        {
          name: "fullimage",
          pagetype:"fullimage",
          label: "Full Image",
          img: fulltexticon
        },
        {
          name: "halftexthalfimage",
          pagetype:"halftexthalfimage",
          label: "Image Text",
          img: fulltexticon
        },
        (props.has.includes('chapter') && 
        {
          name: "fulltext",
          pagetype:"fulltext",
          label: "Chapter",
          content: ChapterPage,
          img: chapterIcon
        })
      ]
    }
  }

  render() {
    let self = this;
    let props = this.props;
    return (
      <div className='toolbar' style={{marginRight:'auto'}}>
        {
          self.state.pageTypes.filter(v=>v).map((page, key) => (
            <Button
              variant="outlined"
              key={key}
              className='page-preview'
              draggable
              onDragStart={() => props.setDragedPage(page)}
              onClick={() => props.setCurrentPage(page)}>
              <div className='imageIcon'><img src={page.img} /></div>
              <small>{page.label}</small>
            </Button>
          ))
        }
      </div>
    )
  }
}

export default LeftToolbar;