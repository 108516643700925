import { Fragment, useEffect, useState } from "react";
import Booklet from "../Booklet/Booklet";
import "./HandPrintPreview.css";
import EditSteps from "./EditSteps";
import api from "../../app-services/API";
import { useParams } from "react-router-dom";
import axios from "axios";
import PageNotFound from "../pageNotFound/pageNotFound"

const HandPrintPreview = () => {


    let { id } = useParams();
    const generateBlankPage = () => {
        let arr = [];
        arr.length = 19;
        arr = arr.fill({});
        let pages = arr.map((v, i) => {
            return {
                pagetype: "BlankPage",
                name: `Page${i + 1}`,
                label: `Page ${i + 1} - ${i + 2}`,
            }
        });
        return pages;
    };

    const [bookPages, setBookPages] = useState(null);
    //console.log({ bookPages });
    const [pages, setPages] = useState([

        // {
        //     pagetype: "TitlePage",
        //     label: "TitlePage",
        // },
        // {
        //     pagetype: "CopyRight",
        //     label: "CopyRight",
        // },
        // {
        //     pagetype: "DedicationPage",
        //     label: "DedicationPage",
        // },
        // {
        //     pagetype: "BlankPage",
        //     label: "BlankPage",
        // },
        // {
        //     pagetype: "Static1",
        //     label: "Static1",
        // },
        {
            pagetype: "birdPage",
            label: "BIRD PAGE",
        },
        // {
        //     pagetype: "Static2",
        //     label: "Static2",
        // },
        {
            pagetype: "owlPage",
            label: "OWL PAGE",
        },
        // {
        //     pagetype: "Static3",
        //     label: "Static3",
        // },
        {
            pagetype: "giraffe",
            label: "GIRAFFE PAGE",
        },
        {
            pagetype: "butterfly",
            label: "BUTTERFLY PAGE",
        },
        {
            pagetype: "crab",
            label: "CRAB PAGE",
        },
        // {
        //     pagetype: "Static4",
        //     label: "Static4",
        // },
        {
            pagetype: "elephant",
            label: "ELEPHANT PAGE",
        },
        // {
        //     pagetype: "Static5",
        //     label: "Static5",

        // },
        // {
        //     pagetype: "Static6",
        //     label: "Static6",
        // },
        {
            pagetype: "family",
            label: "NIGHT FAMILY PAGE",
        },
        // {
        //     pagetype: "AuthorAndArtist",
        //     label: "Author And Artist",
        // },
        // {
        //     pagetype: "BackCover",
        //     label: "Back Cover",
        // },

    ]);

    useEffect(() => {
        getList();

    }, [])

    const getList = () => {
        api.getHandPrint(id).then(async (response) => {
            if (response && response.data && response.data) {
                let book = response.data[0];
                let savedBook = JSON.parse(book.savedBook);
                let fileNames = Object.keys(savedBook);
                let promises = fileNames.map(async (fileName, i) => {
                    try {
                        let html = await axios.get(book.filePath + "/" + fileName + ".html");
                        return { key: fileName, value: html.data };
                    } catch (err) {
                        return { key: fileName, value: "" };
                    }
                });
                Promise.all(promises).then((fullfilled) => {
                    book.content = fullfilled;
                    setBookPages(book);
                });
            }
        })

    }

    const onPage = (obj, i, arr) => {
        let tempPage = bookPages.content.find((pageObj) => {
            return pageObj.key == (obj.name || obj.pagetype);
        })

        return {

            style: {
                height: "100%",
                backgroundPosition: "center",
                backgroundSize: "cover"
            },
            content: tempPage ? () => {
                return <div>
                    <div className="previewPages" dangerouslySetInnerHTML={{ __html: tempPage.value || '' }}></div>
                    <div className="col">
                        <div className="d-flex" style={{ hight: '100%' }}>
                            <span className="m-auto">{tempPage.key} / 7</span>
                        </div>
                    </div>
                </div>
            } : () => {
                return <div>
                    <div style={{ backgroundColor: "#ffff" }}><PageNotFound /></div>
                    <div className="col">
                        <div className="d-flex" style={{ hight: '100%' }}>
                            <span className="m-auto">Page not found</span>
                        </div>
                    </div></div>
            }
        }
    };

    const stepfilter = (step) => {
        return step.value != 1;
    }
    return (
        <Fragment >
            <div className='handprint-preview pb-5'>
                <div className="row no-gutters ">
                    <div className='col-1'></div>
                    {
                        bookPages &&
                        <div className='col'>
                            <Booklet
                                pages={pages}
                                onPage={onPage} />
                        </div>

                    }
                    <div className='col-1'></div>
                </div>
                <div className="row">
                    <div className='col'></div>
                    <div className="col-10">
                        <EditSteps stepfilter={stepfilter} />
                    </div>
                    <div className='col'></div>
                </div>
            </div>
        </Fragment>
    )

}
export default connect(HandPrintPreview);