import { TextField } from "@material-ui/core";
import "./AppInput.css";
export default (props) => {
    return (
        ["textarea"].includes(props.type)?
        <div className="AppInput">
            <textarea {...props} style={{padding:'10px',width:'100%', textAlign:'center'}} rows={4}  ></textarea>
        </div>
        :
        <div className="AppInput">
            <TextField
                {...props}
            />
        </div>
    )
}