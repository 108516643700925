import React, { useEffect } from "react";
import style from './Pages.module.css'
import Features from '../components/home/features/Features'
import Footer from "../components/layouts/footer/Footer";
import About from '../components/home/about/About'
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import Banner from "../components/home/banner/Banner";
import Testimonials from "../components/home/testimonials/Testimonials";
import Newtestimonial from "../components/home/testimonials/Newtestimonial";
import StaticBanner from "../components/home/banner/StaticBanner";
import Types from "../components/home/types/Types";
import TypesNew from "../components/home/types/TypesNew";
import BannerNew from "../components/home/banner/BannerNew";
import Updatebanner from '../components/home/banner/bannernew/Banner';
import { useHistory } from "react-router-dom";
const Home = (props) => {
    const history = useHistory()
    // console.log("Home props", props);

    //mixins.setStore("loading",true,props);
    useEffect(()=>{
    if(localStorage.getItem("customer") === "false")
{
    history.push("/dashboard")
}
    },[])
    return (
      <div className={style["container"]}>
        <Announcement />
        <Header current={props.location.pathname} />
        {/* <Banner /> */}
        {/* <BannerNew /> */}
        <Updatebanner />
        {/* <StaticBanner /> */}
        {/* <Types/> */}
        <TypesNew />
        <Features />
        {/* <Testimonials /> */}
        <Newtestimonial />
        <About />
        <Footer />
      </div>
    );
}
export default connect(Home);