import { Fragment, useEffect, useState, useRef } from "react";
import "./paymentConfirmation.css";

const paymentConfirmation = (props) => {
    return (
        <Fragment >
            <div className="container pt-5">
                <div className='row'>
                    <div className="col-12">
                        <p>Customer Name</p>
                        <p>Thank you for placing an order. </p>
                        <p>If you have any questions, you can email us at customer@plotterbox.com</p>
                    </div>
                </div>
            </div>
            <section className="SecondOrderSection pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Your Order #</h3>
                            <hr></hr>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                        <p>Placed on <span className="dateTime">DATE & TIME</span></p>
                        </div>
                        <div className="col-12">
                            <h4>Shipping Info</h4>
                            <p>Customer Name: <span className="shipping">Name</span></p>
                            <p>Address 1: <span className="shipping">Address</span></p>
                            <p>Address 2: <span className="shipping">Address</span></p>
                            <p>ZipCode: <span className="shipping">ZipCode</span></p>
                            <p>Country: <span className="shipping">India</span></p>
                            <p>Contact No.: <span className="shipping">9874563214</span></p>
                        </div>
                        {/* <div className="col-6">
                            <div className="rightText">
                                <p>Placed on <span className="dateTime">DATE & TIME</span></p>
                            </div>
                        </div> */}
                        <div className="col-12">
                            <h5>Payment Method</h5>
                            <p>COD/ Paypal</p>
                        </div>
                        <div className="col-12 pt-5">
                            <table className="customTable">
                                <thead className="head">
                                    <td className="item">Items</td>
                                    <td className="qty">Quantity</td>
                                    <td className="price">Price</td>
                                </thead>
                                <tr className="productList">
                                    <td>Product Name</td>
                                    <td>1</td>
                                    <td>$60</td>
                                </tr>
                                <tr className="subTotal topborder">
                                    <td colSpan={2} className="textRight">Subtotal:</td>
                                    <td>$33 SGD</td>
                                </tr>
                                <tr className="subTotal">
                                    <td colSpan={2} className="textRight">Shipping & Handling:</td>
                                    <td>$5 SGD</td>
                                </tr>
                                <tr className="subTotal">
                                    <td colSpan={2} className="textRight">Grand Total (Excl. Tax):</td>
                                    <td>$5 SGD</td>
                                </tr>
                                <tr className="subTotal">
                                    <td colSpan={2} className="textRight">Tax:</td>
                                    <td>$5 SGD</td>
                                </tr>
                                <tr className="subTotal bottomborder">
                                    <td colSpan={2} className="textRight">Grand Total (Incl. Tax):</td>
                                    <td>$5 SGD</td>
                                </tr>
                                <tr className="subTotal">
                                    <td colSpan={2} className="textRight">Grand Total to be charged :</td>
                                    <td>$555 SGD</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default connect(paymentConfirmation);