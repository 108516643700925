import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@mui/material/Pagination";
import api1 from "../../../../app-services/API/api1";
export default connect((props) => {
  const [profile, setProfile] = useState("");
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [courseList, setCourseList] = useState([]);
  const [deleteList, setDeleteList] = useState("");
  useEffect(() => {
    getallBookList(page);
  }, []);
  const getallBookList = (page = 1) => {
    api.getAllBooks(page).then((res) => {
      if (res && res.data && res.total) {
        setCourseList(res.data);
        setTotalPages(res.total);
      } else {
        setCourseList([]);
        setTotalPages(0);
      }
      //console.log("response book page ", res);
    });
  };
  const deleteBookList = (id) => {
    api.deleteBookList(id).then((res) => {
      if (res && res.statusCode === 200) {
        setDeleteList(res.data);
      }
    });
  };
  /*custom pagination */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= totalPages) {
      getallBookList(newPage);
    }
  };

  const handleDelete = (params) => {
    deleteBookList(params.id);
  };
  function CustomPagination() {
    return (
      <Pagination
        // className={classes.root}
        color="primary"
        count={totalPages}
        page={page}
        onChange={handleChangePage}
      />
    );
  }
  const columns = [
    { width: 100, field: "id", headerName: "ID" },
    {
      field: "bookTitle",
      headerName: "Book Title",
      width: 250,
    },
    {
      field: "bookPrice",
      headerName: "Book Price",
      width: 250,
    },
    {
      field: "catogory",
      headerName: "Catogory",
      width: 250,
    },
    {
      field: "isbn",
      headerName: "ISBN",
      width: 250,
    },
    {
      field: "totalPages",
      headerName: "Total Pages",
      width: 250,
    },
    {
      field: "publisherName",
      headerName: "Publisher Name",
      width: 250,
    },
    {
      field: "publishedOn",
      headerName: "Published On",
      width: 250,
    },
    {
      field: "genre",
      headerName: "Genre",
      width: 250,
    },
    {
      field: "author",
      headerName: "Author",
      width: 250,
    },
    {
      field: "image",
      headerName: "Image",
      width: 250,
      renderCell: (params) => (
        <img
          src={params.row.bookImageUrl}
          alt="subject icon"
          style={{ margin: "10px", height: "50px", width: "50px" }}
        />
      ),
    },
    {
      field: "ageGroups",
      headerName: "Age Groups",
      width: 250,
    },
    {
      field: "dimension",
      headerName: "Dimension",
      width: 250,
    },
    {
      field: "standardPrice",
      headerName: "Standard Price",
      width: 250,
    },
    {
      field: "customPrice",
      headerName: "Custom Price",
      width: 250,
    },
    {
      field: "tax",
      headerName: "Tax",
      width: 250,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 250,
    },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   sortable: false,
    //   width: 100,
    //   disableClickEventBubbling: true,
    //   renderCell: (params) => (
    //     <EditIcon
    //       color="primary"
    //       onClick={() => {
    //         //import Pagination from '@mui/material/Pagination';
    //       }}
    //     />
    //   ),
    // },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <DeleteIcon
          color="primary"
          cursor="pointer"
          onClick={() => {
            handleDelete(params);
          }}
        ></DeleteIcon>
      ),
    },
  ];

  return (
    <div>
      Book Table
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={
            courseList &&
            courseList.map((item) => {
              return {
                id: item.id,
                bookTitle: item.bookTitle,
                catogory: item.category,
                isbn: item.ISBN,
                totalPages: item.totalPages,
                publisherName: item.bookPublisher?.publisherName,
                publishedOn: item?.publisherDate,
                bookPrice: item.bookPrice,
                genre: item.booksGenre?.genre?.name,
                author: item.booksAuthor?.author?.firstName,
                image:
                  item.product?.bookImageUrl && `${item.product.bookImageUrl}`,
                ageGroups: item.bookDetail?.ageGroups,
                dimension: item.bookDetail?.dimension,
                standardPrice: item.product?.standardPrice,
                customPrice: item.product?.actualPrice,
                tax: item.product?.tax,
                currency: item.product?.currency,
              };
            })
          }
          columns={columns}
          pageSize={5}
          responsive
          components={{
            Pagination: CustomPagination,
          }}
        />
      </div>
    </div>
  );
});
