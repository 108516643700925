import React, { useEffect, useState } from "react";
import "./Personalize.css";
import Steps from "../Steps";
import personalizeFlow from "./personalize-flow";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import api from "../../../app-services/API";
import { useSelector } from "react-redux";
import axios from "axios";
import Base from "./basecharacter";

export default connect((props, ref) => {
  const history = useHistory();
  const stepref = useRef(null);
  const cardref = useRef(null);
  const {
    alert,
    editmode,
    editstep = 0,
    data,
    onProceed = () => {
      history.push(`/preview-book/${props.id}`);
    },
    closable = false,
    onClose = () => ({}),
  } = props;
  const [formStep, setFormStep] = useState(
    [
      { value: 1, label: "Name" },
      { value: 2, label: "Gender" },
      { value: 3, label: "Skin Tone" },
      { value: 4, label: "T-shirt Colour" },
      { value: 5, label: "Hair Style" },
      { value: 6, label: "Hair Colour" },
      { value: 7, label: "Eyewear" },
      //{ value: 8, label: "Dedication" },
    ].filter((obj) => {
      if (editmode) {
        return data.value == obj.value;
      } else {
        return true;
      }
    })
  );
  const [questions, setQuestion] = useState(personalizeFlow(formStep));
  const [step, setStep] = useState(editstep);
  const customCharacter = useSelector((state) => state.customCharacter);
  const [steps, setSteps] = useState(customCharacter);
  const [charectorStyles, setCharectorStyles] = useState([]);
  const [overlayStyle, setOverlayStyle] = useState({});

  const setAnswer = (obj, i) => {
    let keys = Object.keys(obj);
    let tmpSteps = [];
    tmpSteps = questions.map((o, i) => ({
      ...o,
      ...steps[i],
      ...(customCharacter[i] || {}),
    }));
    tmpSteps[i].ans = obj;
    if (keys.includes("gender")) {
      let hairStyleIndex = tmpSteps.findIndex((o) => {
        return o.name == "hair-style";
      });
      // tmpSteps[hairStyleIndex].ans = {'hair-style':''};
      tmpSteps[hairStyleIndex] = [];
    }
    let refobj = tmpSteps[i];
    if (["text", "textarea"].includes(refobj.collect[0].type)) {
      clearTimeout(cardref.current && cardref.current.sio);
      cardref.current.sio = setTimeout(() => {
        mixins.setStore(
          "customCharacter",
          tmpSteps.map((temp) =>
            Object.filter(temp, (v, k) => !["collect", "overlay"].includes(k))
          ),
          props
        );
        setSteps(tmpSteps);
        //console.log({ setAnswer: tmpSteps });
      }, 500);
    } else {
      mixins.setStore(
        "customCharacter",
        tmpSteps.map((temp) =>
          Object.filter(temp, (v, k) => !["collect", "overlay"].includes(k))
        ),
        props
      );
      setSteps(tmpSteps);
    }
  };

  useEffect(() => {
    updateStyles();
    updateHairStyle();
  }, [customCharacter]);

  const validateAns = (ques, index) => {
    switch (ques.type) {
      case "option":
        return Boolean(steps[index]?.ans?.value);
      case "image-form":
        if (steps[index]?.ans?.first_name == "") {
          return false;
        } else {
          if (typeof steps[index]?.ans == "object") {
            return Boolean(
              Object.entries(steps[index]?.ans)
                .map(([key, val]) => val)
                .join("")
            );
          } else {
            return Boolean(steps[index]?.ans);
          }
        }
        return false;

      case "booklet":
        return true;
      case "finalpage":
        return true;
    }
  };

  const nextStep = (obj, index, finish) => {
    let tmpSteps = [...steps];
    if (!finish && index + 1 < questions.length) {
      tmpSteps[index] = { ...obj, ...(tmpSteps[index] || {}) };
      setStep(index + 1);
      setSteps(tmpSteps);
    } else {
      mixins.setStore("newbook", tmpSteps, props);
      alert.close();
      onProceed();
      setTimeout(() => {
        const platform = window.navigator.platform;
        let appleSystems = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
        if (appleSystems.includes(platform)) {
          window.location.reload(true);
        }
      });
    }
  };

  const prevStep = (index, a) => {
    if (index == 0) {
      alert.close();
    } else {
      setStep(step - 1);
    }
  };

  const updateStyles = () => {
    let skinStyle = customCharacter.find((o) => o.name == "skin");
    let hairStyle = customCharacter.find((o) => o.name == "hair");
    let tshirtStyle = customCharacter.find((o) => o.name == "tshirt");
    let eyewearStyle = customCharacter.find((o) => o.name == "eyewear");
    setOverlayStyle({
      "--skin":
        (skinStyle && skinStyle.ans && skinStyle.ans.skin) || "transparent",
      "--hair":
        (hairStyle && hairStyle.ans && hairStyle.ans.hair) || "transparent",
      "--shoes": "#ae7928",
      "--shirt":
        (tshirtStyle && tshirtStyle.ans && tshirtStyle.ans.tshirt) ||
        "transparent",
      "--short": "#597aab",
      "--glass":
        eyewearStyle && eyewearStyle.ans && eyewearStyle.ans.eyewear == "yes"
          ? "black"
          : "transparent",
    });
  };

  const updateHairStyle = () => {
    return new Promise((resolve, reject) => {
      let hairStyle = customCharacter.find((o) => o.name == "hair-style");
      if (hairStyle && hairStyle.ans && hairStyle.ans["hair-style"].parse()) {
        let ans = hairStyle.ans["hair-style"].parse();
        let { gender, style } = ans;
        let imgObj = charectorStyles.find(
          (o) => o.gender == gender && o.style == style
        );
        if (imgObj) {
          let tmpQuestions = questions.map((q) => {
            q.overlay = () => (
              <div
                className="overlay-customcharector"
                dangerouslySetInnerHTML={{ __html: imgObj.imgresponce }}
              ></div>
            );
            return q;
          });
          setQuestion(tmpQuestions);
        }

        resolve(hairStyle);
      } else {
        let imgObj = charectorStyles[1];
        if (imgObj) {
          let tmpQuestions = questions.map((q) => {
            q.overlay = () => (
              <div className="overlay-customcharector">
                <Base />
              </div>
            );
            return q;
          });
          setQuestion(tmpQuestions);
        }
        resolve();
      }
    });
  };

  useEffect(() => {
    updateHairStyle();
  }, [charectorStyles, customCharacter]);
  const getAssets = () => {
    let promiseCharAssets = api.getCharacterAssets();
    let promiseCustomCharImages = api.listCustomCharacterImage();

    Promise.all([promiseCharAssets, promiseCustomCharImages]).then(
      (results) => {
        let res = results[0];
        let assets = {};
        formStep.map((step) => {
          assets[step.label] = res.data
            .filter((o) => o.typeId == step.value)
            .map((o) => {
              o.type = o.assetType.type;
              if (o.type != "Hair Style") {
                o.code = JSON.parse(o.code);
                o.code = `rgb(${o.code.R},${o.code.G},${o.code.B})`;
              }

              return o;
            });
        });
        let skinRadios = questions[3].collect[0].radios;
        if (assets["Skin Tone"]) {
          questions[3].collect[0].typeName = "Skin Tone";

          questions[3].collect[0].radios = assets["Skin Tone"].map((o, i) => ({
            ...(skinRadios[i] || {}),
            label: (
              <div
                className="skin-tone"
                style={{
                  backgroundColor: o.code,
                  height: "40px",
                  width: "40px",
                  border: "1px solid grey",
                  borderRadius: "50%",
                }}
              ></div>
            ),
            value: o.code,
          }));
        }
        let hairColor = questions[6].collect[0].radios;
        if (assets["Hair Colour"]) {
          questions[6].collect[0].typeName = "Hair Colour";
          questions[6].collect[0].radios = assets["Hair Colour"].map(
            (o, i) => ({
              ...(hairColor[i] || {}),
              label: (
                <div
                  className="hair-color"
                  style={{
                    backgroundColor: o.code,
                    height: "40px",
                    width: "40px",
                    border: "1px solid grey",
                    borderRadius: "50%",
                  }}
                ></div>
              ),
              value: o.code,
            })
          );
        }
        let hairStyle = questions[5].collect[0].radios;
        if (assets["Hair Style"]) {
          questions[5].collect[0].typeName = "Hair Style";
          questions[5].collect[0].radios = assets["Hair Style"].map((o, i) => ({
            ...(hairStyle[i] || {}),
            label: <img src={o.file.filePath} />,
            value: o.code,
          }));
          questions[5].collect[0].filter = (obj, anslist) => {
            let genderObj = anslist.find((o) => o.name == "gender");
            return obj.value.includes(
              genderObj.ans.gender == "boy" ? "boy" : "girl"
            );
          };
        }
        let t_shirt_Color = questions[4].collect[0].radios;
        if (assets["T-shirt Colour"]) {
          questions[4].collect[0].typeName = "T-shirt Colour";
          questions[4].collect[0].radios = assets["T-shirt Colour"].map(
            (o, i) => ({
              ...(t_shirt_Color[i] || {}),
              label: (
                <div
                  className="hair-color"
                  style={{
                    backgroundColor: o.code,
                    height: "40px",
                    width: "40px",
                    border: "1px solid grey",
                    borderRadius: "50%",
                  }}
                ></div>
              ),
              value: o.code,
            })
          );
        }
        let customImages = results[1].data;
        let customImagesPromises = customImages.map(
          (o) =>
            new Promise((resolve, reject) => {
              axios
                .get(o.filePath)
                .then((res) => resolve({ ...o, imgresponce: res.data }))
                .catch((err) => resolve({ ...o, imgresponce: "" }));
            })
        );
        Promise.all(customImagesPromises)
          .then((res) => {
            setCharectorStyles(res);
          })
          .catch((err) => console.log({ err }));
      }
    );
  };
  const goBack = () => {
    props.alert.close();
    let subCat = localStorage.subCategory;
    history.push(`/custom-books/product-list/${subCat}`);
  };

  useEffect(() => {
    getAssets();
  }, []);

  return (
    <div className="personolize" style={overlayStyle} ref={cardref}>
      {closable === true && (
        <i
          className="close fa fa-times"
          onClick={() => onClose(alert.close, step, steps[step])}
        ></i>
      )}
      {closable === false && (
        <i className="close fa fa-times" onClick={() => goBack()}></i>
      )}
      <Steps
        ref={stepref}
        questions={questions.filter((o) => !o.hidden)}
        index={step}
        setAnswer={(...arg) => setAnswer(...arg)}
        validateAns={(...arg) => validateAns(...arg)}
        answers={steps}
        nextStep={nextStep}
        prevStep={prevStep}
        editmode={editmode}
        setStep={(i) => setStep(i + 1)}
        closable={closable}
      ></Steps>
    </div>
  );
});
