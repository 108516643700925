import React, { Component } from "react";
import PageEditor from "../PageEditor/PageEditor";
import "./CustomCharector.css";
import mixins from "../../app-services/mixins";
import { readUrl } from "../PageEditor/support";
import Frontpage from "./chapters/chapterone/frontpage.txt";
import personalizeFlow from "../common/Personalize/personalize-flow";
import Personalize from "../common/Personalize/Personalize";
import { withRouter } from "react-router";
// Chapter Four Content
import Mainpage from "./chapters/MainPage.txt";
import FrontCover from "./chapters/frontCover.txt";
import CopyRight from "./chapters/copyRight.txt";
import Ch4frontpage from "./chapters/chapterfour/frontpage.txt";
import Ch4pageone from "./chapters/chapterfour/pageone.txt";
import Ch4pagetwo from "./chapters/chapterfour/pagetwo.txt";
import Ch4pagethree from "./chapters/chapterfour/pagethree.txt";
import Ch4pagefour from "./chapters/chapterfour/pagefour.txt";
import Ch4pagefive from "./chapters/chapterfour/pagefive.txt";
import Ch4pagesix from "./chapters/chapterfour/pagesix.txt";
import Ch4pageseven from "./chapters/chapterfour/pageseven.txt";
import Ch4pageight from "./chapters/chapterfour/pageight.txt";
import Ch4pagenine from "./chapters/chapterfour/pagenine.txt";
// Chapter Five Content
import Ch5frontpage from "./chapters/chapterfive/frontpage.txt";
import BlankPage from "./chapters/chapterfive/blank.txt";
// Chapter Seven Content
import Ch7frontpage1 from "./chapters/chapterseven/left.txt";
import Ch7frontpage2 from "./chapters/chapterseven/frontpage.txt";
// Chapter Final Content
import Chfinalfrontpage from "./chapters/finalchapter/frontpage.txt";
import Backcover from "./chapters/backcover.txt";
import dedicationPage from "./chapters/dedication.txt";
import ChapterNineFrontpage from "./chapters/chapternine/frontpage.txt";
import ChapterNineFrontpage2 from "./chapters/chapternine/frontpage2.txt";

import { Button } from "@mui/material";

const CustomCharacterFlow = personalizeFlow();
// mixins.addScript("https://html2canvas.hertzen.com/dist/html2canvas.min.js");
// mixins.addScript("https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js");

export class CustomCharector extends Component {
  constructor(props) {
    super(props);
    this.BOOKID = props.match.params.id;
    initialConstruct.bind(this)("CustomCharector");
    const book = props.store.book.customCharacter;
    this.pageeditorref = React.createRef();

    this.state = {
      staticPages: {
        frontcover: FrontCover,
        mainpage: Mainpage,
        copyRight: CopyRight,
        frontpage: Frontpage,
        ch4frontpage: Ch4frontpage,
        ch4pageone: Ch4pageone,
        ch4pagetwo: Ch4pagetwo,
        ch4pagethree: Ch4pagethree,
        ch4pagefour: Ch4pagefour,
        ch4pagefive: Ch4pagefive,
        ch4pagesix: Ch4pagesix,
        ch4pageseven: Ch4pageseven,
        ch4pageight: Ch4pageight,
        ch4pagenine: Ch4pagenine,
        ch5frontpage: Ch5frontpage,
        ch7frontpage1: Ch7frontpage1,
        ch7frontpage2: Ch7frontpage2,
        chapterNineFrontpage: ChapterNineFrontpage,
        chapterNineFrontpage2: ChapterNineFrontpage2,
        chfinalfrontpage: Chfinalfrontpage,
        backcover: Backcover,
        dedication: dedicationPage,
        ch5blank: BlankPage,
      },
      savedBook: {},
      bolierplates: {},
      svgs: {},
      customNew: {},
      lastPageNo: 31,
      pages: [
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `backcover`,
          chapter: 1,
          label: `1`,
          content: book && book[`backcover`],
          readonly: true,
        },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `frontcover`,
          chapter: 1,
          label: `2`,
          content: book && book[`frontcover`],
          readonly: true,
        },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `mainpage`,
          chapter: 1,
          label: `3`,
          content: book && book[`mainpage`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `copyRight`,
          chapter: 1,
          label: `4`,
          content: book && book[`copyRight`],
          readonly: true,
        },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `frontpage`,
          chapter: 1,
          label: `5`,
          content: book && book[`frontpage`],
          readonly: true,
        },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `ch4frontpage`,
          chapter: 4,
          label: `6`,
          content: book && book[`ch4frontpage`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `ch4pageone`,
          chapter: 4,
          label: `7`,
          content: book && book[`ch4pageone`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `ch4pagetwo`,
          chapter: 4,
          label: `8`,
          content: book && book[`ch4pagetwo`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `ch4pagethree`,
          chapter: 4,
          label: `9`,
          content: book && book[`ch4pagethree`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `ch4pagefour`,
          chapter: 4,
          label: `10`,
          content: book && book[`ch4pagefour`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `ch4pagefive`,
          chapter: 4,
          label: `11`,
          content: book && book[`ch4pagefive`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `ch4pagesix`,
          chapter: 4,
          label: `12`,
          content: book && book[`ch4pagesix`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `ch4pageseven`,
          chapter: 4,
          label: `13`,
          content: book && book[`ch4pageseven`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `ch4pageight`,
          chapter: 4,
          label: `14`,
          content: book && book[`ch4pageight`],
          readonly: true,
        },
        {
          pagetype: "fulltext",
          contentSize: 200,
          name: `ch4pagenine`,
          chapter: 4,
          label: `15`,
          content: book && book[`ch4pagenine`],
          readonly: true,
        },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `ch5frontpage`,
          chapter: 5,
          label: `16`,
          content: book && book[`ch5frontpage`],
          readonly: true,
        },
        // {
        //   pagetype: "halftexthalfimage",
        //   contentSize: 200,
        //   name: `ch5blank`,
        //   chapter: 7,
        //   label: `14`,
        //   content: book && book['ch5blank'],
        //   readonly: true,
        // },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `ch7frontpage1`,
          chapter: 7,
          label: `17`,
          content: book && book[`ch7frontpage1`],
          readonly: true,
        },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `ch7frontpage2`,
          chapter: 7,
          label: `18`,
          content: book && book[`ch7frontpage2`],
          readonly: true,
        },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `chapterNineFrontpage`,
          chapter: 9,
          label: `19`,
          content: book && book[`chapterNineFrontpage`],
          readonly: true,
        },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `chapterNineFrontpage2`,
          chapter: 9,
          label: `20`,
          content: book && book[`chapterNineFrontpage2`],
          readonly: true,
        },
        {
          pagetype: "halftexthalfimage",
          contentSize: 200,
          name: `chfinalfrontpage`,
          chapter: 1,
          label: `21`,
          content: book && book[`chfinalfrontpage`],
          readonly: true,
        },
        // {
        //   pagetype: "fulltext",
        //   contentSize: 500,
        //   name: `dedication`,
        //   chapter: 1,
        //   label: `21`,
        //   content: book && book[`dedication`],
        //   readonly: true,
        //   style: { position: "relative" },
        // },
      ],
      overlayStyle: {},
    };
  }

  getCustomCharacterStyles() {
    const customCharacter = this.props?.store?.customCharacter || [];
    if (!customCharacter || customCharacter.length == 0) {
      return {};
    }
    let skinStyle = customCharacter.find((o) => o.name == "skin");
    let hairStyle = customCharacter.find((o) => o.name == "hair");
    let tshirtStyle = customCharacter.find((o) => o.name == "tshirt");
    let eyewearStyle = customCharacter.find((o) => o.name == "eyewear");
    let customname = customCharacter.find((o) => o.name == "name");
    let dedication = customCharacter.find((o) => o.name == "dedication");
    let fullname =
      customname &&
      customname.ans &&
      (customname?.ans?.first_name || "") +
        " " +
        (customname?.ans?.last_name || "");
    let firstname = customname && customname.ans && customname.ans.first_name;
    let genders = customCharacter.find((o) => o.name == "gender");
    let currentGender = genders && genders["ans"] && genders["ans"]["gender"];

    return {
      "--skin":
        (skinStyle && skinStyle.ans && skinStyle.ans.skin) || "transparent",
      "--hair":
        (hairStyle && hairStyle.ans && hairStyle.ans.hair) || "transparent",
      "--shoes": "#ae7928",
      "--shirt":
        (tshirtStyle && tshirtStyle.ans && tshirtStyle.ans.tshirt) ||
        "transparent",
      "--short": "#597aab",
      "--glass":
        eyewearStyle && eyewearStyle.ans && eyewearStyle.ans.eyewear == "yes"
          ? "black"
          : "none",
      "--name": fullname,
      "--fname": firstname,
      "--currentGender": currentGender,
      "--dedictaion_text": dedication?.ans?.dedication,
    };
  }

  getContent(book, page) {
    let ele = mixins.getDataNode(book);
    let overlayStyle = this.getCustomCharacterStyles();

    let custom_name = ele.querySelector("#custom_name");
    if (custom_name && overlayStyle["--fname"]) {
      custom_name.innerText = overlayStyle["--fname"];
    }

    // For First Name
    let first_name = ele.querySelectorAll("#first_name");
    let items = [...first_name].map((elem) => {
      elem.innerText = overlayStyle["--fname"];
    });
    // For Gender Name
    let gender = ele.querySelectorAll("#gender");
    let items1 = [...gender].map((elem) => {
      elem.innerText = overlayStyle["--currentGender"];
    });
    // For He, she, and His, her

    let Gender = overlayStyle["--currentGender"];
    if (Gender == "boy") {
      let his = ele.querySelectorAll("#his");
      let items2 = [...his].map((elem) => {
        elem.innerText = "his";
      });
      let he = ele.querySelectorAll("#he");
      let items3 = [...he].map((elem) => {
        elem.innerText = "he";
      });
    } else {
      let his = ele.querySelectorAll("#his");
      let items2 = [...his].map((elem) => {
        elem.innerText = "her";
      });
      let he = ele.querySelectorAll("#he");
      let items3 = [...he].map((elem) => {
        elem.innerText = "she";
      });
    }

    let dedictaion_text = ele.querySelector("#dedictaion_text");
    if (dedictaion_text && overlayStyle["--dedictaion_text"]) {
      dedictaion_text.innerText = overlayStyle["--dedictaion_text"];
    }

    book = ele.innerHTML;
    //console.log({overlayData:ele.querySelector("#overlay-data"),page:page.name,book});

    if (page.chapter) {
      let svgObj = this.state.svgs[page.chapter];
      //  console.log("OBejs",svgObj);
      //let svgEle = mixins.getDataNode(this.state.svgs[page.chapter]);
      let overlayData = ele.querySelector("#overlay-data");
      if (overlayData && svgObj) {
        let svg = overlayData.querySelector("svg,img");
        if (!svg) {
          return book;
        }

        let svgStyle = svg?.style?.getStyle();
        // svgStyle.height = svg.getAttribute('height');
        // svgStyle.width = svg.getAttribute('width');
        let displayStyle = { ...svgStyle, ...overlayStyle };
        let styleString = Object.styleString(svgStyle);
        svg.remove();
        overlayData.innerHTML += `<img src="${svgObj.svgbase64}" style='${styleString}' />`;
        // console.log("Image ", `<img src="${svgObj.base64}" style='${styleString}' />`)
      }

      book = ele.innerHTML;
      //book.co
    }

    return book;
  }

  getLatestBook(msg = null) {
    const customCharacter = this.props.store?.customCharacter || [];
    return new Promise((resolve, reject) => {
      api
        .getUpdatedSelfBook(
          {
            sessionId: localStorage.getItem("sessionID") || null,
            type: "customcharacter",
          },
          msg
        )
        .then((responce) => {
          if (responce && responce.data) {
            let payload = responce.data;
            let remoteCustomeCharector = JSON.parse(payload.attributes || "[]");
            let largeAttributes =
              customCharacter.length > remoteCustomeCharector.length
                ? customCharacter
                : remoteCustomeCharector;
            this.setStore(
              "customCharacter",
              largeAttributes.map((o) => ({
                ...o,
                ...(remoteCustomeCharector.find((v) => v.name == o.name) || {}),
              }))
            );
            this.setState({
              savedBook: payload,
            });
            setTimeout(() => {
              resolve(payload);
            });
          } else {
            resolve(this.state.savedBook);
          }
        })
        .catch(reject);
    });
  }

  onUpdate(...arg) {
    if (arg[0] == "dedication") {
      this.updateBook();
    }
  }

  updateBoilerplate() {
    return new Promise((resolve, reject) => {
      let { staticPages } = this.state;
      let promises = Object.entries(staticPages).map(([key, url]) => {
        return new Promise((resolve, reject) => {
          readUrl(url)
            .then((responce) => resolve({ key, responce }))
            .catch(reject);
        });
      });
      Promise.all(promises)
        .then((responce) => {
          let bolierplates = responce.reduce(
            (o, n) => ({ ...o, [n.key]: n.responce }),
            {}
          );
          this.setState({
            bolierplates: bolierplates,
          });
          resolve(bolierplates);
        })
        .catch(reject);
    });
  }

  updateSVGs() {
    return new Promise((resolve, reject) => {
      const customCharacter = this.props.store?.customCharacter || [];
      let hairStyle = customCharacter.find((o) => o.name == "hair-style");
      let { gender, style } = hairStyle.ans["hair-style"].parse();
      let overlayStyle = this.getCustomCharacterStyles();
      let svgChapStyle =
        gender == "boy"
          ? {
              1: {
                svgViewBox: "50 20 150 200",
                height: 500,
                width: 280,
              },
              4:
                style == "02"
                  ? {
                      svgViewBox: "0 0 590 817",
                      height: 500,
                      width: 350,
                    }
                  : style == "03" || style == "04"
                  ? {
                      svgViewBox: "0 0 590 817",
                      height: 500,
                      width: 350,
                    }
                  : {
                      svgViewBox: "0 0 590 817",
                      height: 500,
                      width: 350,
                    },
              5: {
                svgViewBox: "0 0 200 300",
                height: 350,
                width: 250,
              },
              7: {
                svgViewBox: "0 0 200 170",
                height: 310,
                width: 310,
              },
              9:
                style == "01"
                  ? {
                      svgViewBox: "0 0 150 110",
                      height: 140,
                      width: 140,
                    }
                  : style == "02"
                  ? {
                      svgViewBox: "0 0 150 125",
                      height: 140,
                      width: 140,
                    }
                  : style == "03"
                  ? {
                      svgViewBox: "0 0 150 90",
                      height: 140,
                      width: 140,
                    }
                  : {
                      svgViewBox: "0 0 150 90",
                      height: 140,
                      width: 140,
                    },
            }
          : {
              1: {
                svgViewBox: "50 20 150 200",
                height: 500,
                width: 280,
              },
              4:
                style == "01"
                  ? {
                      svgViewBox: "80 75 165 210",
                      height: 350,
                      width: 230,
                    }
                  : style == "02"
                  ? {
                      svgViewBox: "80 75 165 210",
                      height: 350,
                      width: 230,
                    }
                  : style == "04" || style == "03"
                  ? {
                      svgViewBox: "80 75 165 210",
                      height: 350,
                      width: 230,
                    }
                  : style == "05"
                  ? {
                      svgViewBox: "80 75 165 230",
                      height: 350,
                      width: 230,
                    }
                  : {
                      svgViewBox: "80 75 165 230",
                      height: 350,
                      width: 230,
                    },
              5: {
                svgViewBox: "0 0 200 300",
                height: 350,
                width: 250,
              },
              7:
                style == "02" || style == "04"
                  ? {
                      svgViewBox: "10 0 200 240",
                      height: 310,
                      width: 310,
                    }
                  : style == "03"
                  ? {
                      svgViewBox: "10 0 200 210",
                      height: 310,
                      width: 310,
                    }
                  : style == "05" || style == "06"
                  ? {
                      svgViewBox: "0 0 200 170",
                      height: 310,
                      width: 310,
                    }
                  : {
                      svgViewBox: "0 0 200 170",
                      height: 310,
                      width: 310,
                    },
              9:
                style == "02" || style == "04"
                  ? {
                      svgViewBox: "0 0 150 160",
                      height: 140,
                      width: 140,
                    }
                  : style == "03"
                  ? {
                      svgViewBox: "20 0 150 140",
                      height: 140,
                      width: 140,
                    }
                  : style == "05"
                  ? {
                      svgViewBox: "0 0 150 90",
                      height: 140,
                      width: 140,
                    }
                  : style == "06"
                  ? {
                      svgViewBox: "0 0 150 90",
                      height: 140,
                      width: 140,
                    }
                  : {
                      svgViewBox: "0 0 150 70",
                      height: 140,
                      width: 140,
                    },
            };
      api
        .getCustomBookImagesWithContents({
          gender,
          style,
          svgStyle: Object.styleString(overlayStyle),
          svgViewBox: JSON.stringify(svgChapStyle),
        })
        .then((result) => {
          let arr = result.data;
          const svgColor = this.props.store.customCharacter[3].ans.skin;
          const shirt = this.props.store.customCharacter[4].ans.tshirt;
          const glass =
            this.props.store.customCharacter[7].ans.eyewear == "yes"
              ? "#000"
              : "none";
          const hairColor = this.props.store.customCharacter[6].ans.hair;
          let customsvg = arr.map((o) => {
            //console.log(o);
            let svgContent = o.fileContent;
            let chapter = o.chapter;
            if (chapter == 9) {
              let svgStye;
              //console.log(o.style, o.gender);
              if (o.gender == "girl") {
                if (o.style == "01") {
                  svgStye = `position: absolute;top: 45px;left: 310px; width:30%`;
                } else if (o.style == "02") {
                  svgStye = `position: absolute;top: 50px;left: 280px; width:30%`;
                } else if (o.style == "03") {
                  svgStye = `position: absolute;top: 50px;left: 300px; width:30%`;
                } else if (o.style == "04") {
                  svgStye = `position: absolute;top: 50px;left: 300px; width:30%`;
                } else if (o.style == "05") {
                  svgStye = `position: absolute;top: 50px;left: 300px; width:30%`;
                } else if (o.style == "06") {
                  svgStye = `position: absolute;top: 50px;left: 290px; width:30%`;
                }
              } else {
                if (o.style == "01") {
                  svgStye = `position: absolute;top: 55px;left: 310px; width:30%`;
                } else if (o.style == "02") {
                  svgStye = `position: absolute;top: 55px;left: 310px; width:30%`;
                } else if (o.style == "03") {
                  svgStye = `position: absolute;top: 55px;left: 310px; width:30%`;
                } else if (o.style == "04") {
                  svgStye = `position: absolute;top: 55px;left: 310px; width:30%`;
                }
              }
              let new_svg = svgContent
                .replaceAll(`width='140'`, `width='126'`)
                .replaceAll(`height='140'`, `height='126'`)
                .replaceAll(
                  `style="fill:var(--skin)"`,
                  `style="fill:${svgColor}"`
                )
                .replaceAll(
                  `style="fill:var(--hair)"`,
                  `style="fill:${hairColor}"`
                )
                .replaceAll(
                  `style="fill:var(--shirt)"`,
                  `style="fill:${shirt}"`
                )
                .replaceAll(
                  `style="fill:var(--glass)"`,
                  `style="fill:${glass}"`
                )
                .replaceAll(`style="fill:var(--shoes)"`, `style="fill:#ae7928"`)
                .replaceAll(
                  `style="fill:var(--short)"`,
                  `style="fill:#597aab"`
                );
              return { chapter: chapter, svg: new_svg, stylesvg: svgStye };
            } else {
              let new_svg = svgContent
                .replaceAll(
                  `style="fill:var(--skin)"`,
                  `style="fill:${svgColor}"`
                )
                .replaceAll(
                  `style="fill:var(--hair)"`,
                  `style="fill:${hairColor}"`
                )
                .replaceAll(
                  `style="fill:var(--shirt)"`,
                  `style="fill:${shirt}"`
                )
                .replaceAll(
                  `style="fill:var(--glass)"`,
                  `style="fill:${glass}"`
                )
                .replaceAll(`style="fill:var(--shoes)"`, `style="fill:#ae7928"`)
                .replaceAll(
                  `style="fill:var(--short)"`,
                  `style="fill:#597aab"`
                );
              return { chapter: chapter, svg: new_svg, stylesvg: null };
            }
          });
          console.log(customsvg);
          this.setState({
            customNew: customsvg,
          });
          this.customSvgObj = arr;
          let imgprms = arr.map(
            (o, i) =>
              new Promise((imgres, reject) => {
                let dim = svgChapStyle[o.chapter];
                o.base64
                  .svg2png(dim.height, dim.width, [0, 0, dim.width, dim.height])
                  .then((base64) => {
                    imgres({
                      ...o,
                      svgbase64: o.base64,
                      base64: base64,
                      filePath: o.filePath,
                    });
                  })
                  .catch((error) => console.log({ error }));
              })
          );
          Promise.all(imgprms).then((newarr) => {
            let svgs = newarr.reduce(
              (o, n) => ({
                ...o,
                [n.chapter]: {
                  //content: n.fileContent,
                  base64: n.base64,
                  svgbase64: n.svgbase64,
                  filePath: n.filePath,
                },
              }),
              {}
            );
            this.setState({
              svgs,
            });
            resolve(svgs);
          });
        })
        .catch(reject);
    });
  }

  updateBook(
    bookObj = this.state.savedBook,
    bolierplates = this.state.bolierplates
  ) {
    return new Promise((resolve, reject) => {
      let book = bookObj?.savedBook?.parse({}) || {};
      let pages = this.state.pages.map((o) => {
        let content = this.getContent(
          book[o.name] || o.content || bolierplates[o.name] || "",
          o
        );
        return {
          ...o,
          content: content,
        };
      });
      this.setState({
        pages,
      });
      resolve(pages);
    });
  }

  updateData() {
    return new Promise((resolve, reject) => {
      let { savedBook } = this.state;
      this.updateBoilerplate().then((bolierplates) => {
        this.updateSVGs().then((svgs) => {
          this.updateBook(savedBook, bolierplates).then(resolve).catch(reject);
        });
      });
    });
  }

  onProceed() {
    this.updateData().then(() => {
      this.pageeditorref.current.querySelector("#previewBook").click();
    });
  }

  onCloseModal(close, step, obj) {
    this.updateData().then(() => {
      this.pageeditorref.current.querySelector("#previewBook").click();
      close();
    });
  }

  collectInfo(closable = false) {
    let modal = alert(
      (props) => (
        <Personalize
          alert={props}
          onProceed={() => this.onProceed()}
          onClose={(...arg) => this.onCloseModal(...arg)}
          closable={closable}
        />
      ),
      "",
      "model",
      {
        footer: false,
        class: "personalize-col col-12 col-md-10 col-lg-10 col-xl-8",
        parentclass: "personalize-row row",
        options: {},
      }
    );
  }

  prepareForCollection() {
    let {
      match: {
        params: { genpdf, customBookId },
      },
    } = this.props;
    const customCharacter = this.props.store?.customCharacter || [];
    let ans = (customCharacter || []).filter((o) => o.ans);
    if (
      ans.length < CustomCharacterFlow.filter((o) => !o.hidden).length - 1 &&
      !customBookId
    ) {
      this.collectInfo();
    } else {
      this.updateData().then(() => {
        this.pageeditorref.current.querySelector("#previewBook").click();
      });
    }
  }

  onSave(bookjson, self) {
    let file = null;
    let payload = bookjson;
    const customCharacter = self.props.store?.customCharacter || [];
    const BOOKID = self.BOOKID;
    const { savedBook, svgs, customNew } = self.state;
    const dedication =
      self.props.store?.customCharacter[8]?.ans?.dedication ||
      "Believe in yourself and your dreams will come true! Love, Mum";
    let firstNamevar =
      customCharacter[1]["ans"]["first_name"];
    let lastNamevar = 
      customCharacter[1]["ans"]["last_name"];
    return new Promise((resolve, reject) => {
      let apiPayload = {
        file,
        bookId: BOOKID,
        savedBook: JSON.stringify(payload),
        option: "dynamic",
        sessionId: localStorage.getItem("sessionID") || null,
        type: "customcharacter",
        attributes: JSON.stringify(customCharacter),
        customcharacterpng: JSON.stringify(customNew),
        firstname: firstNamevar,
        lastname: lastNamevar || null,
        gender: customCharacter[2]["ans"]["gender"],
        dedication: dedication,
      };
      //console.log(payload);
      mixins.checkLogin(self.history).then(() => {
        // console.log("Saving");
        if (savedBook && savedBook.id) {
          api.updateSelfPublish(apiPayload, savedBook.id).then((responce) => {
            self
              .getLatestBook("updatemsg")
              .then((responce) => {
                resolve(responce);
              })
              .catch(reject);
          });
        } else {
          api.createSelfPublish(apiPayload).then((responce) => {
            //console.log({createSelfPublish:responce});
            self.history.push(
              self.history.location.pathname + `/${responce.data.id}`
            );
            setTimeout(() => {
              self
                .getLatestBook("createmsg")
                .then((responce) => {
                  resolve(responce);
                })
                .catch(reject);
            });
          });
        }
      });
    });
  }

  async updateSelfBook(payload) {
    let file = null;
    let self = this;
    const { savedBook, svgs, customNew } = self.state;
    const BOOKID = self.BOOKID;
    const customCharacter = self.props.store?.customCharacter || [];
    const dedication =
      self.props.store?.customCharacter[8]?.ans?.dedication ||
      "Believe in yourself and your dreams will come true! Love, Mum";
    let firstNamevar =
      customCharacter[1]["ans"]["first_name"];
    let lastNamevar =
      customCharacter[1]["ans"]["last_name"];
    let apiPayload = {
      file,
      bookId: BOOKID,
      savedBook: JSON.stringify(payload),
      option: "dynamic",
      sessionId: localStorage.getItem("sessionID") || null,
      type: "customcharacter",
      attributes: JSON.stringify(customCharacter),
      customcharacterpng: JSON.stringify(customNew),
      firstname: firstNamevar,
      lastname: lastNamevar || null,
      gender: customCharacter[2]["ans"]["gender"],
      dedication: dedication,
    };
    await api.updateSelfPublish(apiPayload, savedBook.id);
  }

  componentDidMount() {
    emit.on("updatebook", async (...arg) => await this.updateSelfBook(...arg));
    this.getLatestBook()
      .then(() => {
        this.prepareForCollection();
      })
      .catch(() => {
        this.prepareForCollection();
      });
  }

  componentWillUnmount() {
    emit.remove("updatebook");
  }

  RightChildren(self) {
    return () => (
      <Button
        className="edit"
        variant="outlined"
        id="editBook"
        onClick={() => self.collectInfo(null)}
      >
        <i className="fa fa-edit"></i>
        <small>Edit</small>
      </Button>
    );
  }

  getNoticeMeesage() {
    let defaultmessage =
      "Due to copyright restrictions, only one chapter of this book can be previewed.";
    let device = this.$store.device;
    if (["mob", "small", "tab", "lap"].includes(device)) {
      defaultmessage +=
        "<br/><span style='font-size:12px; color:#cc3300; font-weight:400;'>Books Best view in Desktop version only</span>";
    }
    return defaultmessage;
  }

  onPreviewClose(alert) {
    alert.close();
    //history.back();
  }

  render() {
    let me = this;
    initialConstruct.bind(this)("CustomCharector");
    let noticeMessage = this.getNoticeMeesage();
    return ((
      { savedBook, pages, overlayStyle, lastPageNo },
      props,
      { onSave, RightChildren }
    ) => (
      <div className="CustomCharector top-margin" ref={me.pageeditorref}>
        <PageEditor
          png={false}
          pagePayload={pages}
          onSave={(...arg) => onSave(...arg, this)}
          onUpdate={(...arg) => me.onUpdate(...arg)}
          charcount={100}
          lastPageNo={lastPageNo}
          overlay={true}
          style={overlayStyle}
          pageStyle={{ position: "relative" }}
          leftTool={false}
          readonly={false}
          showCount={false}
          editbackground={false}
          RightChildren={RightChildren(this)}
          nextmode={true}
          message={noticeMessage}
          bookDetails={savedBook}
          closablePreview={false}
          onPreviewClose={(...arg) => this.onPreviewClose(...arg)}
        ></PageEditor>
      </div>
    ))(this.state, this.props, this);
  }
}

export default connect(withRouter(CustomCharector));
