import { Fragment, useEffect, useState, useRef } from "react";

import "./character.css";
import chsevenbase from "./chapterseven/chapter_7.png";

const Chap7Front = (props) => {
    return (
        <Fragment >
            <div className="chapter_seven_front_cover height">
                <img src={chsevenbase} />
                <div className="chaptersevencustomimage">
                    {props.children}
                </div>
            </div>
        </Fragment>
    )
}
export default connect(Chap7Front);