import React from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import AuthorDashboard from "../components/authorDashboard";

const DashboardPage = (props) => {
    // console.log("Home props", props);

    //mixins.setStore("loading",true,props);
    return (<div className={style['container']}>
        <Announcement />
        <Header current={props.location.pathname} />
        <AuthorDashboard/>
        <Footer />
    </div>)
}
export default connect(DashboardPage);