import { useState, useRef, useEffect } from "react";
import image from "../../assets/images/character.png";
import Personalize from "../common/Personalize/Personalize";
import DearUser from "./DearUser";
import c1 from "../../assets/images/c1.png"
import covercolor1 from "../../assets/images/cover_color_1.png";
import covercolor2 from "../../assets/images/cover_color_2.png";
import covercolor3 from "../../assets/images/cover_color_3.png";
import covercolor4 from "../../assets/images/cover_color_4.png";
import papertype1 from "../../assets/images/paper_type_1.png";
import papertype2 from "../../assets/images/paper_type_2.png";
import EditForm from "./EditForm";
import { useHistory } from 'react-router-dom'
import "./EditStep.css";
import { useParams } from "react-router-dom";
import api from "../../app-services/API";
import { useSelector } from "react-redux";

export default connect((props) => {
    let { PreviewComponent = null } = props;
    let { id } = useParams();
    const history = useHistory();
    const alertprop = props.alert;
    const customCharacter = useSelector((state) => state.customCharacter);
    const productCustomPayload = useSelector((state) => state.productCustomPayload);
    const [stepans, setStepAns] = useState({
        "bookId": id,
        //"file":
        "firstName": customCharacter[1]?.ans.first_name,
        "lastName": customCharacter[1]?.ans.last_name,
        "gender": customCharacter[2]?.ans.gender,
        "skinTone": customCharacter[3]?.ans.skin,
        "tShirtColor": customCharacter[4]?.ans.tshirt,
        "hairColor": customCharacter[6]?.ans.hair,
        "hairStyle": customCharacter[5]?.ans['hair-style'],
        "eyeWear": customCharacter[7]?.ans.eyewear == 'yes' ? 1 : 0,
        //"coverId":1
        ...productCustomPayload
    });
    const [formStep, setFormStep] = useState([
        { value: 1, label: "Edit Character", subhead: "Edit Your Character", image },
        // { value: 2, label: "Cover Format", subhead: "Select Cover Format", image },
        { value: 2, label: "Write A Dedication", subhead: "Add a Personal Note", image },
    ].filter(props.stepfilter || (() => (true))));
    const [question, setQuestions] = useState({
        question: "COVER OPTIONS",
        collect: [
            {
                name: "coverId",
                type: "radio",
                label: "Cover Options",
                radios: [
                    {
                        label: <img src={covercolor1} />,
                        value: "Hard Cover",
                        price: "13.90",
                        attr: {
                            style: {
                                margin: "auto",
                                marginLeft: '14px',
                                color: 'white'
                            },
                            labelPlacement: "start",
                        }
                    },
                    {
                        value: "Soft Cover",
                        price: "13.90",
                        label: <img src={covercolor2} />,
                        attr: {
                            style: {
                                margin: "auto",
                                marginLeft: '14px',
                                color: 'white'
                            },
                            labelPlacement: "start"
                        }
                    },
                ],
            }
        ],
    });
    const [charSteps, setCharSteps] = useState([
        { value: 1, label: "Character Name" },
        { value: 2, label: "Edit Gender" },
        { value: 3, label: "Skin Tone" },
        { value: 4, label: "T-shirt Color" },
        { value: 5, label: "Hair Style" },
        { value: 6, label: "Hair Color" },
        { value: 7, label: "Eyewear" },
    ]);
    const [currentactive, setActive] = useState(formStep[0]);

    const onCharterEdit = (obj) => {
        let editstep = obj.value;
        switch (obj.value) {
            case 1:
                break;
            default:
                editstep = obj.value
        };
        alert((c1props) => (<Personalize alert={c1props} data={obj} editstep={editstep} editmode={true} />), "", "model", {
            footer: false,
            class: "personalize-col col-12 col-md-10 col-lg-10 col-xl-8",
            parentclass: "personalize-row row",
            options: {

            },
        });
    };

    useEffect(() => {
        api.getBookFormat().then((res) => {
            if (res && res.data) {
                let bookformatradios = question.collect[0].radios
                question.collect[0].radios = res.data.map((o, i) => ({
                    ...(bookformatradios[i] || {}),
                    label: <img src={o.file.filePath} />,
                    display: o.shortDescription,
                    value: o.id,
                    price: o.price,
                    attr: {
                        style: {
                            margin: "auto",
                            marginLeft: '14px',
                            color: 'white'
                        },
                        labelPlacement: "start",
                    }
                }));
            }
        })
    }, []);
    const editField = (obj) => {
        setActive(obj);
    };

    const setAnswer = (ans) => {
        let payload = {
            ...stepans,
            ...ans
        }
        setStepAns(payload);
        mixins.setStore("productCustomPayload", payload, props);
        //console.log({ setAnswer: payload });
    };
    
    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);  // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = useState(false);  // For detecting end of scrolling
    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift); // Updates the latest scrolled postion
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const stepContinue = (obj) => {
        let lastnode = formStep[formStep.length - 1];
        let previousnode = formStep.find(o => o.value == (obj - 1));
        let activenode = formStep.find(o => o.value == obj);
        if (previousnode) {
            switch (previousnode.value) {
                case 1: break;
                // case 2: if (stepans.coverId) { break; } else { alert("please select cover"); return }
                case 2: if (stepans.save) { break; } else { alert("please save your dedication"); return }

            }

        }
        if (activenode && activenode.value <= lastnode.value) {
            setActive(activenode);
        } else {
            mixins.checkLogin(history).then(() => {
                let product = props.store.product;
                if ((product && product.type == "selfpublish") || (product && product.item && product.item.category && product.item.category.type === "SelfPublishing") ) {
                    if(localStorage.getItem("editmode")==="true"){
                        history.push("/checkout-page")
                    }
                  else { api.addToCart({
                        "promotionId": null,
                        "productId": props.store.product.id,
                        "quantity": 1,
                        "price": props.store.product.actualPrice,
                        "discount": null,
                        "bookCover": stepans.coverId
                    }).then((res) => {
                        if (res && res.statusCode === 200) {
                            history.push("/checkout-page")
                        } else {
                            //console.log("res", res)
                            //console.log("cart item not created")
                        }
                    })}

                }else if((product && product.type == "handPrintBook") || (product && product.item && product.item.category && product.item.category.type === "handPrintBook"))
                {
                    if(localStorage.getItem("editmode")==="true"){
                        history.push("/checkout-page")
                    }
                  else { api.addToCart({
                        "promotionId": null,
                        "productId": props.store.product.id,
                        "quantity": 1,
                        "price": props.store.product.actualPrice,
                        "discount": null,
                        "bookCover": stepans.coverId
                    }).then((res) => {
                        if (res && res.statusCode === 200) {
                            history.push("/checkout-page")
                        } else {
                            //console.log("res", res)
                            //console.log("cart item not created")
                        }
                    })}
                    
                } else {
                    if (localStorage.getItem("editmode") === "true") {
                        //console.log("stepans", stepans)
                        const characterAttributes = {
                            "bookId": stepans.bookId,
                            "firstName": customCharacter[1]?.ans.first_name,
                            "lastName": customCharacter[1]?.ans.last_name,
                            "gender": customCharacter[2]?.ans.gender,
                            "skinTone": customCharacter[3]?.ans.skin,
                            "tShirtColor": customCharacter[4]?.ans.tshirt,
                            "hairColor": customCharacter[6]?.ans.hair,
                            "hairStyle": customCharacter[5]?.ans['hair-style'],
                            "eyeWear": customCharacter[7]?.ans.eyewear == 'yes' ? 1 : 0,
                            // "coverId": 1,
                            "dedication": stepans.dedication,
                            // "file": 
                        }
                        //console.log("characterAttributes", characterAttributes);
                        api.updateCharacterAttributes(characterAttributes, props.store.characterId).then((res) => {
                            //console.log("response", res)
                            if (res && res.statusCode === 200) {
                                history.push("/checkout-page")
                            }else{
                                alert("charcter not saved please try again")
                            }
                        })
                    } else {
                        api.addCharacterCustomeDetails(productCustomPayload).then((res) => {
                            if (res && res.data && res.statusCode === 200) {
                                api.addToCart({
                                    "promotionId": null,
                                    "productId": props.store.product.id,
                                    "quantity": 1,
                                    "price": props.store.product.actualPrice,
                                    "discount": null,
                                    "bookCover": stepans.coverId
                                }).then((res) => {
                                    if (res && res.statusCode === 200) {
                                        history.push("/checkout-page")
                                    } else {
                                        //console.log("res", res)
                                        //console.log("cart item not created")
                                    }
                                })
                            } else {
                                alert("charcter not saved please try again")
                            }
                        })
                    }


                }

            });
        }
        alertprop && alertprop.close();
    }
    const getFiledDisplay = (obj) => {
        switch (obj.value) {
            case 1:
                return <div className="chareditcard row no-gutters">
                    <div className="col-2">
                        <div className="edithead" > {customCharacter[1]?.ans.first_name} {customCharacter[1]?.ans.last_name}</div>
                        {
                            PreviewComponent && <PreviewComponent />
                        }
                    </div>
                    {scrollX !== 0 && (
                        <i className="fa fa-angle-left prev" onClick={() => slide(-50)}></i>
                    )}
                    <div className="col-9 editoptions" onScroll={scrollCheck}
                        ref={scrl}>

                        {charSteps.map((cobj, i) => (

                            <div key={i} className="editcard"  >

                                <div className="editlabel">{cobj.label}</div>
                                <div className="editbutton" onClick={() => onCharterEdit(cobj)}>
                                    <i className="fa fa-edit">Edit</i>
                                </div>
                            </div>


                        ))}

                    </div>
                    {!scrolEnd && (
                        <i className="fa fa-angle-right next" onClick={() => slide(+50)}></i>
                    )}
                </div>

            // case 2:
            //     return <div className="cover-options">
            //         <EditForm data={question} setAnswer={setAnswer} answer={stepans} />
            //     </div>
            case 2:
                let userData = {
                    question: "DEDICATE THIS BOOK TO YOUR SPECIAL SOMEONE. WRITE YOUR DEDICATION HERE:",
                    type: "text-area",
                    // image: c1,
                    name: "dedication"
                };
                return <div>
                    <DearUser data={userData} setAnswer={setAnswer} answer={stepans} />
                </div>
            default:
                return <img src={currentactive.image} className="imagform-img"></img>
        }
    };

    return (
        <div className="editstep">
            <div className="imagform">
                <div className="image-container">
                    {getFiledDisplay(currentactive)}
                </div>
                <div className="step-bars">
                    {formStep.map((obj, i) => (
                        <div key={i} className="step-bar" active={(obj.value == currentactive.value).toString()} >
                            <div className="subhead" onClick={() => editField(obj)}>
                                <i className="fa fa-caret-right"></i>
                                {obj.subhead}
                            </div>
                            <div className="label">
                                {obj.label}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <button className="continuebtn" type="button" onClick={() => stepContinue(currentactive.value + 1)}>{currentactive.value === 2 ? (localStorage.getItem("editmode") === "true" ? "ADD TO CART" : "ADD TO CART") : "CONTINUE"}</button>
            </div>
        </div>
    );
}
)