import React, { useEffect, useState } from "react";
import "./Profile.css"
export default connect((props) => {
    const[profile,setProfile]=useState("");
    useEffect(() => {
        getProfileDetails();
    }, [])
    const getProfileDetails = () => {
        api.getuserProfile().then((res) => {
            if (res && res.data) {
              //console.log("profile",res)
            }

        });
    }
    return (
        <div className="profileContainer">
            <div className='formContainer'>
            <div className='title'>Profile</div>
                <div className="rowcontainer">
                    <div className="columncontainer">
                        <i className="fas fa-user icon"></i>
                        <input className="input" type="text" value="profile first name"  />
                    </div>
                    <div className="columncontainer">
                        <i className="fas fa-user icon-right"></i>
                        <input className="input-right" type="text" value="profile second name" />
                    </div>

                </div>
                <div className="rowcontainer">
                    <div className="columncontainer">
                        {/* <i class="fa fa-mobile icon" ></i> */}
                        <i className="fas fa-phone-alt icon"></i>
                        <input className="input" type="text" value="965324856532" />
                    </div>
                    <div className="columncontainer">
                        <i className="fas fa-envelope icon-right"></i>
                        <input className="input-right" type="text" value="profile@gmail.com" />
                    </div>

                </div>
                <div className="rowcontainer">
                    <div className="columncontainer">
                        <i className="fas fa-home icon"></i>
                    </div>
                    <input className="input" type="text" value="profile address" />
                </div>
                <div className="rowcontainer">
                    <div className="columncontainer">
                    <i className="fas fa-asterisk icon"></i>
                    </div>
                    <textarea className="textinput" type="text" value="profile address" />
                </div>
            </div>

        </div>
    )



})