import { Fragment, useEffect, useState, useRef } from "react";
import "./paymentConfirmation.css";
import { useHistory } from 'react-router-dom'
const orderConfirmation = (props) => {
    useEffect(() => {
        getCartDetails();
        promotionCode();
    }, [])
    const getCartDetails = () => {
        api.getCartDetails().then((res) => {
            if (res && res.statusCode === 200 && res.data) {
                mixins.setStore("cartItem", res.data.totalNumberOfBooks, props)
            }
        });
    }
    const promotionCode =() =>{
        mixins.setStore("seletedpromotion", [], props);
        mixins.setStore("currentCoupon", "" , props)
    }
    //console.log(props.store.seletedpromotion);
    const history = useHistory()
    return (
        <Fragment >
            <div className="container pt-5 pb-5">
                <div className='row'>
                    <div className="col-12">
                        <div className="text-center">
                            <h3>Thank you for placing your order. You order number is : <span className="orderNumber">{localStorage.getItem("orderId")}</span></h3>
                            <p>If you have any questions, you can email us at <a href="mailto:customer@plotterbox.com">customer@plotterbox.com</a></p>
                        </div>
                        <div className="text-center pt-5">
                            <div className="btn orderSummury" onClick={()=>{history.push("/home")}}>Continue Shopping</div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}
export default connect(orderConfirmation);