import React, { useState, useEffect } from "react";
import checkout from "../../../assets/images/checkout.png";
import p from "../../../assets/images/p.png";
import paypal from "../../../assets/images/paypal.png";
import toparrow from "../../../assets/images/toparrow.png";
import cart from "../../../assets/images/cart.png";
import CircularProgress from "@mui/material/CircularProgress";
import { ConstructionOutlined } from "@mui/icons-material";
export default connect((props) => {
  //console.log(props);
  const [cartDetails, setcartDetails] = useState([]);
  const [loading, setLoading] = useState("none");
  const [alertFail, setAlertFail] = useState(null);
  const subTotal = () =>
    (cartDetails?.cart || []).map((v) => Number(v.price)).sum();
  useEffect(() => {
    getCartDetails();
  }, []);
  useEffect(() => {
    if (props.store.deliveryDetails && props.store.deliveryDetails.price) {
      let finalAmount = (
        Number(props.store.finalAmount) +
        Number(props.store.deliveryDetails.price)
      ).toFixed(2);
      mixins.setStore("Total", finalAmount, props);
    } else {
      mixins.setStore("Total", props.store.finalAmount, props);
    }
  }, [props.store.deliveryDetails, props.store.finalAmount]);
  const getCartDetails = () => {
    api.getCartDetails().then((res) => {
      if (res && res.statusCode === 200 && res.data) {
        setcartDetails(res.data);
        mixins.setStore("CartDetails", res.data, props);
      }
    });
  };
  const onClickApplyPromotion = () => {
    setLoading("flex");
    api
      .getPromotionValidation({
        promotionId: props.store.seletedpromotion.id,
        totalAmount: subTotal(),
        quantity: cartDetails.totalNumberOfBooks,
      })
      .then((res) => {
        setLoading("none");
        if (res && res.statusCode === 200 && res.data) {
          mixins.setStore("finalAmount", res.data.amount, props);
          mixins.setStore("dicount", res.data.discount, props);
          mixins.setStore("applied", true, props);
        } else {
          if (res && res.errorMessages) {
            setAlertFail(res.errorMessages[0]);
            setTimeout(() => {
              setAlertFail(null);
            }, 3000);
          } else {
            setAlertFail(res.message);
            setTimeout(() => {
              setAlertFail(null);
            }, 3000);
          }
        }
      });
  };
  return (
    <div className="BillingAddress">
      <div className="order-title">Order Summary</div>
      {cartDetails &&
        cartDetails.cart &&
        cartDetails.cart.map((item, index) => {
          return (
            <div className="bookdetails-row">
              <div className="image-container">
                <img
                  src={item.product.bookImageUrl}
                  className="cart-image-container"
                />
              </div>
              <div className="col">
                <div className="product_name">{item.product.bookTitle}</div>
                <div className="price">Price: ${item.price} SGD</div>
              </div>
            </div>
          );
        })}
      <div className="contents">
        {props.store.seletedpromotion &&
          props.store.seletedpromotion.couponCode && (
            <div className="discount-code-row">
              <div className="discount-code-row-label">
                <label>Discount Code: </label>
                <span>{props.store.seletedpromotion.couponCode}</span>
              </div>
              <div
                style={{
                  display: loading,
                  margin: "auto",
                  position: "relative",
                }}
              >
                <CircularProgress color="success" />
              </div>
              <div
                className="apply-btn"
                onClick={!props.store.applied && onClickApplyPromotion}
              >
                {props.store.applied ? "APPLIED" : "APPLY"}
              </div>
            </div>
          )}
        <h6 style={{ color: "red", marginBottom: "5px" }}>
          {alertFail && alertFail}
        </h6>
        <div className="order-subtitle-row-border">
          <div className="order-item">
            Sub Total ({cartDetails.totalNumberOfBooks} items)
          </div>
          <div className="order-price">SGD {subTotal().toFixed(2)}</div>
        </div>
        {(!!Number(props.store.dicount) && (
          <div className="order-subtitle-row-border">
            <div className="order-item">Discount price</div>
            <div className="order-price">SGD {props.store.dicount}</div>
          </div>
        )) ||
          ""}
        {(!!Number(props.store.totaltaxamount) && (
          <div className="order-subtitle-row-border">
            <div className="order-item">Total Tax</div>
            <div className="order-price">SGD {props.store.totaltaxamount}</div>
          </div>
        )) ||
          ""}

        {props.store.deliveryDetails && props.store.deliveryDetails.price && (
          <div className="order-subtitle-row">
            <div className="order-item">Shipping Price</div>
            <div className="order-price">
              SGD {props.store.deliveryDetails.price}
            </div>
          </div>
        )}
        <div className="order-subtitle-row">
          <div className="order-item">Total Order Price</div>
          <div className="order-price">
            SGD {props.store.Total && props.store.Total}
          </div>
        </div>
      </div>
    </div>
  );
});
