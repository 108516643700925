import { Fragment, useEffect, useState, useRef } from "react";

import "./character.css";
import frontcover from "./chapter_one/front-cover.png";



const Chap1Front = (props) => {
    return (
        <Fragment >
            <div className="front-cover height">
                <img src={frontcover} />
                <div className="custom-title">
                    <h3>{props.name||'CUSTOMNAME'}</h3>
                </div>
                
                <div className="customimage">
                    {props.children}
                </div>
            </div>
        </Fragment>
    )
}
export default connect(Chap1Front);