import axios from "axios";

export default {
  login_user(data) {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .post(`${base}api/Account/Login?ReturnUrl=test`, data)
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },
  // GET TESTIMONIALS
  getTestimonials() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/testimonial/list`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },
  // GET ANNOUNCEMENT
  getAnnouncement() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/Announcement/activeAnnouncement`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // GET FEATURES
  getFeatures() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/whyPick/list`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // GET CMS Info
  getcmsInfo() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/cmsInfo/list`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  // GET CMS LIST
  getCMSList() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/cmsPages/list`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // POST NewSletter
  postNewsLetter(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/subscriber/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  //post like and dislike
  postReviewFeedback(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/review-feedback/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  //post bookreview
  postBookReview(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/review/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  //post rating
  postBookRating(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/book-ratings/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // GET bannerdetails
  getBannerlist() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/banners/list`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // GET booklist
  getBooklist(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(
          `${base}/api/books/listByCategory?category=${data.category}&subCategory=${data.subCategory}`,
          {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          }
        )
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, true);
  },
  // delete book list
  deleteBookList(id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .delete(`${base}/api/books/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // GET booklist
  getBookDetails(id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/book-details/ProductInfo/${id}`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // GET header menu and submenu
  getHeaderMenue() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/menus/listWithSubMenu`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, true);
  },
  //login user
  login(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/user/login`, data)
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  //signup user
  signup(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/user/register`, data)
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  //forgotpassword user
  forgot_password(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/user/sendMailOtp`, data)
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // OTP verification
  otp_verification(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/user/verifyOtp`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // Password reset
  password_reset(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/user/resetPassword`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // GET cart details
  getCartDetails(id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/cart/cartDetails`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  // POST All city by
  postAllCities(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/shippingAddress/cities`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // POST All city by
  createShipingDetails(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/shippingAddress/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // POST All city by
  updateShipingAddress(id, data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/shippingAddress/update/${id}`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // delete Subscription item
  deleteSubscriptionItem(id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .delete(`${base}/api/subscription/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // Delete Cart Item
  deleteCartItem(id, data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .delete(
          `${base}/api/cart/cartDetails/delete/${id}?` +
            Object.QueryString({
              ...data,
            }),
          {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          }
        )
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // delete shipping address

  deleteShippingItem(id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .delete(`${base}/api/shippingAddress/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  // GET address details
  getAllAddress() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/shippingAddress/userAddresses`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // POST All city by
  cashonDeliveryMethod(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/orders/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // Add to cart
  addToCart(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/cart/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // Update to cart
  updateToCart(data, id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/cart/update/${id}`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },

  // save for later
  SaveforLater(data, id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(
          `${base}/api/cart/addSaveForLater/${id}?` +
            Object.QueryString({
              ...data,
            }),
          {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          }
        )
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // list of save all list
  getAllSaveforLater() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/subscription/Subscriptionlist`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  // Move to cart
  movetocart(id, data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(
          `${base}/api/cart/moveToCart/${id}?` +
            Object.QueryString({
              ...data,
            }),
          {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          }
        )
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  getAllSaveforLaterCart() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/cart/saveForLaterDetails`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  //getAll book list
  getAllBooks(page) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/books/listByAuthorOrArtist`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  //getAll book list
  getBookFormat() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/bookFormat/list`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  //getAll book list
  addCharacterCustomeDetails(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/characters/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  //getAll book list
  getPromotionList() {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/promotion/listDiscountCoupon`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  //getAll book list
  getPromotionValidation(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(
          `${base}/api/promotion/validateCoupon?` +
            Object.QueryString({
              ...data,
            }),
          {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          }
        )
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },

  // create payment
  paymentCreate(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/payments/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  //getAll book list
  getCharacterAttributes(id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/characters/getAttributes?customBookId=${id}`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, true);
  },
  updateCharacterAttributes(data, id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .put(`${base}/api/characters/update/${id}`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  //post Get In Touch
  postGetInTouch(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/contact/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          //console.log("response", response);
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  acumbaNewsletter(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`https://acumbamail.com/api/1/addSubscriber/`, data)
        .then((response) => {
          console.log("response", response);
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
};
