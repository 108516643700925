import React from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Header from "../components/layouts/header/Header";
import OrderDetails from "../components/payment/checkout/paymentConfirmation";


const about = (props) => {
    // console.log("Home props", props);

    //mixins.setStore("loading",true,props);
    return (<div className={style['container']}>
        <Header current={props.location.pathname} />
        <OrderDetails />
        <Footer />
    </div>)
}
export default connect(about);