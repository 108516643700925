import React from "react";
import style from "./Pages.module.css";
import Footer from "../components/layouts/footer/Footer";
import Header from "../components/layouts/header/Header";
import HandPrintPreview from "../components/preview/HandPrintPreview";
const previewHandPrint = (props) => {
  return (
    <div className={style["container"]}>
      <Header />
      <HandPrintPreview />

      <Footer />
    </div>
  );
};
export default connect(previewHandPrint);
