import env from "../API/config";

export default {
  appmode: () =>
    window.mobapp ||
    (window.navigator.appVersion.toLowerCase().includes("android") && {
      name: "android",
    }) ||
    (window.navigator.appVersion.toLowerCase().includes("iphone") && {
      name: "iphone",
    }),
  hasGardedRoute(user, callback) {
    //console.log({ hasGardedRoute: user });
    const GardedRoutes = [
      {
        path: "/book",
        user: "customer",
        message:
          "Please login with the valid customer id to continue the process.",
      },
    ];
    let current_path = window.reduxHistory.location.pathname;
    let routeobj = GardedRoutes.find((o) => current_path.includes(o.path));
    let flag = false;
    if (routeobj) {
      flag = user.roles.map((o) => o.name).includes(routeobj.user);
    } else {
      flag = true;
    }
    if (flag) {
      callback(user);
    } else {
      api.appLogout().then(() => {
        callback(null);
        alert(routeobj.message);
      });
    }
  },
  checkLogin(history, message = "") {
    return new Promise((resolve, reject) => {
      if (window.token === env.STATIC_TOKEN) {
        localStorage.setItem("redirect", window.location.pathname);
        localStorage.setItem("redirect-message", message);
        login_popup()
          .promise.then((user) => {
            mixins.hasGardedRoute(user, (user) =>
              user ? resolve(user) : reject()
            );
          })
          .catch(reject);
      } else {
        mixins.hasGardedRoute(AppLayout.props.store.user, (user) =>
          user ? resolve(user) : reject()
        );
      }
    });
  },
  addScript(urlOfTheLibrary) {
    const script = document.createElement("script");
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  },
  downloadCanvasAsImage(
    canvasElement,
    filename = "capture_" + new Date().toDate()
  ) {
    let canvasImage = canvasElement.toDataURL("image/png");
    // this can be used to download any image from webpage to local disk
    let xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      let a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = filename;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    };
    xhr.open("GET", canvasImage); // This is to download the canvas Image
    xhr.send();
  },
  getImageNode(src) {
    let divEle = document.createElement("div");
    divEle.innerHTML = `<img src='${src}' style="object-fit: fill; height: calc(100% - 4px); width: 100%; object-position: center center;" />`;
    divEle.setAttribute(
      "style",
      Object.styleString({
        display: "inline-block",
        height: "100%",
        width: "30%",
        position: "relative",
        overflow: "auto",
      })
    );
    divEle.classList = "ImageContainer";
    return divEle;
  },
  lasycall(callback, delay = 0, key = "lasycall") {
    document.debounce = document.debounce || {};
    clearTimeout(document.debounce[key]);
    let id = setTimeout(callback, delay);
    document.debounce[key] = id;
  },
  debounce(e = document, callback, key = "main", delay = 400) {
    let value = e?.target?.value || e || "";
    let tagName = e?.target?.tagName;
    let type =
      (e?.target?.getAttribute && e?.target?.getAttribute("text")) || "text";
    switch (type) {
      case "file":
        let files = this.target.files;
        let file =
          files.length == 1
            ? this.target.files[0]
            : files.length > 0
            ? this.target.files
            : null;
        value = { file, value };
        break;
      case "radio":
      case "checkbox":
        value = { value, checked: this.target.checked };
        break;
      default:
    }
    document.debounce = document.debounce || {};
    clearTimeout(document.debounce[key]);
    let id = setTimeout(() => callback.bind(this)(value, e), delay);
    document.debounce[key] = id;
  },
  setAccessToken(accessToken = window.STATIC_TOKEN) {
    //console.log({setAccessTokenBefor:token,windowToken:window.token});
    localStorage.setItem("token", accessToken);
    window.token = accessToken;
    //console.log({setAccessTokenAfter:token,windowToken:window.token});
  },
  reFectchSavedBookDetails(props) {
    return new Promise((resolve, reject) => {
      if (!props.store.user) {
        reject();
      }
      api
        .getSavedBookDetails()
        .then((res) => {
          mixins.setStore(
            [
              { name: "user.savedBookCount", value: res.data.savedBookCount },
              { name: "user.savedBooks", value: res.data.savedBooks },
              { name: "user.subscription", value: res.data.subscription },
            ],
            false,
            props
          );
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  sleep(secounds) {
    return new Promise((resolve) => {
      setTimeout(resolve, secounds);
    });
  },
  convertToHtmlEntities(input) {
    if (typeof input !== "string") {
      return input;
    }
    return input?.replace(/[“”’<>&]/g, (match) => {
      switch (match) {
        case "“":
          return `"`;
        case "”":
          return `"`;
        case "’":
          return "'";
        default:
          return match;
      }
    });
  },
};
