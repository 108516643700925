export default `<div class="book-page active"
style="min-height: 197mm; height: 197mm; width: 130mm; max-width: 130mm; padding: 20mm 15mm 25mm 20mm; margin: auto auto auto 0px; overflow: hidden; background-repeat: no-repeat; background-color: rgb(255, 255, 255); box-sizing: border-box; background-size: cover; background-position: 50% 50%; font-family: Quicksand-Bold;">
<div style="display: flex; flex-direction: column; height: 100%; box-sizing: border-box;">
    <div class="AppEditor TextResizeConatiner" tabindex="0" draggable="true"
        style="overflow: hidden; position: relative; height: 100%; background: rgba(255, 255, 255, 0.89);">
        <div id="editor-container" class="" contenteditable="true" placeholder="Edit here">
            <h2 style="text-align: center; font-size: 20px;">Chapter Title or Number</h2>
            <p style="text-align: left; font-size: 12px;">Use this page template every time you want to start new
                chapters for your Middle Grade Chapter Book. Middle Grade books are written for children between the
                ages of 8 and 12 years old.</p>
            <p style="font-size: 12px;"> Typically, the word count for a Chapter Book averages between 6,000 to
                9,000 words. And each Chapter Book could have 6 to 10 short chapters.</p>
            <p style="font-size: 12px;">Most Middle Grade Chapter Books include illustrations – either in colour or
                black and white – that accompany the text story. The illustrations do not need to appear on every
                page. They can just be used in order to engage your readers or illustrate a particularly interesting
                part of your story.</p>
            <p style="font-size: 12px;">For reluctant readers, the use of visually interesting or funny
                illustrations or artwork helps make reading an interesting experience.</p>
            <p style="font-size: 12px;"> To start your story, type directly into the text box or copy and paste your
                existing story. Do note that any text that falls outside the print area will not be printed. Move
                the excess text over to the next page.</p>
        </div>
    </div>
    <div class="App-Editor-Toolbar editButtons editable" style="display: none;"><span title="STYLES"><button
                data-edit="bold" class="radiusopen"><i class="fa fa-bold"></i></button><button data-edit="italic"><i
                    class="fa fa-italic"></i></button><button data-edit="underline"><i
                    class="fa fa-underline"></i></button><button data-edit="strikeThrough" class="radiusclose"><i
                    class="fa fa-strikethrough"></i></button></span><span><button data-edit="formatBlock:p"
                class="radiusopen"><b>P</b></button><button data-edit="formatBlock:H1"
                class=""><b>H1</b></button><button data-edit="formatBlock:H2" class=""><b>H2</b></button><button
                data-edit="formatBlock:H3" class=""><b>H3</b></button><button data-edit="formatBlock:H4"
                class=""><b>H4</b></button><button data-edit="formatBlock:H5" class=""><b>H5</b></button><button
                data-edit="formatBlock:H6" class="radiusclose"><b>H6</b></button></span><span><select>
                <option disabled="">Choose font</option>
                <option value="Quicksand-Medium">Quicksand-Medium</option>
                <option value="Arial">Arial</option>
                <option value="Courier">Courier</option>
                <option value="Georgia">Georgia</option>
                <option value="Gotham">Gotham</option>
                <option value="Helvetica">Helvetica</option>
                <option value="Baskerville">Baskerville</option>
                <option value="Caslon">Caslon</option>
                <option value="Myriad Pro">Myriad Pro</option>
                <option value="Rockwell">Rockwell</option>
                <option value="Times-New-Roman">Times-New-Roman</option>
            </select><button data-edit="" class="radiusopen font-color" title="Font Color"><small><input
                        type="color" style="height: 24px; width: 24px;"></small></button><button data-edit=""
                class="radiusclose back-color" title="Back Color"><small><input type="color"
                        style="height: 24px; width: 24px;"></small></button></span><span title="FONT SIZE"><button
                data-edit="fontSize:1" class="radiusopen"><i class="fa fa-font"
                    style="font-size: small;"></i></button><button data-edit="fontSize:3"><i
                    class="fa fa-font"></i></button><button data-edit="fontSize:5" class="radiusclose"><i
                    class="fa fa-font" style="font-size: large;"></i></button></span><span title="LISTS"><button
                data-edit="insertUnorderedList" class="radiusopen"><i class="fa fa-list-ul"></i></button><button
                data-edit="insertOrderedList" class="radiusclose"><i class="fa fa-list-ol"></i></button></span><span
            title="ALIGNMENT"><button class="radiusopen" data-edit="justifyLeft"><i
                    class="fa fa-align-left"></i></button><button data-edit="justifyCenter"><i
                    class="fa fa-align-center"></i></button><button class="radiusclose" data-edit="justifyRight"><i
                    class="fa fa-align-right"></i></button></span><span><input type="file"
                style="display: none;"></span><span title="CLEAR FORMATTING"><button data-edit="removeFormat"><i
                    class="fa fa-ban"></i></button></span></div>
</div>
</div>`;