import Modal from '@material-ui/core/Modal';

export default function (props, store, state) {
    return (
        this.filterList().map((alertVar, i) => (
            <div className={Object.className({"AlertContainer":true}) } key={i + 'alert-model'}>
                <Modal
                    className={Object.className({"Alert":true,[store.device]:true,[alertVar.option.parentclass]:true}) }
                    open={alertVar.display}
                    onClose={alertVar.close}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className={"alert-container "} >
                        <div className={"content-space " + (alertVar.option.class || "")} >
                            {
                                alertVar.title &&
                                <div className="alert-title">
                                    {alertVar.title}
                                </div>
                            }
                            <div className={"alert-content "}>
                                {
                                    this.evalContent(alertVar.content, alertVar)
                                }
                            </div>
                            {alertVar.option.footer && <div>
                                {
                                    (alertVar.type == 'confirm' || alertVar.type == 'prompt') &&
                                    <>
                                        <button type="button" className="cancel"
                                            onClick={() => this.alertFailure(alertVar)}
                                        >
                                            {alertVar.option.cancel}
                                        </button>
                                        <div className="sep"></div>
                                    </>
                                }
                                <button onClick={() => this.alertSuccess(alertVar)} type="button">{alertVar.option.ok}</button>
                            </div>}
                        </div>
                    </div>
                </Modal>
            </div>
        ))
    );
}