import React, { useRef } from 'react'
import "./BackgroundModal.css";

function BackgroundModal(props) {
    const { defaultColor = "#ffffff", defaultImage, colorRef, imageRef, splitRef } = props;
    let internalColorRef = colorRef||useRef(null);
    let internalImageRef = imageRef||useRef(null);
    let internalSplitRef = splitRef||useRef(null);

    function ValidateFileUpload() {

        var fuData = document.getElementById('fileChooser');
        var FileUploadPath = fuData.value;

        if (FileUploadPath == '') {
            alert("Please upload an image");
        
        } else {
            var Extension = FileUploadPath.substring(FileUploadPath.lastIndexOf('.') + 1).toLowerCase();
        
            if (Extension == "png" || Extension == "jpeg" || Extension == "jpg") {
            } 
        else {
         alert("Photo only allows file types of PNG, JPG and JPEG. ")
         fuData.value = ''
            }
        }}

    return (
        <div className="BackgroundModal">
            <div className="row align-items-center">
                <div className="col-6">
                    <div className="inner-border">

                        <div className="col color-bg">
                            <div className="input-border">
                                <label>
                                    <strong>Pick from the pallet</strong>
                                </label>
                                <input className="mx-auto" type={"color"} ref={internalColorRef} defaultValue={defaultColor} ></input>
                            </div>
                        </div>
                    </div>
                    <div style={{"padding":"13px"}}></div>
                </div>

                {/* <hr />
                    Or
                    <hr /> */}
                <div className="col-6">
                    <div className="inner-border">

                        <div className="col image-upload">
                            <label>
                                <strong>Select the image</strong>
                            </label>
                            <input className="mx-auto" type={"file"} ref={internalImageRef} placeholder="Select File to Upload"  id="fileChooser"  onChange={ValidateFileUpload}></input>
                            {
                                defaultImage && <div className='backgroundPlacholder' style={{ backgroundImage: defaultImage }} ></div>
                            }
                        </div>
                    </div>
                    <div>
                        <input ref={internalSplitRef} type={"checkbox"}></input>
                        <span style={{margin:'0px 10px'}}>Split Mode</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default BackgroundModal