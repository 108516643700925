import React, { useState, useEffect } from "react";
import edit from "../../../assets/images/edit.png";
import search from "../../../assets/images/search.png";
import deleteicon from "../../../assets/images/delete.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import SubscriptionPlan from "./SubscriptionPlan";
export default connect((props) => {
  const [loading, setLoading] = useState("none");
  const history = useHistory();
  const deleteCartitem = (item) => {
    let id = item.id;
    api.deleteCartItem(id, {
      bookId: item?.customBookDetails?.bookId,
      customBookId: item?.customBookDetails?.id,
    })
      .then((res) => {
        setLoading("none");
        if (res && res.statusCode === 200) {
          props.getCartDetails();
        }
      });
  };
  const saveforLaterCartitem = (item) => {
    let subscribeUsFlag = false;
    subscribeUsFlag = api.checkSubscribeUser({ props });
    if (subscribeUsFlag) {
      if (
        subscribeUsFlag != messages.save_book_limitation_warning_subscribed_user
      ) {
        alert(
          (props) => (
            <SubscriptionPlan
              alert={props}
              id={props.id}
              key={props.id}
            />
          ),
          "",
          "model",
          {
            footer: false,
            class: "personalize-col col-8 col-md-8 col-lg-6 col-xl-6",
            // parentclass: "personalize-row row",
            options: {},
          }
        );
      }
      alert(subscribeUsFlag);
    } else {
      let id = item?.id;
      setLoading("flex");
      api
        .SaveforLater(
          {
            bookId: item?.product?.item?.bookId,
            customBookId: item?.customBookDetails?.id,
          },
          id
        )
        .then((res) => {
          setLoading("none");
          //console.log("res",res)
          if (res && res.statusCode === 200) {
            props.getCartDetails();
            props.getAllSaveForLaterDetails();
            mixins.reFectchSavedBookDetails(props);
          }
        });
    }
  };

  return (
    <div className="ProductCard row no-gutters card">
      <div className="image-container">
        <img
          src={props.item.product?.bookImageUrl}
          className="cart-image-container"
        />
        <img src={search} className="search-image-container" />
      </div>
      <div className="col">
        <div className="product_name">{props.item.product?.bookTitle}</div>
        <div className="price">Price: $ {props.item?.price} SGD</div>
        <div style={{ display: loading }}>
          <CircularProgress color="success" />
        </div>
        <div className="button-container">
          <div
            className="edit-button"
            onClick={() => {
              //handprint, customcharacter, chapterbook, picturebook
              localStorage.setItem("editmode", true);
              mixins.setStore("cartdetails", props.item, props);
              let section = "chapter-book";
              let { customizedId, customBookType } =
                props.item?.customBookDetails;
              let { customBookId, bookId } = props.item?.customProduct;
              switch (customBookType) {
                case "chapterbook":
                  section = "chapter-book";
                  break;
                case "picturebook":
                  section = "picture-book";
                  break;
                case "handprint":
                  section = "handprint-book";
                  break;
                case "customcharacter":
                  section = "customcharacter-book";
                  break;
              }
              history.push(`/book/${section}/${bookId}/${customizedId}`);
            }}
          >
            <img src={edit} className="edit-button-img" />
            Edit Book
          </div>
          <div
            className="delete-button"
            onClick={() => deleteCartitem(props.item)}
          >
            <img src={deleteicon} />
            Remove book
          </div>
          <div
            className="save-button"
            onClick={() => saveforLaterCartitem(props.item)}
          >
            Save For Later
          </div>
        </div>
      </div>
    </div>
  );
});
