File.prototype.toBase64 = function () {
    let file = this;
    return new Promise((resolve, reject) => {
        try {
            let reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            }
            reader.readAsDataURL(file);
        } catch (error) {
            reject(false)
        }
    })
}

SVGElement.prototype.toBase64 = function() {
   let content =  new XMLSerializer().serializeToString(this);
   let encodedData = window.btoa(content);
   return `data:image/svg+xml;base64,`+encodedData;
}

Blob.prototype.toFile = function(filename="file") {
    this.lastModifiedDate =  new Date();
    this.name = filename;
    return new File(this,filename);
}