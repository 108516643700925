import React, { Component } from "react";
import PageEditor from "../PageEditor/PageEditor";
import "./HandPrint.css";
import mixins from "../../app-services/mixins";
import { readUrl } from "../PageEditor/support";
import FrontCover from "./FrontCover.txt";
import StaticPage1 from "./StaticPage1.txt";
import StaticPage2 from "./StaticPage2.txt";
import StaticPage3 from "./StaticPage3.txt";
import StaticPage4 from "./StaticPage4.txt";
import BirdPage1 from "./BirdPage1.txt";
import BirdPage2 from "./BirdPage2.txt";
import StaticPage5 from "./StaticPage5.txt";
import StaticPage6 from "./StaticPage6.txt";
import OwlPage1 from "./OwlPage1.txt";
import OwlPage2 from "./OwlPage2.txt";
import BearPage1 from "./BearPage1.txt";
import BearPage2 from "./BearPage2.txt";
import GiraffePage1 from "./GiraffePage1.txt";
import GiraffePage2 from "./GiraffePage2.txt";
import ButterflyPage1 from "./ButterflyPage1.txt";
import ButterflyPage2 from "./ButterflyPage2.txt";
import CrabPage1 from "./CrabPage1.txt";
import CrabPage2 from "./CrabPage2.txt";
import PeacockPage1 from "./PeacockPage1.txt";
import PeacockPage2 from "./PeacockPage2.txt";
import AnimalPage1 from "./AnimalPage1.txt";
import AnimalPage2 from "./AnimalPage2.txt";
import AnimalPage3 from "./AnimalPage3.txt";
import AnimalPage4 from "./AnimalPage4.txt";
import ElephantPage1 from "./ElephantPage1.txt";
import ElephantPage2 from "./ElephantPage2.txt";
import BoyPage1 from "./BoyPage1.txt";
import BoyPage2 from "./BoyPage2.txt";
import StarPage1 from "./StarPage1.txt";
import StarPage2 from "./StarPage2.txt";
import RoutePage1 from "./RoutePage1.txt";
import RoutePage2 from "./RoutePage2.txt";
import CountPage1 from "./CountPage1.txt";
import CountPage2 from "./CountPage2.txt";
import LastPage1 from "./LastPage1.txt";
import Lastpagetwo from "./LastPage2.txt";
import PreInfoColletion from "./Preinfo";
import { withRouter } from "react-router";
import { Button } from "@mui/material";
import uploadplaceholder from "./uploadPlaceholder.png";
export class HandPrint extends Component {
	constructor(props) {
		super(props);
		this.BOOKID = props.match.params.id;
		initialConstruct.bind(this)("HandPrint");
		const book = props.store.book.customCharacter;
		this.pageeditorref = React.createRef();
		this.state = {
			pageStyle: {
				position: "relative",
				minHeight: "200mm",
				height: "200mm",
				width: "200mm",
				maxWidth: "200mm",
			},
			staticPages: {
				frontCover: FrontCover,
				staticpage1: StaticPage1,
				staticpage2: StaticPage2,
				staticpage3: StaticPage3,
				staticpage4: StaticPage4,
				birdpage1: BirdPage1,
				birdpage2: BirdPage2,
				staticpage5: StaticPage5,
				staticpage6: StaticPage6,
				owlpage1: OwlPage1,
				owlpage2: OwlPage2,
				bearpage1: BearPage1,
				bearpage2: BearPage2,
				giraffepage1: GiraffePage1,
				giraffepage2: GiraffePage2,
				butterflypage1: ButterflyPage1,
				butterflypage2: ButterflyPage2,
				crabpage1: CrabPage1,
				crabpage2: CrabPage2,
				peacockpage1: PeacockPage1,
				peacockpage2: PeacockPage2,
				animalpage1: AnimalPage1,
				animalpage2: AnimalPage2,
				animalpage3: AnimalPage3,
				animalpage4: AnimalPage4,
				elephantpage1: ElephantPage1,
				elephantpage2: ElephantPage2,
				boypage1: BoyPage1,
				boypage2: BoyPage2,
				starpage1: StarPage1,
				starpage2: StarPage2,
				routepage1: RoutePage1,
				routepage2: RoutePage2,
				countpage1: CountPage1,
				countpage2: CountPage2,
				lastpage1: LastPage1,
				lastpage2: Lastpagetwo,
			},
			savedBook: {},
			bolierplates: {},
			svgs: {},
			pages: [
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `frontCover`,
					label: `1`,
					content: book && book[`frontCover`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `staticpage1`,
					label: `2`,
					content: book && book[`staticpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `staticpage2`,
					label: `3`,
					content: book && book[`staticpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `staticpage3`,
					label: `4`,
					content: book && book[`staticpage3`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `staticpage4`,
					label: `5`,
					content: book && book[`staticpage4`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `birdpage1`,
					label: `6`,
					content: book && book[`birdpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `birdpage2`,
					assetname: "bird",
					label: `7`,
					content: book && book[`birdpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `staticpage5`,
					label: `8`,
					content: book && book[`staticpage5`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `staticpage6`,
					label: `9`,
					content: book && book[`staticpage5`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `owlpage1`,
					assetname: "owl",
					label: `10`,
					content: book && book[`owlpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `owlpage2`,
					label: `11`,
					content: book && book[`owlpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `bearpage1`,
					assetname: "bear",
					label: `12`,
					content: book && book[`bearpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `bearpage2`,
					label: `13`,
					content: book && book[`bearpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `giraffepage1`,
					assetname: "giraffe",
					label: `14`,
					content: book && book[`giraffepage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `giraffepage2`,
					label: `15`,
					content: book && book[`giraffepage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `butterflypage1`,
					label: `16`,
					content: book && book[`butterflypage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `butterflypage2`,
					assetname: "butterfly",
					label: `17`,
					content: book && book[`butterflypage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `crabpage1`,
					label: `18`,
					content: book && book[`crabpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `crabpage2`,
					assetname: "crab",
					label: `19`,
					assetname: "crab",
					content: book && book[`crabpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `peacockpage1`,
					assetname: "peacock",
					label: `20`,
					content: book && book[`peacockpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `peacockpage2`,
					label: `21`,
					content: book && book[`peacockpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `animalpage1`,
					assetname: "animal",
					label: `22`,
					content: book && book[`animalpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `animalpage2`,
					label: `23`,
					content: book && book[`animalpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `animalpage3`,
					assetname: "animal",
					label: `24`,
					content: book && book[`animalpage3`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `animalpage4`,
					label: `25`,
					content: book && book[`animalpage4`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `elephantpage1`,
					assetname: "elephant",
					label: `26`,
					content: book && book[`elephantpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `elephantpage2`,
					label: `27`,
					content: book && book[`elephantpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `boypage1`,
					assetname: "boy",
					label: `28`,
					content: book && book[`boypage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `boypage2`,
					label: `29`,
					content: book && book[`boypage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `starpage1`,
					label: `30`,
					content: book && book[`starpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					assetname: "star",
					name: `starpage2`,
					label: `31`,
					content: book && book[`starpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `routepage1`,
					assetname: "route",
					label: `32`,
					content: book && book[`routepage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `routepage2`,
					label: `33`,
					content: book && book[`routepage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `countpage1`,
					assetname: "count",
					label: `34`,
					content: book && book[`countpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `countpage2`,
					label: `35`,
					content: book && book[`countpage2`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `lastpage1`,
					assetname: "last",
					label: `36`,
					content: book && book[`lastpage1`],
				},
				{
					pagetype: "fullimage",
					contentSize: 200,
					name: `lastpage2`,
					label: `Last Page2`,
					content: book && book[`lastpage2`],
				},
			].map((o, i) => {
				o.label = i == 0 ? "Frontpage" : i == 36 ? "Backcover" : i + 1;
				return o;
			}),
			overlayStyle: {},
		};
	}

	getContent(book, page) {
		let ele = mixins.getDataNode(book);
		const handprintPreInfo = this.props.store?.book?.handprintPreInfo;
		const answer = handprintPreInfo?.answer;
		const handprints = handprintPreInfo?.handprints;
		if (page.assetname && handprints[page.assetname]) {
			let src = handprints[page.assetname].filepath;
			let imgEle = ele.querySelector("#image-element");
			imgEle.src = src;
		}
		let child_name = ele.querySelector("#child_name");
		if (child_name && answer && answer.child_name) {
			child_name.innerText = answer.child_name;
		}
		let dedictaion_text = ele.querySelector("#dedictaion_text");
		if (dedictaion_text && answer && answer.dedication) {
			dedictaion_text.innerText = answer.dedication;
		}
		let adult_name = ele.querySelector("#adult_name");
		if (adult_name && answer && answer.parent_name) {
			adult_name.innerText = answer.parent_name;
		}
		book = ele.innerHTML;
		return book;
	}
	getLatestBook() {
		let self = this;
		const handprintPreInfo = this.props.store?.book?.handprintPreInfo;
		return new Promise((resolve, reject) => {
			api
				.getUpdatedSelfBook({
					sessionId: localStorage.getItem("sessionID") || null,
					type: "handprint",
				})
				.then((responce) => {
					if (responce && responce.data) {
						let payload = responce.data;
						let remoteHadprintAttributes = JSON.parse(
							payload.attributes || "{}"
						);
						this.setStore("book.handprintPreInfo", {
							...handprintPreInfo,
							...remoteHadprintAttributes,
						});
						this.setState({
							savedBook: payload,
						});
						setTimeout(() => {
							resolve(payload);
						});
					} else {
						resolve(this.state.savedBook);
					}
				})
				.catch(reject);
		});
	}

	async updateBook(payload) {
		let self = this;
		const { savedBook } = self.state;
		const BOOKID = self.BOOKID;
		const handprintPreInfo = self.props.store?.book?.handprintPreInfo;
		await api.updateSelfPublish(
			{
				bookId: BOOKID,
				savedBook: JSON.stringify(payload),
				option: "dynamic",
				sessionId: localStorage.getItem("sessionID") || null,
				type: "handprint",
				attributes: JSON.stringify(handprintPreInfo),
			},
			savedBook.id
		)
	}

	onSave(payload, self) {
		const BOOKID = self.BOOKID;
		const { savedBook } = self.state;
		let history = self.history;
		const handprintPreInfo = self.props.store?.book?.handprintPreInfo;
		return new Promise((resolve, reject) => {
			mixins.checkLogin(self.history).then(() => {
				if (savedBook && savedBook.id) {
					self.updateBook(payload).then((responce) => {
						self.getLatestBook().then((responce) => {
							resolve(responce);
						})
						.catch(reject);
					});
				} else {
					api.createSelfPublish({
						bookId: BOOKID,
						savedBook: JSON.stringify(payload),
						option: "dynamic",
						sessionId: localStorage.getItem("sessionID") || null,
						file: null,
						type: "handprint",
						attributes: JSON.stringify(handprintPreInfo),
					})
					.then((responce) => {
						self.history.push(
							self.history.location.pathname + `/${responce.data.id}`
						);
						setTimeout(() => {
							self
								.getLatestBook("createmsg")
								.then((responce) => {
									resolve(responce);
								})
								.catch(reject);
						});
					});
				}
			});
		});
	}
	onUpdate() {}
	updateBoilerplate() {
		return new Promise((resolve, reject) => {
			let { staticPages } = this.state;
			let promises = Object.entries(staticPages).map(([key, url]) => {
				return new Promise((resolve, reject) => {
					readUrl(url)
						.then((responce) => resolve({ key, responce }))
						.catch(reject);
				});
			});
			Promise.all(promises)
				.then((responce) => {
					let bolierplates = responce.reduce(
						(o, n) => ({ ...o, [n.key]: n.responce }),
						{}
					);
					resolve(bolierplates);
				})
				.catch(reject);
		});
	}
	onProceed(...arg) {
		this.uploadHanprints(...arg).then(()=>{
			this.pageeditorref.current.querySelector("#previewBook").click();
		})
	}
	updateChanges(payload, modal) {
		let self = this;
		const handprintPreInfo = self.props.store?.book?.handprintPreInfo;
		let preInfo = handprintPreInfo || {};
		preInfo.answer = payload;
		preInfo.answer = payload;
		self.setStore("book.handprintPreInfo", preInfo);
		modal && modal.close();
		setTimeout(() => {
			self.updateData();
		}, 0);
	}
	uploadHanprints(payload, modal) {
		//console.log({uploadHanprints:payload});
		return new Promise((resolve,reject)=>{
			let self = this;
			const handprintPreInfo = self.props.store?.book?.handprintPreInfo;
			let preInfo = handprintPreInfo || {};
			if (
				payload.child_handprint instanceof File ||
				payload.parent_handprint instanceof File
			) {
				const formData = new FormData();
				formData.append("child", payload.child_handprint);
				formData.append("adult", payload.parent_handprint);
				api.uploadHandprint(formData).then((responce) => {
					if (responce && responce.statusCode === 200) {
						preInfo.handprints = Object.map(responce.data, (o) => ({
							...o,
							filepath: "data:image/png;base64," + o.image_base64,
						}));
						preInfo.folder = responce.folder;
						preInfo.answer = payload;
						self.setStore("book.handprintPreInfo", preInfo);
						modal && modal.close();
						self.updateData().then(resolve).catch(reject);
					} else {
						alert(responce.msg);
						reject(responce.msg);
					}
				}).catch(reject);
			} else {
				//console.log({preInfo});
				preInfo.answer = payload;
				self.setStore("book.handprintPreInfo", preInfo);
				modal && modal.close();
				setTimeout(() => {
					self.updateData().then(resolve).catch(reject);
				}, 0);
			}
		});
	}
	collectInfo(closable = false) {
		let onChange = (answer) => {};
		let modal = alert(
			(props) => (
				<PreInfoColletion
					closable={closable}
					alert={props}
					id={this.BOOKID}
					onChange={(...arg) => onChange(...arg)}
					onSave={(...arg) => this.onProceed(...arg)}
					onClose={(...arg) => this.onProceed(...arg)}
				/>
			),
			"",
			"model",
			{
				footer: false,
				class: "",
				parentclass: "HandPrint",
				options: {},
			}
		);
	}
	updateData() {
		return new Promise((resolve,reject)=>{
			let { savedBook } = this.state;
			this.updateBoilerplate().then((bolierplates) => {
				let book = savedBook?.savedBook?.parse({}) || {};
				//console.log({ savedBook, book });
				let pages = this.state.pages.map((o) => ({
					...o,
					content: this.getContent(
						book[o.name] || o.content || bolierplates[o.name] || "",
						o
					),
				}));
				this.setState({
					bolierplates: bolierplates,
					pages,
				});
				resolve(pages);
			}).catch(reject);
		});
	}
	prepareForCollection() {
		let {
			match: {
				params: { genpdf, customBookId },
			},
		} = this.props;
		const handprintPreInfo = this.props.store?.book?.handprintPreInfo;
		const handprints = handprintPreInfo?.handprints;
		if (
			!handprintPreInfo ||
			!handprints ||
			(handprints && Object.keys(handprints).length <= 0 && !customBookId)
		) {
			this.collectInfo();
		} else {
			this.updateData().then(()=>{
				this.pageeditorref.current.querySelector("#previewBook").click();
			});
		}
	}
	componentDidMount() {
		emit.on('updatebook',async (...arg)=>await this.updateBook(...arg));
		this.getLatestBook()
			.then(() => {
				this.prepareForCollection();
			})
			.catch(() => {
				this.prepareForCollection();
			});
	}
	componentWillUnmount() {
		emit.remove('updatebook');
	}
	RightChildren(self) {
		return () => (
			<Button
				className="edit"
				variant="outlined"
				id="editBook"
				onClick={() => self.collectInfo(true)}
			>
				<i className="fa fa-edit"></i>
				<small>Edit</small>
			</Button>
		);
	}
	getNoticeMeesage() {
		let defaultmessage = "";
		let device = this.$store.device;
		if (["mob", "small", "tab", "lap"].includes(device)) {
			defaultmessage +=
				"<br/><span style='font-size:12px; color:#cc3300; font-weight:400;'>Books Best view in Desktop version only</span>";
		}
		return defaultmessage;
	}
	
	onPreviewClose(alert) {
		alert.close();
		//history.back();
	}

	render() {
		let me = this;
		let noticeMessage = this.getNoticeMeesage();
		return ((
			{ savedBook, pages, overlayStyle, pageStyle },
			props,
			{ onSave, RightChildren }
		) => (
			<div className="HandPrint top-margin" ref={me.pageeditorref}>
				<PageEditor
					pagePayload={pages}
					onSave={(...arg) => onSave(...arg, this)}
					charcount={100}
					lastPageNo={31}
					overlay={true}
					style={overlayStyle}
					leftTool={false}
					readonly={true}
					editbackground={false}
					RightChildren={RightChildren(this)}
					showCount={false}
					pageBordered={false}
					pageStyle={pageStyle}
					uploadplaceholder={uploadplaceholder}
					nextmode={true}
					bookDetails={savedBook}
					message={noticeMessage}
					closablePreview={false}
					onPreviewClose={(...arg)=>this.onPreviewClose(...arg)}
				></PageEditor>
			</div>
		))(this.state, this.props, this);
	}
}
export default connect(withRouter(HandPrint));
