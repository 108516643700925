import { Fragment, useEffect, useState, useRef } from "react";
import "../chapter.css";
const pagetwo = (props) => {
    return (
        <Fragment >
            <div className="pageformat height">
                <div className="text-center chapter-title">
                    {/* <h1>Chapter 4</h1> */}
                </div>
                <div className="text-center chapter-content">
                    <p>Snowy tilted his head, as if thinking. "Woof," he said.</p>
                    <p>"What does that mean?" Ellie said, wishing for the hundredth time that she could somehow switch on her power. "I can't understand you, Snowy."</p>
                    <p>Snowy turned to the window and wagged his tail. "Woof woof woof!" he said, getting increasingly excited. He ran towards the window and barked at it.</p>
                    <p>"What is it, Snowy?" Ellie asked. Snowy was a little West Highland terrier and too short to see out of the window even on his hind legs. She picked him up and held him up so that he could peer down below.</p>
                    <p>Ellie's room window overlooked Bishan Park and from where she stood, she could make out two children below, running excitedly, pointing at something. They stopped and knelt down. It looked like they were looking at something.</p>
                </div>
                <div className="page-number">
                    <span className="digite-number">2</span>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(pagetwo);