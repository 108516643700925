import React, { useState } from "react";
import AppInput from "../../partials/AppInput";
import "./customtext.css";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import step01 from "../../assets/images/step1.png";
import step02 from "../../assets/images/step2.png";

let answer = {};

function preinfo(props) {
  
  const history = useHistory();
  const [alertFail, setAlertFail] = useState(null);
  const {
    alert,
    onChange = () => ({}),
    onSave = () => ({}),
    closable = false,
    onClose = () => alert.close(),
  } = props;
  const handprintPreInfo = props.store?.book?.handprintPreInfo || {};
  answer = {
    ...handprintPreInfo.answer,
    ...answer,
  };
  const handleChange = (val) => {
    //console.log({ before: answer });
    answer = { ...answer, ...val };
    onChange(answer);
    //console.log({ after: answer });
  };
  const steps = [
    {
      Content: ({ data }) => (
        <div className="maincontainer">
          <div className="image-flow">
            <div className="uploadcontainer">
            <img src={step01} className="step1-image" />
              <div className="centered">Step 01</div>
            </div>
            <div>
            </div>
            <div className="uploadcontainer">
            <img src={step02} className="step2-image" />
              <div className="centered_02">Step 02</div>
            </div>
          </div>
          <div className="first-row">First, tell us your friend's name.</div>

          <AppInput
            className="second-row"
            type={"text"}
            variant="filled"
            label={"Your friend's first name"}
            onChange={(e) =>
              handleChange({ ...answer, friend_name: e.target.value })
            }
            defaultValue={answer["friend_name"] || ""}
          ></AppInput>
        </div>
      ),
    },
    {
      Content: ({ data }) => (
        <div className="maincontainer">
          <div className="image-flow">
            <div className="uploadcontainer">
            <img src={step01} className="step1-image" />
              <div className="centered">Step 01</div>
            </div>
            <div>
            </div>
            <div className="uploadcontainer">
            <img src={step01} className="step2-image" />
              <div className="centered_02">Step 02</div>
            </div>
          </div>
          <div className="first-row">What is your name. </div>
          <AppInput
            className="second-row"
            type={"text"}
            variant="filled"
            label={"Your first name"}
            onChange={(e) =>
              handleChange({ ...answer, your_name: e.target.value })
            }
            defaultValue={answer["your_name"] || ""}
          ></AppInput>
        </div>
      ),
    },
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const isLast = () => {
    return currentStep == steps.length - 1;
  };

  const onPrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onNext = () => {
    //console.log({ onNext: answer, isLast: isLast(), onSave });
    //console.log(currentStep);
    if (isLast()) {
      if (
        currentStep === 2 &&
        answer.child_handprint &&
        answer.parent_handprint
      ) {
        onSave(answer, props.alert);
      } else {
        onSave(answer, props.alert);
      }
    } else {
      if (currentStep === 0 && answer.friend_name) {
        setCurrentStep(currentStep + 1);
      } else if (currentStep === 1 && answer.your_name) {
        setCurrentStep(currentStep + 1);
      } else if (currentStep === 2 && answer.dedication) {
        setCurrentStep(currentStep + 1);
      } else {
        setAlertFail("Enter the field");
        setTimeout(() => {
          setAlertFail(null);
        }, 2000);
      }
    }
  };

  // const [errorMessage, setErrorMessage] = useState("");
  // const handleClick = () => {
  //   setErrorMessage("Example error message!")
  // }

  const goBack = (alert) => {
    props.alert.close();
    history.push('/handprint-books/product-list');
  }
  return (
    <div className="PreInfoCollection" id="handprint_pre_info_collection">
      {alertFail && (
        <Alert severity="error" variant="filled" className="alert">
          <strong>{alertFail}</strong>
        </Alert>
      )}
      <div className="row">
        {steps.map((step, i) => (
          <div
            key={i}
            className={Object.className({
              "col-12": true,
              active: currentStep == i,
              step: true,
            })}
          >
            <step.Content data={{ index: i }}></step.Content>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12">
          <button className="continue" onClick={() => onNext()}>
            {isLast() ? "SEE YOUR BOOK" : "Next"}
          </button> 
        </div>
      </div>
      <div className="row">
        {currentStep > 0 && (
          <button className="previous" onClick={() => onPrev()}>
            BACK TO PREVIOUS STEP
          </button>
        )}
      </div>
      <div className="row">
        {currentStep == 0 && (
          <button
            className="previous"
            onClick={() => {
              history.push(
                `/handprint-books/product-details/${props.store.bookId}`
              );
              alert.close();
            }}
          >
            BACK TO PRODUCT STEP
          </button>
        )}
      </div>
      {(
      <div className="row">
        <span
          onClick={() => onSave(answer, props.alert) }
          style={{
            position: "absolute",
            top: "-15px",
            right: "-15px",
            padding: "5px 10px",
            background: " lightgray",
            borderRadius: "20px",
            cursor: "pointer",
          }}
        >
          <i className="fa fa-times"></i>
        </span>
      </div>
      )} 
    </div>
  );
}
export default connect(preinfo);
