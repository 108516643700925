import React, { Component, useEffect, useRef, useState } from 'react';
import "./pageNotFound.css";

class pageNotFound extends Component {
    render() {
        return (
            <div className='noPage'>
                <div className='row'>
                    <div className='col'>
                        <div id='page'>
                            <h2>Content not yet saved !</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default pageNotFound;