import React, { Component } from 'react';
import PageEditor from '../PageEditor/PageEditor';
import './PictureBook.css';
import mixins from '../../app-services/mixins';
import { readUrl } from '../PageEditor/support';

import FrontCover from "./FrontCover.txt";
import Copyright from "./Copyright.txt";
import Titlepage from "./Titlepage.txt";
import Dedication from "./Dedication.txt";
import Chaptertitle from "./Chaptertitle.txt";
import Authorandartist from "./Authorandartist.txt";
import Backcover from "./Backcover.txt";
import ImageTextBolierplate from './Bolierplates/ImageTextBolierplate';

import Personalize from '../common/Personalize/Personalize';
import { withRouter } from "react-router";

export class PictureBook extends Component {
    constructor(props) {
        super(props);
        this.BOOKID = props.match.params.id;
        initialConstruct.bind(this)('PictureBook');
        const book = props.store.book.selfpublish.picturebook;
        this.state = {
            staticPages: {
                frontpage: FrontCover,
                copyright: Copyright,
                titlepage: Titlepage,
                dedication: Dedication,
                chaptertitle: Chaptertitle,
                authorandartist: Authorandartist,
                backcover: Backcover
            },
            savedBook: {},
            bolierplates: {},
            svgs: {},
            pages: [
                {
                    pagetype: "fulltext",
                    contentSize: 200,
                    name: `frontpage`,
                    label: `Front Cover`,
                    content: book && book[`frontpage`]
                },
                {
                    pagetype: "fulltext",
                    contentSize: 800,
                    name: `titlepage`,
                    label: `Titlepage`,
                    content: book && book[`titlepage`]
                },
                {
                    pagetype: "fulltext",
                    name: `copyright`,
                    label: `Copyright`,
                    content: book && book[`copyright`]
                },
                {
                    pagetype: "fulltext",
                    name: `dedication`,
                    label: `Dedication`,
                    content: book && book[`dedication`]
                },
                {
                    pagetype: "fulltext",
                    name: `chaptertitle`,
                    label: `Chapter Title`,
                    content: book && book[`chaptertitle`]
                },

                ...this.generateBlankPage(),
                {
                    pagetype: "fulltext",
                    name: `authorandartist`,
                    label: `Author and Artist`,
                    content: book && book[`authorandartist`]
                },
                {
                    pagetype: "fulltext",
                    name: `backcover`,
                    label: `Back Cover`,
                    content: book && book[`backcover`]
                },
            ],
            overlayStyle: {},
            pageStyle: {
                minHeight: '200mm',
                height: '200mm',
                width: '200mm',
                maxWidth: '200mm',
            }
        }
    }

    generateBlankPage(count = 27) {
        let arr = [];
        arr.length = count;
        arr = arr.fill({});
        let pages = arr.map((v, i) => {
            return {
                pagetype: "halftexthalfimage",
                contentSize: 500,
                name: `Page${i + 6}`,
                label: `Page ${i + 6}`,
                content: ImageTextBolierplate
            }
        });
        return pages;
    }

    getContent(book, page) {
        let ele = mixins.getDataNode(book);
        book = ele.innerHTML;
        return book;
    }

    getLatestBook() {
        return new Promise((resolve, reject) => {
            api.getUpdatedSelfBook({
                sessionId: localStorage.getItem("sessionID") || null,
                type:"picturebook"
            }).then((responce) => {
                if (responce && responce.data) {
                    let payload = responce.data;
                    this.setState({
                        savedBook: payload
                    });
                    //console.log({savedBook:payload});
                    resolve(payload);
                } else {
                    resolve(this.state.savedBook);
                }
            }).catch(reject);
        })
    };

    onSave(payload, self) {
        const BOOKID = self.BOOKID;
        const { savedBook } = self.state;
        return new Promise((resolve, reject) => {
            mixins.checkLogin(self.history).then(() => {
                if (savedBook && savedBook.id) {
                    api.updateSelfPublish({
                        bookId: BOOKID,
                        savedBook: JSON.stringify(payload),
                        option: "dynamic",
                        sessionId: localStorage.getItem("sessionID") || null,
                        type:"picturebook"
                    }, savedBook.id).then((responce) => {
                        self.getLatestBook().then((responce) => {
                            resolve(responce);
                        }).catch(reject);
                    });
                } else {
                    api.createSelfPublish({
                        bookId: BOOKID,
                        savedBook: JSON.stringify(payload),
                        option: "dynamic",
                        sessionId: localStorage.getItem("sessionID") || null,
                        file: null,
                        type:"picturebook"
                    }).then((responce) => {
    
                        self.history.push(self.history.location.pathname+`/${responce.data.id}`);
                        setTimeout(()=>{
                            self.getLatestBook("createmsg").then((responce) => {
                                resolve(responce);
                            }).catch(reject);
                        });
                        
                    });
                }
            });
        });
    }
    onUpdate() {

    }

    updateBoilerplate() {
        return new Promise((resolve, reject) => {
            let { staticPages } = this.state;
            let promises = Object.entries(staticPages).map(([key, url]) => {
                return new Promise((resolve, reject) => {
                    readUrl(url).then((responce) => resolve({ key, responce })).catch(reject)
                })
            });
            let load = loader("Loading boilerplates please wait...");
            Promise.all(promises).then((responce) => {
                let bolierplates = responce.reduce((o, n) => ({ ...o, [n.key]: n.responce }), {});
                this.setState({
                    bolierplates: bolierplates
                });
                load.close();
                resolve(bolierplates);
            }).catch(() => { load.close(); reject(); });
        });
    }

    onProceed() {
        this.updateData();
    }

    collectInfo() {
        let modal = alert((props) => (<Personalize alert={props} onProceed={() => this.onProceed()} />), "", "model", {
            footer: false,
            class: "personalize-col col-12 col-md-10 col-lg-10 col-xl-8",
            parentclass: "personalize-row row",
            options: {
            }
        });
    }

    updateData() {
        this.updateBoilerplate().then((bolierplates) => {
            this.getLatestBook().then((bookObj) => {
                let book = bookObj?.savedBook?.parse({}) || {};
                //console.log({ page:"updateData",bookObj, book });
                this.setState({
                    pages: this.state.pages.map((o) => {
                        // if(o.name=="Page6") {
                        //     console.log({page:"getLatestBook",content:o.content ,book: book[o.name] ,bolierplates: bolierplates[o.name]});
                        // }
                        return { ...o, content: this.getContent(book[o.name] || o.content || bolierplates[o.name] || "", o) }
                    })
                });
            });
        });
    }

    componentDidMount() {
        this.updateData();
    }
    getNoticeMeesage() {
        let defaultmessage = "";
        let device = this.$store.device;
        if(["mob","small","tab", "lap"].includes(device)) {
          defaultmessage+="<br/><span style='font-size:12px; color:#cc3300; font-weight:400;'>Books Best view in Desktop version only</span>";
        }
        return defaultmessage;
      }

    render() {
        let noticeMessage = this.getNoticeMeesage();
        initialConstruct.bind(this)('PictureBook');
        return (({ savedBook,pages, overlayStyle, pageStyle }, props, { onSave, onUpdate }) => (
            <div className='PictureBook top-margin'>
                <PageEditor
                    pagePayload={pages}
                    pageStyle={pageStyle}
                    pageMode={'mono'}
                    onSave={(...arg) => onSave(...arg, this)}
                    onUpdate={onUpdate}
                    charcount={1000}
                    lastPageNo={34}
                    overlay={false}
                    style={overlayStyle}
                    bookDetails={savedBook}
                    message={noticeMessage}
                ></PageEditor>
            </div>
        ))(this.state, this.props, this);
    }
}

export default connect(withRouter(PictureBook));