import { Fragment, useEffect, useState, useRef } from "react";
import '../about.css';
import image1 from "./Green.svg";
import image2 from "./LightgreensmallCircle.svg";
import image3 from "./lines.svg";
import image4 from "./liningCircle.svg";
import { useForm } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import { data } from "jquery";

const contact = (props) => {
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });
    const [alertFail, setAlertFail] = useState(null);
    const [alert, setAlert] = useState(null);
    const [name,setName] = useState("");
    const [email,setEmail] = useState("")
    const [phone,setPhone] = useState("")
    const [subject,setSubject] = useState("")
    const [description,setDescription] = useState("")

    const onSubmit = (data) => {
        data.Name = name;
        data.email = email;
        data.phone = phone;
        data.Subject = subject;
        data.Description = description;
        api.postGetInTouch(data).then((res) => {
           // console.log(res)
            if (res && res.statusCode === 200) {
                //console.log(res);
                setAlert(res.message);
                setName("");
                setEmail("");
                setPhone("");
                setSubject("");
                setDescription("");
                setTimeout(() => {
                    setAlert(null);
                }, 2000)
                data = null;
            } else if (res && res.message) {
                //console.log(2)
                setAlertFail(res.errorMessages[0]);
                setTimeout(() => {
                    setAlertFail(null);
                }, 2000)
            }
        })
    }
    return (
        <Fragment >
            <section className="contact pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span className="sub-title">GET IN TOUCH</span> 
                            <h1 className="title">Contact Us</h1>
                            {alert && (

                                <Alert severity="success" variant="filled" style={
                                    { "width": "100%" }
                                } >
                                    <strong>{alert}</strong>
                                </Alert>

                            )}
                            {alertFail && (
                                <Alert severity="error" variant="filled" className='alert'>
                                    <strong>{alertFail}</strong>
                                </Alert>
                            )}
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} className="col-md-8 offset-md-2">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your Name"
                                            name={name}
                                            value={name}
                                            onChange={(e)=>{
                                                setName(e.target.value)
                                            }}
                                            onKeyPress={(event) => {
                                                if (!/[a-z,A-Z]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            required
                                           />
                                    </div>
                                    {/* <div className="form-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Phone Number"
                                            required
                                            name="phone"
                                            {...register("phone", { required: true })} />
                                    </div> */}
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email Address"
                                            required
                                            name={email}
                                            value={email}
                                            onChange={(e)=>{
                                                setEmail(e.target.value)
                                            }}
                                            />
                                    </div>
                                    {/* <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Subject"
                                            required
                                            name="Subject"
                                            {...register("Subject", { required: true })} />
                                    </div> */}
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Phone Number"
                                            required
                                            name={phone}
                                            value={phone}
                                            onChange={(e)=>{
                                                const value = e.target.value.replace(/\D/g, "");
                                                setPhone(value);
                                            }} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Subject"
                                            required
                                            name={subject}
                                            value={subject}
                                            onChange={(e)=>{
                                                setSubject(e.target.value)
                                            }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea
                                            placeholder="Description"
                                            className="form-control"
                                            rows={5}
                                            required
                                            name={description}
                                            value={description}
                                            onChange={(e)=>{
                                                setDescription(e.target.value)
                                            }}>
                                        </textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input type="submit" className="btn" value={"Submit Now"} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <img src={image1} className="contact_image_one" />
                    <img src={image2} className="contact_image_two" />
                    <img src={image3} className="contact_image_three" />
                    <img src={image4} className="contact_image_four" />
                </div>
            </section>
        </Fragment>
    )
}
export default connect(contact);