import React, { useEffect, useState } from "react";
import Select from "react-select";
import i from "../../../assets/images/i.png";
import car from "../../../assets/images/tipper.png";
import location from "../../../assets/images/location.png";
import home from "../../../assets/images/home.png";
import { useHistory, useParams } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Alert from "@mui/material/Alert";
import "react-phone-input-2/lib/style.css";
export default connect((props) => {
  const history = useHistory();
  const { code } = useParams();
  const { handleSubmit } = useForm({ mode: "onBlur" });
  const [number, setNumber] = useState("");
  const [city, setCity] = useState([]);
  const [stateName, setStateName] = useState([]);
  const [selected, setSelected] = useState("SG");
  const [emailId, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [citySelected, setCitySelected] = useState("");
  const [zip, setZip] = useState("");
  const [subscribe, setSubscribe] = useState(0);
  const [defaults, setDefaults] = useState(0);
  const [alertFail, setAlertFail] = useState(null);
  // Billing Address
  const [billedit, setBilledit] = useState(1);
  const [sameAdd, setSameadd] = useState(true);
  const [billfname, setBillfname] = useState("");
  const [billlname, setBilllname] = useState("");
  const [billbuilding, setBillbuilding] = useState("");
  const [billstreet, setBillstreet] = useState("");
  const [billcountry, setBillcountry] = useState("");
  const [billzip, setBillzip] = useState("");
  const [billcity, setBillcity] = useState("");
  const [billphone, setBillphone] = useState("");

  useEffect(() => {
    OnHandleChangeCountry("SG");
    
  }, []);
  useEffect(() => {
    let selectedCity =
      city.find((o) => o.label == props.store?.EditableAddress?.city) || {};
    // console.log("Selected City",{ city, name: props.store?.fulladdress?.city, selectedCity });
    setCitySelected(city[0]);
   

  }, [city]);
  useEffect(() => {
    let billcities =
      city.find((o) => o.label == props.store?.BillingAddress?.city) || {};
    if (billcities) {
      setBillcity(city[0]);
    }

  }, [city]);
  useEffect(() => {
    if (props.store?.BillingAddress?.id) {
      setSameadd(false);
    }
    setBillfname(props.store?.BillingAddress?.firstName || '');
    setBilllname(props.store?.BillingAddress?.lastName || '');
    setBillcity(props.store?.BillingAddress?.city || '');
    setBillbuilding(props.store?.BillingAddress?.address1 || '');
    setBillstreet(props.store?.BillingAddress?.address2 || '');
    setBillcountry(props.store?.BillingAddress?.country || '');
    setBillphone(props.store?.BillingAddress?.contactNo || '');
    setBillzip(props.store?.BillingAddress?.zipCode || '');
    if (code) {
      if (props.store.EditableAddress) {
        setFname(props.store.EditableAddress?.firstName || '');
        setLname(props.store.EditableAddress?.lastName || '');
        setAddress(props.store.EditableAddress?.address1 || '');
        setAddress1(props.store.EditableAddress?.address2 || '');
        setStateName(props.store.EditableAddress?.state || '');
        setZip(props.store.EditableAddress?.zipCode || '');
        setNumber(props.store.EditableAddress?.contactNo?.toString() || '');
        setSelected(props.store.EditableAddress?.country || '');
        setDefaults(props.store.EditableAddress?.default || '');
        // Set Billing Address
      }
    } else if (props.store?.AddressList?.length == 0) {
      setDefaults(1);
    }
  }, []);
  //Get all cities bu country Id
  const OnHandleChangeCountry = (code) => {
    setSelected(code);
    setBillcountry(code);
    if (code) {
      api
        .postAllCities({
          code: code,
        })
        .then((res) => {
          if (res && res.statusCode === 200 && res.data && res.data.cities) {
            const arrayObj = res.data.cities.filter(item => item.city == 'Singapore').map((item) => {
              return {
                value: item.id,
                label: item.city,
              };
            });
            console.log(arrayObj)
            setCity(arrayObj);
            setEmail(res.data.email);
          }
        });
    }
  };
  const onSubmit = () => {
    // Billing Address Temporary Varible

    let billingFirtname = sameAdd == false ? billfname : fname;
    let billingLastname = sameAdd == false ? billlname : lname;
    let billingPhone = sameAdd == false ? billphone : number;
    let billingCityname = sameAdd == false ? billcity : citySelected;
    let billingCountry = sameAdd == false ? billcountry : selected;
    let billingZip = sameAdd == false ? billzip : zip;
    let billingBuilding = sameAdd == false ? billbuilding : address;
    let billingStreet = sameAdd == false ? billstreet : address1;
    let billingId = props.store?.BillingAddress?.id;
    //console.log(billedit, billingId);
    if (code) {
      api
        .updateShipingAddress(code, {
          firstName: fname,
          lastName: lname,
          email: emailId,
          contactNo: number,
          address1: address || '',
          address2: address1 || '',
          landmark: "",
          city: citySelected && citySelected.label,
          state: "",
          country: selected,
          zipCode: zip,
          default: defaults,
          subscribe: +subscribe,
          // Billing
          billEditstatus: billedit,
          billId: billingId || null,
          billaddress1: billingBuilding || '',
          billaddress2: billingStreet || '',
          billcity: billingCityname && billingCityname.label,
          billcountry: billingCountry,
          billfirstname: billingFirtname,
          billlastname: billingLastname,
          billphone: billingPhone,
          billzipcode: billingZip,
        })
        .then((res) => {
          if (res && res.statusCode === 200 && res.data) {
            mixins.setStore("fulladdress", res.data, props);
            if (props.store.deliveryDetails) {
              history.push("/payment");
            } else {
              history.push("/delivery");
            }
          } else {
            if (res && res.errorMessages) {
              setAlertFail(res.errorMessages[0]);
              setTimeout(() => {
                setAlertFail(null);
              }, 3000);
            } else {
              setAlertFail(res.message);
              setTimeout(() => {
                setAlertFail(null);
              }, 3000);
            }
          }
        });
    } else {
      api
        .createShipingDetails({
          firstName: fname,
          lastName: lname,
          email: emailId,
          contactNo: number,
          address1: address,
          address2: address1 || '',
          landmark: "",
          city: citySelected && citySelected.label,
          state: "",
          country: selected,
          zipCode: zip,
          default: defaults,
          subscribe: +subscribe,
          // Billing
          billEditstatus: billingId == null ? 1 : 0,
          billId: billingId || null,
          billaddress1: billingBuilding,
          billaddress2: billingStreet || '',
          billcity: billingCityname && billingCityname.label,
          billcountry: billingCountry,
          billfirstname: billingFirtname,
          billlastname: billingLastname,
          billphone: billingPhone,
          billzipcode: billingZip,
        })
        .then((res) => {
          if (res && res.statusCode === 200) {
            history.push("/delivery");
          } else {
            if (res && res.errorMessages) {
              setAlertFail(res.errorMessages[0]);
              setTimeout(() => {
                setAlertFail(null);
              }, 3000);
            } else {
              setAlertFail(res.message);
              setTimeout(() => {
                setAlertFail(null);
              }, 3000);
            }
          }
        });
    }

  };
  //subscribe radion button
  const handleChangeRadioButton = (e) => {
    if (e.target.name === "subscribe") {
      setSubscribe(e.target.value);
    }
  };
  const log = console.log;
  return (
    <div className="addresscard">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="address-fields-container">
          {alertFail && (
            <Alert severity="error" variant="filled">
              <strong>{alertFail}</strong>
            </Alert>
          )}
          <div className="address-form-input default-address">
            <span>Save as Default Address</span>
            <input
              type="checkbox"
              name="default"
              checked={defaults}
              onChange={(e) => setDefaults(e.target.checked)}
            />
          </div>
          <div className="address-form-input">
            <div className="address-form-input-row">
              <i className="fas fa-user icon"></i>
              <input
                type="text"
                placeholder="First Name"
                value={fname}
                required
                onChange={(e) => {
                  setFname(e.target.value);
                }}
                className="input"
              />
            </div>
            <div className="address-form-input-row">
              <input
                type="text"
                placeholder="Last Name"
                className="input-last"
                value={lname}
                required
                onChange={(e) => {
                  setLname(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="address-form-input">
            <div className="address-form-input-row">
              <img src={home} className="icon-png" />
              <textarea
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                type="textarea"
                required
                placeholder="House/Apt Number, Street Name"
                className="input"
              />
            </div>
            <div className="address-form-input-row">
              <img src={location} className="icon-png" />
              <textarea
                value={address1}
                onChange={(e) => {
                  setAddress1(e.target.value);
                }}
                type="textarea"
                placeholder="Address line 2 (optional) "
                className="input"
              />
            </div>
          </div>
          <div className="address-form-input">
            {/* <div className="address-form-input-row">
              <input
                value={stateName}
                required
                onChange={(e) => {
                  setStateName(e.target.value);
                }}
                type="text"
                placeholder="State"
                className="input-last"
              />
            </div> */}
            <div className="address-form-input-row">
              <ReactFlagsSelect
                selected={selected}
                countries={["SG"]}
                onSelect={(code) => OnHandleChangeCountry(code)}
                searchable={false}
              />
              {/* <select onChange={(e) => OnHandleChangeCountry(e.target.value)}>
                <option value="SG" selected>
                  Singapore
                </option>
              </select> */}
            </div>
            <div className="address-form-input-row">
              <input
                type="number"
                required
                placeholder="Postcode"
                className="input-last"
                value={zip}
                name="zip"
                minLength={6}
                maxLength={6}
                onChange={(e) => {
                  setZip(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="address-form-input">
            <div className="address-form-input-row">
              <Select
                placeholder="Town / City"
                value={citySelected}
                options={city}
                onChange={(e) => setCitySelected(e)}
                isSearchable={true}

              />
            </div>
            <div className="address-form-input-row">
              <PhoneInput
                country={"sg"}
                value={number}
                defaultValue={number}
                onChange={(phone) => setNumber(phone)}
              />
            </div>
          </div>
          <div className="address-form-input">
            <div className="address-form-input-row">
              <img src={i} className="i-img" />
              <label>We'll update you on your order status</label>
            </div>
            <div className="address-form-input-row">
              <img src={i} className="i-img" />
              <label>Get free updates on where your parcel is</label>
            </div>
          </div>
          {/* Billing Address */}
          <div className="address-form-input default-address">
            <input
              type="checkbox"
              name="default"
              checked={sameAdd}
              onChange={(e) => setSameadd(e.target.checked)}
            />
            <span>My billing and shipping address are the same</span>
          </div>
          {/* condition for Same address */}
          {sameAdd == false && (
            <>
              <div className="address-form-input">
                <div className="address-form-input-row">
                  <i className="fas fa-user icon"></i>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={billfname}
                    required
                    onChange={(e) => {
                      setBillfname(e.target.value);
                      setBilledit(1);
                    }}
                    className="input"
                  />
                </div>
                <div className="address-form-input-row">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="input-last"
                    value={billlname}
                    required
                    onChange={(e) => {
                      setBilllname(e.target.value);
                      setBilledit(1);
                    }}
                  />
                </div>
              </div>
              <div className="address-form-input">
                <div className="address-form-input-row">
                  <img src={home} className="icon-png" />
                  <textarea
                    value={billbuilding}
                    onChange={(e) => {
                      setBillbuilding(e.target.value);
                      setBilledit(1);
                    }}
                    type="textarea"
                    required
                    placeholder="House/Apt Number, Street Name"
                    className="input"
                  />
                </div>
                <div className="address-form-input-row">
                  <img src={location} className="icon-png" />
                  <textarea
                    value={billstreet}
                    onChange={(e) => {
                      setBillstreet(e.target.value);
                      setBilledit(1);
                    }}
                    type="textarea"
                    placeholder="Address line 2 (optional) "
                    className="input"
                  />
                </div>
              </div>
              <div className="address-form-input">
                <div className="address-form-input-row">
                  <ReactFlagsSelect
                    selected={selected}
                    countries={["SG"]}
                    onSelect={(code) => { OnHandleChangeCountry(code); setBilledit(1); }}
                    searchable={false}
                  />
                </div>
                <div className="address-form-input-row">
                  <input
                    type="number"
                    required
                    placeholder="Postcode"
                    className="input-last"
                    value={billzip}
                    name="zip"
                    minLength={6}
                    maxLength={6}
                    onChange={(e) => {
                      setBillzip(e.target.value);
                      setBilledit(1);
                    }}
                  />
                </div>
              </div>
              <div className="address-form-input">
                <div className="address-form-input-row">
                  <Select
                    placeholder="Town / City"
                    value={billcity}

                    options={city}
                    onChange={(e) => { setBillcity(e); setBilledit(1); }}
                    isSearchable={true}
                  />
                </div>
                <div className="address-form-input-row">
                  <PhoneInput
                    country={"sg"}
                    value={billphone}
                    defaultValue={number}
                    onChange={(phone) => { setBillphone(phone); setBilledit(1); }}
                  />
                </div>
              </div>
            </>
          )}
          {/* End Billing Address */}
          {code ? null : (
            <div className="address-form-input-radio-container">
              <div className="address-form-input-radio">
                <input
                  type="radio"
                  checked={subscribe == 1}
                  value={1}
                  name="subscribe"
                  onChange={(e) => handleChangeRadioButton(e)}
                />
                Join our members email to get 20% off your next order, and other
                treats & offers throughout the year
              </div>
              <div className="address-form-input-radio">
                <input
                  type="radio"
                  checked={subscribe == 0}
                  value={0}
                  name="subscribe"
                  onChange={(e) => handleChangeRadioButton(e)}
                />
                No Thanks
              </div>
            </div>
          )}
          <div className="address-form-input">
            <button className="buttoncontainer" type="submit">
              <img src={car} className="icon-png" /> Continue to Delivery
            </button>
          </div>
          <div className="address-form-input">
            <span>
              By clicking Continue, you agree to our <a target='_blank' href="/terms" >terms & conditions </a>{" "}
              and <a target='_blank' href="/privacy">privacy policy.</a>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
});
