import React, { useState } from "react";
import style from "./ContactUs.module.css";
import commentIcon from "../../../assets/images/commentingicon.png";
import Alert from "@mui/material/Alert";
const ContactUs = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const[alert,setAlert]=useState(null);
    const[alertFail,setAlertFail]=useState(null);
    const [suggestions, setSuggestions] = useState('');
    const[nameerror,setNameerror] = useState(null);
    const[emailerror,setEmailerror] = useState(null);
    const[suggestionerror,setSuggestionerror] = useState(null);
    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      }
    const OnHandleclickSubcribe = () => {
        if (email && name && suggestions) {
            setNameerror('')
            setEmailerror('')
            setSuggestionerror('')
            if(validateEmail(email)){
                api.postNewsLetter(
                    {
                        "type": "feedback",
                        "email": email,
                        "name": name,
                        "suggestions": suggestions,
                    }
                ).then((res) => {
                    if (res && res.statusCode === 200) {
                       setAlert(res.message);
                       setEmail('');
                       setName('');
                       setSuggestions('');
                       setTimeout(() => {
                            setAlert(null);
                           
                     }, 2000)
                    }else{
                        setAlertFail(res.message);
                        setTimeout(() => {
                            setAlertFail(null);
                         }, 2000)
                    }
                })
            }else{
                setEmailerror("please provide valid email")
            }
            
        }
        else if(!name){
            setNameerror("please provide name ")
        }else if(!email){
            setNameerror('')
            setEmailerror("please provide  email")
        }else if(!suggestions){
            setNameerror('')
            setEmailerror('') 
            setSuggestionerror("please provide description ")
        }
       
    }
    return (
        <div className={style["container"]}>
            <div className={style["title"]}>
                We Love to hear from you
            </div>
            <form className={style["form"]}>
                <div className={style["form-input-div"]}>
                    <div className={style["form-input-div-name"]}>
                        <div className={style["form-input1-container"]}>
                            <i className="fas fa-user"></i>
                        </div>
                        <div className={style["form-input1-sub-container"]}>
                        <input type="text" placeholder="Your Name"
                        value={name}
                            name={name}
                            onChange={(e) => { setName(e.target.value) }}
                            className={style["form-input1"]} 
                            onKeyPress={(event) => {
                                if (!/[a-z,A-Z]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            required/>
                              <span>{nameerror?<Alert severity="error">{nameerror}</Alert>:null}</span>
                        </div>
                       
                          
                            
                    </div>
                    
                    <div className={style["form-input-div-email"]}>
                        <div className={style["form-input2-container"]}>
                            <i className="fas fa-envelope"></i>
                        </div>
                        <div className={style["form-input1-sub-container"]}>
                        <input type="email" placeholder="Email Address" className={style["form-input2"]}
                            name='email'
                            defaultValue={email||''}
                            onChange={(e) => { setEmail(e.target.value) }} required />
                             <span>{emailerror?<Alert severity="error">{emailerror}</Alert>:null}</span>
                        </div>
                        
                    </div>
                </div>
                <div className={style["form-textarea-div"]}>
                    <div className={style["form-textarea-container"]}>
                        <img src={commentIcon} style={{ width:"20px",height:"20px" }} />
                    </div>
                    <div className={style["form-textarea-sub-container"]}>
                     <textarea
                        name='suggestions'
                        placeholder="Describe your Feedback and Suggestions....."
                        className={style["form-textarea"]}
                        value={suggestions}
                        onChange={(e) => { setSuggestions(e.target.value) }}
                        required
                    ></textarea>
                    <span>{suggestionerror?<Alert severity="error">{suggestionerror}</Alert>:null}</span>
                    </div>
                   
                </div>
                {alert&&(
                 <Alert severity="success" variant="filled">
                     <strong>{alert}</strong>
                 </Alert>
            )}
             {alertFail&&(
                 <Alert severity="error" variant="filled">
                     <strong>{alertFail}</strong>
                 </Alert>
            )}
                <button type="button" className={style["form-submit"]} onClick={OnHandleclickSubcribe}>Submit Now</button>
            </form>
        </div>
    )
}
export default ContactUs;
