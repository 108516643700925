import apisetup from "./apisetup";
import api1 from "./api1";
import api2 from "./api2";
import SelfPublishAPI from "./apiSelfPublish.js";
import customCharacterAPI from "./apiCustomCharacter.js";
import apiProfile from "./apiProfile.js";
import apiDashboard from "./apiDashboard.js";
import apiHandPrint from "./apiHandPrint";

window.api = {
    ...apisetup,
    ...api1,
    ...api2,
    ...SelfPublishAPI,
    ...customCharacterAPI,
    ...apiProfile,
    ...apiDashboard,
    ...apiHandPrint
}
export default api;