import { Fragment, useEffect, useState, useRef } from "react";
import "./contact.css"
import banner from "./contactus.jpg"

const contact = (props) => {
    const [cmsinfo, setcmsinfo] = useState({});
    const getcmsinfo = () => {
        api.getcmsInfo().then((res) => {

            if (res && res.data) {
                let ret = res.data.reduce((ov, nv) => {
                    ov[nv.cmsPages.type] = nv;
                    return ov;
                }, {})
                // console.log(ret);
                setcmsinfo(ret);
            }

        });
    }
    useEffect(() => {
        getcmsinfo();
    }, [])
    // console.log(cmsinfo['Privacy Policy']);
    let contactcms = cmsinfo['Contact Us'];
    //console.log(contactcms);
    // console.log(contactcms.description);
    return (
        <Fragment >
            <div className="banner singlePageBanner">
                {contactcms && contactcms.file['filePath'] &&
                    <img src={contactcms.file['filePath']} />
                }
            </div>
            <div className="container pb-5 pt-5">
                {/* <div className='row pt-5'>
                    <div className="col-12">
                        {contactcms && contactcms.cmsPages['type'] &&
                            <h1 dangerouslySetInnerHTML={{ __html: contactcms.cmsPages['type'] }} />
                        }
                    </div>
                </div> */}
                <div className='row pt-5'>
                    <div className="col-12 mb-5">
                        {contactcms && contactcms.description &&
                            <div dangerouslySetInnerHTML={{ __html: contactcms.description }} />
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(contact);