import React, { Component } from 'react';
import ScrollContainer from '../common/ScrollContainer';
import { Modal, Box, TextareaAutosize, Button, Select, MenuItem } from '@mui/material';

export class PageFooter extends Component {
    constructor(props) {
        super(props);
        this.changePage = props.changePage;
        initialConstruct.bind(this)('PageFooter');
    }
    
    render() {
        let self = this;
        let { currentIndex, pagePayload } = this.props;
        return (
            <div className="row no-gutters py-3">
                <div className="col d-flex">
                    {
                        currentIndex === 0 ? null : <Button className="nav-button ml-auto" variant="contained" style={{ background: "#50A1A4" }}
                            onClick={() => self.changePage('prev')} >
                            <i className="fa fa-angle-left px-2"></i>Previous</Button>
                    }
                </div>
                <div className="col">
                    <div className="d-flex" style={{ hight: '100%' }}>
                        {
                            pagePayload.length && <span className="m-auto">{pagePayload[currentIndex].label} / {pagePayload.length}</span>
                        }

                    </div>
                </div>
                <div className="col d-flex">
                    <Button className="nav-button"
                        variant="contained"
                        style={{ background: "#50A1A4" }}
                        onClick={() => self.changePage('next')}>
                        {(currentIndex >= pagePayload.length - 1)?'Preview':'Next'}
                        <i className="fa fa-angle-right px-2"></i>
                    </Button>
                </div>
            </div>
        )
    }
}

export class PageScroller extends Component {
    constructor(props) {
        super(props);
        this.setPage = props.setPage;
        initialConstruct.bind(this)('PageScroller');
    }
    render() {
        let self = this;
        let { pagePayload, currentIndex, pagetileref, scrlpagetiles } = this.props;
        return (
            <div className='row no-gutters my-5'>
                <div className={"col-12"}>
                    <ScrollContainer scrlref={scrlpagetiles} top={10} left={-50} right={-108} shift={108}>
                        {
                            (cprop) => (
                                <div className="page-tiles" ref={scrlpagetiles}>
                                    {
                                        pagePayload.map((o, i) => (
                                            <div ref={i == currentIndex ? pagetileref : null} key={i} className={"page-tile " + ((i == currentIndex) && 'active')} onClick={() => self.setPage(i)}>
                                                <div className='child'></div>
                                                <div className='page_name'>{o.pagename || o.label}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </ScrollContainer>
                </div>
            </div>
        )
    }
}

export default PageFooter