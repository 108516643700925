import React, { Component } from "react";
import { withRouter } from "react-router";
import Booklet from "../../../components/Booklet/Booklet";
import CheckoutSteps from "../../preview/CheckoutSteps/CheckoutSteps";
import "./BookPreview.css";

export class BookPreview extends Component {
  constructor(props) {
    super(props);
    const { onFetch = (...arg) => api.getSelfPublish(...arg) } = props;
    this.onFetch = onFetch;
    this.CUSTOMBOOKID = props.bookDetails.id;
    initialConstruct.bind(this)("BookPreview");
    this.$ele = React.createRef();
    this.state = {
      pages: [],
      bookDetails: null,
      productDetails: null,
      answers: {},
      parsedBook: null,
      bookobj: null,
    };
  }

  onBookSetup(bookobj) {
    this.setState({ bookobj });
    let previewPageNo = localStorage.getItem("previewPageNo");
    if (previewPageNo) {
      setTimeout(() => {
        bookobj.turnToPage(Number(previewPageNo));
        localStorage.removeItem("previewPageNo");
      }, 100);
    }
  }

  getPages() {
    let { getPayload } = this.props;
    return new Promise((resolve, reject) => {
      getPayload()
        .then((book) => {
          resolve(
            Object.entries(book).map(([key, value], i) => ({
              pagetype: key,
              name: key,
              label: i + 1,
              content: value.html,
            }))
          );
        })
        .catch((err) => reject(err));
    });
  }

  getProductDetails() {
    let self = this;
    let { bookDetails } = this.props;
    //console.log(bookDetails);
    api.getBookDetails(bookDetails.bookId).then((res) => {
      self.setState({
        productDetails: res.data.bookDetails,
      });
    });
  }

  getBookDetails() {
    let { bookDetails } = this.props;
    return new Promise((resolve, reject) => {
      if (bookDetails.id) {
        this.onFetch(bookDetails.id).then((responce) => {
          if (responce.data && Object.keys(responce.data).length) {
            let savedBookObj = responce.data || { savedBook: "{}" };
            let parsedBook = savedBookObj.savedBook.parse();
            this.setState({
              bookDetails: savedBookObj,
              parsedBook,
            });
            resolve(savedBookObj);
          }
        });
      }
    });
  }
  componentDidMount() {
    window.BookPreview = this;
    this.getPages().then((pages) => {
      this.setState({
        pages,
      });
      this.getBookDetails().then(() => {
        this.getProductDetails();
      });
    });
  }

  componentWillUpdate(props,state) {
    mixins.debounce(document, () => {}, "customCharacter.8.ans.dedication");
    this.watch({
      productDetails:function(val) {
        if(props.store.triggeradd2cart) {
          this.setStore('triggeradd2cart',false);
          setTimeout(()=>{
            this.addToCart();
          });
        }
      }
    },state,this.state);
  }

  componentDidUpdate() {

  }

  getPageSize(name) {
    let { bookname } = this.props;
    let booksize = this.$store.bookSize[bookname];
    if (booksize && booksize[name]) {
      return `${booksize[name]}${booksize["unit"]}`;
    } else {
      return "";
    }
  }

  getCorrection(attributeName) {
    let device = this.$store.device;
    let correct = 0;
    let { bookname } = this.props;
    //console.log({getCorrection:bookname});
    if(["picture-book","handprint-book"].includes(bookname)) {
      switch(device) { 
        case "desk": correct = `${(1-0.8).toFixed(2).parse()*200}mm`.toPx(); break; 
        case "lap":
        case "mac": correct = `${(1-0.6).toFixed(2).parse()*200}mm`.toPx(); break;
      }
    }
    return correct;
  }

  getPageWidth() {
    return this.getPageSize("width").toPx() - this.getCorrection("width");
  }

  getPageHeight() {
    return this.getPageSize("height").toPx() - this.getCorrection("height");
  }

  getStyle() {
    return {
      "--bookHeight": this.getPageSize("height"),
      "--bookWidth": this.getPageSize("width"),
    };
  }

  onPage(obj, i, arr) {
    return {
      ...obj,
      content: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: obj.content || "" }}></div>
          <div className="col text-center">
            <b>{obj.label}</b> / {arr.length}
          </div>
        </div>
      ),
    };
  }

  onAnswer(value, key) {
    let { answers } = this.state;
    let { onUpdate } = this.props;
    if (key == "dedication") {
      onUpdate(key, value);
    }
    this.setState({
      answers: {
        ...answers,
        [key]: value,
      },
    });
  }

  onDedicationSave() {
    let { saveBook } = this.props;
    let { bookname } = this.props;
    return new Promise((resolve,reject)=>{
      saveBook().then(() => {
        this.props.alert.close();
        setTimeout(() => {
          if(bookname === 'customcharacter-book'){
            localStorage.setItem("previewPageNo", 18);
          }
          document.querySelector(".right-toolbar #previewBook").click();
          resolve();
        }, 100);
      }).catch(reject);
    });
  }

  checkoutSave() {
    return new Promise((resolve,reject)=>{
      this.onDedicationSave().then(()=>{
        let { bookDetails } = this.state;
        this.setState({
          productDetails: {...bookDetails,triggeradd2cart:true},
        });
        this.setStore('triggeradd2cart',true);
      });
    });
  }

  addToCart() {
    let props = this.props;
    let { productDetails, answers } = this.state;
    let { saveBook,getPayload } = props;
    let { bookDetails } = this.state;
    let { history } = this;
    //console.log({addToCart:productDetails});
    mixins.checkLogin(history).then(async (user)=>{
      if (!productDetails) {
        this.checkoutSave();
        return;
      } else {
        let payload = await getPayload();
        await emit.trigger('updatebook',payload);
      }
      Modal.state.list.filter((o) => o.type == "alert")[0]?.close();
      if (localStorage.getItem("editmode") === "true") {
        api.updateToCart(
            {
              promotionId: null,
              productId: productDetails?.product?.id,
              quantity: 1,
              price: productDetails?.book?.bookPrice,
              discount: null,
              bookCover: answers.cover || null,
              customProductId: props.store.cartdetails?.customProductId,
            },
            props.store.cartdetails?.id
          )
          .then((res) => {
            localStorage.setItem("editmode",false)
            if (res && res.statusCode === 200) {
              props.alert.close();
              history.push("/checkout-page");
            } else {
              alert("Something went wrong please try again later..");
            }
          });
      } else {
       // console.log("addding to cart");
        api
          .addToCart({
            promotionId: null,
            productId: productDetails?.product?.id,
            quantity: 1,
            price: productDetails?.book?.bookPrice,
            discount: null,
            bookCover: answers.cover || null,
            customBookId: bookDetails.customBook.id,
          })
          .then((res) => {
            if (res && res.statusCode === 200) {
              props.alert.close();
              history.push("/checkout-page");
              mixins.reFectchSavedBookDetails(this.props);
            } else {
              alert("Something went wrong please try again later..");
            }
          });
      }
    });
  }

  getStepKeys() {
    let { productDetails, bookDetails } = this.state;
    let { bookname } = this.props;
    switch (bookname) {
      case "customcharacter-book":
        return ["edit","dedication"];
      case "handprint-book":
          return ["edit"];
      default:
        return [];
    }
  }

  render() {
    let me = this;
    let { bookname } = this.props;
    return (({ alert,message,closablePreview=true,onPreviewClose=(a)=>a.close() }, { pages, bookDetails,productDetails }) => (
      <div className={'BookPreview '+bookname} style={me.getStyle()} ref={me.$ele} >
        { closablePreview && <i className="close fa fa-times" onClick={() => onPreviewClose(alert) }></i> }
        <div className='mt-5'>
        {
          (pages.length > 0) && <Booklet
            onSetup={(bookObj)=>me.onBookSetup(bookObj)}
            pageWidth={me.getPageWidth()}
            pageHeight={me.getPageHeight()}
            pages={pages}
            onPage={(...arg) => me.onPage(...arg)}
          />
        }
        </div>
        <div className='mt-4'>
          {
            message && <div className="col text-center" style={{fontWeight: 'bold', color: 'red'}} dangerouslySetInnerHTML={{__html:message}}>
            </div>
          }
        </div>
        <div className='my-5' >
        {
          <CheckoutSteps
            keys={me.getStepKeys()}
            addToCart={() => me.addToCart()}
            onAnswer={(v, k) => me.onAnswer(v, k)}
            onSave={(v)=>me.onDedicationSave(v)}
            bookname={bookname}
          />
        }
        </div>
      </div>
    ))(this.props, this.state, this);
  }
}

export default connect(withRouter(BookPreview));
