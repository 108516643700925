import React, { useEffect, useState } from 'react';
import forget from '../../../assets/images/forget.png'
import email from '../../../assets/images/email.png'
import './ForgetPassword.css'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import Alert from '@mui/material/Alert';
export default connect((props) => {
    const { handleSubmit, register } = useForm({ mode: 'onBlur' });
    const [input1, setInput1] = useState(null);
    const [input2, setInput2] = useState(null);
    const [input3, setInput3] = useState(null);
    const [input4, setInput4] = useState(null);
    const [alertFail, setAlertFail] = useState(null);
    const history = useHistory();

    const handlenext = (event) => {
        if (event.target.value.length === 1) {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    };

    const onsubmit = () => {
        const result = input1.concat(input2, input3, input4);
        //console.log("data", result);
        const data = {
            "otp": result
        }
        api.otp_verification(data).then((res) => {
            //console.log(res);
            if (res && res.statusCode === 200) {
                //console.log(res);
                history.push('/new-password')
            } else if (res && res.message) {
                //console.log(res);
                setAlertFail(res.message);
            }
        })
    }
    return (
        <div className='forgetcontainer'>

            <div className='forget-formcontainer'>
                {alertFail && (

                    <Alert severity="error" variant="filled" >

                        <strong>{alertFail}</strong>
                    </Alert>

                )}
                {/* <img src={forget} className="img" /> */}
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div className='subcontainer'>
                        <div className='title-verify'>Verify your email</div>
                        <div className='des'>Please fill the verification code We sent you in your email</div>
                        <div className='form-input'>
                            <input type='text' className='input-small' maxlength="1" onKeyUp={handlenext} pattern="[0-9]" required onChange={(e) => {
                                setInput1(e.target.value)
                            }} />
                            <input type='text' className='input-small' maxlength="1" onKeyUp={handlenext} pattern="[0-9]" required onChange={(e) => {
                                setInput2(e.target.value)
                            }} />
                            <input type='text' className='input-small' maxlength="1" onKeyUp={handlenext} pattern="[0-9]" required onChange={(e) => {
                                setInput3(e.target.value)
                            }} />
                            <input type='text' className='input-small' maxlength="1" onKeyUp={handlenext} pattern="[0-9]" required onChange={(e) => {
                                setInput4(e.target.value)
                            }} />
                        </div>
                        <div className='form-input'>
                            <input type="submit" className='buttoncontainer'></input>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
})