import React, { Suspense, Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import store from "../app-services/store";
import C404 from "../components/C404/C404";
import Alert from "../partials/Alert/Alert";
import GuardRoute from "../partials/GuardRoute/GuardRoute";
import getDevice from "../app-services/lib/device";
import routes from "./routes";
import "../lib/bootstrap-grid.min.css";

import "./AppLayout.css";
import "../Fonts.css";
import ScrollToTop from "./ScrollToTop";
import api from "../app-services/API";

import PopupLogin from "../components/PopupLogin/PopupLogin";

/**
 * Routes isn't exported in react-router-dom v5, it is part of v6, replacing the Switch component. Just remove the Routes component from your Pages component and use the Switch. Or upgrade to v6 (still in beta I believe so don't use for production code) and remove the Switch
 *
 */

class SwitchContainer extends Component {
    constructor(props) {
		super(props);
		initialConstruct.bind(this)("SwitchContainer");
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	addBodyClass() {
        let bookname = this.props.location.pathname.pluck(1);
        this.removeBodyClass();
        document.querySelector("body").classList.add('page-'+bookname);
    }

	removeBodyClass() {
        document.querySelector("body").classList =  [...document.querySelector("#root").classList].filter(o=>!o.includes('page-'));
    }

	onRouteChanged() {
		Modal?.state?.list.find((o) => o.type == "model")?.close();
		this.addBodyClass();
	}
	render() {
		return (<Switch>
			{routes.map((prop, key) => {
				return (
					<GuardRoute
						path={prop.path}
						component={prop.component}
						key={key}
						exact={prop.exact}
					/>
				);
			})}
			<GuardRoute path="/404" component={C404} />
		</Switch>);
	}
}

const RouteContainer = connect(withRouter(SwitchContainer));

export class AppLayout extends Component {
	constructor(props) {
		super(props);
		initialConstruct.bind(this)("AppLayout");
	}

	getCartDetails() {
		return new Promise((resolve, reject) => {
			if (this.props.store.user !== null) {
				api
					.getCartDetails()
					.then((res) => {
						if (res && res.statusCode === 200 && res.data) {
							resolve(res.data);
						}
						reject();
					})
					.catch(reject);
			} else {
				resolve([]);
			}
		});
	}
	UNSAFE_componentWillMount() {
		let self = this;
		window.onresize = function () {
			//console.log({ onresize: getDevice().class });
			self.setStore("device", getDevice().class);
		};
		api.getSessionID().then((sessionID) => {
			let localSessionID = localStorage.getItem("sessionID");
			localStorage.setItem("sessionID", localSessionID || sessionID);
		});
		//if(window.STATIC_TOKEN == window.token)
		api
			.getuserProfile()
			.then((res) => {
				//console.log({getuserProfile:res.data});
				self.getCartDetails().then((cartItem) => {
					//console.log({getCartDetails:cartItem});
					self.setStore([
						{ name: "cart_list", value: cartItem || [] },
						{ name: "cartItem", value: cartItem.totalNumberOfBooks },
						{ name: "user", value: res.data },
						{ name: "device", value: getDevice().class },
					]);
				});
			})
			.catch((err) => {
				self.setStore("device", getDevice().class);
			});
	}

	componentDidMount() {
		window.login_popup = () =>
			alert((alertVar) => <PopupLogin model={alertVar} />, "", "login", {
				footer: false,
			});
		emit.on('openloader',(payload)=>{
			loader(payload);
		})
		emit.on('closeloader',(callback=()=>(true))=>{
			let modal = Modal.state.list.find(o=>o.type=="info" && callback(o));
			modal.close();
		});
	}

	componentWillUnmount() {
		emit.remove('openloader');
		emit.remove('closeloader');
	}

	render() {
		//console.log({AppLayout:JSON.stringify(this.props).length});
		return (
			<Router store={store()}>
				<Suspense fallback={<h1>loading...</h1>}>
					<div className={"main " + this.props.store.device}>
						<Alert />
						<ScrollToTop>
							<RouteContainer />
						</ScrollToTop>
						{!mixins.appmode()}
					</div>
				</Suspense>
			</Router>
		);
	}
}

export default connect(AppLayout);
