import { Fragment, useEffect, useState, useRef } from "react";

import "./character.css";
import chfivebase from "./chapter_five/chapter_five_base_cover.jpg";


const Chap5Front = (props) => {
    return (
        <Fragment >
            <div className="chapter_five_front_cover height">
                <img src={chfivebase} />
                <div className="chapterfivecustomimage">
                    {props.children}
                </div>
            </div>
        </Fragment>
    )
}
export default connect(Chap5Front);