import { Fragment, useEffect, useState, useRef } from "react";
import "../chapter.css";
const pagethee = (props) => {
    return (
        <Fragment >
            <div className="pageformat height">
                <div className="text-center chapter-title">
                    {/* <h1>Chapter 4</h1> */}
                </div>
                <div className="text-center chapter-content">
                    <p>Ellie stood on tiptoes and craned her neck. She couldn't make out what they were looking at. "Shall we take a walk and have a look, Snowy?"</p>
                    <p>"Woof woof!" Snowy said. He never needed a reason to go for a walk. Walks were the best thing in the whole world. He wagged his tail.</p>
                    <p>Ellie ran to get his leash. She ran back to Snowy and attached his leash to his harness. She led him to the door. Just as she was about to open the door, Gabby popped her head out of her room. </p>
                    <p>"Just where do you think you are going? Mama left me in charge. You're not allowed to go out," Gabby said bossily.</p>
                    <p>"There's something… I, er… Snowy needs to pee," Ellie said, not wanting to tell Gabby too much. Not that she was trying to hide anything from her. Gabby was one of the very few people in the whole world who knew that she could talk to animals. </p>
                </div>
                <div className="page-number">
                    <span className="digite-number">3</span>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(pagethee);