import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import "./banner.css";

const Banner = (productpage) => {
  const history = useHistory();
  const [bannerlist, setBannerlist] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const getBannerlist = () => {
    api.getBannerlist().then((res) => {
      if (res && res.data) {
        setBannerlist(res.data);
        if (productpage.productpage == true) {
          const homeBanner = res.data.filter(
            (index) => index.page === "product" && index.display === 1
          );
          setCurrentData(homeBanner);
        } else {
          const homeBanner = res.data.filter(
            (index) => index.page === "home" && index.display === 1
          );
          setCurrentData(homeBanner);
        }
        
      }
    });
  };
  useEffect(() => {
    getBannerlist();
  }, []);
  //console.log("productpage", currentData.length);
  return (
    <>
      <div
        className={
          "bannerContainer " +
          (productpage.productpage == true ? "" : "homebanner") +
          (currentData.length == 1 ? "hideindicator" : "")
        }
      >
        <Carousel interval={1500} variant="dark">
          {currentData.map((value, index) => (
            <Carousel.Item key={index}>
              <img
                className="BannerIMage"
                src={value.file.filePath}
                alt={value.file.fileName}
              />
              {value.title && (
                <Carousel.Caption>
                  <h3>{value.title}</h3>
                  <p>{value.subtitle}</p>
                </Carousel.Caption>
              )}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default Banner;
