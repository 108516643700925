import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import view from "./Alert.view";
import "./Alert.css";
import loader from "./loader.gif";
class modalSturct {
  constructor(option = {}) {
    let self = this;
    this.display = false;
    this.title = "";
    this.content = "";
    this.type = "alert";
    this.option = Object.assign(
      {
        ok: "OK",
        cancel: "CANCEL",
        footer: true,
        class: "",
        onOpen: () => {},
      },
      option
    );
    this.promise = new Promise((res, rej) => {
      self.res = res;
      self.rej = rej;
    });
    this.close = (...arg) => {
    //   console.log({ arg });
      this.display = false;
    };
    this.open = () => {
      this.display = true;
    };
  }
}
export class Alert extends Component {
  constructor(props) {
    super(props);
    initialConstruct.bind(this)("Modal");
    this.alertref = React.createRef();
    this.state = {
      list: [],
    };
    document.body.addEventListener("keyup", this.handleEscape);
    document.body.addEventListener("keydown", this.handleEscape);
  }

  handleEscape(event) {
    if (event.key == "Escape") {
      //console.log({ event }, "Escaped");
      event.preventDefault();
      event.stopPropagation();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.handleEscape);
    this.removeBodyClass();
  }

  removeBodyClass() {
    document.querySelector("body").classList = [
      ...document.querySelector("#root").classList,
    ].filter((o) => !o.includes("page-"));
  }

  addBodyClass() {
    let bookname = this.props.location.pathname.pluck(1);
    this.removeBodyClass();
    document.querySelector("body").classList.add("page-" + bookname);
  }

  componentDidMount() {
    let self = this;
    window.alert = this.createDialog.bind(this);
    window.info = this.info.bind(this);
    window.loader = this.loader.bind(this);
    window.confirm = ((content, title, options) => {
      return this.createDialog.bind(this)(
        content,
        title || "Confirm",
        "confirm",
        options || {}
      );
    }).bind(this);
  }

  evalContent(Content, alertVar) {
    switch (typeof Content) {
      case "function":
        return Content(alertVar, this);
      default:
        return Content;
    }
  }

  alertSuccess(alertVar) {
    alertVar.res(alertVar);
    //console.log({ alertVar });
    setTimeout(() => {
      alertVar.close();
      //console.log("close");
    });
  }

  alertFailure(alertVar) {
    alertVar.rej(alertVar);
    setTimeout(() => {
      alertVar.close();
      //console.log("failure");
    });
  }

  filterList() {
    return [].concat(this.state.list.filter((o) => o.display));
  }

  loader(content, header = false) {
    return this.info(
      <>
        {/* <i className='fa fa-spinner spin'></i> */}
        <img src={loader} style={{ width: "100px", height: "100px" }} />
        <br />
        {content}
      </>,
      header
    );
  }

  info(content, footer = false, header = false) {
    return this.createDialog(content, header ? "Info" : "", "info", {
      footer: footer,
    });
  }

  createDialog(content, title = "", type = "alert", option = {}) {
    this.addBodyClass();
    // console.log("createDialog",content,title,type,option);
    let self = this;
    let o = new modalSturct(option);
    o.content = content;
    o.title = title;
    o.display = true;
    o.type = type;
    setTimeout(() => {
      o.option.onOpen(self);
    });
    //o.option = Object.assign(o.option,option);
    switch (type) {
      default:
    }
    o.close = () => {
      o.display = false;
      self.setState({
        list: self.filterList(),
      });
    };
    self.state.list.push(o);
    self.setState({
      list: self.state.list,
    });
    // o.promise.then(()=>{
    //     console.log("promise.resolve");
    // })
    return o;
  }

  render() {
    initialConstruct.bind(this)("Modal");
    return view.bind(this)(this.props, this.props.store, this.state);
  }
}

export default connect(withRouter(Alert));
