import React, { useEffect, useState } from 'react';
import forget from '../../../assets/images/forget.png'
import email from '../../../assets/images/email.png'
import Alert from '@mui/material/Alert';
import './ForgetPassword.css'
import { useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form';
export default connect((props) => {
    const history = useHistory();
    const { handleSubmit, register } = useForm({ mode: 'onBlur' });
    const [emailid, setEmail] = useState(null);
    const [emailerror, setEmailerror] = useState(null);
    const [alertFail, setAlertFail] = useState(null);
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    const OnHandleclickSubcribe = () => {
        //console.log("forgot password", emailid)
        const data = {
            "email": emailid
        }
        if (validateEmail(emailid)) {
            setEmailerror('')
            api.forgot_password(data).then((res) => {
                if (res && res.statusCode === 200) {
                    mixins.setAccessToken(res.accessToken);
                    //console.log(res);
                    history.push('/otp-verify')
                } else if (res && res.message) {
                    setAlertFail(res.message);
                }
            })
        } else {
            setEmailerror("please provide valid email")
        }
    }
    return (
        <div className='forgetcontainer'>

            <div className='forget-formcontainer'>
                {alertFail && (

                    <Alert severity="error" variant="filled" >

                        <strong>{alertFail}</strong>
                    </Alert>

                )}
                {/* <img src={forget} className="img" /> */}
                <div className='subcontainer'>
                    <div className='title'>Forgot Password</div>
                    <div className='des'>Please fill the registered email ID and we will send you a verification code there to reset your password.</div>
                    <form onSubmit={handleSubmit(OnHandleclickSubcribe)}>
                        <div className='form-input'>
                            <img src={email} className="icon" />
                            <input type='email' className='input' placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} />
                            {emailerror ? <span style={{ "color": "red" }}>{emailerror}</span> : null}
                        </div>
                        <div className='form-input'>
                            {alertFail ? (
                            <Link to={`/signup`}><button className="buttoncontainer" >SignUp</button></Link>
                            ) : (
                                <button type = "submit" className="buttoncontainer" >Submit</button>
                            )}
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
})