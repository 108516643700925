import React, { createRef, Component } from "react";
import view from "./PopupLogin.view";
import "./PopupLogin.css";
import validator from "validator";
import { Password } from "@mui/icons-material";

export class PopupLogin extends Component {
	constructor(props) {
		super(props);
		initialConstruct.bind(this)("PopupLogin");
		this.loginref = createRef();
		this.signupref = createRef();
		this.forgetpasswordref = createRef();
		this.verifyotpref = createRef();
		this.passwordresetref = createRef();

		this.state = {
			form: "login",
			errorMsg: "",
			failureMsg: "",
			loginFaild:"",
		};
	}

	isValidPassword(value) {
		return validator.isStrongPassword(value, {
			minLength: 8,
			minLowercase: 1,
			minUppercase: 1,
			minNumbers: 1,
			minSymbols: 1,
		});
	}

	validate(e, name, formref) {
		let data = formref.getData();
		let errorMsg = "";
		//console.log(data, e.target.value,name);
		switch (name) {
			case "email":
				if (!validator.isEmail(e.target.value)) {
					errorMsg = "Please enter the vaild email.";
				}
				break;
			case "password":
				if (!this.isValidPassword(e.target.value)) {
					errorMsg =
						"Password not strong. Use Minimum One special character,Minimum 8 letters,One uppercase letter,One lowercase letter,One number.";
				}
				break;
			case "confirm_password":
				if (data.password != e.target.value) {
					errorMsg = "Please match the password.";
				}
				break;
		}
		this.setState({ errorMsg });
	}

	getSession() {
		let localSessionID = localStorage.getItem("sessionID");
		return new Promise((resolve, reject) => {
			if (localSessionID) {
				resolve(localSessionID);
			} else {
				api
					.getSessionID()
					.then((sessionID) => resolve(sessionID))
					.catch(reject);
			}
		});
	}

	onLogin(e) {
		let self = this;
		let data = this.loginref.current.getData();
		let {loginFaild} = self.state;
		e.preventDefault();
		let load = loader("Logging in please wait...");
		api.login(data).then((res) => {
			if (res && res.statusCode === 200 && res.accessToken) {
				mixins.setAccessToken(res.accessToken);
				self.getSession().then((sessionId) => {
					localStorage.setItem("sessionID", sessionId);
					api.getuserProfile().then((res) => {
						self.setStore("user", res.data);
						setTimeout(()=>{
							self.props.model.close();
							self.props.model.res(res.data);
							load.close();
						});
					});
				});
			} else{
				load.close();
				self.setState({
					loginFaild:"Invalid Credential"
				})
			}
		});
	}
	onSignup(e) {
		let self = this;
		let data = this.signupref.current.getData();
		data['subscribe'] = data['subscribe'] == 'on';
		data.subscribe = `${data.subscribe}`;
		e.preventDefault();
		self.setState({ failureMsg: "" });
		if (this.state.errorMsg) {
			alert("Please fill the valid values.");
			return;
		}
		let load = loader("Signing up please wait...");
		api.signup(data).then((res) => {
			if (res && res.statusCode === 200 && res.accessToken) {
				mixins.setAccessToken(res.accessToken);
				self.getSession().then((sessionId) => {
					localStorage.setItem("sessionID", sessionId);
					api.getuserProfile().then((res) => {
						self.setStore("user", res.data);
						setTimeout(()=>{
							self.props.model.close();
							self.props.model.res(res.data);
							load.close();
						});
					});
				});
			} else if (res && res.message) {
				let $USER_EXISTS = "The email has already been taken.";
				if (
					res.errorMessages.length >= 1 &&
					res.errorMessages.includes($USER_EXISTS)
				) {
					alert(
						"User is already registered, please login to continue"
					).promise.then(() => {
						self.setState({ form: "login" });
					});
				} else {
					self.setState({ failureMsg: res.message });
				}
				load.close();
			}
		});
	}

	onForgetPassword(e) {
		let self = this;
		e.preventDefault();
		self.setState({ failureMsg: "" });
		if (this.state.errorMsg) {
			alert("Please fill the valid values.");
			return;
		}
		let data = this.forgetpasswordref.current.getData();
		let load = loader("Sending your verification code please wait...");
		api.forgot_password(data).then((res) => {
			if (res && res.statusCode === 200) {
				mixins.setAccessToken(res.accessToken);
				self.setState({ form: "otp-verify" });
			} else if (res && res.message) {
				self.setState({ failureMsg: res.message });
			}
			load.close();
		});
	}

	onVerifyOtp(e) {
		let self = this;
		e.preventDefault();
		self.setState({ failureMsg: "" });
		if (this.state.errorMsg) {
			alert("Please fill the valid values.");
			return;
		}
		let data = this.verifyotpref.current.getData();
		let load = loader("Sending your verification code please wait...");
		api.otp_verification(data).then((res) => {
			if (res && res.statusCode === 200) {
				self.setState({ form: "new-password" });
			} else if (res && res.message) {
				self.setState({ failureMsg: res.message });
			}
			load.close();
		});
	}

	onPasswordReset(e) {
		let self = this;
		e.preventDefault();
		self.setState({ failureMsg: "" });
		if (this.state.errorMsg) {
			alert("Please fill the valid values.");
			return;
		}
		
		let data = this.passwordresetref.current.getData();
		let load = loader("Password resetting please wait....");
		api.password_reset(data).then((res) => {
			if (res && res.statusCode === 200) {
				mixins.setAccessToken();
				self.setState({ form: "login" });
			} else if (res && res.message) {
				self.setState({ failureMsg: res.message });
			}
			load.close();
		});
	}

	componentDidMount() {}

	render() {
		initialConstruct.bind(this)("PopupLogin");
		return view.bind(this)(this.props, this.props.store, this.state);
	}
}

export default connect(PopupLogin);
