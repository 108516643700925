import React from 'react';
import { Route, Redirect,useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import menuList from "../../components/Navbar/menu-list";


const GuardRoute = ({ path:path,component: Component, auth, ...rest }) => {
    let state = useSelector((state) => state);
    let o =  menuList.find(o=>o.path==path);
    window.reduxHistory = useHistory();
    let middleware = ()=>{
        return true;
    }
    let check = ()=>(
        (o.needLogin==2&&state.user&&state.user.company.CompanyId)||
        (o.needLogin==1&&state.user)||
        (o.needLogin==3&&state.user && state.user.roles[0]=="Admin")||
        (o.needLogin==-1&&!state.user)||
        (o.needLogin==0)||
        (o.needLogin==-2)
    )
    return (<Route {...rest} render={(props) => (
            middleware()
            ? <Component {...props} />
            : <Redirect to='/404' />
    )} />)
}
export default GuardRoute;