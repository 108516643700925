import React from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import Delivery from "../components/payment/checkout/Delivery";

const DeliveryPage = (props) => {
    return (<div className={style['container']}>
        <Announcement />
        <Header />
        <Delivery/>
        <Footer />
    </div>)
}
export default connect(DeliveryPage);