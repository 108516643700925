import React  from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import ProductDetails from "../components/product/productdetails/ProductDetails";
import Description from "../components/product/description/Description";
import BannerImage from "../components/product/banner/BannerImage";
import CustomerReview from "../components/product/review/CustomerReview";
const ProductDetailsPage = (props) => {
  return (
  <div className={style['container']}>
    <Announcement />
    <Header current={props.location.pathname} />
    <ProductDetails  />
    <Description />
    {/* <BannerImage /> */}
    <CustomerReview />
    <Footer />
  </div>)
}
export default connect(ProductDetailsPage);

