import "./app-services/imports";
import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider,connect,useSelector, useDispatch } from "react-redux";
import storeSynchronize from 'redux-localstore';
import store from "./app-services/store";
import AppLayout from "./AppLayout/AppLayout";
import ScreenSizeDetector from "screen-size-detector";
import reportWebVitals from './reportWebVitals';
import messages from "./app-services/messages";
import Emitter from "./app-services/emiters";
window.emit = new Emitter();
window.messages = messages;
window.screen = new ScreenSizeDetector();
const storeState = store();
storeSynchronize(storeState);

const routing = (
  <Provider store={storeState}>
    <AppLayout />
  </Provider>
);
ReactDOM.render(routing, document.getElementById("root"));
import "./index.css";
reportWebVitals();
