import { Fragment, useEffect, useState, useRef } from "react";

import "./terms-content.css";
import banner from "./banner_Terms_of_Use.jpg"

const termscontent = (props) => {
    const [cmsinfo, setcmsinfo] = useState({});
    const getcmsinfo = () => {
        api.getcmsInfo().then((res) => {

            if (res && res.data) {
                let ret = res.data.reduce((ov, nv) => {
                    ov[nv.cmsPages.type] = nv;
                    return ov;
                }, {})
                // console.log(ret);
                setcmsinfo(ret);
            }

        });
    }
    useEffect(() => {
        getcmsinfo();
    }, [])
    // console.log(cmsinfo['Privacy Policy']);
    let termsCms = cmsinfo['Terms of Use'];
    //console.log(termsCms);
    // console.log(termsCms.description);
    return (
      <Fragment>
        <div className="banner singlePageBanner">
          {termsCms && termsCms.file["filePath"] && (
            <img src={termsCms.file["filePath"]} />
          )}
        </div>
        <div className="container terms-page">
          <div className="row">
            <div className="col-12 pt-5">
              <div className="row no-gutters SelfPublisher">
                {termsCms && termsCms.cmsPages["type"] && (
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: termsCms.cmsPages["type"],
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5 ">
              {termsCms && termsCms.description && (
                <div
                  dangerouslySetInnerHTML={{ __html: termsCms.description }}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
}
export default connect(termscontent);