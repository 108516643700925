import React, { Component } from "react";
import lock from "../../assets/images/lock.png";
import email from "../../assets/images/email.png";

export class ForgetPasswordForm extends Component {
	constructor(props) {
        super(props);
        initialConstruct.bind(this)("ForgetPasswordForm");
		this.validate = props.validate;
		this.submit = props.submit;
        this.setParent = props.setState;
        this.formref = props.formref;
        
	}
	render() {
		return (
			<form
				ref={this.formref}
				className="popuplogin"
				onSubmit={(e) => this.submit(e)}
			>
				<div className="title">Forget Password</div>
				<div className="form-input">
					<img src={email} className="icon" />
					<input
						type="text"
						className="input"
						placeholder="Email Address"
						name="email"
                        onChange={(e) => this.validate(e, "email", this.formref.current)}
						required
					/>
				</div>
				<div className="form-input forgetpassword">
					<label
						className="label"
						onClick={() => this.setParent({ form: "login" })}
					>
						Sign In
					</label>
				</div>
				<div className="form-input">
					<span style={{ fontWeight: "bold", color: "red", fontSize: "10px" }}>
						{this.props.store.errorMsg}<br/>{this.props.store.failureMsg}
					</span>
				</div>
				<div className="form-input">
					<button type="submit" className="button" id="signup">
						Submit
					</button>
				</div>
				<div className="form-input">
					Don't have an account?&nbsp;{" "}
					<label
						className="customlable mousepointer"
						onClick={() => this.setParent({ form: "signup" })}
					>
						{" "}
						Sign Up Now
					</label>
				</div>
			</form>
		);
	}
}

export default ForgetPasswordForm;
