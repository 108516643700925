import OptionCard from "./OptionCard";
import ImageForm from "./ImageForm";
import Booklet from "../Booklet/Booklet.js";
import AwesomeCard from "../product/productdetails/AwesomeCard";
import { useEffect } from "react";
export default connect((props) => {
    const { index, setAnswer, validateAns, questions, answers, nextStep, prevStep, className, editmode,setStep } = props;
    const gotoNext = (p) => {
        if (p.autoredirct) {
            setTimeout(() => {
               nextStep(p.q, index, !((index + 1) < questions.length));
            }, p.autoredirct);
        }
    };
    const Footer = (p) => (<div className="row footer">
        {!editmode && <div className="col">
            <button className="btn prev" onClick={() => prevStep(index, answers)}>
                {index == 0 ? (props.store.device != 'desk' ? "BACK" : "BACK TO PRODUCT PAGE") : "PREV"}
            </button>
        </div>}
        {
            editmode ? <div className="col">
                <button className="btn" onClick={() => nextStep(p.q, index, true)}>
                    {"SAVE"}
                </button>
            </div>
                : validateAns(p.q, index) && <div className="col-6">
                    {
                        <button className="btn" onClick={() => nextStep(p.q, index, !((index + 1) < questions.length))}>
                            {((index + 1) < questions.length) ? "NEXT" : "PREVIEW THE BOOK"}
                        </button>
                    }
                </div>

        }
    </div >);
    useEffect(() => {
        let current = questions[index];
        gotoNext(current);
    },[index]);
    let steps = questions.map((ques, i) => {
        switch (ques.type) {
            case "finalpage":
                return <div key={i} className={className}>
                    <AwesomeCard data={ques} index={index} className={ques.className||''} />
                    {!ques.autoredirct ? <Footer q={ques} /> : ""}
                </div>
            case "option":
                return <div key={i} className={className} >
                    <OptionCard
                        setAnswer={(obj) => setAnswer(obj, index)}
                        answer={answers[i]?.ans}
                        data={ques}
                        index={index}
                    />
                    {!ques.autoredirct ? <Footer q={ques} /> : ""}
                </div>
            case "image-form":
                return <div key={i} className={className}>
                    <ImageForm
                        className={ques.className||''}
                        setAnswer={(obj) => setAnswer(obj, index)}
                        answer={answers[i]?.ans}
                        answers={answers}
                        data={ques}
                        pos={i}
                        overlay={ques.overlay}
                        index={index}
                        setStep={setStep}
                    />
                    {!ques.autoredirct ? <Footer q={ques} /> : ""}
                </div>
            case "booklet":
                return <div key={i} className={className + ' booklet-container'}>
                    <Booklet pages={ques.getPages(answers)} onPage={ques?.onPage} />
                    {!ques.autoredirct ? <Footer q={ques} /> : ""}
                </div>
          
            default:
                return "";
        }
    });
    return steps[index];
})