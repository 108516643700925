import { Fragment, useEffect, useState, useRef } from "react";
import Booklet from "../Booklet/Booklet";
import image from "../../assets/images/personalize.png";
import "./preview.css";
import EditSteps from "./EditSteps";
import api from "../../app-services/API";
import { useSelector } from "react-redux";
import axios from "axios";
import Chap1Front from "../character/Chap1Front";
import Chap1Back from "../character/Chap1Back";
import Chap4Front from "../character/Chap4Front";
import Chap5Front from "../character/Chap5Front";
import Chap7Front from "../character/Chap7Front";
import FinalChapFront from "../character/FinalChapFront";
import Pageone from "../chapter/chapterOnecontent/pageone";
import Pagetwo from "../chapter/chapterOnecontent/pagetwo";
import Ch_2pageone from "../chapter/chapterTwocontent/pageone";
import Ch_3pageone from "../chapter/chapterThreecontent/pageOne";
import Ch_4pageone from "../chapter/chapterFour/pageone";
import Ch_4pagetwo from "../chapter/chapterFour/pagetwo";
import Ch_4pagethree from "../chapter/chapterFour/pagethree";
import Ch_4pagefour from "../chapter/chapterFour/pagefour";
import Ch_4pagefive from "../chapter/chapterFour/pagefive";
import Ch_4pagesix from "../chapter/chapterFour/pagesix";
import Ch_4pageseven from "../chapter/chapterFour/pageseven";
import Ch_4pageeight from "../chapter/chapterFour/pageeight";
import Ch_5pageone from "../chapter/chapterfive/pageone";



const Preview = (props) => {
    useEffect(() => {
        if (localStorage.getItem("editmode") === "true") {
            api.getCharacterAttributes(props.store.customeBookId.customBookId).then((res) => {
                if (res && res.data) {
                    const temparyCustomCharacter = [
                        {
                            autoredirct: 2000,
                            "className": "Awesome",
                            "image": "/static/media/awesome.a80f1c40.png",
                            "question": "",
                            "subtitle": "Now have fun customising your character(s)!",
                            "title": "Awesome!",
                            "type": "finalpage",
                        },
                        {
                            "question": "Enter Child's Name",
                            "ans": { "first_name": res.data.firstName, "last_name": res.data.lastName },
                            "formStep": [
                                { "value": 1, "label": "Name" },
                                { "value": 2, "label": "Gender" },
                                { "value": 3, "label": "Skin Tone" },
                                { "value": 4, "label": "T-shirt Colour" },
                                { "value": 5, "label": "Hair Style" },
                                { "value": 6, "label": "Hair Colour" },
                                { "value": 7, "label": "Eyewear" },

                            ],
                            "name": "name",
                            "type": "image-form",
                        },
                        {
                            "ans": { "gender": res.data.gender },
                            "formStep": [
                                { "value": 1, "label": "Name" },
                                { "value": 2, "label": "Gender" },
                                { "value": 3, "label": "Skin Tone" },
                                { "value": 4, "label": "T-shirt Colour" },
                                { "value": 5, "label": "Hair Style" },
                                { "value": 6, "label": "Hair Colour" },
                                { "value": 7, "label": "Eyewear" },
                            ],
                            "name": "gender",
                            "type": "image-form",
                        },
                        {
                            "question": "Select Skin Tone",
                            "ans": { "skin": res.data.skinTone },
                            "formStep": [
                                { "value": 1, "label": "Name" },
                                { "value": 2, "label": "Gender" },
                                { "value": 3, "label": "Skin Tone" },
                                { "value": 4, "label": "T-shirt Colour" },
                                { "value": 5, "label": "Hair Style" },
                                { "value": 6, "label": "Hair Colour" },
                                { "value": 7, "label": "Eyewear" },
                            ],
                            "name": "skin",
                            "type": "image-form",
                        },
                        {
                            "question": "Select T-shirt Colour",
                            "ans": { "tshirt": res.data.tShirtColor },
                            "formStep": [
                                { "value": 1, "label": "Name" },
                                { "value": 2, "label": "Gender" },
                                { "value": 3, "label": "Skin Tone" },
                                { "value": 4, "label": "T-shirt Colour" },
                                { "value": 5, "label": "Hair Style" },
                                { "value": 6, "label": "Hair Colour" },
                                { "value": 7, "label": "Eyewear" },
                            ],
                            "name": "tshirt",
                            "type": "image-form"
                        },
                        {
                            "question": "Select Hair Style",
                            "ans": { "hair-style": res.data.hairStyle },
                            "formStep": [
                                { "value": 1, "label": "Name" },
                                { "value": 2, "label": "Gender" },
                                { "value": 3, "label": "Skin Tone" },
                                { "value": 4, "label": "T-shirt Colour" },
                                { "value": 5, "label": "Hair Style" },
                                { "value": 6, "label": "Hair Colour" },
                                { "value": 7, "label": "Eyewear" },
                            ],
                            "name": "hair-style",
                            "type": "image-form"
                        },
                        {
                            "question": "Select Hair Colour",
                            "ans": { "hair": res.data.hairColor },
                            "formStep": [
                                { "value": 1, "label": "Name" },
                                { "value": 2, "label": "Gender" },
                                { "value": 3, "label": "Skin Tone" },
                                { "value": 4, "label": "T-shirt Colour" },
                                { "value": 5, "label": "Hair Style" },
                                { "value": 6, "label": "Hair Colour" },
                                { "value": 7, "label": "Eyewear" },
                            ],
                            "name": "hair",
                            "type": "image-form",
                        },
                        {
                            "name": "eyewear",
                            "type": "image-form",
                            "ans": { "eyewear": res.data.eyeWear === 1 ? "yes" : "no" },
                            "formStep": [
                                { "value": 1, "label": "Name" },
                                { "value": 2, "label": "Gender" },
                                { "value": 3, "label": "Skin Tone" },
                                { "value": 4, "label": "T-shirt Colour" },
                                { "value": 5, "label": "Hair Style" },
                                { "value": 6, "label": "Hair Colour" },
                                { "value": 7, "label": "Eyewear" },
                            ],
                        },
                    ]
                    mixins.setStore("customCharacter", temparyCustomCharacter, props);
                    mixins.setStore("characterId", res.data.id, props);

                }
            })

        }
    }, [])
    const customCharacter = useSelector((state) => state.customCharacter);
    const productCustomPayload = useSelector((state) => state.productCustomPayload);
    const device = useSelector(state => state.device);
    const [svgcharacter, setSvgcharacter] = useState({});
    const [overlayStyle, setOverlayStyle] = useState({});
    const [hairStyleList, setHairStyleList] = useState([])
    const [pages, setPages] = useState([
        {
            pagetype: "Chap1Front"
        },
        // {
        //     pagetype: "Pageone"
        // },
        // {
        //     pagetype: "Pagetwo"
        // },
        {
            pagetype: "Chap4Front"
        },
        {
            pagetype: "Ch_4pageone"
        },
        {
            pagetype: "Ch_4pagetwo"
        },
        {
            pagetype: "Ch_4pagethree"
        },
        {
            pagetype: "Ch_4pagefour"
        },
        {
            pagetype: "Ch_4pagefive"
        },
        {
            pagetype: "Ch_4pagesix"
        },
        {
            pagetype: "Ch_4pageseven"
        },
        {
            pagetype: "Ch_4pageeight"
        },
        {
            pagetype: "Chap5Front"
        },
        // {
        //     pagetype: "Ch_5pageone"
        // },
        {
            pagetype: "Chap7Front"
        },
        // {
        //     pagetype: "Ch_3pageone"
        // },
        {
            pagetype: "FinalChapFront"
        },
        // {
        //     pagetype: "Ch_3pageone"
        // },
        // {
        //     pagetype: "FinalChapBack"
        // },
        // {
        //     pagetype: "Chap7Back"
        // },
        // {
        //     pagetype: "Chap5Back"
        // },
        // {
        //     pagetype: "Chap4Back"
        // },
        {
            pagetype: "Chap1Back"
        }
    ]);
    const [customBookImages, setCustomBookImages] = useState([]);

    const updateStyles = () => {
        let skinStyle = customCharacter.find(o => o.name == 'skin');
        let hairStyle = customCharacter.find(o => o.name == 'hair');
        let tshirtStyle = customCharacter.find(o => o.name == 'tshirt');
        let eyewearStyle = customCharacter.find(o => o.name == 'eyewear');
        let customname = customCharacter.find(o => o.name == 'name');
        let fullname = customname && customname.ans && customname.ans.first_name + " " + customname.ans.last_name;
        let firstname = customname && customname.ans && customname.ans.first_name;
        let genders = customCharacter.find(o => o.name == 'gender');
        let currentGender = genders['ans']['gender'];
        setOverlayStyle({
            "--skin": skinStyle && skinStyle.ans && skinStyle.ans.skin || 'transparent',
            "--hair": hairStyle && hairStyle.ans && hairStyle.ans.hair || 'transparent',
            "--shoes": "#ae7928",
            "--shirt": tshirtStyle && tshirtStyle.ans && tshirtStyle.ans.tshirt || 'transparent',
            "--short": "#597aab",
            "--glass": (eyewearStyle && eyewearStyle.ans && eyewearStyle.ans.eyewear == 'yes') ? 'black' : 'transparent',
            "--name": fullname,
            "--fname": firstname,
            "--currentGender": currentGender
        });
    }

    const updateHairStyle = (res = false) => {
        return new Promise((resolve, reject) => {
            let imageList = (res || customBookImages);
            let imgpromises = imageList.map((img) => {
                return new Promise((resolve, reject) => {
                    axios.get(img.filePath).then((res) => {
                        let svg = res.data;
                        resolve({ key: ("chapter" + img.chapter), value: svg });
                    }).catch(reject)

                }).catch(() => resolve(""));
            });
            Promise.all(imgpromises).then((svglist) => {
                let payload = svglist.reduce((ov, nv) => ({ ...ov, [nv.key]: nv.value }), {});
                setSvgcharacter(payload);
                setTimeout(() => {
                    resolve(payload);
                })
            }).catch(reject);
        });

    };

    const getCustomBookImages = () => {
        return new Promise((resolve, reject) => {
            let hairStyle = customCharacter.find(o => o.name == 'hair-style');
            let { gender, style } = hairStyle.ans["hair-style"].parse();
            api.getCustomBookImages({ gender, style }).then((result) => {
                setCustomBookImages(result.data);
                //console.log(result.data);
                resolve(result.data)
            }).catch(reject)
        });
    };
    useEffect(() => {
        //console.log({ getCustomBookImages });
        getCustomBookImages().then(async (res) => {
            setHairStyleList(res);
            updateStyles();
            await updateHairStyle(res);
            setPages([...pages]);
        }).catch(err => console.log({ err }))
        // api.listCustomCharacterImage().then((result) => {
        //     let customImagesPromises = result.data.map(o => new Promise((resolve, reject) => {
        //         axios.get(o.filePath)
        //             .then((res) => resolve({ ...o, imgresponce: res.data }))
        //             .catch((err) => resolve({ ...o, imgresponce: '' }))
        //     }));
        //     Promise.all(customImagesPromises).then(async (res) => {
        //         setHairStyleList(res);
        //         updateStyles();
        //         await updateHairStyle(res);
        //         setPages([...pages]);
        //     }).catch(err => console.log({ err }));
        // })
    }, []);

    useEffect(() => {
        //console.log("custom", customCharacter)
        getCustomBookImages().then(async (res) => {
            setHairStyleList(res);
            updateStyles();
            await updateHairStyle(res);
            setPages([...pages]);
        }).catch(err => console.log({ err }))

    }, [customCharacter]);

    const onPage = (obj, i, arr) => {
        //   console.log("svgCharacter", svgcharacter)
        let contentComponent = "No Content";
        switch (obj.pagetype) {
            case "Chap1Front":
                contentComponent = () => (
                    <div className="CustomCharectorChapter chonefront" style={{ position: "relative" }}>
                        <Chap1Front name={(overlayStyle["--name"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Chap1Front>
                    </div>)
                break;
            case "Chap1Back":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Chap1Back name={(overlayStyle["--name"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Chap1Back>
                    </div>)
                break;
            case "Chap4Front":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Chap4Front >
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter4'] || '' }}>
                            </div>
                        </Chap4Front>
                    </div>)
                break;
            // case "Chap4Back":
            //     contentComponent = () => (
            //         <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
            //             <Chap4Back name={(overlayStyle["--name"])}>
            //                 <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter4']||'' }}>
            //                 </div>
            //             </Chap4Back>
            //         </div>)
            //     break;
            case "Chap5Front":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Chap5Front >
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter5'] || '' }}>
                            </div>
                        </Chap5Front>
                    </div>)
                break;
            // case "Chap5Back":
            //     contentComponent = () => (
            //         <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
            //             <Chap5Back name={(overlayStyle["--name"])}>
            //                 <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter5']||'' }}>
            //                 </div>
            //             </Chap5Back>
            //         </div>)
            //     break;
            case "Chap7Front":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Chap7Front>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter7'] || '' }}>
                            </div>
                        </Chap7Front>
                    </div>)
                break;
            // case "Chap7Back":
            //     contentComponent = () => (
            //         <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
            //             <Chap7Back name={(overlayStyle["--name"])}>
            //                 <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter7']||'' }}>
            //                 </div>
            //             </Chap7Back>
            //         </div>)
            //     break;
            case "FinalChapFront":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <FinalChapFront name={(overlayStyle["--name"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </FinalChapFront>
                    </div>)
                break;
            case "Pageone":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Pageone name={(overlayStyle["--name"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Pageone>
                    </div>)
                break;
            case "Pagetwo":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Pagetwo name={(overlayStyle["--name"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Pagetwo>
                    </div>)
                break;
            case "Ch_2pageone":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_2pageone name={(overlayStyle["--name"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_2pageone>
                    </div>)
                break;
            case "Ch_3pageone":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_3pageone name={(overlayStyle["--name"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_3pageone>
                    </div>)
                break;
            case "Ch_4pageone":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_4pageone fname={(overlayStyle["--fname"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_4pageone>
                    </div>)
                break;
            case "Ch_4pagetwo":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_4pagetwo fname={(overlayStyle["--fname"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_4pagetwo>
                    </div>)
                break;
            case "Ch_4pagethree":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_4pagethree fname={(overlayStyle["--fname"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_4pagethree>
                    </div>)
                break;
            case "Ch_4pagefour":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_4pagefour fname={(overlayStyle["--fname"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_4pagefour>
                    </div>)
                break;
            case "Ch_4pagefive":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_4pagefive fname={(overlayStyle["--fname"])} currentGender={(overlayStyle["--currentGender"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_4pagefive>
                    </div>)
                break;
            case "Ch_4pagesix":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_4pagesix fname={(overlayStyle["--fname"])} currentGender={(overlayStyle["--currentGender"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_4pagesix>
                    </div>)
                break;
            case "Ch_4pageseven":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_4pageseven fname={(overlayStyle["--fname"])} currentGender={(overlayStyle["--currentGender"])} >
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_4pageseven>
                    </div>)
                break;
            case "Ch_4pageeight":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_4pageeight fname={(overlayStyle["--fname"])} currentGender={(overlayStyle["--currentGender"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_4pageeight>
                    </div>)
                break;
            case "Ch_5pageone":
                contentComponent = () => (
                    <div className="CustomCharectorChapter" style={{ position: "relative", margin: "auto" }}>
                        <Ch_5pageone fname={(overlayStyle["--fname"])} currentGender={(overlayStyle["--currentGender"])}>
                            <div className="customsvg" dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}>
                            </div>
                        </Ch_5pageone>
                    </div>)
                break;

        };
        return {
            style: {
                //backgroundImage: `url(${image})`,
                height: "100%",
                backgroundPosition: "center",
                backgroundSize: "cover"
            },
            content: contentComponent
        }
    };
    
    const [pageRefs] = useState(pages.map(v=>useRef(null)));

    setTimeout(()=>{
        //console.log({pageRefs})
    },1000);
    

    return (
        <Fragment >
            <div>
                <div className='row no-gutters preview' style={overlayStyle} >
                    <div className="col-1"></div>
                    <div className='col'>
                        {/* <div className=''></div> */}
                        <Booklet
                            pageRefs={pageRefs}
                            svg={svgcharacter}
                            pages={pages}
                            onPage={onPage} />
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="row no-gutters">
                <div className="col"></div>
                <div className="col-lg-10" >
                    <EditSteps PreviewComponent={() => (
                        <div style={overlayStyle} dangerouslySetInnerHTML={{ __html: svgcharacter['chapter1'] || '' }}></div>
                    )} />
                </div>
                <div className="col"></div>
            </div>
            <div className="row no-gutters">
            <div className="col"></div>
          
            <span style={
                {
                    marginTop: "1%",
                    color : "red"
                }
            } >Note: Due to copyright restrictions, only one chapter of this book can be previewed.</span>
           
            <div className="col"></div>
            </div>
            

        </Fragment>
    )
}
export default connect(Preview);