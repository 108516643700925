import React from "react";
import style from "./Pages.module.css";
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import Dashboard from "../components/dashboard";
import DashboardLayout from "../components/layouts/dashboard";

const DashboardPage = (props) => {
  // console.log("Home props", props);

  //mixins.setStore("loading",true,props);
  // return (<div className={style['container']}>
  //     <Announcement />
  //     <Header current={props.location.pathname} />
  //     <Dashboard/>
  //     <Footer />
  // </div>)
  return <DashboardLayout location={props.location}></DashboardLayout>;
};
export default connect(DashboardPage);
