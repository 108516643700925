import React from "react";
import style from './Pages.module.css'

import Footer from "../components/layouts/footer/Footer";
import Header from "../components/layouts/header/Header";
import Termscontent from "../components/terms-content/terms-content";

const terms = (props) => {
    // console.log("Home props", props);

    //mixins.setStore("loading",true,props);
    return (<div className={style['container']}>
        <Header current={props.location.pathname} />
        <div className={style['cmsmin_height']}>
            <Termscontent />
        </div>
        <Footer />
    </div>)
}
export default connect(terms);