import axios from "axios";

export default {

    // list CustomCharacterImages
    listCustomCharacterImage(data) {
        return this.handelAlert((res, rej, loadObj) => {
            axios.get(`${base}/api/bookChapter/getImage?` + Object.QueryString({
                "chapter": "1",
                ...data
            }), {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token") ? localStorage.getItem("token") : token}`
                }
            }).then(d => {
                let data = d.data.data || [];
                data = data.map(o => {
                    o.filePath = o.file.filePath;
                    return o;
                });
                res(d.data);
            }).catch(rej);
        }, true, "Getting the character images please wait...");
    },

    getCustomBookImages(data)  {
        return this.handelAlert((res, rej, loadObj) => {
            axios.get(`${base}/api/bookChapter/allImages?`+Object.QueryString({
                ...data
            }), {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token") ? localStorage.getItem("token") : token}`
                }
            }).then(d => {
                let data = d.data.data || [];
                data = data.map(o => {
                    o.filePath = o.file.filePath;
                    return o;
                });
                res(d.data);
            }).catch(rej);
        }, true, "Getting the character images please wait...");
    },

    getCustomBookImagesWithContents(data)  {
        return this.handelAlert((res, rej, loadObj) => {
            axios.get(`${base}/api/bookChapter/allImagesWithContent?`+Object.QueryString({
                ...data
            }), {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token") ? localStorage.getItem("token") : token}`
                }
            }).then(d => {
                let data = d.data.data || [];
                data = data.map(o => {
                    o.filePath = o.file.filePath;
                    return o;
                });
                res(d.data);
            }).catch(rej);
        }, true, "Getting the character images please wait...");
    },
}
