import { Fragment, useEffect, useState, useRef } from "react";
import "../chapter.css";
const pageone = (props) => {
    let pronoun = "";
    let his="";
    if(props.currentGender=='boy'){
        pronoun ="he";
        his="his";
    }
    else{
        pronoun ="she";
        his="her";
    }  
    return (
        <Fragment >
            <div className="pageformat height">
                <div className="text-center chapter-title">
                    <h1>Chapter 5</h1>
                </div>
                <div className="text-center chapter-content">
                    <p>Ellie gasped and stepped forward. She pointed at  {props.fname||'NAME'}. "You… you… you," she said, speechless, for once.</p>
                    <p>Gabby was confused. "What happened? What's going on?"</p>
                    <p>"You… you," Ellie continued, flabbergasted.</p>
                    <p>Gabby put her hands on her hips. "Will you stop saying ‘you, you, you’, and tell me what's going on?" </p>
                    <p>Gabby put her hands on her hips. "Will you stop saying ‘you, you, you’, and tell me what's going on?" </p>
                   
                </div>
                <div className="page-number">
                    <span className="digite-number">1</span>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(pageone);