import React from 'react';
import { useSelector } from 'react-redux';
import mixins from '../app-services/mixins';
import AppEditor from '../components/AppEditor/AppEditor';
import PageEditor from '../components/PageEditor/PageEditor';


function Test(props) {
  
  const book = useSelector(state=>state.book.selfpublish.picturebook);

  const generateBlankPage = () => {
    let arr = [];
    arr.length = 55;
    arr = arr.fill({});
    let pages = arr.map((v, i) => {
        return {
            pagetype: "halftexthalfimage",
            name: `Page${i + 9}`,
            label: `Page ${i + 9}`,
            content:book[`Page${i + 9}`]
        }
    });
    return pages;
  };

  const pages = [
    {
      pagetype: "halftexthalfimage",
      name: `frontpage`,
      label: `Front Page`,
      content:book[`frontpage`]
    },
    ...generateBlankPage()
  ];


 // console.log({book});
  const onSave = (payload)=>{
    mixins.setStore("book.selfpublish.picturebook",payload,props);
  }

  return (
    <div>
        <PageEditor pagePayload={pages} onSave={onSave} charcount={100} ></PageEditor>
    </div>
  )
}

export default connect(Test);