import axios from "axios";

export default {
  // upload HandPrint
  // Responce expected as Objects with keys ['crab', 'owl', 'bird', 'butterfly', 'giraffe', 'star', 'elephant']
  uploadHandprint(data) {
    return this.handelAlert((res, rej, loadObj) => {
      //console.log({ uploadHandprint: data });
      axios
        .post(UPLOADHANDPRINT, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((d) => {
          let data = d.data;
          if (data.mess) {
            if (data.mess.includes("error for files")) {
              alert("Please Upload Handprint Again with clear view.");
            } else {
              alert(data.mess);
            }
            rej(data);
          } else {
            res(d.data);
          }
        })
        .catch(rej);
    }, true);
  },
  // create HandPrint
  createHandPrint(data) {
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .post(`${base}/api/handprints/create`, data, {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          })
          .then((d) => {
            res(d.data);
          })
          .catch(rej);
      },
      true,
      "Saving the page please wait ..."
    );
  },
  // update HandPrint
  updateHandPrint(data, id) {
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .post(`${base}/api/handprints/update/${id}`, data, {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          })
          .then((d) => {
            res(d.data);
          })
          .catch(rej);
      },
      true,
      "Updating the page please wait ..."
    );
  },
  // view HandPrint
  getHandPrint(id) {
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .get(`${base}/api/handprints/show/${id}`, {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          })
          .then((d) => {
            res(d.data);
          })
          .catch(rej);
      },
      true,
      "Getting saved HandPrint book please wait..."
    );
  },
  // list HandPrint
  listHandPrint(data) {
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .get(`${base}/api/handprints/list?` + Object.QueryString(data), {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          })
          .then((d) => {
            res(d.data);
          })
          .catch(rej);
      },
      true,
      "Getting the saved page please wait..."
    );
  },
  //
  getSessionID() {
    return this.handelAlert((res, rej) => {
      axios
        .get(`${base}/api/selfPublishing/getSessionId`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    });
  },
};
