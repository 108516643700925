import { useState, useEffect } from "react";
import royalty from "../../assets/images/royalty.png";
import gift from "../../assets/images/gift.png";
import arrowwhite from "../../assets/images/arrowwhite.png";
import hand from "../../assets/images/hand.png";
import "./Dashboard.css";
import { useSelector } from "react-redux";
import moment from "moment";

export default connect((props) => {
  const redeemPoints = useSelector((state) => state.royaltyPoints);
  const [redeemHistory, setRedeemHistory] = useState("");

  useEffect(() => {
    getRedeemHistoryDetails();
  }, []);

  const getRedeemHistoryDetails = () => {
    api.getRedeemHistory().then((res) => {
      //console.log("redeemhistory", res);
      if (res && res.data && res.statusCode === 200) {
        setRedeemHistory(res.data);
      }
    });
  };
  return (
    <div className="dashboard">
      <div className="content ">
        <div className="maincontainer">
          <div className="leftcontainer">
            <div className="left-container-row">
              <div className="left-col">
                <div className="left-col-row">
                  <img src={royalty} className="royalty" />
                </div>

                <div className="left-col-row">
                  <div className="left-row">Total Royalties Balance</div>
                </div>
                <div className="left-row">
                  <span>
                    {redeemPoints && redeemPoints.royaltyBalance} SGD{" "}
                  </span>
                </div>
                {/* <div className="left-col-row">
                  <div className="left-row">Updated on: 09 Jan. 2022</div>
                </div> */}
              </div>
              <div className="right-column">
                <div className="right-column-1">
                  <div className="right-column-row">
                    <div className="right-column-col-left">
                      Royalty Redeemed
                      <span>
                        {redeemPoints && redeemPoints.royaltyRedeemed} SGD{" "}
                      </span>
                    </div>
                    <div className="right-column-col-right">
                      <img src={gift} />
                    </div>
                  </div>
                  {/* <div className="right-column-row">
                    <div className="right-column-col-left">
                      Last Redeemed on: 30 Jan. 2022
                    </div>
                    <img src={arrowwhite} className="i-white" />
                  </div> */}
                </div>
                <div className="right-column-2">
                  <div className="right-column-row">
                    <div className="right-column-col-left">
                      Current Royalty Balance
                      <span>
                        {redeemPoints && redeemPoints.royaltyEarned} SGD{" "}
                      </span>
                    </div>
                    <div className="right-column-col-right">
                      <img src={hand} />
                    </div>
                  </div>
                  <div className="right-column-row">
                    <div className="right-column-col-left">
                      {/* Last Updated on: 01 Feb. 2022 */}
                    </div>
                    {/* <img src={arrowwhite} className="i-white" /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="rule-container">
              <ul>
                <h2>Redeem rules</h2>

                {redeemHistory &&
                  redeemHistory.redeemRules &&
                  redeemHistory.redeemRules[0]?.rules
                    .filter((item) => item.active)
                    .map((item, key) => {
                      return <li>{item.rule_description}</li>;
                    })}
              </ul>
              {/* <ul>
                <h2>Redeem rules</h2>
                <li>
                  There is no one who loves pain itself, who seeks after it and
                  wants to have it, simply pain...
                </li> 
                 <li>
                  There is no one who loves pain itself, who seeks after it and
                  wants to have it, simply pain...
                </li> 
                 <li>
                  There is no one who loves pain itself, who seeks after it and
                  wants to have it, simply pain...
                </li>
                <li>
                  There is no one who loves pain itself, who seeks after it and
                  wants to have it, simply pain...
                </li>
                <li>
                  There is no one who loves pain itself, who seeks after it and
                  wants to have it, simply pain...
                </li>
                <li>
                  There is no one who loves pain itself, who seeks after it and
                  wants to have it, simply pain...
                </li>
                <li>
                  There is no one who loves pain itself, who seeks after it and
                  wants to have it, simply pain...
                </li>
                <li>
                  There is no one who loves pain itself, who seeks after it and
                  wants to have it, simply pain...
                </li> 
              </ul> */}
            </div>
          </div>
          <div className="royalty-rightcontainer redeem">
            <div className="heading">Redeem History</div>
            {redeemHistory &&
              redeemHistory.redeemHistroy &&
              redeemHistory.redeemHistroy.map((item, key) => {
                return (
                  <div className="contentrow">
                    <div className="itemrow">
                      <div>
                        <span>Redeemed at Unity Mall</span>
                      </div>
                      <div>
                        <span>${item.royaltyRedeemed}</span>
                      </div>
                    </div>
                    <div className="itemrow">
                      <div>
                        {moment(item.created_at).utc().format("YYYY-MM-DD")}
                      </div>
                      <div>${item.royaltyBalance}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
});
