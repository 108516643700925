import React, { Component } from "react";
import PageEditor from "../PageEditor/PageEditor";
import "./customtext.css";
import mixins from "../../app-services/mixins";
import { readUrl } from "../PageEditor/support";
import PreInfo from "./PreInfo";
import { withRouter } from "react-router";
import { Button } from "@mui/material";
// Import Pages
import FrontPage from "./frontpage.txt";
import Pageone from "./pageone.txt";
import Pagetwo from "./pagetwo.txt";

export class Costomtext extends Component {
  constructor(props) {
    super(props);
    this.BOOKID = props.match.params.id;
    initialConstruct.bind(this)("HandPrint");
    const book = props.store.book.customCharacter;
    this.state = {
      pageStyle: {
        position: "relative",
        minHeight: "197mm",
        height: "197mm",
        width: "130mm",
        maxWidth: "130mm",
      },
      staticPages: {
        frontpage: FrontPage,
        pageone: Pageone,
        pagetwo: Pagetwo
      },
      savedBook: {},
      bolierplates: {},
      svgs: {},
      pages: [
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `frontpage`,
          label: `1`,
          content: book && book[`frontpage`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `pageone`,
          label: `2`,
          content: book && book[`pageone`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `pagetwo`,
          label: `3`,
          content: book && book[`pagetwo`],
        },
      ],
    };
  }

  getContent(book, page) {
    let ele = mixins.getDataNode(book);
    const handprintPreInfo = this.props.store?.book?.handprintPreInfo;
    const answer = handprintPreInfo?.answer;
    const handprints = handprintPreInfo?.handprints;
    //console.log(page);
    let friend_name = ele.querySelector("#friend_name");
    if (friend_name && answer && answer.friend_name) {
      friend_name.innerText = answer.friend_name;
    }

    let adult_name = ele.querySelector("#your_name");
    if (adult_name && answer && answer.your_name) {
      adult_name.innerText = answer.your_name;
    }
    book = ele.innerHTML;
    return book;
  }
  
  
  getLatestBook() {
    let self = this;
    const handprintPreInfo = this.props.store?.book?.handprintPreInfo;
    return new Promise((resolve, reject) => {
      api
        .getUpdatedSelfBook({
          sessionId: localStorage.getItem("sessionID") || null,
          type: "handprint",
        })
        .then((responce) => {
          if (responce && responce.data) {
            let payload = responce.data;
            if (Object.entries(handprintPreInfo).length === 0) {
              this.setStore(
                "book.handprintPreInfo",
                JSON.parse(payload.attributes || "{}")
              );
            }
            this.setState({
              savedBook: payload,
            });
            resolve(payload);
          } else {
            resolve(this.state.savedBook);
          }
        })
        .catch(reject);
    });
  }

  onSave(payload, self) {
    const BOOKID = self.BOOKID;
    const { savedBook } = self.state;
    let history = self.history;
    const handprintPreInfo = self.props.store?.book?.handprintPreInfo;
    return new Promise((resolve, reject) => {
      if (savedBook && savedBook.id) {
        api
          .updateSelfPublish(
            {
              bookId: BOOKID,
              savedBook: JSON.stringify(payload),
              option: "dynamic",
              sessionId: localStorage.getItem("sessionID") || null,
              type: "handprint",
              attributes: JSON.stringify(handprintPreInfo),
            },
            savedBook.id
          )
          .then((responce) => {
            mixins.checkLogin(history).then(() => {
              self
                .getLatestBook()
                .then((responce) => {
                  resolve(responce);
                })
                .catch(reject);
            });
          });
      } else {
        api
          .createSelfPublish({
            bookId: BOOKID,
            savedBook: JSON.stringify(payload),
            option: "dynamic",
            sessionId: localStorage.getItem("sessionID") || null,
            file: null,
            type: "handprint",
            attributes: JSON.stringify(handprintPreInfo),
          })
          .then((responce) => {
            mixins.checkLogin(history).then(() => {
              self
                .getLatestBook()
                .then((responce) => {
                  resolve(responce);
                })
                .catch(reject);
            });
          });
      }
    });
  }
  onUpdate() {}

  updateBoilerplate() {
    return new Promise((resolve, reject) => {
      let { staticPages } = this.state;
      let promises = Object.entries(staticPages).map(([key, url]) => {
        return new Promise((resolve, reject) => {
          readUrl(url)
            .then((responce) => resolve({ key, responce }))
            .catch(reject);
        });
      });
      Promise.all(promises)
        .then((responce) => {
          let bolierplates = responce.reduce(
            (o, n) => ({ ...o, [n.key]: n.responce }),
            {}
          );
          resolve(bolierplates);
        })
        .catch(reject);
    });
  }

  onProceed() {
    this.updateData();
  }

  updateChanges(payload, modal) {
    let self = this;
    const handprintPreInfo = self.props.store?.book?.handprintPreInfo;
    let preInfo = handprintPreInfo || {};
    preInfo.answer = payload;
    preInfo.answer = payload;
    self.setStore("book.handprintPreInfo", preInfo);
    modal && modal.close();
    setTimeout(() => {
      self.updateData();
    }, 0);
  }

  uploadHanprints(payload, modal) {
    let self = this;
    const handprintPreInfo = self.props.store?.book?.handprintPreInfo;
    //console.log({ handprintPreInfo, payload });
    let preInfo = handprintPreInfo || {};
    preInfo.answer = payload;
      self.setStore("book.handprintPreInfo", preInfo);
      modal && modal.close();
      setTimeout(() => {
        self.updateData();
      }, 0);
  }

  collectInfo(closable = false) {
    let onChange = (answer) => {};
    let modal = alert(
      (props) => (
        <PreInfo
          closable={closable}
          alert={props}
          id={this.BOOKID}
          onChange={(...arg) => onChange(...arg)}
          onSave={(...arg) => this.uploadHanprints(...arg)}
          onClose={(...arg) => this.uploadHanprints(...arg)}
        />
      ),
      "",
      "model",
      {
        footer: false,
        class: "",
        parentclass: "HandPrint",
        options: {},
      }
    );
  }

  updateData() {
    this.updateBoilerplate().then((bolierplates) => {
      this.getLatestBook().then((bookObj) => {
        let book = bookObj?.savedBook?.parse({}) || {};
        //console.log({ bookObj, book });
        this.setState({
          savedBook: bookObj,
          bolierplates: bolierplates,
          pages: this.state.pages.map((o) => ({
            ...o,
            content: this.getContent(
              o.content || book[o.name] || bolierplates[o.name] || "",
              o
            ),
          })),
        });
      });
    });
  }

  componentDidMount() {
    let {
      match: {
        params: { genpdf, customBookId },
      },
    } = this.props;
    const handprintPreInfo = this.props.store?.book?.handprintPreInfo;
    const handprints = handprintPreInfo?.handprints;
    if (
      !handprintPreInfo ||
      !handprints ||
      (handprints && Object.keys(handprints).length <= 0 && !customBookId)
    ) {
      this.collectInfo();
    } else {
      this.updateData();
    }
  }

  RightChildren(self) {
    return () => (
      <Button
        className="edit"
        variant="outlined"
        onClick={() => {
            localStorage.setItem('customEdit', true);
            self.collectInfo(true);
    } }
      >
        <i className="fa fa-edit"></i>
        <small>Edit</small>
      </Button>
    );
  }
  getNoticeMeesage() {
    let defaultmessage = "";
    let device = this.$store.device;
    if (["mob", "small", "tab", "lap"].includes(device)) {
      defaultmessage +=
        "<br/><span style='font-size:12px; color:#cc3300; font-weight:400;'>Books Best view in Desktop version only</span>";
    }
    return defaultmessage;
  }

  render() {
    let noticeMessage = this.getNoticeMeesage();
    return ((
      { savedBook, pages, overlayStyle, pageStyle },
      props,
      { onSave, RightChildren }
    ) => (
      <div className="customtext top-margin">
        <PageEditor
          pagePayload={pages}
          onSave={(...arg) => onSave(...arg, this)}
          charcount={100}
          lastPageNo={3}
          overlay={true}
          style={overlayStyle}
          leftTool={false}
          readonly={true}
          editbackground={false}
          RightChildren={RightChildren(this)}
          showCount={false}
          pageBordered={false}
          pageStyle={pageStyle}
          uploadplaceholder={false}
          nextmode={true}
          bookDetails={savedBook}
          message={noticeMessage}
        ></PageEditor>
      </div>
    ))(this.state, this.props, this);
  }
}

export default connect(withRouter(Costomtext));
