import { Fragment, useEffect, useState, useRef } from "react";

import "./privacy-content.css";
import banner from "./banner_privacy_policy.jpg"

const privacycontent = (props) => {
    const [cmsinfo, setcmsinfo] = useState({});
    const getcmsinfo = () => {
        api.getcmsInfo().then((res) => {

            if (res && res.data) {
                let ret = res.data.reduce((ov, nv) => {
                    ov[nv.cmsPages.type] = nv;
                    return ov;
                }, {})
                // console.log(ret);
                setcmsinfo(ret);
            }

        });
    }
    useEffect(() => {
        getcmsinfo();
    }, [])
    // console.log(cmsinfo['Privacy Policy']);
    let privacyCms = cmsinfo['Privacy Policy'];
    //console.log(privacyCms);
    // console.log(privacyCms.description);
    return (
      <Fragment>
        <div className="banner singlePageBanner">
          {privacyCms && privacyCms.file["filePath"] && (
            <img src={privacyCms.file["filePath"]} />
          )}
        </div>
        <div className="container privacy-page">
          <div className="row pt-5">
            <div className="col-12">
              {privacyCms && privacyCms.cmsPages["type"] && (
                <h1
                  dangerouslySetInnerHTML={{
                    __html: privacyCms.cmsPages["type"],
                  }}
                />
              )}
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12 mb-5">
              {privacyCms && privacyCms.description && (
                <div
                  dangerouslySetInnerHTML={{ __html: privacyCms.description }}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
}
export default connect(privacycontent);