import React, { Component } from "react";
import AppEditor from "../AppEditor/AppEditor";
import ImagePlaceHolder from "../ImagePlaceHolder/ImagePlaceHolder";
import "./PageEditor.css";
import LeftToolbar from "./LeftToolbar";
import RightToolbar from "./RightToolbar";
import { withRouter } from "react-router";
import { PageFooter, PageScroller } from "./PageFooter";
import SubscriptionPlan from "../payment/checkout/SubscriptionPlan";

mixins.addScript("https://html2canvas.hertzen.com/dist/html2canvas.min.js");
mixins.addScript("https://unpkg.com/jspdf@latest/dist/jspdf.umd.min.js");

export class PageEditor extends Component {
  constructor(props) {
    super(props);
    initialConstruct.bind(this)("PageEditor");
    this.refs = {};
    this.pageContents = {};
    let self = this;
    this.state = {
      inputValue: "",
      outerPageStyle: {
        background: "whitesmoke",
        padding: "4px",
        display: "flex",
        minWidth: "max-content",
        margin: "auto",
      },
      currentIndex: 0,
      scrlpagetiles: React.createRef(),
      pagetileref: React.createRef(),
      pageref: React.createRef(),
      dragedPage: null,
      overlayStyle: {},
      pageStyle: {
        minHeight: "197mm",
        height: "197mm",
        width: "130mm",
        maxWidth: "130mm",
        paddingTop: "20mm",
        paddingBottom: "25mm",
        margin: "auto",
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#ffffff",
        boxSizing: "border-box",
        textAlign: "left",
        //position:"relative",
        ...(props.pageStyle || {}),
      },
      pages: props.pagePayload || [],
    };
  }

  generatePDF() {
    let self = this;
    let modal = loader("Generating PDF please wait");
    setTimeout(() => {
      self.getPayload(true).then((pages) => {
        let doc = new jspdf.jsPDF({
          html2canvas: {
            useCORS: true,
            allowTaint: true,
          },
        });
        Object.values(pages).map((obj, i) => {
          let height = (197 * 2).toPx();
          let width = (130 * 2).toPx();
          if (i > 0) {
            //pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
            doc.addPage(width, height);
            //doc.addPage(612, 791);
          }
          doc.setPage(i + 1);
          doc.addImage(
            obj.base64,
            "PNG",
            0,
            0,
            width,
            height,
            "alias" + i,
            "SLOW"
          );
        });
        doc.save("book.pdf");
        modal.close();
        alert("Thank you for generating the book, please visit again");
        self.history.push("/");
      });
    });
  }

  componentWillUpdate(props) {
    let self = this;
    this.watch(
      {
        bookDetails() {},
        pagePayload(val) {
          let {
            match: {
              params: { genpdf },
            },
          } = self.props;
          genpdf = Number(genpdf);
          let payload = val.reduce(
            (o, n) => ({ ...o, [n.name]: n.content }),
            {}
          );
          //console.log({page:"componentWillUpdate",content:payload.Page6,val});
          self.pageContents = Object.assign(self.pageContents, payload);
          if (genpdf) {
            mixins.debounce(
              {},
              () => {
                self.generatePDF();
              },
              "pagePayload"
            );
          }
        },
      },
      props
    );
  }

  updatePageContents() {
    const MAX_CONTENT_SIZE = 500;
    let { pagePayload, charcount = MAX_CONTENT_SIZE } = this.props;
    let self = this;
    let payload = Object.map(self.refs, (val, key) => {
      return val.current && val.current.outerHTML;
    });
    self.pageContents = Object.assign(self.pageContents, payload);
    Object.map(self.pageContents, (html, key) => {
      let editor = (html || "")
        .toHTML()
        .querySelector("#editor-container")
        ?.toText();
      let pageEle = document.querySelector("#charcount-" + key);
      if (pageEle) {
        let count = pageEle.querySelector("#count");
        let pageno = Number(pageEle.getAttribute("pageno") || "0");
        let page = pagePayload[pageno];
        let charcountlength = Number(page?.contentSize || charcount);
        if (count && editor) {
          if (editor?.length > charcountlength) {
            count.style.setProperty("color", "red");
          } else {
            count.style.setProperty("color", "green");
          }
          count.innerText = editor.length;
        }
      }
    });
  }

  componentDidMount() {
    let self = this;
    let mountRef = this.state.pageref.current;
    mountRef.onkeydown = mountRef.onmousedown = () => {
      clearTimeout(mountRef.sti || 0);
      mountRef.sti = setTimeout(() => {
        self.updatePageContents();
      }, 500);
    };
  }

  scrollAdjust() {
    let { pagetileref } = this.state;
    setTimeout(() => {
      pagetileref.current.parentElement.scrollLeft =
        pagetileref.current.offsetLeft - 108 * 4;
    });
  }

  changePage(dir) {
    let { currentIndex } = this.state;
    let { pagePayload, nextmode = false } = this.props;
    let step = 1;
    if (
      nextmode &&
      currentIndex % 2 == 0 &&
      currentIndex + (dir == "next" ? 2 : -2) < pagePayload.length
    ) {
      step = 2;
    }
    switch (dir) {
      case "prev":
        if (currentIndex <= 0) {
          return;
        }
        this.setState({
          currentIndex: currentIndex - step,
        });
        break;
      case "next":
        if (currentIndex >= pagePayload.length - 1) {
          this.previewBook();
          return;
        }
        this.setState({
          currentIndex: currentIndex + step,
        });
        break;
    }
    this.scrollAdjust();
  }

  handelPast(e) {
    let props = this.props;
    let { pagePayload } = props;
    let { currentIndex } = this.state;
    let getNode = (i) =>
      this.refs[pages[i].name].current.querySelector("#editor-container");
    e.stopPropagation();
    e.preventDefault();
    let localpages = this.state.pages;
    let pages = pagePayload;
    let rightpages = pages.slice(currentIndex);
    let clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");
    rightpages
      .filter((o) => o.pagetype != "fullimage")
      .map((page, i) => {
        let localpage = localpages.find((o) => o.name == page.name) || page;
        let filldata = pastedData.slice(
          0,
          localpage.contentSize || props.charcount
        );
        let node = getNode(currentIndex + i);
        if (i === 0) {
          node.innerText += filldata;
        } else {
          node.innerText = filldata;
        }

        pastedData = pastedData.slice(localpage.contentSize || props.charcount);
      });
  }

  getPageStyle(i, splitMode = false) {
    let { pageMode = "dual", bookMode = true } = this.props;
    let { currentIndex } = this.state;
    let isCurrentEven = () => currentIndex % 2 == 0 || currentIndex == 0;
    let objStyle = '';
    if(this.props.match.params.name == "customcharacter-book"){
      objStyle =
      i % 2 == 0 || i == 0
        ? {
            
            marginLeft:
              pageMode == "dual" && bookMode && currentIndex != 0
                ? !isCurrentEven()
                  ? "auto"
                  : "0px"
                : "auto",
            marginRight:
              pageMode == "dual" && bookMode && currentIndex != 0
                ? !isCurrentEven()
                  ? "0px"
                  : "auto"
                : "auto",
          }
        : {
            
            marginRight:
              pageMode == "dual" && currentIndex != 0
                ? !isCurrentEven()
                  ? "auto"
                  : "0px"
                : "auto",
            marginLeft:
              pageMode == "dual" && bookMode && currentIndex != 0
                ? !isCurrentEven()
                  ? "0px"
                  : "auto"
                : "auto",
          };
    }else{
      objStyle =
      i % 2 == 0 || i == 0
        ? {
            paddingLeft: "20mm",
            paddingRight: "15mm",
            marginLeft:
              pageMode == "dual" && bookMode && currentIndex != 0
                ? !isCurrentEven()
                  ? "auto"
                  : "0px"
                : "auto",
            marginRight:
              pageMode == "dual" && bookMode && currentIndex != 0
                ? !isCurrentEven()
                  ? "0px"
                  : "auto"
                : "auto",
          }
        : {
            paddingLeft: "15mm",
            paddingRight: "20mm",
            marginRight:
              pageMode == "dual" && currentIndex != 0
                ? !isCurrentEven()
                  ? "auto"
                  : "0px"
                : "auto",
            marginLeft:
              pageMode == "dual" && bookMode && currentIndex != 0
                ? !isCurrentEven()
                  ? "0px"
                  : "auto"
                : "auto",
          };
    }
    return {
      ...objStyle,
      ...(splitMode
        ? {
            backgroundSize: "200% 100%",
            backgroundPosition: i % 2 == 0 || i == 0 ? "right" : "left",
          }
        : {
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
          }),
    };
  }

  getPages() {
    return this.props.pagePayload.map((v, i) => this.getPage(v, i));
  }

  getPage(pageObj, i) {
    let {
      readonly,
      pageMode = "dual",
      match: {
        params: { genpdf },
      },
      pageBordered = true,
      uploadplaceholder = undefined,
    } = this.props;
    if (!readonly && genpdf) {
      readonly = true;
    }
    let { pageStyle, pages, currentIndex } = this.state;
    let localPage = pages[i];
    let page = { ...localPage, ...pageObj, pagetype: localPage.pagetype };
    // if(page.name=="Page6") {
    //   console.log({page:page.content,localPage:localPage.content,pageObj:pageObj.content});
    // }
    let { fullTextStyle, fullImageStyle, imageTextStyle, outerStyle } = {
      fullTextStyle: { height: "100%", background: "#ffffffe3" },
      fullImageStyle: { height: "100%", background: "transparent" },
      imageTextStyle: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
      outerStyle: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        boxSizing: "border-box",
      },
    };

    let backgroundObj = localPage.backgroundObj || {};
    let splitMode = backgroundObj.splitMode || false;
    let finalcontent = this.getContent(page, localPage);
    page.content = finalcontent;
    let pageEle = mixins.getDataNode(finalcontent).querySelector(".book-page");
    let contentStyle = pageEle?.style.getStyle() || { ...pageStyle };
    let acceptedKeys = ["background", "backgroundImage", "backgroundColor"];
    contentStyle = Object.filter(
      contentStyle,
      (v, k) => acceptedKeys.includes(k) || !k.includes("background")
    );
    let viewonly = pageMode == "dual" && i == currentIndex - 1;
    if (pageEle) {
      splitMode =
        splitMode || (pageEle.getAttribute("splitmode") || "false").parse();
      let localPageStyle = this.getPageStyle(i, splitMode);
      contentStyle = {
        ...contentStyle,
        ...localPageStyle,
        ...backgroundObj,
      };
    } else {
      let localPageStyle = this.getPageStyle(i, splitMode);
      contentStyle = {
        ...contentStyle,
        ...localPageStyle,
        ...backgroundObj,
      };
    }

    let component = () => <div></div>;
    let attributes = {};
    this.refs = {
      ...this.refs,
      [page.name]: React.createRef(),
    };
    let getReadOnly = () => viewonly || page.readonly || readonly;
    let self = this;
    switch (page.pagetype) {
      case "fulltext":
        component = (props) => (
          <div
            style={outerStyle}
            className={Object.className({
              pageborder: pageBordered,
            })}
            tabIndex={0}
          >
            <AppEditor
              style={fullTextStyle}
              data={page}
              hasToolBar={!(page.readonly || readonly)}
              pageref={this.refs[page.name]}
              readonly={getReadOnly()}
            ></AppEditor>
          </div>
        );
        break;
      case "fullimage":
        component = (props) => (
          <div
            style={outerStyle}
            className={Object.className({
              pageborder: pageBordered,
            })}
            tabIndex={0}
          >
            <ImagePlaceHolder
              style={fullImageStyle}
              data={page}
              pageref={this.refs[page.name]}
              readonly={getReadOnly()}
              onUpdate={() => self.updatePageContents()}
              uploadplaceholder={uploadplaceholder}
            ></ImagePlaceHolder>
          </div>
        );
        break;
      case "halftexthalfimage":
        component = (props) => (
          <div
            style={imageTextStyle}
            className={Object.className({
              pageborder: pageBordered,
            })}
            tabIndex={0}
          >
            <ImagePlaceHolder
              style={{ height: "50%", background: "transparent" }}
              data={page}
              pageref={this.refs[page.name]}
              readonly={getReadOnly()}
              onUpdate={() => self.updatePageContents()}
              uploadplaceholder={uploadplaceholder}
            ></ImagePlaceHolder>
            <AppEditor
              style={{ height: "50%", background: "#ffffffe3" }}
              data={page}
              hasToolBar={!getReadOnly()}
              pageref={this.refs[page.name]}
              readonly={viewonly || page.readonly || readonly}
            ></AppEditor>
          </div>
        );
        break;
    }

    return [
      component,
      attributes,
      this.refs[page.name],
      contentStyle,
      splitMode,
      page,
    ];
  }

  getContent(page) {
    let { pageContents } = this;
    let content = pageContents[page.name] || page.content;
    return content;
  }

  getOverlayStyle(i, page) {
    let overlayData = mixins
      .getDataNode(this.getContent(page))
      .querySelector("#overlay-data");
    let overlayStyle = {};
    if (overlayData) {
      overlayStyle = overlayData.style.getStyle();
    }
    let { pageStyle } = this.state;
    return {
      position: "absolute",
      left: 0,
      top: 0,
      width: "inherit",
      height: "inherit",
      zIndex: 1,
      // marginLeft: "-" + this.getPageStyle(i).paddingLeft,
      // marginTop: "-" + pageStyle.paddingTop,
      ...overlayStyle,
    };
  }

  updatePagesStyles() {
    const changeHandler = (event) => {
      this.inputValue = event.target.value;
    };
    let dialog = confirm(
      () => (
        <div className="Background">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="inner-border">
                <div>
                  <input
                    onChange={(e) => changeHandler(e)}
                    placeholder="No. of Pages"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {/* <button onClick={this.send}>send</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      "Set Template",
      {
        ok: "Apply",
        class: "setpagestyle",
        parentclass: "SelfPublish",
      }
    );
    return new Promise((resolve, reject) => {
      dialog.promise.then(() => {
        emit.trigger('openloader',`Please wait...`);
        let pageLenght = this.props.lastPageNo;
        if (this.inputValue > pageLenght - 5) {
          alert(`Please enter within ${pageLenght - 5} pages.`);
          emit.trigger('closeloader');
        } else {
          setTimeout(()=>{
            resolve(this.inputValue);
          },0);
        }
      });
    });
  }
  setCurrentPage(page) {
    
    if (page) {
      this.updatePagesStyles().then((rangeCount) => {
        for (let i = 0; i < rangeCount; i++) {
          let { currentIndex, pages } = this.state;
          let oldpage = pages[currentIndex + i];
          pages[currentIndex + i].pagetype = page.pagetype;

          switch (page.pagetype) {
            case "fulltext":
              pages[currentIndex + i].contentSize = 1000;
              break;
            case "halftexthalfimage":
              pages[currentIndex + i].contentSize = 500;
              break;
          }

          let liveeditor = (selector = "#editor-container") =>
            this.refs[oldpage.name].current.querySelector(selector) || {
              style: {},
            };
          if (page.content && this.refs[oldpage.name]) {
            let saveeditor = (selector = "#editor-container") =>
              mixins.getDataNode(page.content).querySelector(selector) || {
                style: {},
              };
            if (liveeditor && saveeditor) {
              let savedStyle = saveeditor(".book-page").style.getStyle();
              let oldStyle = pages[currentIndex + i].backgroundObj || {};
              pages[currentIndex + i].backgroundObj = {
                ...oldStyle,
                ...Object.filter(savedStyle, (v, k) =>
                  ["background", "fontFamily"].includes(k)
                ),
              };
              this.pageContents[oldpage.name] = saveeditor.innerHTML;
              setTimeout(() => {
                liveeditor().innerHTML = saveeditor().innerHTML;
                liveeditor(".TextResizeConatiner").style.height =
                  saveeditor(".TextResizeConatiner").style.height || "100%";
              });
              this.setState({
                pages: [...pages],
              });
            }
          } else {
            setTimeout(() => {
              switch (page.pagetype) {
                case "fulltext":
                  liveeditor(".TextResizeConatiner").style.height = "100%";
                  break;
              }
            });
            this.setState({
              pages: [...pages],
            });
          }
        }
        emit.trigger('closeloader');
      });
    }
  }

  setPage(index) {
    this.setState({
      currentIndex: index,
    });
    this.scrollAdjust();
  }

  setDragedPage(obj) {
    this.setState({
      dragedPage: obj,
    });
  }

  saveBook() {
    //console.log("saveBook");
    return new Promise((resolve, reject) => {
      let { onSave = () => ({}), png = false } = this.props;
      let modal = loader("Generating the pages please wait ...");
      let subscribeUsFlag = false;

      if (!this.props.bookDetails.id) {
        subscribeUsFlag = api.checkSubscribeUser(this);
      }
      if (subscribeUsFlag) {
        modal.close();
        if(subscribeUsFlag!=messages.save_book_limitation_warning_subscribed_user) {
          alert(
            (props) => (
              <SubscriptionPlan
                alert={props}
                id={this.props.bookDetails.id}
                key={this.props.bookDetails.id}
              />
            ),
            "",
            "model",
            {
              footer: false,
              class: "personalize-col col-8 col-md-8 col-lg-6 col-xl-6",
              // parentclass: "personalize-row row",
              options: {},
            }
          );
        }
        alert(subscribeUsFlag);
      } else {
        let self = this;
        setTimeout(() => {
          this.getPayload(png).then((...arg) => {
            //console.log({page:true,saveBook:arg[0].Page6.html});
            modal.close();
            onSave(...arg)
              .then((...arg) => {
                mixins.reFectchSavedBookDetails(self.props)
                .then(()=>resolve(...arg))
                .catch(()=>resolve(...arg));
              })
              .catch(reject);
          });
        });
      }
    });
  }

  previewBook() {
    this.state.pageref.current.querySelector("#previewBook").click();
  }

  getPayload(png = false) {
    let self = this;
    if (png) {
      return new Promise((resolve, reject) => {
        // Object.mapPromise(self.refs,(val)=>new Promise((resolvehtml,rejecthtml)=>{
        //     html2pdf().from(val.current).toPdf().output('datauristring').then(datauri=>{
        //     resolvehtml({
        //       html:val.current.outerHTML,
        //       base64:datauri
        //     })
        //   }).catch(rejecthtml);
        // })).then((payload)=>{
        //   resolve(payload);
        // }).catch(reject);
        Object.mapPromise(
          self.refs,
          (o) =>
            new Promise((r, rj) => {
              o.current
                .toDataURL()
                .then((png) => r({ html: o.current.outerHTML, png }))
                .catch(rj);
            })
        )
          .then((htmls) => {
            let payload = Object.map(htmls, (obj) => ({
              html: obj.html,
              // png: `
              //   <html>
              //     <body style='display: flex;'>
              //       <img src='${obj.png}' style='margin: auto;' >
              //     </body>
              //   </html>
              // `,
              base64: obj.png,
            }));
            resolve(payload);
          })
          .catch(reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(
          Object.map(this.refs, (val, key) => {
            return {
              html: val?.current?.outerHTML,
              png: "",
              base64: "",
            };
          })
        );
      });
    }
  }

  applyBackground(backgroundObj) {
    let { currentIndex, pages } = this.state;
    let oldstyle = pages[currentIndex].backgroundObj;
    pages[currentIndex].backgroundObj = {
      ...oldstyle,
      ...backgroundObj,
    };
    this.setState({
      pages: [...pages],
    });
  }

  render() {
    let self = this;
    let {
      match: {
        params: { genpdf },
      },
      message = "",
    } = self.props;
    genpdf = Number(genpdf);

    return ((
      {
        dragedPage,
        outerPageStyle,
        pageStyle,
        currentIndex,
        scrlpagetiles,
        pagetileref,
        pageref,
        pages,
      },
      {
        showCount = true,
        pagePayload = [],
        bookDetails = {},
        pageMode = "dual",
        overlay = false,
        leftTool = !genpdf,
        has = [],
        editbackground = true,
        pageBordered = true,
        paginate = true,
        onUpdate = () => "",
        closablePreview = true
      }
    ) => (
      <div
        className="PageEditor"
        onPaste={(e) => self.handelPast(e)}
        ref={pageref}
        tabIndex={0}
      >
        <div className="container-fluid">
          <div className="row no-gutters">
            <div className="col-12 col-lg-1 left-toolbar">
              {leftTool && (
                <LeftToolbar
                  has={has}
                  setDragedPage={(...arg) => self.setDragedPage(...arg)}
                  setCurrentPage={(...arg) => self.setCurrentPage(...arg)}
                />
              )}
            </div>
            <div
              className="col-12 col-lg-10"
              onDrop={() => self.setCurrentPage(dragedPage)}
              onDragOver={(ev) => ev.preventDefault()}
              style={{ overflowX: "auto", maxWidth: "100vw" }}
            >
              <div style={outerPageStyle}>
                {self
                  .getPages()
                  .map(([Page, attrs, ref, style, splitMode, page], i) => (
                    <div
                      ref={ref}
                      key={i}
                      style={{ ...pageStyle, ...style, ...(page.style || {}) }}
                      className={
                        "book-page " +
                        Object.className({
                          active: i == currentIndex,
                          viewonly: pageMode == "dual" && i == currentIndex - 1,
                          pageBordered,
                        })
                      }
                      splitmode={(!!splitMode).toString()}
                    >
                      {overlay && (
                        <div
                          id="overlay-data"
                          style={{ ...self.getOverlayStyle(i, page) }}
                        ></div>
                      )}
                      {showCount && (
                        <div
                          id={"charcount-" + page.name}
                          className="charcount"
                          pageno={i}
                          style={{
                            display: "none",
                            position: "absolute",
                            top: "50px",
                            left: "10px",
                            backgroundColor: "rgba(221, 221, 212, 0.56)",
                            padding: "10px",
                            border: "4px",
                            boxShadow: "1px 1px 1px 0px",
                          }}
                        >
                          <b>Content Count</b>
                          <span
                            id="count"
                            style={{
                              padding: "5px",
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }}
                          ></span>
                        </div>
                      )}
                      <Page {...attrs}></Page>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-12 col-lg-1 right-toolbar">
              <RightToolbar
                saveBook={(...arg) => self.saveBook(...arg)}
                onPreview={(...arg) => self.previewBook(...arg)}
                currentIndex={currentIndex}
                pages={pages.map((o) => ({
                  ...o,
                  backgroundObj: o.backgroundObj || {},
                }))}
                getPayload={() => self.getPayload()}
                applyBackground={(...arg) => self.applyBackground(...arg)}
                hasBtns={{
                  editbackground: editbackground && !genpdf,
                  needsave: !genpdf,
                  needpreview: !genpdf,
                }}
                bookDetails={bookDetails}
                message={message}
                onUpdate={onUpdate}
                closablePreview={closablePreview}
                params={this.props.match.params}
                onPreviewClose={this.props.onPreviewClose}
              >
                {this.props.RightChildren || (() => "")}
              </RightToolbar>
            </div>
          </div>
          {message && (
            <div className="row">
              <div className="col-1"></div>
              <div
                className="col text-center"
                style={{ fontWeight: "bold", color: "red" }}
                dangerouslySetInnerHTML={{ __html: message }}
              ></div>
              <div className="col-1"></div>
            </div>
          )}
          <PageFooter
            currentIndex={currentIndex}
            pagePayload={pagePayload}
            changePage={(...arg) => self.changePage(...arg)}
          />
        </div>
        {paginate && (
          <div className="container bottom_scroller">
            <PageScroller
              currentIndex={currentIndex}
              pagePayload={pagePayload}
              setPage={(...arg) => self.setPage(...arg)}
              scrlpagetiles={scrlpagetiles}
              pagetileref={pagetileref}
            />
          </div>
        )}
      </div>
    ))(this.state, this.props, this);
  }
}

export default connect(withRouter(PageEditor));
