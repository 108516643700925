import { Fragment, useEffect, useState, useRef } from "react";
import '../about.css';
import secondimage from "./Abouts.png";
const secondPart = (props) => {
    const [aboutpage, setaboutpage] = useState({});
    const getAboutusPage = () => {
        api.getAboutusPage().then((res) => {
            //console.log(res);
            if (res && res.data && res.data.length > 0) {
                setaboutpage(res.data[0])
            }

        });
    }
    useEffect(() => {
        getAboutusPage();
    }, [])
    //console.log(aboutpage);
    return (
        <Fragment >
            <div className="container pt-5 pb-5">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="Inner_Image">
                            {aboutpage?.fileImage?.filePath &&
                                <img src={aboutpage?.fileImage?.filePath} />
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        {/* <span className="sub-title">ABOUT US</span> */}
                        <h2 className="title-about">About Us</h2>
                        {aboutpage && aboutpage.aboutUs &&
                            <div dangerouslySetInnerHTML={{ __html: aboutpage.aboutUs }} />
                        }
                    </div>
                </div>
            </div>
            <div className="container pb-5">
                <div className="row">
                    <div className="col-md-4 custom-padding ">
                        <div className="card custom_card light_green">
                            <div className="row">
                                <div className="col-2">
                                    <div className="left_content white" >
                                        <span className="digit">1</span>
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="right_content">
                                        <h5>Creativity</h5>
                                        {aboutpage && aboutpage.creativity &&
                                            <p>{aboutpage.creativity}</p>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 custom-padding">
                        <div className="card custom_card blue" >
                            <div className="row">
                                <div className="col-2">
                                    <div className="left_content white">
                                        <span className="digit">2</span>
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="right_content">
                                        <h5>Simplicity</h5>
                                        {aboutpage && aboutpage.simplicity &&
                                            <p>{aboutpage.simplicity}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 custom-padding">
                        <div className="card custom_card red">
                            <div className="row ">
                                <div className="col-2">
                                    <div className="left_content white">
                                        <span className="digit">3</span>
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="right_content">
                                        <h5>Equality</h5>
                                        {aboutpage && aboutpage.equality &&
                                            <p>{aboutpage.equality}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-2 section_padding">
                <div className="container pb-5 ">
                    <div className="row">
                        <div className="col-md-12">
                            {aboutpage && aboutpage.otherInfo &&
                                <div dangerouslySetInnerHTML={{ __html: aboutpage.otherInfo }} />
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default connect(secondPart);
