import { connect } from "react-redux";
import mixins from ".";
import { parse, stringify, toJSON, fromJSON } from 'flatted';
const isset = (val, def = undefined) => {
    return val === undefined ? def : val;
};
const setStore = function (key, value=null, props = null) {
    props = props || this.props;
    let dispatch = props.dispatch;
    let watch = props.watch || this.watch;
    return dispatch((state) => {
        let storeObject = { ...state };
        let updateStore = (payload,storeObject)=>{
            let key = payload.name;
            let watchme = watch[key] || ((v) => (v));
            let arr = payload.name.split(".");
            if (arr.length > 1) {
                let tmp = storeObject, key = "", old = null;
                arr.forEach(k => {
                    old = tmp; tmp[k] = tmp[k] || {}; tmp = tmp[k]; key = k;
                });
                // arr.forEach(k => {
                //     old = tmp, tmp = tmp[k], key = k;
                // });
                old[key] = isset(watchme(payload.value), payload.value);
            } else {
                storeObject[key] = isset(watchme(payload.value), payload.value);
            }
            return storeObject;
        }
        if(typeof key == "object" && key instanceof Array) {
            key.map(obj=>{
                storeObject = updateStore(obj,storeObject);
            });
        } else {
            storeObject = updateStore({ name: key, value: value },storeObject);
        }
        //storeObject = JSON.stringify(storeObject).parse();
        //localStorage.setItem("reduxStore",JSON.stringify(storeObject));
        return {
            ...storeObject
        }
    });
};
export default {
    connect(component) {
        return connect(
            (state) => ({ store: state }),
            (dispatch) => {
                let ret = {};
                ret.dispatch = (func, ...payload) => dispatch.bind(ret)({ type: func, payload: payload });
                ret.setStore = (key, value, props = {}) => setStore.bind(ret)(key, value, { ...props, dispatch: ret.dispatch });
                ret.watch = {};
                return ret;
            }
        )(component);
    },
    initialConstruct(key) {
        this.$store = this.props.store;
        this.dispatch = this.props.dispatch;
        this.history = this.props.history || window.reduxHistory;
        this.setStore = mixins.setStore.bind(this);
        this.setStates = mixins.setStates.bind(this);
        this.debounce = mixins.debounce.bind(this);
        this.lasycall = mixins.lasycall.bind(this);
        this.watch = mixins.watch.bind(this);
        window[key] = this;
    },
    getCurrentPath() {
        return window.reduxHistory ? window.reduxHistory.location.pathname : '';
    },
    getDotedKeyValue(key, obj) {
        let arr = key.split(".");
        if (arr.length > 1) {
            let tmp = obj, key = "", old = null;
            arr.forEach(k => {
                old = tmp, tmp = tmp[k], key = k;
            });
            return old[key];
        } else {
            return obj[key]
        }
    },
    getDotedValueKeys(key, obj) {
        let arr = key.split(".");
        if (arr.length > 1) {
            let tmp = obj || {}, key = "", old = null;
            arr.forEach(k => {
                old = tmp, tmp = tmp[k], key = k;
            });
            return {
                obj: old,
                key
            };
        } else {
            return {
                obj,
                key
            }
        }
    },
    setStore,
    setStates(key, val) {
        let self = this;
        return new Promise((res, rej) => {
            self.setState((...arg) => {
                let ret = mixins.getDotedValueKeys(key, arg[0]);
                let obj = ret.obj;
                ret.obj[ret.key] = (typeof val == "function") ? val(...arg) : val;
                // key = ret.key;
                // let obj = {};
                // obj[key] = (typeof val == "function") ? val(...arg) : val;
                return {
                    ...arg[0]
                };
            }, (...arg) => {
                res(...arg);
            });
        });
    },
    watch(watchers, nextState=null, prevState = null) {
        prevState = prevState || { ...this.props };
        //console.log({nextState,prevState});
        let keys = Object.keys(watchers);
        keys.map((key) => {
            let oldval = mixins.getDotedKeyValue(key, prevState);
            let newval = mixins.getDotedKeyValue(key, nextState);
            if (mixins.detectComplexChange(oldval,newval)) {
                let func = watchers[key];
                if (func instanceof Function) {
                    func.bind(this)(newval, oldval);
                }
            }
        })
    },
    detectComplexChange(prev,next) {
        let trusy = prev||next;
        if(((typeof prev) != (typeof next)) || prev==null || next==null ) {
            return prev != next;
        } else if(["object"].includes(typeof trusy)) {
            let nextLen = (trusy instanceof Array)?next.length:Object.entries(next).length;
            let prevLen = (trusy instanceof Array)?prev.length:Object.entries(prev).length;
            if(prevLen!=nextLen) { return true; }
            else { return JSON.stringify(prev) != JSON.stringify(next) }
        } else {
            return prev != next;   
        }
    },
    getSelectionText() {
        var txt = '';
        if (window.getSelection) {
            txt = window.getSelection().toString();
        }
        else if (document.getSelection) {
            txt = document.getSelection().toString();
        }
        else if (document.selection) {
            txt = document.selection.createRange().text;
        }
        else return;
        return txt;
    },
    getDataNode(data) {
        let divEle = document.createElement("div");
        divEle.innerHTML = data;
        return divEle;
    }
}