export default class Emitter {
    constructor() {
        this.listners = {};
    }
    remove(key) {
        delete this.listners[key];
    }
    on(key,callback) {
        this.listners[key] =  callback;
    }
    trigger(key,...arg) {
        let callback  = this.listners[key]||(()=>(''));
        return new Promise((resolve,reject)=>{
            let ret = callback(...arg);
            if(ret instanceof Promise) {
                Promise.all([ret]).then(([val])=>resolve(val));
            } else {
                resolve(ret);
            }
        });
    }
};