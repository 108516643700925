import { Fragment, useEffect, useState, useRef } from "react";

import "./returns.css";
import banner from "./banner_delivery_returns.jpg";

const returncontent = (props) => {
    const [cmsinfo, setcmsinfo] = useState({});
    const getcmsinfo = () => {
        api.getcmsInfo().then((res) => {

            if (res && res.data) {
                let ret = res.data.reduce((ov, nv) => {
                    ov[nv.cmsPages.type] = nv;
                    return ov;
                }, {})
                // console.log(ret);
                setcmsinfo(ret);
            }

        });
    }
    useEffect(() => {
        getcmsinfo();
    }, [])
    //  console.log(cmsinfo);
    let returnCms = cmsinfo['Delivery and Returns'];
    // console.log(returnCms);
    // console.log(returnCms.description);
    return (
      <Fragment>
        <div className="banner singlePageBanner">
          {returnCms && returnCms.file["filePath"] && (
            <img src={returnCms.file["filePath"]} />
          )}
        </div>
        <div className="container return-page ">
          <div className="row pt-5">
            <div className="col-12">
              {returnCms && returnCms.cmsPages["type"] && (
                <h1
                  dangerouslySetInnerHTML={{
                    __html: returnCms.cmsPages["type"],
                  }}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              {returnCms && returnCms.description && (
                <div
                  dangerouslySetInnerHTML={{ __html: returnCms.description }}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
}
export default connect(returncontent);