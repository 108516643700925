import React, { Component } from "react";
import PageEditor from "../PageEditor/PageEditor";
import "./ThumbPrint.css";
import mixins from "../../app-services/mixins";
import { readUrl } from "../PageEditor/support";
import FrontCover from "./FrontCover.txt";
import StaticPage1 from "./StaticPage1.txt";
import StaticPage2 from "./StaticPage2.txt";
import StaticPage3 from "./StaticPage3.txt";
import StaticPage4 from "./StaticPage4.txt";
import BirdPage1 from "./BirdPage1.txt";
import BirdPage2 from "./BirdPage2.txt";
import StaticPage5 from "./StaticPage5.txt";
import StaticPage6 from "./StaticPage6.txt";
import OwlPage1 from "./OwlPage1.txt";
import OwlPage2 from "./OwlPage2.txt";
import BearPage1 from "./BearPage1.txt";
import BearPage2 from "./BearPage2.txt";
import GiraffePage1 from "./GiraffePage1.txt";
import GiraffePage2 from "./GiraffePage2.txt";
import ButterflyPage1 from "./ButterflyPage1.txt";
import ButterflyPage2 from "./ButterflyPage2.txt";
import CrabPage1 from "./CrabPage1.txt";
import CrabPage2 from "./CrabPage2.txt";
import PeacockPage1 from "./PeacockPage1.txt";
import PeacockPage2 from "./PeacockPage2.txt";
import AnimalPage1 from "./AnimalPage1.txt";
import AnimalPage2 from "./AnimalPage2.txt";
import AnimalPage3 from "./AnimalPage3.txt";
import AnimalPage4 from "./AnimalPage4.txt";
import ElephantPage1 from "./ElephantPage1.txt";
import ElephantPage2 from "./ElephantPage2.txt";
import BoyPage1 from "./BoyPage1.txt";
import BoyPage2 from "./BoyPage2.txt";
import StarPage1 from "./StarPage1.txt";
import StarPage2 from "./StarPage2.txt";
import RoutePage1 from "./RoutePage1.txt";
import RoutePage2 from "./RoutePage2.txt";
import CountPage1 from "./CountPage1.txt";
import CountPage2 from "./CountPage2.txt";
import LastPage1 from "./LastPage1.txt";
import LastPage2 from "./LastPage2.txt";
import PreInfoColletion from "./PreInfoColletion";
import { withRouter } from "react-router";
import { Button } from "@mui/material";
import uploadplaceholder from "./uploadPlaceholder.png";

export class ThumbPrint extends Component {
  constructor(props) {
    super(props);
    this.BOOKID = props.match.params.id;
    initialConstruct.bind(this)("ThumbPrint");
    const book = props.store.book.customCharacter;
    this.state = {
      staticPages: {
        frontCover: FrontCover,
        staticpage1: StaticPage1,
        staticpage2: StaticPage2,
        staticpage3: StaticPage3,
        staticpage4: StaticPage4,
        birdpage1: BirdPage1,
        birdpage2: BirdPage2,
        staticpage5: StaticPage5,
        staticpage6: StaticPage6,
        owlpage1: OwlPage1,
        owlpage2: OwlPage2,
        bearpage1: BearPage1,
        bearpage2: BearPage2,
        giraffepage1: GiraffePage1,
        giraffepage2: GiraffePage2,
        butterflypage1: ButterflyPage1,
        butterflypage2: ButterflyPage2,
        crabpage1: CrabPage1,
        crabpage2: CrabPage2,
        peacockpage1: PeacockPage1,
        peacockpage2: PeacockPage2,
        animalpage1: AnimalPage1,
        animalpage2: AnimalPage2,
        animalpage3: AnimalPage3,
        animalpage4: AnimalPage4,
        elephantpage1: ElephantPage1,
        elephantpage2: ElephantPage2,
        boypage1: BoyPage1,
        boypage2: BoyPage2,
        starpage1: StarPage1,
        starpage2: StarPage2,
        routepage1: RoutePage1,
        routepage2: RoutePage2,
        countpage1: CountPage1,
        countpage2: CountPage2,
        lastpage1: LastPage1,
        // lastpage2: LastPage2,
      },
      savedBook: {},
      bolierplates: {},
      svgs: {},
      pages: [
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `frontCover`,
          label: `Front Cover`,
          content: book && book[`frontCover`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `staticpage1`,
          label: `Static Page1`,
          content: book && book[`staticpage1`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `staticpage2`,
          label: `Static Page2`,
          content: book && book[`staticpage2`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `staticpage3`,
          label: `Static Page3`,
          content: book && book[`staticpage3`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `staticpage4`,
          label: `Static Page4`,
          content: book && book[`staticpage4`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `birdpage1`,
          label: `Bird Page1`,
          content: book && book[`birdpage1`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `birdpage2`,
          assetname: "bird",
          label: `Bird Page2`,
          content: book && book[`birdpage2`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `staticpage5`,
          label: `Static Page5`,
          content: book && book[`staticpage5`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `staticpage6`,
          label: `Static Page6`,
          content: book && book[`staticpage5`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `owlpage1`,
          assetname: "owl",
          label: `Owl Page1`,
          content: book && book[`owlpage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `owlpage2`,
          label: `Owl Page2`,
          content: book && book[`owlpage2`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `bearpage1`,
          assetname: "bear",
          label: `Bear Page1`,
          content: book && book[`bearpage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `bearpage2`,
          label: `Bear Page2`,
          content: book && book[`bearpage2`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `giraffepage1`,
          assetname: "giraffe",
          label: `Giraffe Page1`,
          content: book && book[`giraffepage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `giraffepage2`,
          label: `Giraffe Page2`,
          content: book && book[`giraffepage2`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `butterflypage1`,
          label: `Butterfly Page1`,
          content: book && book[`butterflypage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `butterflypage2`,
          assetname: "butterfly",
          label: `Butterfly Page2`,
          content: book && book[`butterflypage2`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `crabpage1`,
          label: `Crab Page1`,
          content: book && book[`crabpage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `crabpage2`,
          assetname: "crab",
          label: `Crab Page2`,
          assetname: "crab",
          content: book && book[`crabpage2`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `peacockpage1`,
          assetname: "peacock",
          label: `Peacock Page1`,
          content: book && book[`peacockpage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `peacockpage2`,
          label: `Peacock Page2`,
          content: book && book[`peacockpage2`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `animalpage1`,
          assetname: "animal",
          label: `Animal Page1`,
          content: book && book[`animalpage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `animalpage2`,
          label: `Animal Page2`,
          content: book && book[`animalpage2`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `animalpage3`,
          assetname: "animal",
          label: `Animal Page3`,
          content: book && book[`animalpage3`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `animalpage4`,
          label: `Animal Page4`,
          content: book && book[`animalpage4`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `elephantpage1`,
          assetname: "elephant",
          label: `Elephant Page1`,
          content: book && book[`elephantpage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `elephantpage2`,
          label: `Elephant Page2`,
          content: book && book[`elephantpage2`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `boypage1`,
          assetname: "boy",
          label: `Boy Page1`,
          content: book && book[`boypage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `boypage2`,
          label: `Boy Page2`,
          content: book && book[`boypage2`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `starpage1`,
          label: `Star Page1`,
          content: book && book[`starpage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          assetname: "star",
          name: `starpage2`,
          label: `Star Page2`,
          content: book && book[`starpage2`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `routepage1`,
          assetname: "route",
          label: `Route Page1`,
          content: book && book[`routepage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `routepage2`,
          label: `Route Page2`,
          content: book && book[`routepage2`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `countpage1`,
          assetname: "count",
          label: `Count Page1`,
          content: book && book[`countpage1`],
        },
        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `countpage2`,
          label: `Count Page2`,
          content: book && book[`countpage2`],
        },

        {
          pagetype: "fullimage",
          contentSize: 200,
          name: `lastpage1`,
          assetname: "last",
          label: `Last Page1`,
          content: book && book[`lastpage1`],
        },
        // {
        //   pagetype: "fullimage",
        //   contentSize: 200,
        //   name: `lastpage2`,
        //   label: `Last Page2`,
        //   content: book && book[`lastpage2`],
        // },
      ],
      overlayStyle: {},
    };
  }

  getContent(book, page) {
    let ele = mixins.getDataNode(book);
    const thumbprintPreInfo = this.props.store?.book?.thumbprintPreInfo;

    const answer = thumbprintPreInfo?.answer;
    const handprints = thumbprintPreInfo?.handprints;
    //console.log(page);
    if (page.assetname && handprints[page.assetname]) {
      let src = handprints[page.assetname].filepath;
      let imgEle = ele.querySelector("#image-element");
      imgEle.src = src;
    }
    let child_name = ele.querySelector("#child_name");
    if (child_name && answer && answer.child_name) {
      child_name.innerText = answer.child_name;
    }
    let dedictaion_text = ele.querySelector("#dedictaion_text");
    if (dedictaion_text && answer && answer.dedication) {
      dedictaion_text.innerText = answer.dedication;
    }
    let adult_name = ele.querySelector("#adult_name");
    if (adult_name && answer && answer.parent_name) {
      adult_name.innerText = answer.parent_name;
    }
    book = ele.innerHTML;
    return book;
  }

  getLatestBook() {
    let self = this;
    const thumbprintPreInfo = this.props.store?.book?.thumbprintPreInfo;
    return new Promise((resolve, reject) => {
      api
        .getUpdatedSelfBook({
          sessionId: localStorage.getItem("sessionID") || null,
          type: "thumbprint",
        })
        .then((responce) => {
          if (responce && responce.data) {
            let payload = responce.data;
            if (Object.entries(thumbprintPreInfo).length === 0) {
              this.setStore(
                "book.thumbprintPreInfo",
                JSON.parse(payload.attributes || "{}")
              );
            }
            this.setState({
              savedBook: payload,
            });
            resolve(payload);
          } else {
            resolve(this.state.savedBook);
          }
        })
        .catch(reject);
    });
  }

  onSave(payload, self) {
    const BOOKID = self.BOOKID;
    const { savedBook } = self.state;
    let history = self.history;
    const thumbprintPreInfo = self.props.store?.book?.thumbprintPreInfo;
    return new Promise((resolve, reject) => {
      if (savedBook && savedBook.id) {
        api
          .updateSelfPublish(
            {
              bookId: BOOKID,
              savedBook: JSON.stringify(payload),
              option: "dynamic",
              sessionId: localStorage.getItem("sessionID") || null,
              type: "thumbprint",
              attributes: JSON.stringify(thumbprintPreInfo),
            },
            savedBook.id
          )
          .then((responce) => {
            mixins.checkLogin(history).then(() => {
              self
                .getLatestBook()
                .then((responce) => {
                  resolve(responce);
                })
                .catch(reject);
            });
          });
      } else {
        api
          .createSelfPublish({
            bookId: BOOKID,
            savedBook: JSON.stringify(payload),
            option: "dynamic",
            sessionId: localStorage.getItem("sessionID") || null,
            file: null,
            type: "thumbprint",
            attributes: JSON.stringify(thumbprintPreInfo),
          })
          .then((responce) => {
            mixins.checkLogin(history).then(() => {
              self
                .getLatestBook()
                .then((responce) => {
                  resolve(responce);
                })
                .catch(reject);
            });
          });
      }
    });
  }
  onUpdate() {}

  updateBoilerplate() {
    return new Promise((resolve, reject) => {
      let { staticPages } = this.state;
      let promises = Object.entries(staticPages).map(([key, url]) => {
        return new Promise((resolve, reject) => {
          readUrl(url)
            .then((responce) => resolve({ key, responce }))
            .catch(reject);
        });
      });
      Promise.all(promises)
        .then((responce) => {
          let bolierplates = responce.reduce(
            (o, n) => ({ ...o, [n.key]: n.responce }),
            {}
          );
          resolve(bolierplates);
        })
        .catch(reject);
    });
  }

  onProceed() {
    this.updateData();
  }

  uploadHanprints(payload, modal) {
    let self = this;
    const thumbprintPreInfo = self.props.store?.book?.thumbprintPreInfo;
    //console.log({ thumbprintPreInfo, payload });
    let preInfo = thumbprintPreInfo || {};
    if (
      payload.child_handprint instanceof File ||
      payload.parent_handprint instanceof File
    ) {
      const formData = new FormData();
      formData.append("child", payload.child_handprint);
      formData.append("adult", payload.parent_handprint);
      api.uploadHandprint(formData).then((responce) => {
        if (responce && responce.statusCode === 200) {
          preInfo.handprints = Object.map(responce.data, (o) => ({
            ...o,
            filepath: "data:image/png;base64," + o.image_base64,
          }));
          preInfo.folder = responce.folder;
          preInfo.answer = payload;
          self.setStore("book.thumbprintPreInfo", preInfo);
          modal && modal.close();
          self.updateData();
        } else {
          alert(responce.msg);
        }
      });
    } else {
      preInfo.answer = payload;
      self.setStore("book.thumbprintPreInfo", preInfo);
      modal && modal.close();
      setTimeout(() => {
        self.updateData();
      }, 0);
    }
  }

  collectInfo(closable = false) {
    let onChange = (answer) => {};
    let modal = alert(
      (props) => (
        <PreInfoColletion
          closable={closable}
          alert={props}
          id={this.BOOKID}
          onChange={(...arg) => onChange(...arg)}
          onSave={(...arg) => this.uploadHanprints(...arg)}
        />
      ),
      "",
      "model",
      {
        footer: false,
        class: "",
        parentclass: "ThumbPrint",
        options: {},
      }
    );
  }

  updateData() {
    this.updateBoilerplate().then((bolierplates) => {
      this.getLatestBook().then((bookObj) => {
        let book = bookObj?.savedBook?.parse({}) || {};
        //console.log({ bookObj, book });
        this.setState({
          savedBook: bookObj,
          bolierplates: bolierplates,
          pages: this.state.pages.map((o) => ({
            ...o,
            content: this.getContent(
              o.content || book[o.name] || bolierplates[o.name] || "",
              o
            ),
          })),
        });
      });
    });
  }

  componentDidMount() {
    let {
      match: {
        params: { genpdf, customBookId },
      },
    } = this.props;
    const thumbprintPreInfo = this.props.store?.book?.thumbprintPreInfo;
    const handprints = thumbprintPreInfo?.handprints;
    if (
      !thumbprintPreInfo ||
      !handprints ||
      (handprints && Object.keys(handprints).length <= 0 && !customBookId)
    ) {
      this.collectInfo();
    } else {
      this.updateData();
    }
  }

  RightChildren(self) {
    return () => (
      <Button
        className="edit"
        variant="outlined"
        onClick={() => self.collectInfo(true)}
      >
        <i className="fa fa-edit"></i>
        <small>Edit</small>
      </Button>
    );
  }

  render() {
    return ((
      { pages, overlayStyle },
      props,
      { onSave, RightChildren }
    ) => (
      <div className="ThumbPrint top-margin">
        <PageEditor
          pagePayload={pages}
          onSave={(...arg) => onSave(...arg, this)}
          charcount={100}
          lastPageNo={31}
          overlay={true}
          style={overlayStyle}
          leftTool={false}
          readonly={true}
          editbackground={false}
          RightChildren={RightChildren(this)}
          showCount={false}
          pageBordered={false}
          pageStyle={{ position: "relative" }}
          uploadplaceholder={uploadplaceholder}
          nextmode={true}
        ></PageEditor>
      </div>
    ))(this.state, this.props, this);
  }
}

export default connect(withRouter(ThumbPrint));
