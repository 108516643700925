import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Announcement from "../announcement/AnnouncementBar";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import routes from "./dashboard-routes";
import "./DashboardLayout.css";

function DashboardLayout(props) {
  const user = useSelector((state) => state.user);
  const roles = () => user && user.roles.map((v) => v.name);
  return (
    <div className="DashboardLayout">
      <Announcement />
      <Header current={props.location.pathname} />
      <div className="container " style={{ width: "100vw", height: "100%", margin:"20px auto" }}>
        <div className="row no-gutters">
          <div className="sidebar">
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {routes
                .filter((route) => {
                  return roles()?.includes(route?.access) ||
                    route.access === "both"
                    ? true
                    : false;
                })
                .filter((route,i,arr)=>{
                  let routpaths = arr.filter(o=>o.path==route.path);
                  return routpaths[0]==route;
                })
                .map((route, i) => (
                  <li key={i}>
                    {/* {console.log("route", route)} */}
                    <Link to={route.path}>
                      {route.icon && typeof route.icon == "function" && (
                        <route.icon />
                      )}
                      {route.icon && typeof route.icon == "string" && (
                        <i className={route.icon}></i>
                      )}
                      {
                        <label>
                          <route.sidebar></route.sidebar>
                        </label>
                      }
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="content col customradius">
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default connect(DashboardLayout);
