import royalty from "../../assets/images/royalty.png";
import gift from "../../assets/images/gift.png";
import arrowwhite from "../../assets/images/arrowwhite.png";
import hand from "../../assets/images/hand.png";
import "./Dashboard.css";
export default connect((props) => {
  return (
    <div className="maincontainer">
      <div className="leftcontainer">
        <div className="left-container-row">
          <div className="left-col">
            <div className="left-col-row">
              <img src={royalty} className="royalty" />
            </div>
            <div className="left-col-row">
              <div className="left-row">Total Royalties Balance</div>
            </div>
            <div className="left-row">
              <span>$12500.65</span>(on 12 Jan. 2022)
            </div>
            <div className="left-col-row">
              <div className="left-row">Updated on: 09 Jan. 2022</div>
            </div>
          </div>
          <div className="right-column">
            <div className="right-column-1">
              <div className="right-column-row">
                <div className="right-column-col-left">
                  Royalty Redeemed
                  <span>$ 1500.00</span>
                </div>
                <div className="right-column-col-right">
                  <img src={gift} />
                </div>
              </div>
              <div className="right-column-row">
                <div className="right-column-col-left">
                  Last Redeemed on: 30 Jan. 2022
                </div>
                <img src={arrowwhite} className="i-white" />
              </div>
            </div>
            <div className="right-column-2">
              <div className="right-column-row">
                <div className="right-column-col-left">
                  Current Royalty Balance
                  <span>$ 11000.65</span>
                </div>
                <div className="right-column-col-right">
                  <img src={hand} />
                </div>
              </div>
              <div className="right-column-row">
                <div className="right-column-col-left">
                  Last Updated on: 01 Feb. 2022
                </div>
                <img src={arrowwhite} className="i-white" />
              </div>
            </div>
          </div>
        </div>
        <div className="rule-container">
          <ul>
            <li>
              There is no one who loves pain itself, who seeks after it and
              wants to have it, simply pain...
            </li>
            <li>
              There is no one who loves pain itself, who seeks after it and
              wants to have it, simply pain...
            </li>
            <li>
              There is no one who loves pain itself, who seeks after it and
              wants to have it, simply pain...
            </li>
            <li>
              There is no one who loves pain itself, who seeks after it and
              wants to have it, simply pain...
            </li>
            <li>
              There is no one who loves pain itself, who seeks after it and
              wants to have it, simply pain...
            </li>
            <li>
              There is no one who loves pain itself, who seeks after it and
              wants to have it, simply pain...
            </li>
            <li>
              There is no one who loves pain itself, who seeks after it and
              wants to have it, simply pain...
            </li>
            <li>
              There is no one who loves pain itself, who seeks after it and
              wants to have it, simply pain...
            </li>
          </ul>
        </div>
      </div>
      <div className="royalty-rightcontainer">
        <div className="heading">Redeem History</div>
        <div className="contentrow">
          <div className="itemrow">
            <div>
              <span>Redeemed at Unity Mall</span>
            </div>
            <div>
              <span>$275.00</span>
            </div>
          </div>
          <div className="itemrow">
            <div>30 Jan, 12:31pm</div>
            <div>Balance: $12225.65</div>
          </div>
        </div>
        <div className="contentrow">
          <div className="itemrow">
            <div>
              <span>Redeemed at Unity Mall</span>
            </div>
            <div>
              <span>$275.00</span>
            </div>
          </div>
          <div className="itemrow">
            <div>30 Jan, 12:31pm</div>
            <div>Balance: $12225.65</div>
          </div>
        </div>
        <div className="contentrow">
          <div className="itemrow">
            <div>
              <span>Redeemed at Unity Mall</span>
            </div>
            <div>
              <span>$275.00</span>
            </div>
          </div>
          <div className="itemrow">
            <div>30 Jan, 12:31pm</div>
            <div>Balance: $12225.65</div>
          </div>
        </div>
        <div className="contentrow">
          <div className="itemrow">
            <div>
              <span>Redeemed at Unity Mall</span>
            </div>
            <div>
              <span>$275.00</span>
            </div>
          </div>
          <div className="itemrow">
            <div>30 Jan, 12:31pm</div>
            <div>Balance: $12225.65</div>
          </div>
        </div>
        <div className="contentrow">
          <div className="itemrow">
            <div>
              <span>Redeemed at Unity Mall</span>
            </div>
            <div>
              <span>$275.00</span>
            </div>
          </div>
          <div className="itemrow">
            <div>30 Jan, 12:31pm</div>
            <div>Balance: $12225.65</div>
          </div>
        </div>
        <div className="contentrow">
          <div className="itemrow">
            <div>
              <span>Redeemed at Unity Mall</span>
            </div>
            <div>
              <span>$275.00</span>
            </div>
          </div>
          <div className="itemrow">
            <div>30 Jan, 12:31pm</div>
            <div>Balance: $12225.65</div>
          </div>
        </div>
      </div>
    </div>
  );
});
