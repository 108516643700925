import React, { useState, useRef, useEffect } from "react";
import AppInput from "../../../partials/AppInput";
import ScrollContainer from "../ScrollContainer";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import diacritics from "diacritics";
export default (
  obj,
  i,
  setAnswer,
  answer,
  answers,
  collect,
  handleFirstName
) => {
  const handleChange = (e) => {
    let inputValue = diacritics.remove(e.target.value);
    const words = inputValue.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    );
    inputValue = capitalizedWords.join(" ");
    e.target.value = inputValue;
  };
  let scrl = useRef(null);
  if (obj.type == "radio") {
    return (
      <div
        className="col-12"
        style={{ display: "flex", flexDirection: "row", width: "80px" }}
        key={i}
      >
        <ScrollContainer
          scrlref={scrl}
          top={10}
          right={-20}
          left={-20}
          shift={50}
        >
          {() => (
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              ref={scrl}
              row={obj?.row || true}
              defaultValue={answer[obj.name] || ""}
              onChange={(e) =>
                setAnswer({ ...answer, [obj.name]: e.target.value })
              }
            >
              {(obj.radios || [])
                .filter(obj.filter ? (v) => obj.filter(v, answers) : () => true)
                .map((radio, idx) => (
                  <FormControlLabel
                    key={idx}
                    control={<Radio className={"custom-radio"} />}
                    label={radio.label}
                    value={radio.value}
                    {...(radio?.attr || {})}
                  />
                ))}
            </RadioGroup>
          )}
        </ScrollContainer>
      </div>
    );
  } else {
    return (
      <div
        className={Object.className({
          "col-6": collect.length > 1,
          col: collect.length <= 1,
        })}
        key={i}
      >
        <AppInput
          type={obj.type}
          variant="filled"
          label={obj.label}
          inputProps={
            obj.name == "first_name"
              ? {
                  maxLength: 12,
                  autoComplete: "off",
                }
              : ""
          }
          onChange={(e) => {
            if (obj.name == "first_name") {
              console.log(e.target.value);
              handleChange(e);
            }
            setAnswer({ ...answer, [obj.name]: e.target.value });
          }}
          onKeyDown={(e) => handleFirstName(e)}
          defaultValue={answer[obj.name] || ""}
        ></AppInput>
      </div>
    );
  }
};
