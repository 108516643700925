import { Fragment, useEffect, useState, useRef } from "react";

import "./character.css";
import chfourfrontcover from "./one_chapter_four/Chapter-4-frontcover.png";

const Chap4Front = (props) => {
    return (
        <Fragment >
            <div className="chapter_four_front_cover height">
                <img src={chfourfrontcover} />
                <div className="chapterfourcustomimage">
                {props.children}
                </div>

            </div>
        </Fragment>
    )
}
export default connect(Chap4Front);