import React from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Header from "../components/layouts/header/Header";
import Selfpublishpreview from "../components/preview/selfpublishpreview";
const previewSelfPublish = (props) => {
  return (
    <div className={style['container']}>
      
      <Header />
      <Selfpublishpreview />
     
      <Footer />
    </div>)
}
export default connect(previewSelfPublish);