import { Fragment, useEffect, useState, useRef } from "react";
import "../chapter.css";
const pageseven = (props) => {
    return (
        <Fragment >
            <div className="pageformat height">
                <div className="text-center chapter-title">
                    {/* <h1>Chapter 4</h1> */}
                </div>
                <div className="text-center chapter-content">
                    <p>Gabby turned to glare at Ellie. "That's rude! Don't make fun of people's names!"</p>
                    <p>"I'm not making fun!” Ellie said indignantly. “I'm just asking what kind of a name is Bean? Your mother named you after a vegetable?"</p>
                    <p>"Beans are not vegetables," Bean said, smiling. "Beans are legumes, actually."</p>
                    <p>{props.fname||'NAME'} spoke up. "Anyway, Bean isn't his real name. That's just what we call him."</p>
                    <p>"Just like how my friends call me Ellie Belly," Ellie said. She liked these two children. She had a good feeling about them. She felt like she wouldn't mind if they called her Ellie Belly.</p>
                    <p>"Woof woof woof," said Snowy, not wanting to be left out.</p>
                    <p>"Oh, and this is Snowy," Ellie said. "Sorry I forgot you!"</p>
                </div>
                <div className="page-number">
                    <span className="digite-number">7</span>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(pageseven);