import React, { useEffect, useRef, useState } from "react";
// import uploadplaceholderPNG from "./Imagenew_placeholder.PNG";
import "./ImagePlaceHolder.css";
import "./Cropper/cropper.min.css";
import "./Cropper/cropper.min.js";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import mixins from "../../app-services/mixins";

function ImagePlaceHolder(props) {
  const uploadplaceholderPNG = "https://d3bdvx1zkw4kc8.cloudfront.net/e-book-api/background_images/Imagenew_placeholder.PNG";
  let bordercolor = useRef("white");
  let bordersize = useRef(null);

  const {
    style = {},
    data,
    pageref,
    readonly = false,
    uploadplaceholder = uploadplaceholderPNG,
  } = props;
  const [reload, setReload] = useState(false);
  const imgTag = useRef(null);
  const upload = useRef(null);
  const imageresize = useRef(null);
  const [cropMode, setCropMode] = useState(null);
  const [imageResizeStyle, setImageResizeStyle] = useState({});
  const [ImageBorderstyle, setImageBorderstyle] = useState({});
  const [dragObj, setDragObj] = useState({ x: 0, y: 0 });
  const [imgAttributes, setImgAttributes] = useState({
    brightness: 0,
    sharpness: 0,
    hue: 0,
    sat: 0,
    lightness: 0,
    contrast: 0,
  });

  const [instyle, setInstyle] = useState({
    position: "relative",
    "--img-x": "0px",
    "--img-y": "0px",
    ...style,
  });

  const setImageSrc = (src) => {
    //console.trace({setImageSrc:src,uploadplaceholder});
    let { onUpdate } = props;
    imgTag.current.oldsrc = src;
    imgTag.current.src = src;
    if (!isPlaceHolderImage()) {
      mixins.debounce("", () => onUpdate(), 300);
    }
  };

  const renderImage = function (ev) {
    let file = ev.target.files[0];
    let FileUploadPath = file.name
        if (FileUploadPath == '') {
        alert("Please upload an image");
    } else {
        var Extension = FileUploadPath.substring(FileUploadPath.lastIndexOf('.') + 1).toLowerCase();
        if (Extension == "png" || Extension == "jpeg" || Extension == "jpg") {
          let modal = loader("Rendering the image please wait...");
          setTimeout(() => {
            file.toBase64().then((datauri) => {
              setImageSrc(datauri);
              ev.target.value = "";
              modal.close();
              setReload(!reload);
            });
          });
        } 
    else {
     alert("Photo only allows file types of PNG, JPG and JPEG. ")
        }
    }
  };

  const changeImage = () => {
    upload.current.click();
    let initialheight = imgTag.current.clientHeight;
    let initialwidth = imgTag.current.clientWidth;
  };

  useEffect(() => {
    if (!imgTag.current) {
      setReload(!reload);
    }
  });

  const roundenImage = () => {
    if (
      imageResizeStyle.borderRadius &&
      imageResizeStyle.borderRadius == "50%"
    ) {
      setImageResizeStyle({
        borderRadius: "0%",
      });
    } else {
      setImageResizeStyle({
        borderRadius: "50%",
      });
    }
  };

  const isRounded = () => {
    return (
      imageResizeStyle.borderRadius && imageResizeStyle.borderRadius == "50%"
    );
  };

  const initCropper = (image) => {
    if (cropMode) {
      cropMode.element.setAttribute(
        "src",
        cropMode.getCroppedCanvas().toDataURL()
      );
      cropMode.destroy();
      setCropMode(false);
    } else {
      let crop = new Cropper(image, {
        //aspectRatio: 4/4,
        crop: function (e) {},
      });
      setCropMode(crop);
      return crop;
    }
  };

  const setImageBorder = () => {
    let indexObj = imgTag.current;
    const currentBordercolor = (borderColor, borderSize) => {
      imgTag.current = {
        ...indexObj,
        ...{
          bordercurrentColor: "",
          borderCurrentSize: "",
          ["bordercurrentColor"]: borderColor,
          ["borderCurrentSize"]: borderSize,
        },
      };
    };

    let dialog = confirm(
      () => (
        <div className="Background">
          <div className="row align-items-center">
            <div className="col-6">
              <div className="inner-border">
                <div className="col color-bg">
                  <div className="input-border">
                    <label>
                      <strong>Pick from the pallet</strong>
                    </label>
                    <input
                      className="mx-auto"
                      ref={bordercolor}
                      type={"color"}
                      defaultValue={indexObj.bordercurrentColor || "#ffffff"}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr />
              Or
              <hr /> */}
            <div className="col-6">
              <div className="inner-border">
                <div className="col image-upload">
                  <label>
                    <strong>Border Width</strong>
                  </label>
                  <input
                    className="mx-auto width_100"
                    ref={bordersize}
                    defaultValue={indexObj.borderCurrentSize}
                    type={"number"}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      "Change Background",
      {
        ok: "Apply",
        class: "change-background",
        parentclass: "SelfPublish",
      }
    );
    dialog.promise.then(() => {
      setImageBorderstyle({
        borderWidth: bordersize.current.value + "px",
        borderStyle: "solid",
        borderColor: bordercolor.current.value,
      });
      currentBordercolor(bordercolor.current.value, bordersize.current.value);
    });
  };
  const removeImageBorder = () => {
    let indexObj = imgTag.current;
    setImageBorderstyle({
      borderWidth: "none",
      borderStyle: "none",
      borderColor: "none",
    });
    indexObj.borderCurrentSize = "0";
    indexObj.bordercurrentColor = "#ffffff";
  };
  const removeCurrentImage = () => {
    setImageSrc(uploadplaceholder);
    imgTag.current.style.setProperty("transform", `scale(${1})`);
    setReload(!reload);
  };
  const handleZoomIn = () => {
    if (imgTag.current && imgTag.current.style) {
      let scale = imgTag.current.style.transform.number();
      imgTag.current.style.setProperty("transform", `scale(${scale + 0.1})`);
    }
  };

  const handleZoomOut = () => {
    if (imgTag.current && imgTag.current.style) {
      let scale = imgTag.current.style.transform.number();
      imgTag.current.style.setProperty("transform", `scale(${scale - 0.1})`);
    }
  };

  const resize = {
    onStart(obj) {
      //console.log({ onStart: obj });
      let isEditor =
        obj.target == imageresize.current || obj.target.id == "image-element";
      if ((dragObj.x == 0 || dragObj.y == 0) && !readonly && isEditor) {
        setDragObj({
          ...dragObj,
          x: obj.clientX,
          y: obj.clientY,
        });
      }
    },
    onProgress(obj) {
      let isEditor =
        obj.target == imageresize.current || obj.target.id == "image-element";
      if (!readonly && isEditor) {
        let payload = {
          ...obj,
          dx: obj.clientX - dragObj.x,
          dy: obj.clientY - dragObj.y,
        };
        imageresize.current.style.left = `${payload.dx}px`;
        imageresize.current.style.top = `${payload.dy}px`;
      }
    },
    onEnd(obj) {
      let isEditor =
        obj.target == imageresize.current || obj.target.id == "image-element";
      if (!readonly && isEditor) {
        let payload = {
          ...obj,
          dx: obj.clientX - dragObj.x,
          dy: obj.clientY - dragObj.y,
        };
        imageresize.current.style.left = `${payload.dx}px`;
        imageresize.current.style.top = `${payload.dy}px`;
      }
    },
  };

  const rotate = {
    rotateCanvas(canvas, degrees, currentImage) {
      let temp = canvas.height + canvas.width;
      let imageWidth = canvas.width;
      let ctx = canvas.getContext("2d");
      window.ctx = ctx;
      ctx.save(); //saves the state of canvas
      ctx.clearRect(0, 0, temp, temp); //clear the canvas
      canvas.width = canvas.height;
      canvas.height = imageWidth;

      ctx.translate(canvas.width / 2, canvas.height / 2); //let's translate
      ctx.rotate((Math.PI / 180) * degrees); //increment the angle and rotate the image
      ctx.translate(-canvas.height / 2, -canvas.width / 2);

      //ctx.translate(0, -currentImage.width); //let's translate
      ctx.drawImage(currentImage, 0, 0); //draw the image ;)

      ctx.restore(); //restore the state of canvas
      return canvas;
    },

    draw2canvas(strDataURI) {
      return new Promise((resolve) => {
        let myCanvas = document.createElement("canvas");
        let ctx = myCanvas.getContext("2d");
        let img = new Image();
        img.onload = function () {
          myCanvas.height = img.height;
          myCanvas.width = img.width;
          ctx.drawImage(img, 0, 0); // Or at whatever offset you like
          resolve(myCanvas);
        };
        img.src = strDataURI;
      });
    },

    rotateImage(currentImage, deg = 90) {
      return new Promise((resolve, reject) => {
        rotate
          .draw2canvas(currentImage.getAttribute("src"))
          .then((canvas) => {
            canvas = rotate.rotateCanvas(canvas, deg, currentImage);
            let rotatedImageURI = canvas.toDataURL();
            currentImage.setAttribute("src", rotatedImageURI);
            resolve(rotatedImageURI);
          })
          .catch(reject);
      });
    },
  };

  const getDataNode = (data) => {
    let divEle = document.createElement("div");
    divEle.innerHTML = data;
    return divEle;
  };

  const isPlaceHolderImage = () => {
    let src = imgTag && imgTag.current && imgTag.current.src;
    let flag = (src + "").includes(uploadplaceholder);
    return flag || src == null;
  };

  const onDropFile = (e) => {
    if ("preventDefault" in e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const files = e.target.files || e.dataTransfer.files;
    const fileList = Object.keys(files).map((file) => files[file]);
    if (fileList.length) {
      fileList[0].toBase64().then((base64) => {
        setImageSrc(base64);
        setReload(!reload);
      });
    }
  };

  const imgOperation = (operation = "brightness", value) => {
    let { onUpdate } = props;
    return new Promise((resolve, reject) => {
      let payload = operation;
      if (typeof operation == "string") {
        payload = `${operation}(${value})`;
      }
      if (imgTag.current) {
        let src = imgTag.current.src || imgTag.current.src;
        src
          .img2canvas(payload)
          .then((src) => {
            imgTag.current.src = src;
            onUpdate();
            resolve(src, value);
          })
          .catch(reject);
      }
    });
  };

  const editImage = (operation, value) => {
    switch (operation) {
      case "brightness":
        setImgAttributes({
          ...imgAttributes,
          brightness: value,
        });
        break;
      case "sharpness":
        setImgAttributes({
          ...imgAttributes,
          sharpness: value,
        });
        break;
      case "contrast":
        setImgAttributes({
          ...imgAttributes,
          contrast: value,
        });
        break;
      case "hue":
        setImgAttributes({
          ...imgAttributes,
          hue: value,
        });
        break;
    }
  };

  const dropSetting = () => {};

  useEffect(() => {
    if (imgTag && imgTag.current && !isPlaceHolderImage()) {
      let promise = imgOperation((ctx, img) => {
        let imageData = ctx.getImageData(0, 0, img.width, img.height);
        let filtered = ImageFilters.BrightnessContrastPhotoshop(
          imageData,
          imgAttributes.brightness,
          imgAttributes.contrast
        );
        filtered = ImageFilters.Sharpen(filtered, imgAttributes.sharpness / 10);
        filtered = ImageFilters.HSLAdjustment(
          filtered,
          imgAttributes.hue,
          imgAttributes.sat,
          imgAttributes.lightness
        );
        ctx.putImageData(filtered, 0, 0);
        return ctx;
      });
      promise.then((val) => {});
    }
  }, [imgAttributes]);

  useEffect(() => {
    //console.log({ImagePlaceHolder:data,name:data.name});
    if (data && imgTag) {
      let { onUpdate } = props;
      let element = getDataNode(data.content);
      let node = element.querySelector("#image-element");
      let resizenode = element.querySelector("#image-resize-element");
      let overlayData = element.querySelector("#overlay-data");
      let updateflag = false;
      if (overlayData) {
        let overlayEle = pageref.current.querySelector("#overlay-data");
        if (overlayEle) {
          overlayEle.innerHTML = overlayData.innerHTML;
          updateflag = true;
        }
      }
      if (resizenode) {
        let style = resizenode.style.getStyle();
        let acceptedKeys = [
          "position",
          "height",
          "width",
          "top",
          "left",
          "visibility",
          "display",
        ];
        style = Object.filter(style, (v, k) => acceptedKeys.includes(k));
        // console.log({setImageResizeStyle:style,name:data.name});
        setImageResizeStyle(style);
        updateflag = true;
      }
      if (node) {
        let isRoot = window.location.href == node.src;
        if(!isRoot) { setImageSrc(node.src); }
        
      }
      if (updateflag) {
        mixins.debounce("", () => onUpdate(), 300);
      }
    }
  }, [data]);

  return (
    <div
      className="ImagePlaceHolder"
      style={instyle}
      reload={reload + ""}
      tabIndex={0}
    >
      <div
        id="image-resize-element"
        className="ImageResizeConatiner"
        ref={imageresize}
        style={{
          display: "inline-block",
          height: "100%",
          width: "100%",
          position: "relative",
          ...imageResizeStyle,
          ...ImageBorderstyle,
          overflow: "hidden",
        }}
        onDragStart={(e) => resize.onStart(e)}
        onDrag={(e) => resize.onProgress(e)}
        onDragEnd={(e) => resize.onEnd(e)}
        draggable={true}
        onDrop={(...arg) => onDropFile(...arg)}
      >
        <img
          id="image-element"
          ref={imgTag}
          src={uploadplaceholder}
          style={{
            objectFit: "fill",
            height: "calc(100% - 4px)",
            width: "100%",
            objectPosition: "center",
            transform: "scale(1)",
          }}
        ></img>
      </div>
      {!readonly && (
        <div className="toolbar" style={{ display: "none" }}>
          <span>
            <button
              disabled={cropMode}
              data-edit="image"
              className={Object.className({
                radiusopen: true,
                radiusclose: isPlaceHolderImage(),
              })}
              title="Upload Image"
              onClick={() => changeImage()}
            >
              <i className="fa fa-image" aria-hidden="true"></i>
            </button>
            {imgTag && !isPlaceHolderImage() && (
              <>
                <button
                  disabled={cropMode}
                  data-edit="rotate"
                  title="Rotate Image"
                  onClick={(e) => rotate.rotateImage(imgTag.current)}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                </button>
                <button
                  disabled={cropMode}
                  data-edit="rotate"
                  title={isRounded() ? "Rectangle Image" : "Round Image"}
                  onClick={(e) => roundenImage(e)}
                >
                  {isRounded() ? (
                    <i className="fa fa-square" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-circle" aria-hidden="true"></i>
                  )}
                </button>
                <button
                  data-edit="crop"
                  title="Crop Image"
                  className=""
                  onClick={() => initCropper(imgTag.current)}
                >
                  <i className="fa fa-crop" aria-hidden="true"></i>
                </button>
                <button
                  disabled={cropMode}
                  data-edit="addborder"
                  title="Add Border"
                  className=""
                  onClick={() => setImageBorder()}
                >
                  <i className="fa fa-border-all"></i>
                </button>
                <button
                  disabled={cropMode}
                  data-edit="removeborder"
                  title="Remove Border"
                  className=""
                  onClick={() => removeImageBorder()}
                >
                  <i className="fa fa-eraser"></i>
                </button>
                <button
                  disabled={cropMode}
                  data-edit="zoomplus"
                  title="Zoom Plus"
                  className=""
                  onClick={() => handleZoomIn()}
                >
                  <i className="fa fa-search-plus"></i>
                </button>
                <button
                  disabled={cropMode}
                  data-edit="zoomminus"
                  title="Zoom Minus"
                  className=""
                  onClick={() => handleZoomOut()}
                >
                  <i className="fa fa-search-minus"></i>
                </button>
                {!cropMode && (
                  <span style={{ display: "inline-block" }}>
                    {/* <button data-edit="imageoperation" title='Zoom Minus' className='' onClick={() => dropSetting()} >
                    <i className="fa fa-edit"></i>
                  </button> */}
                    <div className="imageOperation">
                      <Typography gutterBottom>Brightness</Typography>
                      <Slider
                        aria-label="Brightness"
                        size="small"
                        defaultValue={imgAttributes.brightness}
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                        onChange={(e) =>
                          mixins.debounce(e, (v) => editImage("brightness", v))
                        }
                      />
                      <Typography gutterBottom>Sharpness</Typography>
                      <Slider
                        aria-label="Sharpness"
                        size="small"
                        defaultValue={imgAttributes.sharpness}
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                        onChange={(e) =>
                          mixins.debounce(e, (v) => editImage("sharpness", v))
                        }
                      />
                      <Typography gutterBottom>Contrast</Typography>
                      <Slider
                        aria-label="Contrast"
                        size="small"
                        defaultValue={imgAttributes.contrast}
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                        onChange={(e) =>
                          mixins.debounce(e, (v) => editImage("contrast", v))
                        }
                      />
                      <Typography gutterBottom>Hue</Typography>
                      <Slider
                        aria-label="Hue"
                        size="small"
                        defaultValue={imgAttributes.hue}
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                        onChange={(e) =>
                          mixins.debounce(e, (v) => editImage("hue", v))
                        }
                      />
                    </div>
                  </span>
                )}

                <button
                  disabled={cropMode}
                  data-edit="removesrc"
                  title="Remove Image"
                  className="radiusclose"
                  onClick={() => removeCurrentImage()}
                >
                  <i className="fa fa-times"></i>
                </button>
              </>
            )}
          </span>
        </div>
      )}
      <input
        ref={upload}
        type={"file"}
        style={{ display: "none" }}
        onChange={(e) => renderImage(e)}
      ></input>
    </div>
  );
}

export default connect(ImagePlaceHolder);
