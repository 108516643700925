import React from 'react';
const AwesomCard = (props) => {
    const { data } = props;
    return (
        <div className={"imagform " + props.className}>
            <div className="image-container">
                <img src={data.image} className="imagform-img"></img>
                <div className='title-card'>{data.title}</div>
                <div className='subtitle'>{data.subtitle}</div>
            </div>
        </div>
    )
}
export default AwesomCard;