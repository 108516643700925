import React, { Component } from "react";
import lock from "../../assets/images/lock.png";
import email from "../../assets/images/email.png";

export class PasswordResetForm extends Component {
	constructor(props) {
		super(props);
		initialConstruct.bind(this)("VerifyOtpForm");
		this.validate = props.validate;
		this.submit = props.submit;
		this.setParent = props.setState;
		this.formref = props.formref;

		this.state = {
			passwordType: "password",
		};
	}

	togglePassword() {
		this.setState({
			passwordType: this.state.passwordType == "password" ? "text" : "password",
		});
	}

	render() {
		return (
			<form ref={this.formref} className="popuplogin" onSubmit={(e) => this.submit(e)}>
				<div className="title">Enter New Password</div>
				<div className="form-input">
					<img src={lock} className="icon" />
					<input
						type={this.state.passwordType}
						className="input"
						placeholder="New Password"
						name="password"
						onChange={(e) => this.validate(e, "password", this.formref.current)}
						required
					/>
					<span onClick={() => this.togglePassword()}>
						{this.state.passwordType === "password" ? (
							<i className="fa fa-eye eyeicon" aria-hidden="true"></i>
						) : (
							<i className="fa fa-eye-slash eyeicon" aria-hidden="true"></i>
						)}
					</span>
				</div>
				<div className="form-input">
					<img src={lock} className="icon" />
					<input
						type={this.state.passwordType}
						className="input"
						placeholder="Confirm Password"
						onChange={(e) => this.validate(e, "confirm_password", this.formref.current)}
						required
					/>
					<span onClick={() => this.togglePassword()}>
						{this.state.passwordType === "password" ? (
							<i className="fa fa-eye eyeicon" aria-hidden="true"></i>
						) : (
							<i className="fa fa-eye-slash eyeicon" aria-hidden="true"></i>
						)}
					</span>
				</div>
				<div className="form-input">
					<span style={{ fontWeight: "bold", color: "red", fontSize: "10px" }}>
						{this.props.store.errorMsg}
						<br />
						{this.props.store.failureMsg}
					</span>
				</div>
				<div className="form-input">
					{!this.props.store.errorMsg && (
						<button type="submit" className="button" id="reset">
							Submit
						</button>
					)}
				</div>
			</form>
		);
	}
}

export default PasswordResetForm;
