import { Fragment, useEffect, useState } from "react";
import Booklet from "../Booklet/Booklet";
import PageNotFound from "../pageNotFound/pageNotFound"
import image from "../../assets/images/personalize.png";
import "./selpublishpreview.css";
import EditSteps from "./EditSteps";
import api from "../../app-services/API";
import { useParams } from "react-router-dom";
import axios from "axios";
const selpublishPreview = () => {

    let { id } = useParams();
    const generateBlankPage = () => {
        let arr = [];
        arr.length = 55;
        arr = arr.fill({});
        let pages = arr.map((v, i) => {
            return {
                pagetype: "BlankPage",
                name: `Page${i + 9}`,
                label: `Page ${i + 1} - ${i + 2}`,
            }
        });
        return pages;
    };

    const [bookPages, setBookPages] = useState(null);
    //console.log({ bookPages });
    const [pages, setPages] = useState([

        {
            pagetype: "FrontCover",
            label: "Front Cover",

        },
        {
            pagetype: "TitlePage",
            label: "TitlePage",
        },
        {
            pagetype: "CopyRightPage",
            label: "CopyRightPage",
        },
        {
            pagetype: "DedicationPage",
            label: "DedicationPage",
        },
        {
            pagetype: "ChapterTitle",
            label: "ChapterTitle",
        },
        ...generateBlankPage(),
        {
            pagetype: "AuthorAndArtist",
            label: "AuthorAndArtist",
        },
        {
            pagetype: "FullText",
            label: "TitlePage",
        },
        {
            pagetype: "FullImage",
            label: "FullText",
        },

        {
            pagetype: "ImageParagraph",
            label: "ImageParagraph",
        },
        {
            pagetype: "BlankPage",
            label: "BlankPage",
        },
        {
            pagetype: "BackCover",
            label: "BackCover",
        },
    ]);

    useEffect(() => {
        getList();

    }, [])

    const getList = () => {
        api.getSelfPublish(id).then(async (response) => {
            if (response && response.data && response.data) {
                let book = response.data[0];
                let savedBook = JSON.parse(book.savedBook);
                let fileNames = Object.keys(savedBook);
                let promises = fileNames.map(async (fileName, i) => {
                    try {
                        let html = await axios.get(book.filePath + "/" + fileName + ".html");
                        return { key: fileName, value: html.data };
                    } catch (err) {
                        return { key: fileName, value: "" };
                    }
                });
                Promise.all(promises).then((fullfilled) => {
                    book.content = fullfilled;
                    setBookPages(book);
                });
            }
        })

    }

    const onPage = (obj, i, arr) => {
        let tempPage = bookPages.content.find((pageObj) => {
            return pageObj.key == (obj.name || obj.pagetype);
        })

        return {

            style: {

                // backgroundImage: `url(${bookPages.filePath}/FrontCover.html)`,

                height: "100%",
                backgroundPosition: "center",
                backgroundSize: "cover"
            },
            content: tempPage ? () => {
                return <div>
                    <div className="previewPages" dangerouslySetInnerHTML={{ __html: tempPage.value || '' }}></div>

                    <div className="col">
                        <div className="d-flex" style={{ hight: '100%' }}>
                            <span className="m-auto">{tempPage.key} / 60</span>
                        </div>
                    </div>
                </div>
            } : () => {
                return <div>
                    <div style={{ backgroundColor: "#ffff" }}><PageNotFound /></div>
                    <div className="col">
                        <div className="d-flex" style={{ hight: '100%' }}>
                            <span className="m-auto">Page not found</span>
                        </div>
                    </div></div>
            }
        }
    };

    const stepfilter = (step) => {
        return step.value != 1;
    }
    return (
        <Fragment >
            <div className='selfpublish-preview pb-5'>
                <div className="row no-gutters ">
                    <div className='col'></div>
                    {
                        bookPages &&
                        <div className='col-lg-10'>
                            <Booklet
                                pages={pages}
                                onPage={onPage} />
                        </div>

                    }
                    <div className='col'></div>
                </div>
                <div className="row">
                    <div className='col'></div>
                    <div className="col-10">
                        <EditSteps stepfilter={stepfilter} />
                    </div>
                    <div className='col'></div>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(selpublishPreview);