import React, { useState, useRef, useEffect } from 'react';
import "./ScrollContainer.css";

function ScrollContainer(props) {
  const { top=-40, left=-5,right=-5,index=0 } = props;
  const [getscroll, setScroll] = useState(null);
  const scrlref = () => props?.scrlref?.current;
  const checkscrollend = () => {
    let ele = scrlref();
    let flag = ele?.clientWidth < ele?.scrollWidth;
    flag = flag && (ele?.scrollLeft < ele?.scrollWidth - ele?.clientWidth);
    return flag;
  }; 

  const slide = (shift) => {
    if (scrlref()) {
      scrlref().scrollLeft += shift;
      setScroll((v) => !v);
    }
  };

  useEffect(() => {
    if (getscroll === null) {
      setScroll((v) => !v);
    }
  }, [])

  return <div className='scroll-container'>
    {scrlref()?.scrollLeft != 0 && (
      <i className="fa fa-angle-left prev" onClick={() => slide(-(props.shift||10))} style={{top:`${top}px`,left:`${left}px`}}></i>
    )}
    <props.children key={index} onScroll={()=>setScroll(v=>!v)} />
    {checkscrollend() && (
      <i className="fa fa-angle-right next" onClick={() => slide(+(props.shift||10))} style={{top:`${top}px`,right:`${right}px`}}></i>
    )}
  </div>;
}

export default ScrollContainer;
