import React, { useEffect, useState } from 'react'
import './TypesNew.css'
import custombooks from '../../../assets/images/c11.png';
import card12 from '../../../assets/images/card12.png'
import card13 from '../../../assets/images/card13.png'
import cardself1 from "../../../assets/images/cardself1.png";
import cardself2 from "../../../assets/images/cardself2.png";
import { useHistory } from 'react-router-dom';
import api from '../../../app-services/API';
const TypesNew = () => {
    const history = useHistory();
    const [staticBlock, setStaticBlock] = useState([])
    useEffect(() => {
        getStaticBlocklist()
    }, [])
    const getStaticBlocklist = () => {
        api.getStaticBlocks().then((res) => {
            if (res && res.data && res.statusCode === 200) {
                setStaticBlock(res.data)
            }
        })
    }
    const getLink = (item) =>{
        if (item.subCategory === "kids") {
           return '/custom-books/product-list/kids';
        }
        else if (item.subCategory === "chapter") {
            return '/self-publish/product-list/chapter' ;
        } else if (item.subCategory === "picture") {
            return '/self-publish/product-list/picture';
        } else {
            return '/handprint-books/product-list';
        }
    }
    return (
        <div className='TypeNewcontainer'>
            {staticBlock && staticBlock.map((item,i) => {
                return (
                  <a
                    href={getLink(item)}
                    className={
                      item.category === "custom"
                        ? "Card1 customTest"
                        : item.category === "handprint"
                        ? "Card2 customTest"
                        : "Card3 customTest"
                    }
                    key={i}
                    onClick={() => {
                      localStorage.setItem("category", item.category);
                      localStorage.setItem("subCategory", item.subCategory);
                      if (item.subCategory === "kids") {
                        history.push("/custom-books/product-list/kids");
                      } else if (item.subCategory === "chapter") {
                        history.push("/self-publish/product-list/chapter");
                      } else if (item.subCategory === "picture") {
                        history.push("/self-publish/product-list/picture");
                      } else {
                        history.push("/handprint-books/product-list");
                      }
                    }}
                  >
                    <div className="content">
                      {item.category === "self-publishing" && (
                        <img src={cardself1} className="self1" />
                      )}
                      <div className='innerconent'>
                        {item.category === "custom" ? (
                          <div className="heading"> Custom Books</div>
                        ) : item.category === "handprint" ? (
                          <div className="heading"> Handprint Books</div>
                        ) : (
                          <div className="heading"> Self-Publishing</div>
                        )}

                        {item.category === "custom" ? (
                          <div className="details">
                            {" "}
                            Personalise a story for someone special
                          </div>
                        ) : item.category === "handprint" ? (
                          <div className="details">
                            {" "}
                            where your handprints become part of the art
                          </div>
                        ) : (
                          <div className="details">
                            {" "}
                            Easy,Print-ready design templates for your story
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="image">
                      {item.category === "custom" ? (
                        <img src={custombooks} className="imgSize" />
                      ) : item.category === "handprint" ? (
                        <img src={card12} className="imgSize" />
                      ) : (
                        <img src={cardself2} className="imgSize" />
                      )}
                    </div>
                  </a>
                );
            })}
        </div>
    )
}
export default TypesNew;

