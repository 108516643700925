import { Fragment, useEffect, useState, useRef } from "react";
import "../chapter.css";
const pageone = (props) => {
    return (
        <Fragment >
            <div className="pageformat height">
                <div className="text-center chapter-title">
                    <h1>Chapter 4</h1>
                </div>
                <div className="text-center chapter-content">
                    <p>A scuttling, scurrying sound broke her out of her thoughts. A white furry shape bounded onto her bed and leapt onto her tummy.</p>
                    <p>"Oof, Snowy! Don't jump on my belly!" she said.</p>
                    <p>Snowy ignored her and jumped on her tummy again. He jumped off excitedly, then leapt towards her face, tail wagging furiously. He started to lick her face.</p>
                    <p>Ellie giggled. "Stop, Snowy," she said, although she didn't mean it. She liked it when Snowy licked her.</p>
                    <p>An idea popped into her head. Maybe Snowy would know what was going on. She sat up and held Snowy at arm's length so that he would stop licking her.</p>
                    <p>"Snowy, is there something happening to me? I've been hearing animal voices come out of humans all day. Do you know why?" Ellie demanded.</p>
                </div>
                <div className="page-number">
                    <span className="digite-number">1</span>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(pageone);