import { Fragment, useEffect, useState, useRef } from "react";
import banner from "./about_banner.jpg";



const Banner = (props) => {
    
    const [aboutpage, setaboutpage] = useState({});
    const getAboutusPage = () => {
        api.getAboutusPage().then((res) => {
            //console.log(res);
            if (res && res.data && res.data.length > 0) {
                setaboutpage(res.data[0])
            }

        });
    }
    useEffect(() => {
        getAboutusPage();
    }, [])
 
    //console.log(aboutpage);
    return (
        <Fragment >
            <section className="banner singlePageBanner">
                { aboutpage?.fileBanner?.filePath &&
                    <img src={aboutpage?.fileBanner?.filePath} />
                }
            </section>
        </Fragment>
    )
}
export default connect(Banner);