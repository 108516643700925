import React, { useState, useEffect } from 'react';
import style from './Announcement.module.css'
const Announcement = (props) => {
    const [announcement, setAnnouncement] = useState(null)
    const [announcementdata, setAnnouncementData] = useState(null)
    const [countdown, setCountdown] = useState(null);
    const [len, setlen] = useState(null);
    const getAnnouncement = () => {
        api.getAnnouncement().then((res) => {
            if (res && res.statusCode === 200 && res.data) {
                let announcement = res.data[res.data.length-1].announcement;
                setAnnouncementData(res.data)
                setlen(res.data.length);
                setCountdown(res.data.length-2);
                setAnnouncement(announcement);
                if(announcement) { document.body.style.setProperty('--annancement-height','43px'); }
                else { document.body.style.setProperty('--annancement-height','0px'); } 
            }
        });
    }
    const getAnnouncementdata = () => {
            if (announcementdata) {
                if(countdown===0){setCountdown(len);}
                setTimeout(() => {
                    setCountdown(countdown - 1);
                    if(announcementdata[countdown]){
                        setAnnouncement(announcementdata[countdown].announcement);  
                    }
                }, 5000);
                return () => {
                    clearTimeout(getAnnouncementdata);
                }
            }
    }
    useEffect(() => {
        //console.log({getAnnouncement:"onMounted",token});
        getAnnouncement();
    }, [])
    useEffect(() => {
        getAnnouncementdata();
    }, [countdown])
    return (
        <div>
            {announcement &&
                <div className={style['container']} >
                    <span>{announcement}</span>

                </div>}
        </div>
    )
}
export default Announcement;