import awesome from '../../../assets/images/awesome.png'
export default (formStep=[]) => (
    [
        // {
        //     question: "How many children are you making the book for ?",
        //     type: "option",
        //     options: [
        //         {
        //             value: 1,
        //             label: "One Children"
        //         },
        //         {
        //             value: 2,
        //             label: "Two Children"
        //         }
        //     ]
        // },
        {
            question: "",
            type: "finalpage",
            image: awesome,
            title:"Awesome!",
            subtitle: "Now have fun customising your character(s)!",
            className:"Awesome",
            autoredirct:2000
        },
        {
            question: "Enter Child's Name",
            type: "image-form",
            // image: image,

            name:"name",
            collect: [
                {
                    name: "first_name",
                    type: "text",
                    label: " Child's First Name"
                },
                // {
                //     name: "last_name",
                //     type: "text",
                //     label: "Last Name"
                // }
            ],
            formStep:Object.deepClone(formStep)
        },
        {
            
            type: "image-form",

            // image: image,

            name: "gender",
            collect: [
                {
                    name: "gender",
                    type: "radio",
                    label: "Gender",
                    radios: [
                        {
                            label: <b> Boy</b>,
                            value: "boy",
                            attr: {
                                style: {
                                    margin: "auto"
                                },
                                labelPlacement: "start"
                                
                            }
                        },
                        {
                            value: "girl",
                            label: <b> Girl</b>,
                            attr: {
                                style: {
                                    margin: "auto"
                                },
                                labelPlacement: "start"
                            }
                        }
                    ],
                }
            ],
            formStep:Object.deepClone(formStep)
        },
        {
            question: "Select Skin Tone",
            type: "image-form",
            // image: image,

            name: "skin",
            collect: [
                {
                    name: "skin",
                    type: "radio",
                    label: "skin Color",
                    radios: [
                      
                    ],
                }
            ],
            formStep:Object.deepClone(formStep)
        },
        {
            question: "Select T-shirt Colour",
            type: "image-form",

            //image: image,

            name: "tshirt",
            collect: [
                {
                    name: "tshirt",
                    type: "radio",
                    label: "T-shirt Colour",
                    radios: [
                        
                    ],
                }
            ],
            formStep:Object.deepClone(formStep)
        },
        {
            question: "Select Hair Style",
            type: "image-form",

            //image: image,

            name: "hair-style",
            collect: [
                {
                    name: "hair-style",
                    type: "radio",
                    label: "Hair Style",
                    radios: [
                        
                    ],
                }
            ],
            formStep:Object.deepClone(formStep)
        },
        {
            question: "Select Hair Colour",
            type: "image-form",

            //image: image,

            name: "hair",
            collect: [
                {
                    name: "hair",
                    type: "radio",
                    label: "Hair Color",
                    radios: [
                        
                    ],
                }
            ],
            formStep:Object.deepClone(formStep)
        },
        {
            question: "Does the character wear glasses?",
            type: "image-form",

            //image: image,

            name: "eyewear",
            collect: [
                {
                    name: "eyewear",
                    type: "radio",
                    label: "Eyewear",
                    radios: [
                        {
                            label: <b> Yes</b>,
                            value: "yes",
                            attr: {
                                style: {
                                    margin: "auto"
                                },
                                labelPlacement: "start"
                                
                            }
                        },
                        {
                            value: "no",
                            label: <b> No</b>,
                            attr: {
                                style: {
                                    margin: "auto"
                                },
                                labelPlacement: "start"
                            }
                        }
                    ],
                }
            ],
            formStep:Object.deepClone(formStep)
        },
        {
            question: "Enter your dedication",
            type: "image-form",
            name:"dedication",
            collect: [
                {
                    name: "dedication",
                    type: "textarea",
                    label: "Enter hear dedication"
                },
            ],
            formStep:Object.deepClone(formStep),
            hidden:true
        },
    ]
)