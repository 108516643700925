Object.filter = (obj, predicate) =>
    Object.keys(obj)
        .filter(key => predicate(obj[key], key, obj))
        .reduce((res, key) => (res[key] = obj[key], res), {});

Object.map = (obj, predicate) =>
    Object.keys(obj)
        .map(key => ({ key: key, value: predicate(obj[key], key, obj) }))
        .reduce((res, o) => (res[o.key] = o.value, res), {});

Object.mapPromise = function(obj,predicate) {
    let entries = Object.entries(Object.map(obj,predicate));
    let keys = entries.map(([key,val])=>key);
    let promises = entries.map(([key,val])=>val);
    return new Promise((resolve,reject)=>{
        Promise.all(promises).then(resolvedArr=>{
            let payload = resolvedArr.reduce((o,n,i)=>({
                ...o,
                [keys[i]]:n
            }),{});
            resolve(payload);
        })
    });
}

Object.className = (obj) => {
    return Object.keys(Object.filter(obj, v => v)).join(' ');
}

Object.QueryString = (obj) => {
    return Object.values(Object.map(obj, (v, k) => `${k}=${encodeURIComponent(v)}`)).join("&");
};

Object.deepClone = function (obj) {
    return JSON.stringify(obj).parse();
}

CSSStyleDeclaration.prototype.getStyle = function () {
    let keys = Object.values(Object.filter(this, (v, k) => Number(k) >= 0));
    return keys.reduce((o, n) => ({ ...o, [n.dashed2camel(true)]: this[n] }), {});
}

Object.styleString = function (style) {
    return Object.entries(style).map(([k, v]) => `${k}:${v}`).join(';') + ";";
};

HTMLElement.prototype.html2canvas = function(){
    html2canvas(this);
}

HTMLElement.prototype.toDataURL = function(display="block") {
    let self = this;
    let initial = self.style.display;
    self.style.display = display;
    return new Promise((resolve,reject)=>{
        html2canvas(self)
        .then((canvas)=>resolve(canvas.toDataURL()))
        .catch(reject);
        self.style.display = initial;
    });
}

HTMLElement.prototype.screenshot = function (filename="",clone=false,mime="image/png") {
    let ele = clone?this.cloneNode():this;
    html2canvas(ele).then((canvasElement) => {
        canvasElement.download(filename,mime);
    });
}

HTMLCanvasElement.prototype.download = function(filename="",mime="image/png") {
    let canvasImage = this.toDataURL(mime);
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = filename||"screeen-shot-"+(new Date()).timestamp()+"."+mime.split("/")[1];
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };
    xhr.open('GET', canvasImage);
    xhr.send();
}

HTMLElement.prototype.background2base64 = function() {
    let self = this;
    let background = (this.style.background||this.style.backgroundImage);
    let url = background.extractURL(0);
    if(url) {
        url.img2base64().then(base64=>{
            self.style.setProperty('background-image',base64);
        });
    }
}

HTMLElement.prototype.toText = function() {
    return this.textContent;
}

HTMLFormElement.prototype.getData = function() {
    let entries = new FormData(this).entries();
    let obj = {};
    for (var pair of entries) {
        obj[pair[0]] = pair[1];
    }
    return obj;
}
// Object.prototype.$__entries = function() {
//     return Object.entries(this)
// }