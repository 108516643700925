import { Fragment, useEffect, useState, useRef } from "react";
import "../chapter.css";
const pagesix = (props) => {
    let pronoun = "";
    let his="";
    if(props.currentGender=='boy'){
        pronoun ="he";
        his="his";
    }
    else{
        pronoun ="she";
        his="her";
    } 
    return (
        <Fragment >
            <div className="pageformat height">
                <div className="text-center chapter-title">
                    {/* <h1>Chapter 4</h1> */}
                </div>
                <div className="text-center chapter-content">
                    <p>"What are you doing?" Gabby asked. "What are you two looking at?"</p>
                    <p>They looked at each other, unsure.</p>
                    <p>"We're not going to tell on you or anything," Ellie assured them. "We're curious. And also, if it's an animal, we love rescuing animals!"</p>
                    <p>The {props.currentGender||'Gender'} in the collared T-shirt smiled. "We love rescuing animals too!"</p>
                    <p>“Should we be telling them?" Blue T-shirt boy said.</p>
                    <p>The {props.currentGender||'Gender'} in the collared T-shirt stepped forward. "I feel we can trust them. Okay, we'll tell you. But first, what's your name? I'm {props.fname||'NAME'}. This is Bean."</p>
                    <p>"I'm Gabby and this is my sister Ellie," Gabby said.</p>
                    <p>"Bean?" Ellie said. "What kind of a name is Bean?"</p>
                </div>
                <div className="page-number">
                    <span className="digite-number">6</span>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(pagesix);