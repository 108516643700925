//window.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FkbWluLnBsb3R0ZXJib3guY29tL2FwaS91c2VyL2xvZ2luIiwiaWF0IjoxNjUxNzQwMTYxLCJuYmYiOjE2NTE3NDAxNjEsImp0aSI6Im9jU2tuTDdNbzdqMkMwY28iLCJzdWIiOiIzNiIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.Ht1pAE-CFIAx_BTnCBMUxZ-YgYCz5NBjSRdmpbhY8hc";
//window.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9lLWJvb2stYXBwLmhlcm9rdWFwcC5jb21cL2FwaVwvdXNlclwvbG9naW4iLCJpYXQiOjE2NDQ5OTkzODQsIm5iZiI6MTY0NDk5OTM4NCwianRpIjoiM1Y4TFc5VThsSVFZMmJPTCIsInN1YiI6MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.lu_zYsXo_PiWHVvYqYW3BFW3Zz0WU7NaxEKF3loKruU";
//Staging Token
// window.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9lLWJvb2stYXBwLWRldmVsb3BtZW50Lmhlcm9rdWFwcC5jb21cL2FwaVwvdXNlclwvcmVnaXN0ZXIiLCJpYXQiOjE2NDUwODA5OTUsIm5iZiI6MTY0NTA4MDk5NSwianRpIjoidWZYMFU0WjBVVERIdU1MeSIsInN1YiI6NCwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.ydXgjf2N8oMsWZ8vCXjbeXx4_6d8ypakucCnblC9R-Y";
//Development Token
// window.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vcGxvdHRlcmJveC1hZG1pbi5kZXZlbG9wbWVudC5zYW5kYm94LmZsb3RpbGxhdGVjaHMuY29tL2FwaS91c2VyL2xvZ2luIiwiaWF0IjoxNjUzNzE2ODkzLCJuYmYiOjE2NTM3MTY4OTMsImp0aSI6IjZmRnZqYUhrVVk5NXBpQzkiLCJzdWIiOiI0IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.HJ70CkH_OGm7qVRQZ-ULWL8CuEl15HuusykjzlZgSVg";
import axios from "axios";
import env from "./config.js";
window.axios = axios;

//console.log({ ENV: env });
const token = localStorage.getItem("token");
window.STATIC_TOKEN = env.STATIC_TOKEN;
window.token = token || env.STATIC_TOKEN;
if (env?.PRODUCTION_URL.split(",").includes(window.location.origin)) {
  window.base = "https://admin.plotterbox.com";
  window.UPLOADHANDPRINT = "https://handprint.plotterbox.com/uploadImage";
  window.token = token || env.STATIC_TOKEN;
} else if (env?.STAGING_URL.split(",").includes(window.location.origin)) {
  window.base = "https://admin74.plotterbox.tsprojects.net";
  window.UPLOADHANDPRINT =
    "http://handprint-plotterbox.tsprojects.net/uploadImage";
  window.token = token || env.STATIC_TOKEN;
} else if (env?.DEVELOPMENT_URL.split(",").includes(window.location.origin)) {
  window.base = "https://admin74.plotterbox.tsprojects.net";
  window.UPLOADHANDPRINT =
    "http://handprint-plotterbox.tsprojects.net/uploadImage";
  window.token = token || env.STATIC_TOKEN;
} else {
  window.base = env.LOCAL_BASEURL;
  window.UPLOADHANDPRINT = env.LOCAL_UPLOADHANDPRINT;
  window.token = token || env.STATIC_TOKEN;
}
if (!token && window.token) {
  localStorage.setItem("token", window.token);
}

const defaultText = "";
export default {
  handelAlert: (func, loaderSet = true, message = "") => {
    return new Promise((res, rej) => {
      let loadObj = { close: () => {} };
      if (loaderSet && window.loader) {
        loadObj = loader(message || defaultText);
      }
      let customRej = (...arg) => {
        loadObj.close();
        let [error] = arg;
        if (error && error.response && error.response.data) {
          let errorMessages = error.response.data.errorMessages || [];
          //console.log({ rejected: errorMessages.map((o) => o.errorMessage) });
          let message = errorMessages.map((o) => o.errorMessage || o).join(",");
          if (message) {
            alert(message);
          }
        }
        rej(...arg);
      };
      let customResolve = (...arg) => {
        loadObj.close(), res(...arg);
      };
      func(customResolve, customRej, loadObj);
    });
  },
};
