String.prototype.titleCase = function () {
  return this.split("-")
    .join(" ")
    .split("_")
    .join(" ")
    .split(" ")
    .map((word) => {
      return word
        .split("")
        .map((char, i) => {
          return i == 0 ? char.toUpperCase() : char.toLowerCase();
        })
        .join("");
    })
    .join(" ");
};

String.prototype.camel2Title = function () {
  return this.split("")
    .map((char, i) => {
      return char.charCodeAt(0) < 97
        ? " " + char
        : i == 0
          ? char.toUpperCase()
          : char;
    })
    .join("");
};

String.prototype.snake2camel = function () {
  let [first, ...rest] = this.split("_").map((char) => {
    let [first, ...rest] = char;
    return first.toUpperCase() + rest.join("");
  });
  return first.toLowerCase() + rest.join("");
};

String.prototype.dashed2camel = function (ignoreVar = false) {
  if (this.startsWith("--") && ignoreVar) { return this; }
  let dashedArr = this.split("-");
  if (dashedArr.length >= 2) {
    let [first, ...rest] = this.split("-").map((char) => {
      let [first, ...rest] = char;
      return first.toUpperCase() + rest.join("");
    });
    return first.toLowerCase() + rest.join("");
  } else {
    return this;
  }
};

String.prototype.chunk = function (num, rev = false) {
  if (rev) {
    return this.split("")
      .reverse()
      .chunk(num)
      .map((arr) => arr.reverse().join(""))
      .reverse();
  } else {
    return this.split("")
      .chunk(num)
      .map((arr) => arr.join(""));
  }
};

Number.prototype.cur = function (...arg) {
  return this.toString().cur(...arg);
};
String.prototype.cur = function (symbol = "inr") {
  let curArr = this.split(".");
  let arr = curArr[0].chunk(3, true);
  let last = arr.reverse()[0];
  let first = arr
    .slice(1, arr.length)
    .reverse()
    .join("")
    .chunk(2, true)
    .join(",");
  let cur = "";
  switch (symbol) {
    case "inr":
      cur = <>&#x20B9;</>;
      break;
    default:
      break;
  }
  return (
    <>
      {cur}{" "}
      {`${first ? first + "," : ""}${last}${curArr[1] ? "." + curArr[1] : ""}`}
    </>
  );
};

String.prototype.word = function (i = 0) {
  return this.split(",").join(" ").split(" ")[i];
};

String.prototype.url = function () {
  try {
    let str = this.includes("http") ? this : window.location.origin + this;
    let url = new URL(str);
    url.params = Object.fromEntries(url.searchParams.entries());
    return url;
  } catch (e) {
    return this;
  }
};

String.prototype.extractURL = function(idx=0) {
  let urls = decodeURI(this).match(/http(s?)\:[^\)'"]+/gi);
  if(urls) {
    return (idx===true)?urls:urls[idx];
  } else {
    return "";
  }
}

String.prototype.toVar = function () {
  return this.toLowerCase().split(" ").join("_").split("-").join("_");
};

String.prototype.parse = function (def = false) {
  try {
    return JSON.parse(this);
  } catch (error) {
    return def;
  }
};


String.prototype.svg2png = function (height=400,width=500,config=[0,0]) {
  return new Promise((resolve, reject) => {
    try {
      let [ sx, sy, sw, sh, dx, dy, dw, dh] = config;
      let canvas = document.createElement("canvas");
      canvas.width = sw;
      canvas.height = sh;
      let img = new Image;
      img.height = height;
      img.width = width;
      img.style.height = height+'px';
      img.style.width = width+'px';
      let ctx = canvas.getContext("2d");
      img.onload = function () {
        //ctx.drawImage(image,50,50,image.width,image.height,0,0,50,50);
        //console.log({imgLoad:this,config,ctx,canvas});
        //document.body.append(this);
        //document.body.append(canvas);
        if((!window.navigator.vendor)&&false) {
          resolve(this.src);
        } else {
          ctx.drawImage(this,...config);
          resolve(canvas.toDataURL())
        }
      }
      //document.body.append(img);
      // document.body.append(canvas);
      img.src = this;
    } catch (e) {
      reject(e);
    }
  });
}

String.prototype.img2base64 = String.prototype.img2canvas = function(operation) {
  return new Promise((resolve,reject)=> {
    try {
      let canvas = document.createElement("canvas");
      let img = new Image;
      let ctx = canvas.getContext("2d");
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        //document.body.append(canvas);
        if(typeof operation == "string") {
          ctx.filter = operation || ctx.filter;
          ctx.drawImage(this,0,0);
        } else if(typeof operation == "function"){
          ctx.drawImage(this,0,0);
          ctx = operation(ctx,img,canvas);
        }
        resolve(canvas.toDataURL())
      }
      //img.crossOrigin = null;
      img.crossOrigin = window.location.origin;
      //img.crossOrigin = 'Anonymous';
      img.src = this;
    } catch(e) {
      reject(e);
    }
  })
}



Number.prototype.toPx = String.prototype.toPx  =  function(){
  var div = document.createElement('div');
  div.style.display = 'block';
  div.style.height = this;
  document.body.appendChild(div);
  var px = parseFloat(window.getComputedStyle(div, null).height);
  div.parentNode.removeChild(div);
  return px;
}

String.prototype.dowload = function (fileName = "download") {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  let blob = new Blob([this], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
  a.id = "download";
  a.href = url;
  a.target = "_blank";
  a.download = fileName;
  a.click();
  a.remove()
  window.URL.revokeObjectURL(url);
}

String.prototype.number = function() {
  let match = this.match(/[+-]?\d+(\.\d+)?/g)[0];
  return match?Number(match):0;
}


String.prototype.toHTML = function() {
  let divEle = document.createElement("div");
  divEle.innerHTML = this;
  return divEle;
}

String.prototype.trimChar = function(charToRemove) {
  let string = this;
  while(string.charAt(0)==charToRemove) {
      string = string.substring(1);
  }
  while(string.charAt(string.length-1)==charToRemove) {
      string = string.substring(0,string.length-1);
  }
  return string;
}

String.prototype.pluck = function(index=0,delim='/') {
  return this.trimChar(delim).split(delim)[index]||'';
};

String.prototype.relativePaths = function() {
  return this.match(/\.\.?\/[^\n"?:*<>|]+\.[A-z0-9]+/gi);
}