import React from "react";
import style from "./Pages.module.css";
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import ShippingDetails from "../components/payment/checkout/ShippingDetails";

const ShipingDetailsPage = (props) => {
  return (
    <div className={style["container"]}>
      <Announcement />
      <Header />
      <ShippingDetails />
      <Footer />
    </div>
  );
};
export default connect(ShipingDetailsPage);
