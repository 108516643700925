import React, { useEffect, useRef, useState } from "react";
import mixins from "../../app-services/mixins";
import "./AppEditor.css";
import "./Cropper/cropper.min.css";
import "./Cropper/cropper.min.js";

import { useSelectionText } from "../../app-services/hooks/editorHooks";

function AppEditor(props) {
  const {
    onToolBar = () => ({}),
    hasToolBar = true,
    onMounted = () => { },
    children,
    onUpdate = () => ({}),
    style = {},
    data,
    readonly = false,
    pageref,
  } = props;
  const root = useRef(null);
  const editor = useRef(null);
  const [contentEditable, setContentEditable] = useState(false);
  const [fontFormat, setFontFormat] = useState("H2");
  const [activeImage, setActiveImage] = useState(null);
  const [cropMode, setCropMode] = useState(null);
  const [dragObj, setDragObj] = useState({ x: 0, y: 0 });
  const [tagType, setTagType] = useState("p");
  const tagTypes = ["p", "H1", "H2", "H3", "H4", "H5", "H6"];
  const [resizeStyle, setResizeStyle] = useState({});
  const computed = {
    style: {
      "--min-height": "calc(100% - 40px)",
      overflow: "hidden",
      position: "relative",
      ...style,
    },
  };
  const fonts = {
    p: [
      "Arial",
      "Baskerville",
      "Caslon",
      "Courier",
      "Georgia",
      "Gotham",
      "Helvetica",
      "Myriad Pro",
      "Rockwell",
      "Times-New-Roman",
    ],
    H2: [
      "Arial",
      "Abril-Fatface",
      "Amantic",
      "Berkshire Swash",
      "BP-Diet",
      "Baskerville",
      "Caslon",
      "Courier",
      "Cabinsketch",
      "Cherry-Swash",
      "Codystar",
      "EastMarket",
      "Elsie",
      "Eraser",
      "FFF-Tusj",
      "Georgia",
      "Gotham",
      "Grand-Hotel",
      "Good-Dog",
      "Helvetica",
      "Komika-Title",
      "League-Spartan",
      "Leckerli-One",
      "Libre-Casion",
      "Lobster-Two",
      "Londrina",
      "Milkshake",
      "Myriad Pro",
      "Ostrich-Sans",
      "Pacifico",
      "Pettingill-CF",
      "Playfair-Display",
      "Rockwell",
      "Ritaglio",
      "Roman-Caps",
      "SeasideResortNF",
      "Shortcut",
      "Sofia",
      "Trash-Hand",
      "Times-New-Roman",
      "WoodenNickelBlack",
    ],
  };

  let tempFontSizes = [];
  tempFontSizes.length = 24;
  tempFontSizes.fill("");
  tempFontSizes = tempFontSizes.map((v, i) => (i + 1) * 2);
  const fontSizes =
    fontFormat == "H2" ? tempFontSizes.slice(5) : tempFontSizes.slice(3, 7);
  const methods = {
    setTextBack(color) {
      document.execCommand("styleWithCSS", false, true);
      document.execCommand("backColor", false, color);
    },
    setColor(color) {
      document.execCommand("styleWithCSS", false, true);
      document.execCommand("foreColor", false, color);
    },
    applayFont(fontName) {
      document.execCommand("fontName", false, fontName);
    },
    applayFormat(format) {
      document.execCommand("formatBlock", false, format);
      setFontFormat(format);
    },

    initCropper(image) {
      let crop = new Cropper(image, {
        //aspectRatio: 4/4,
        crop: function (e) { },
      });
      setCropMode(crop);
      return crop;
    },
    rotateCanvas(canvas, degrees, currentImage) {
      let temp = canvas.height + canvas.width;
      let imageWidth = canvas.width;
      let ctx = canvas.getContext("2d");
      window.ctx = ctx;
      ctx.save(); //saves the state of canvas
      ctx.clearRect(0, 0, temp, temp); //clear the canvas
      canvas.width = canvas.height;
      canvas.height = imageWidth;

      ctx.translate(canvas.width / 2, canvas.height / 2); //let's translate
      ctx.rotate((Math.PI / 180) * degrees); //increment the angle and rotate the image
      ctx.translate(-canvas.height / 2, -canvas.width / 2);

      //ctx.translate(0, -currentImage.width); //let's translate
      ctx.drawImage(currentImage, 0, 0); //draw the image ;)

      ctx.restore(); //restore the state of canvas
      return canvas;
    },

    draw2canvas(strDataURI) {
      return new Promise((resolve) => {
        let myCanvas = document.createElement("canvas");
        let ctx = myCanvas.getContext("2d");
        let img = new Image();
        img.onload = function () {
          myCanvas.height = img.height;
          myCanvas.width = img.width;
          ctx.drawImage(img, 0, 0); // Or at whatever offset you like
          resolve(myCanvas);
        };
        img.src = strDataURI;
      });
    },
    removeImage() {
      const list = root.current.querySelectorAll(".ImageContainer");
      //console.log(list);
      for (const element of list) {
        element.remove();
      }
    },
    rotateImage(currentImage, deg = 90) {
      return new Promise((resolve, reject) => {
        methods
          .draw2canvas(currentImage && currentImage.getAttribute("src"))
          .then((canvas) => {
            canvas = methods.rotateCanvas(canvas, deg, currentImage);
            let rotatedImageURI = canvas.toDataURL();
            currentImage.setAttribute("src", rotatedImageURI);
            resolve(rotatedImageURI);
          })
          .catch(reject);
      });
    },

    initiateImageFetch() {
      root.current.querySelectorAll("img").forEach((img) => {
        img.onclick = (ev) => {
          setActiveImage(img);
        };
      });
    },
    SetupEditor() {
      root.current.parentNode.querySelectorAll("[data-edit]").forEach((btn) => {
        btn.onclick = (ev) => methods.edit(ev, btn);
      });
      if (!cropMode) {
        setContentEditable(!readonly);
      }
      onMounted({
        root,
        methods,
      });
      editor.current && editor.current.focus();
    },
    execSpan: function (attribute, size, unit = "") {
      let selectedText = document.getSelection().toString();
      if (selectedText.length) {
        let selection = document.getSelection();
        let parentTag = selection.anchorNode.parentNode;
        let fontParent =
          parentTag.tagName == "FONT" ? parentTag.parentElement : parentTag;
        let customfontEle = parentTag.querySelector("#customfont");
        customfontEle = parentTag.id == "customfont" ? parentTag : customfontEle;
        customfontEle =
          fontParent.id == "customfont" ? fontParent : customfontEle;
        let rangeCount = selection.rangeCount;
        let parentContainer = selection.getRangeAt(0).commonAncestorContainer;
        const updateWithSpan = function () {
          let spanTag = $("<span/>", { id: "customfont" })[0];
          spanTag.innerHTML =
            parentTag.tagName == "FONT"
              ? parentTag.outerHTML
              : parentTag.innerHTML;
          let styleObj = parentTag.style.getStyle();
          Object.map(styleObj, (val, key) => spanTag.style.setProperty(key, val));
          spanTag.style.setProperty(attribute, size + unit);
          let spanString = spanTag.outerHTML;
          let sizedFont = spanString;
          document.execCommand("insertHTML", false, sizedFont);
        };
        if (customfontEle) {
          customfontEle.style.setProperty(attribute, size + unit);
        }
        if (parentContainer) {
          let wholehtml = ``;
          let commonAncestorContainer = parentContainer;
          for (let i = 0; i < selection.rangeCount; i++) {
            commonAncestorContainer =
              selection.getRangeAt(i).commonAncestorContainer;
            if (commonAncestorContainer instanceof HTMLElement) {
              wholehtml += [...commonAncestorContainer.children]
                .map((o) => {
                  o.style.setProperty(attribute, size + unit);
                  return o.outerHTML;
                })
                .join("");
            }
          }
          if (commonAncestorContainer instanceof HTMLElement) {
            commonAncestorContainer.innerHTML = wholehtml;
          } else {
            updateWithSpan();
          }
        } else {
          updateWithSpan();
        }
      }
    },
    edit(ev, cmd) {
      let cmd_val = cmd;
      //ev.preventDefault();
      if (cmd instanceof HTMLElement) {
        cmd_val = cmd.getAttribute("data-edit").split(":");
      } else {
        cmd_val = cmd.split(":");
      }
      if (["image"].includes(cmd_val[0])) {
        let input = cmd.nextElementSibling;
        if (input) {
          input.click();
        }
      } else if (["rotate"].includes(cmd_val[0])) {
        methods.rotateImage(activeImage, 90);
      } else if (["crop"].includes(cmd_val[0])) {
        if (cropMode) {
          cropMode.element.setAttribute(
            "src",
            cropMode.getCroppedCanvas().toDataURL()
          );
          cropMode.destroy();
          setCropMode(false);
          setContentEditable(!readonly);
        } else {
          methods.initCropper(activeImage);
          setContentEditable(false);
        }
      } else if (["removeimg"].includes(cmd_val[0])) {
        methods.removeImage();
      } else {
        document.execCommand(cmd_val[0], true, cmd_val[1]);
      }
    },

    renderImage(ev) {
      let file = ev.target.files[0];
      if (file) {
        file.toBase64().then((datauri) => {
          let img = mixins.getImageNode(datauri);
          let focusNode = window.getSelection().focusNode;
          if (
            focusNode &&
            focusNode.parentNode &&
            editor.current != focusNode.parentNode &&
            focusNode != editor.current
          ) {
            focusNode.parentNode.appendChild(img);
          } else if (
            editor.current == focusNode.parentNode ||
            focusNode == editor.current
          ) {
            if (editor.current.children[0]) {
              editor.current.children[0].appendChild(img);
            } else {
              editor.current.appendChild(img);
            }
          }
          ev.target.value = "";
          setTimeout(() => {
            methods.initiateImageFetch();
          });
        });
      }
    },
  };

  useEffect(() => {
    methods.initiateImageFetch();
    methods.SetupEditor();
  });

  const getDataNode = (data) => {
    let divEle = document.createElement("div");
    divEle.innerHTML = data;
    return divEle;
  };

  useEffect(() => {
    if (data && editor) {
      let element = getDataNode(data.content);
      let node = element.querySelector("#editor-container");
      if (element) {
          let overlayData = element.querySelector("#overlay-data");
          if (overlayData) {
              let overlayEle = pageref.current.querySelector("#overlay-data");
              if (overlayEle && !overlayEle.innerHTML) {
                  overlayEle.innerHTML = overlayData.innerHTML;
              }
          }
      }
      if (node) {
        let resizenode = node.parentElement;
        if (resizenode) {
          let style = resizenode.style.getStyle();
          setResizeStyle(style);
        }
        editor.current.innerHTML = node.innerHTML;
      }
    }
  }, [data]);

  const ToolBar = () => {
    return (
      <div
        className={Object.className({
          "App-Editor-Toolbar": true,
          editButtons: true,
          editable: !readonly,
        })}
        style={{ display: "none" }}
      >
        <span title="STYLES">
          <button data-edit="bold" className="radiusopen" disabled={cropMode}>
            <i className="fa fa-bold"></i>
          </button>
          <button data-edit="italic" disabled={cropMode}>
            <i className="fa fa-italic"></i>
          </button>
          <button data-edit="underline" disabled={cropMode}>
            <i className="fa fa-underline"></i>
          </button>
          <button
            data-edit="strikeThrough"
            className="radiusclose"
            disabled={cropMode}
          >
            <i className="fa fa-strikethrough"></i>
          </button>
        </span>
        {/* <span>
                {
                    tagTypes.map((tag, i) => (
                        <button
                            disabled={cropMode}
                            key={i}
                            data-edit={"formatBlock:" + tag}
                            className={Object.className({
                                radiusopen: i == 0,
                                radiusclose: i == (tagTypes.length - 1)
                            })}
                        >
                            <b>{tag.toUpperCase()}</b>
                        </button>
                    ))
                }
            </span> */}
        <span>
          <select
            className="customSelectText"
            onChange={(e) => methods.applayFormat(e.target.value)}
            defaultValue={fontFormat}
          >
            <option disabled defaultValue={""}>
              Choose Text Format
            </option>
            <option value={"H2"}>Title Fonts</option>
            <option value={"p"}>Body Fonts</option>
          </select>
          <select
            className="customSelectText"
            onChange={(e) => methods.applayFont(e.target.value)}
          >
            <option disabled defaultValue={""}>
              Choose font
            </option>
            {fonts[fontFormat].map((font, i) => (
              <option key={i} value={font}>
                {font}
              </option>
            ))}
          </select>
          <select
            onChange={(e) =>
              methods.execSpan("font-size", e.target.value, `px`)
            }
            defaultValue={""}
            className="customSelectNum"
          >
            <option disabled defaultValue={""}>
              Choose Font Size
            </option>
            {fontSizes.map((size, i) => (
              <option key={i} value={size}>
                {size}
              </option>
            ))}
          </select>
          <select
            onChange={(e) => methods.execSpan("line-height", e.target.value)}
            defaultValue={""}
            className="customSelectNum"
          >
            <option disabled defaultValue={""}>
              Choose Line Spacing
            </option>
            <option value="1">1</option>
            <option value="1.5">1.5</option>
            <option value="2">2</option>
          </select>
          <button
            disabled={cropMode}
            data-edit=""
            className="radiusopen font-color"
            title="Font Color"
          >
            <small>
              <input
                type={"color"}
                style={{ height: "24px", width: "24px" }}
                onChange={(e) => methods.setColor(e.target.value, e.target)}
              ></input>
            </small>
          </button>
          <button
            disabled={cropMode}
            data-edit=""
            className="radiusclose back-color"
            title="Back Color"
          >
            <small>
              <input
                type={"color"}
                style={{ height: "24px", width: "24px" }}
                onChange={(e) => methods.setTextBack(e.target.value, e.target)}
              ></input>
            </small>
          </button>
        </span>

        {/* <span title="FONT SIZE">
                <button disabled={cropMode} data-edit="fontSize:1" className='radiusopen'>
                    <i className="fa fa-font" style={{ fontSize: 'small' }}></i>
                </button>
                <button disabled={cropMode} data-edit="fontSize:3">
                    <i className="fa fa-font"></i>
                </button>
                <button disabled={cropMode} data-edit="fontSize:5" className='radiusclose'>
                    <i className="fa fa-font" style={{ fontSize: 'large' }}></i>
                </button>
            </span> */}

        <span>
          <button
            disabled={cropMode}
            title="BULLETED LISTS"
            data-edit="insertUnorderedList"
            className="radiusopen"
          >
            <i className="fa fa-list-ul"></i>
          </button>
          <button
            disabled={cropMode}
            title="NUMBERED LISTS"
            data-edit="insertOrderedList"
            className="radiusclose"
          >
            <i className="fa fa-list-ol"></i>
          </button>
        </span>

        <span>
          <button
            disabled={cropMode}
            title="LEFT ALIGN"
            className="radiusopen"
            data-edit="justifyLeft"
          >
            <i className="fa fa-align-left"></i>
          </button>
          <button
            disabled={cropMode}
            title="CENTER ALIGN"
            data-edit="justifyCenter"
          >
            <i className="fa fa-align-center"></i>
          </button>
          <button
            disabled={cropMode}
            title="RIGHT ALIGN"
            className="radiusclose"
            data-edit="justifyRight"
          >
            <i className="fa fa-align-right"></i>
          </button>
        </span>

        <span>
          <button
            disabled={cropMode}
            data-edit="image"
            title="Upload Image"
            className="radiusopen"
          >
            <i className="fa fa-image" aria-hidden="true"></i>
          </button>
          <input
            type={"file"}
            style={{ display: "none" }}
            onChange={(e) => methods.renderImage(e)}
          ></input>
          {activeImage && (
            <>
              <button
                disabled={cropMode}
                data-edit="rotate"
                title="Rotate Image"
              >
                <i className="fa fa-undo" aria-hidden="true"></i>
              </button>
              <button
                data-edit="crop"
                title="Crop Image"
                className="radiusclose"
              >
                <i className="fa fa-crop" aria-hidden="true"></i>
              </button>
              <button
                disabled={cropMode}
                data-edit="removeimg"
                title="Remove Image"
                className="radiusclose"
              >
                <i className="fa fa-times"></i>
              </button>
            </>
          )}
        </span>

        <span title="CLEAR FORMATTING">
          <button disabled={cropMode} data-edit="removeFormat">
            <i className="fa fa-ban"></i>
          </button>
        </span>
      </div>
    );
  };
  onToolBar(ToolBar);

  const resize = {
    onStart(obj) {
      let isEditor = obj.target == root.current;
      if (dragObj.x == 0 || (dragObj.y == 0 && !readonly && isEditor)) {
        setDragObj({
          ...dragObj,
          x: obj.clientX,
          y: obj.clientY,
        });
      }
    },
    onProgress(obj) {
      let isEditor = obj.target == root.current;
      if (!readonly && isEditor) {
        let payload = {
          ...obj,
          dx: obj.clientX - dragObj.x,
          dy: obj.clientY - dragObj.y,
        };
        root.current.style.left = `${payload.dx}px`;
        root.current.style.top = `${payload.dy}px`;
      }
    },
    onEnd(obj) {
      let isEditor = obj.target == root.current;
      if (!readonly && isEditor) {
        let payload = {
          ...obj,
          dx: obj.clientX - dragObj.x,
          dy: obj.clientY - dragObj.y,
        };
        root.current.style.left = `${payload.dx}px`;
        root.current.style.top = `${payload.dy}px`;
      }
    },
  };

  const updateText = (htmlcontent, ev) => {
    //(ev.target.innerText.length);
    onUpdate(htmlcontent);
  };

  return (
    <>
      <div
        ref={root}
        className={Object.className({
          AppEditor: true,
          TextResizeConatiner: true,
        })}
        style={{ ...computed.style, ...resizeStyle }}
        tabIndex={0}
        onDragStart={(e) => resize.onStart(e)}
        onDrag={(e) => resize.onProgress(e)}
        onDragEnd={(e) => resize.onEnd(e)}
        draggable={true}
      >
        <div
          ref={editor}
          id="editor-container"
          className={Object.className({})}
          contentEditable={contentEditable}
          suppressContentEditableWarning={true}
          placeholder={"Edit here"}
          onInput={(ev) => {
            updateText(ev.target.innerHTML, ev);
          }}
        >
          <p>{children}</p>
        </div>
      </div>
      {hasToolBar && <ToolBar></ToolBar>}
    </>
  );
}

export default AppEditor;
