import React from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import Banner from "../components/home/banner/Banner";
import ProductList from "../components/product/productlist/ProductList";
import ContactUs from "../components/product/contactus/ContactUs";
import BannerNew from "../components/home/banner/BannerNew";
import Updatebanner from "../components/home/banner/bannernew/Banner";

const ProductListPage = (props) => {
    // console.log("ProductListPage props", props);

    //mixins.setStore("loading",true,props);
    return (
      <div className={style["container"]}>
        <Announcement />
        <Header current={props.location.pathname} />
        {/* <Banner productpage={true} /> */}
        <Updatebanner productpage={true} />
        <ProductList />
        <ContactUs />
        <Footer />
      </div>
    );
}
export default connect(ProductListPage);