import { Fragment, useEffect, useState, useRef } from "react";
import "../chapter.css";
const pageeight = (props) => {
    let pronoun = "";
    let his="";
    if(props.currentGender=='boy'){
        pronoun ="he";
        his="his";
    }
    else{
        pronoun ="she";
        his="her";
    }  
    return (
        <Fragment >
            <div className="pageformat height">
                <div className="text-center chapter-title">
                    {/* <h1>Chapter 4</h1> */}
                </div>
                <div className="text-center chapter-content">
                    <p>"It's okay," Snowy said. "Hello, {props.fname||'NAME'} and Bean. Nice to meet you."</p>
                    <p>"Nice to meet you too, Snowy," said {props.fname||'NAME'}. {pronoun} gasped and put {his} hand over {his} mouth. {pronoun} looked over at Bean, whose eyes widened.</p>
                    <p>Ellie realised what had happened. Because it was just then that she realised that she could understand what Snowy had said.</p>
                    <p>And also… also… {props.fname||'NAME'} and Bean seemed to have understood him too!</p>
                </div>
                <div className="page-number">
                    <span className="digite-number">8</span>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(pageeight);