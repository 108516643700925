import React, { Component, createRef } from "react";

export class VerifyOtpForm extends Component {
	constructor(props) {
		super(props);
		initialConstruct.bind(this)("VerifyOtpForm");
		this.validate = props.validate;
		this.submit = props.submit;
		this.setParent = props.setState;
		this.formref = props.formref;
		
		this.otpref = createRef();
	}
	handlenext(event) {
		if (event.target.value.length === 1) {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
	}
	setInput(value,index) {
		let otp = this.otpref.current.value.padEnd(4,'0').split('').slice(0,4);
		otp[index] = value;
		this.otpref.current.value = otp.join('');
	}
	render() {
		return (
			<form
				ref={this.formref}
				className="popuplogin"
				onSubmit={(e) => this.submit(e)}
			>
				<div className='title'>Verify your email</div>
                <div className='des'>Please fill the verification code We sent you in your email</div>
				<div className='form-input'>
					<input type='hidden' name="otp" ref={this.otpref}></input>
					<input type='text' className='input-small' maxlength="1" onKeyUp={this.handlenext} pattern="[0-9]" required onChange={(e) => {
						this.setInput(e.target.value,0)
					}} />
					<input type='text' className='input-small' maxlength="1" onKeyUp={this.handlenext} pattern="[0-9]" required onChange={(e) => {
						this.setInput(e.target.value,1)
					}} />
					<input type='text' className='input-small' maxlength="1" onKeyUp={this.handlenext} pattern="[0-9]" required onChange={(e) => {
						this.setInput(e.target.value,2)
					}} />
					<input type='text' className='input-small' maxlength="1" onKeyUp={this.handlenext} pattern="[0-9]" required onChange={(e) => {
						this.setInput(e.target.value,3)
					}} />
				</div>
				<div className="form-input forgetpassword">
					<label
						className="label"
						onClick={() => this.setParent({ form: "login" })}
					>
						Sign In
					</label>
				</div>
				<div className="form-input">
					<span style={{ fontWeight: "bold", color: "red", fontSize: "10px" }}>
						{this.props.store.errorMsg}<br/>{this.props.store.failureMsg}
					</span>
				</div>
				<div className="form-input">
					<button type="submit" className="button" id="verify">
						Verify
					</button>
				</div>
			</form>
		);
	}
}

export default VerifyOtpForm;
