import React, { Component } from "react";
import lock from "../../assets/images/lock.png";
import email from "../../assets/images/email.png";

export class SignInForm extends Component {
    constructor(props) {
        super(props);
        initialConstruct.bind(this)("SignInForm");
        this.validate = props.validate;
        this.submit = props.submit;
        this.setParent = props.setState;
        this.formref = props.formref;
        
    }

	render() {
		return (
			<form
				ref={this.formref}
				className="popuplogin"
				onSubmit={(e) => this.submit(e)}
			>
				<div className="title">Sign Up</div>
				<div className="form-input">
					<i className="fas fa-user icon"></i>
					<input
						type="text"
						className="input"
						placeholder="First Name*"
						name="firstName"
						required
					/>
				</div>
				<div className="form-input">
					<i className="fas fa-user icon"></i>
					<input
						type="text"
						className="input"
						placeholder="Last Name*"
						name="lastName"
						required
					/>
				</div>
				<div className="form-input">
					<img src={email} className="icon" />
					<input
						type="text"
						className="input"
						placeholder="Email Address"
						name="email"
						onChange={(e) => this.validate(e, "email", this.formref.current)}
						required
					/>
				</div>
				<div className="form-input">
					<img src={lock} className="icon" />
					<input
						type="password"
						className="input"
						placeholder="Password"
						name="password"
						onChange={(e) =>
							this.validate(e, "password", this.formref.current)
						}
						required
					/>
				</div>
				<div className="form-input">
					<img src={lock} className="icon" />
					<input
						type="password"
						className="input"
						placeholder="Confirm Password"
						onChange={(e) =>
							this.validate(e, "confirm_password", this.formref.current)
						}
						required
					/>
				</div>
				<div className="form-input">
					<span style={{ fontWeight: "bold", color: "red", fontSize: "10px" }}>
						{this.props.store.errorMsg}<br/>{this.props.store.failureMsg}
					</span>
				</div>
				<div className="form-input">
					<span>
						<input type="checkbox" className="checkbox" name="subscribe" />
						Treat your inbox: splendid discounts, new products and clever ideas.
					</span>
				</div>

				<div className="form-input">
					<button type="submit" className="button" id="signup">
						Sign In Now
					</button>
				</div>
				<div className="form-input">
					Already have an account? &nbsp;
					<label
						className="label"
						onClick={() => this.setParent({ form: "login" })}
					>
						Sign In
					</label>
				</div>
			</form>
		);
	}
}

export default SignInForm;
