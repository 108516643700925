import React, { useState, useRef, useEffect } from 'react';
import AppInput from "../../partials/AppInput";
import {
    RadioGroup,
    FormControlLabel,
    Radio
} from "@material-ui/core";
import Inputs from './Personalize/collectiveInputs';
import ScrollContainer from './ScrollContainer';
export default connect((props) => {
    let scrlheader = useRef(null);
    const { setAnswer, answer = {},answers=[], index, data, pos,setStep } = props;
    const handleFirstName = (event) => {
        if (event.key === " " && (!event.target.value || event.target.value[0] === " ")) {
            event.preventDefault();
          }
    }
    
    useEffect(() => {
        setTimeout(() => {
            scrlheader.current.parentElement.scrollLeft = scrlheader.current.offsetLeft - 110;
        });
    }, [index]);

    let Overlay = data.overlay || (()=>(""));
    return (
        <>
            <div className={"imagform " + props.className}>
                <div className="image-container">
                    <img src={data.image} className="imagform-img"></img>
                    <Overlay/>
                    {/* <ScrollContainer scrlref={scrlheader} >
                        {
                            (cprop) => ( */}
                                <div className="step-bars"   >
                                    {
                                        (data.formStep || []).map((obj, i) => (
                                            <div
                                                ref={((pos + i) < (pos + index)) ? scrlheader : null}
                                                className="step-bar"
                                                active={((pos + i) < (pos + index)).toString()}
                                                key={i}
                                                onClick={()=>setStep(i)}
                                                >
                                                {obj.label}
                                            </div>
                                        ))
                                    }
                                </div>
                            {/* )
                        }
                    </ScrollContainer> */}
                </div>
                <div className="bold-header title">{data.question}</div>
                <div className="formcollection">
                    <div className="row no-gutters">
                        {(data.collect || []).map((obj, i) => (
                            Inputs(obj, i, setAnswer, answer,answers,data.collect, handleFirstName)
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
});