import React, { useCallback, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Switch } from "@material-ui/core";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import "./cropper.css";

const ImageCropper = ({
  onCropImage,
  inputImg,
  imgName,
  closeModal,
  ratio,
}) => {
  const [crop, setCrop] = useState({ x: 100, y: 100 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [alignment, setAlignment] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(inputImg, croppedAreaPixels);
      onCropImage(
        new File([croppedImage], imgName, {
          type: "image/png",
          lastModified: new Date().getTime(),
        })
      );
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [croppedAreaPixels]);
  return (
    /* need to have a parent with `position: relative` 
    to prevent cropper taking up whole page */
    <div className={`cropper ${alignment ? "right":"left"}`}>
      <Cropper
        minZoom={0.4}
        image={inputImg}
        crop={crop}
        zoom={zoom}
        aspect={1}
        restrictPosition={false}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        style={{
          containerStyle: {
            width: 500,
            height: 500,
            position: "relative",
          },
        }}
        cropSize={{ width: 400, height: 400 }}
      />
      {/* <div className="justify-content-between text-center mt-3">
        <span>Left</span>
        <Switch
          color="primary"
          checked={alignment}
          onChange={(event) =>
            setAlignment(event.target.checked)
          }
        />
        <span>Right</span>
      </div> */}
      <div className="d-flex justify-content-between align-items-center mt-2">
        <button onClick={closeModal} className="btn-cancel">
          Cancel
        </button>
        <input
          type="range"
          defaultValue={zoom}
          value={zoom}
          max={3.2}
          min={ratio ? 0.4 : 0.6}
          step={0.1}
          onChange={(e) => setZoom(e.target.value)}
        />
        <button
          onClick={() => {
            showCroppedImage();
            closeModal();
          }}
          className="btn-save"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ImageCropper;
