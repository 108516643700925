import React, { useEffect, useState } from 'react';
import style from './Features.module.css'
const Features = () => {
    const [features, setFeatures] = useState([]);
    const getFeature = () => {
        api.getFeatures().then((res) => {
            if(res.statusCode===200){
                setFeatures(res.data);
            }
        });
    }
    useEffect(() => {
        getFeature();
    }, [])
    return (
        <div className={style['container']}>
           {/* {features && <div className={style['title']}>Why Pick ?</div>} */}
            <div className={style['row-container']}>
                {features&&features.filter((o)=>o.homepage == 1).map((data, index) => {return(
                    <div className={style[`features-container-${index+1}`]} key={index}>
                        <div className={style['feature-title']}>{data.header}</div>
                        <div className={style['feature-des']}>
                        {(data.description.length > 150) ? (data.description.slice(0, 150) + '......') : (data.description)}
                        
                        </div>
                    </div>
                )})}

            </div>
        </div>
    )
}
export default Features;