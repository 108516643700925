const state = {
    announcement:{},
    emits:{},
    menulist:[],
    timestamp: (new Date()).getTime(),
    loading:false,
    newbook:[],
    customCharacter:[],
    user:null,
    productCustomPayload:{},
    SelfPublishBook:{},
    handPrintBook:{},
    bookSize:{
        "chapter-book":{height:197,width:130,unit:'mm'},
        "customcharacter-book":{height:197,width:130,unit:'mm'},
        "picture-book":{height:200,width:200,unit:'mm'},
        "handprint-book":{height:200,width:200,unit:'mm'},
        "thumbprint-book":{height:197,width:130,unit:'mm'},
        "customtext-book":{height:197,width:130,unit:'mm'}
    },
    cart_list:[],
    book:{
        handprint:{},
        handprintPreInfo:{},
        handprintImages:[],

        thumbprint:{},
        thumbprintPreInfo:{},
        thumbprintImages:[],

        selfpublish:{
            picturebook:{},
            chapterbook:{}
        },
        customCharacter:{}
    },
    closablePreview:true,
    loadAPIs:{},
    sid:0,
    ...JSON.parse(localStorage.reduxStore || "{}"),
};
export default state;
