import React from "react";
import style from './Pages.module.css'
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import Preview from "../components/preview/Preview";
import CustomerReview from "../components/product/review/CustomerReview";
const PreviewPage = (props) => {
  return (
    <div className={style['container']}>
      <Announcement />
      <Header />
      <Preview />
      <CustomerReview/>
      <Footer />
    </div>)
}
export default connect(PreviewPage);

