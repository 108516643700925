import axios from "axios";

export default {
  // list selfpublish
  leastPopularBooks(data = {}) {
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .get(`${base}/api/poppular/list?` + Object.QueryString(data), {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          })
          .then((d) => {
            res(d.data);
          })
          .catch(rej);
      },
      true,
      "Getting least popular books.."
    );
  },

  // View author
  getAuthor(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .get(`${base}/api/author/view/${data}`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  // create author
  createAuthor(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/author/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },

  // delete author
  deleteAuthor(id) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .delete(`${base}/api/author/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  // Royalties Earned

  getRoyaltiesEarned() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/user/royaltyPoints`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },
  // redeem history

  getRedeemHistory() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/user/redeemData`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch(rej);
    }, false);
  },

  //   Books sold

  getBooksSold() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/user/royaltyPoints`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },

  //   most popular books

  getMostPopularBooks() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/user/royaltyPoints`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },

  //   Least popular books
  getLeastPopularBooks() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/user/royaltyPoints`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },

  //    royalty balance

  getRoyaltyBalance() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/user/royaltyPoints`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },
};
